import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '../conf';

@Injectable({
  providedIn: 'root'
})
export class TurnoService {
  private turnoSubject: BehaviorSubject<any>;
  public turno: Observable<any>;

  private cambioSubject: BehaviorSubject<any>;
  public cambio: Observable<any>;

  private cajaSubject: BehaviorSubject<any>;
  public caja: Observable<any>;

  private efectivoTurnoSubject: BehaviorSubject<any>;
  public efectivoTurno: Observable<any>;

  private cajasTurnoSubject: BehaviorSubject<any>;
  public cajasTurno: Observable<any>;

  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private configuration: ConfigurationService
  ) {
    this.turnoSubject = new BehaviorSubject(undefined);
    this.turno = this.turnoSubject.asObservable();

    this.cambioSubject = new BehaviorSubject(undefined);
    this.cambio = this.cambioSubject.asObservable();

    this.cajasTurnoSubject = new BehaviorSubject(undefined);
    this.cajasTurno = this.cajasTurnoSubject.asObservable();

    this.cajaSubject = new BehaviorSubject(undefined);
    this.caja = this.cajaSubject.asObservable();

    this.efectivoTurnoSubject = new BehaviorSubject(undefined);
    this.efectivoTurno = this.efectivoTurnoSubject.asObservable();
  }

  public get cajaValue() {
    return this.cajaSubject.value;
  }

  setCajaValue(caja) {
    this.cajaSubject.next(caja);
  }

  public get efectivoTurnoValue() {
    return this.efectivoTurnoSubject.value;
  }

  setEfectivoTurnoValue(efectivoTurno) {
    this.efectivoTurnoSubject.next(efectivoTurno);
    this.setCambioValue(efectivoTurno);
  }

  public get turnoValue() {
    return this.turnoSubject.value;
  }

  setTurnoValue(turno) {
    this.turnoSubject.next(turno);
  }

  public get cambioValue() {
    return this.cambioSubject.value;
  }

  setCambioValue(valor) {
    
    if(valor)
    { 
      let cambio = {
      cambioInicial : valor.cambioInicial || valor.ImporteInicial || '0',
      cambioIntroducido: valor.cambioIntroducido || valor.ImporteIntroducido || '0',
      ventasEfectivo:  valor.ventasEfectivo || '0',
      cambioRetirado: valor.CambioRetirado || valor.ImporteRetirado || '0',
      cambioResultante: valor.ImporteTotalCaja || undefined

    };  

    this.recalcularCambioResultante(cambio);

    }else{
      this.cambioSubject.next(valor);
    }

  }

  public get cajasValue() {
    return this.cajasTurnoSubject.value;
  }

  setCajasValue(cajas) {
    return this.cajasTurnoSubject.next(cajas);
  }

  recalcularCambioResultante(cambio) {
    if(cambio.cambioResultante){
    } else {
      cambio.cambioResultante = 
      Number.parseFloat((cambio.cambioInicial).replace(',', '.'));
         +
        Number.parseFloat((cambio.cambioIntroducido).replace(',', '.'));
         +
        Number.parseFloat((cambio.ventasEfectivo).replace(',', '.'));
         +
        Number.parseFloat((cambio.cambioRetirado).replace(',', '.'));
        ;}
    
    this.cambioSubject.next(cambio);
  }

  getTurnoAbierto(
    idUsuario: string,
    idTpv: string = '0',
    fecha = moment().format('YYYY/MM/DD')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TpvId: idTpv,
      UsuarioId: idUsuario,
      FechaBusqueda: fecha
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/GetTurnoAbierto`,
      body
    );
  }

  insertarTurno(
    idUsuario: string,
    idDivisa: string,
    idTpv: string,
    bolsaDeCambio: string,
    fecha = moment().format('YYYY/MM/DD HH:mm:ss')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      UsuarioId: idUsuario,
      FechaHoraInicio: fecha,
      DivisaId: idDivisa,
      TpvId: idTpv,
      TurnoCerrado: 0,
      BolsaCambio: bolsaDeCambio
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsResumenTurno`,
      body
    );
  }

  insertarCajasTurno(
    idTurno: number,
    cantidad: number,
    idDivisa: number,
    mediosDePago: string[],
    cambioDivisa: number
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const peticiones = mediosDePago.map(medioDePago => {
      const body = {
        ConexionIacpos: conexionId,
        TurnoId: idTurno,
        Cantidad: cantidad,
        DivisaId: idDivisa,
        MedioPago: medioDePago,
        CambioDivisa: cambioDivisa,
        DivisaRefId: idDivisa
      };
      return this.http.post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsCajasTurno`,
        body
      );
    });
    return forkJoin(peticiones);
  }

  // tslint:disable-next-line:max-line-length
  insertarOperacionesDeCaja(
    idUsuario: number,
    idTurno: number,
    comentario: string,
    cantidad: number,
    idDivisa: number,
    tipoOperacion: string,
    bolsaCambio: string,
    fecha = moment().format('YYYY/MM/DD HH:mm:ss')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      UsuarioId: idUsuario,
      TurnoId: idTurno,
      Comentario: comentario,
      Cantidad: cantidad,
      DivisaId: idDivisa,
      TipoOperacion: tipoOperacion,
      BolsaCambio: bolsaCambio,
      FechaHora: fecha
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsOperacionesCaja`,
      body
    );
  }

  getCajasTurno(idTurno: any, idDivisa: number, mediosDePago: string[]) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const peticiones = mediosDePago.map(medioDePago => {
      const body = {
        ConexionIacpos: conexionId,
        TurnoId: idTurno,
        DivisaId: idDivisa,
        FOP: medioDePago
      };
      return this.http.post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/GetCajasTurno`,
        body
      );
    });
    return forkJoin(peticiones);
  }

  getOperacionesCajaMetalico(idTurno: number) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TurnoId: idTurno
    };
    return this.http.post(
      `${this.config.getConfig(
        'API_URL'
      )}api/VentaTaquilla/GetOperacionesCajaMetalicoTurno`,
      body
    );
  }

  finalizarTurno(
    idTurno: number,
    totalCaja: number,
    bolsaCambio: string,
    fecha = moment().format('YYYY/MM/DD HH:mm:ss')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TurnoId: idTurno,
      TotalCajaManual: totalCaja,
      BolsaCambio: bolsaCambio,
      FechaHoraFin: fecha
    };
    return this.http.post(
      `${this.config.getConfig(
        'API_URL'
      )}api/VentaTaquilla/UpdResumenTurnosFin`,
      body
    );
  }

  abrirCaja(idTpv, idUser, idDivisa, idDivisaRef, cambioInicial, desglose) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TpvId: idTpv,
      FechaBusqueda: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: idUser,
      DivisaId: idDivisa,
      DivisaRefId: idDivisaRef,
      BolsaCambio: '',
      ImporteInicial: cambioInicial.replace(',','.'),
      CambiosDesglosados: desglose
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaApertura`,
      body
    );
  }

  consultarEfectivoCaja() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId
    };
    return this.http.post(
      `${this.config.getConfig(
        'API_URL'
      )}api/VentaTaquilla/CajaConsultaEfectivo`,
      body
    );
  }

  arqueoParcial(importe, comentario) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'AP'
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  retirarEfectivo(importe, comentario, desglose?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'CO',
      CambiosDesglosados: desglose || []
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  ingresarEfectivo(importe, comentario, desglose? ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'CH',
      CambiosDesglosados: desglose || []
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  cerrarSesion() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: '',
      Comentario: '',
      TurnoId: this.turnoValue.pkid
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  cerrarCaja(importe, comentario, desglose?) {

    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'CC',
      CambiosDesglosados: desglose || []
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  generarInformeArqueo(cajaId) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: cajaId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Informes/CajaInformeArqueo`,
      body
    );
  }

  generarInformeCierre(cajaId) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: cajaId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Informes/CajaInformeCierre`,
      body
    );
  }

  eliminaReservaAforoCompleto() {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      SesionInternetId: identificadorUnico,
      Accion: 0,
      RecintosSesionesId: ''
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/CancelarReservaAforo`,
      body
    );
  }

  getDatosTPVPath(){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    //obtenemos el tpvPath de la aplicacion
    this.configuration.getTPVPathLocal();
    //asignamos el tpv obtenido desde el servicio
    const config = this.configuration.tpvPathLocalValue;
    //const config = this.config.getConfig('TPV_PATH');
    return this.http.post(`${this.config.getConfig('API_URL')}api/Configuracion/GetDatosTPVPath`,
        {
          ConexionIacpos: conexionId,
          VMPath: config
        }
      )
      .pipe(map((TPVPath: any) => {
        this.configuration.setDatosTPVPathValue(
          TPVPath.DatosResult
        );
        // return TPVPath.DatosResult;
      }))
  }

  updateTPVEnUso(vmPath: string, enUso: string): Observable<any> {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      VMPath: vmPath,
      EnUso: enUso ? enUso : 0
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/UpdTPVEnUso`,
      body
    );
  }

  getTPVenUso(){
    if(!this.configuration.tpvFisicoValue){this.configuration.getTPVEnUso(this.configuration.tpvPathLocalValue).subscribe();}
  }
 
}
