import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { DesglosarCambioService } from 'src/app/modules/gestionar/services/desglosar-cambio.service';
import { PrintService } from 'src/app/shared/services/print.service';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { VisorService } from 'src/app/shared/services/visor.service';
import { TokenManagerService } from '.';
import { ConfigurationService } from '..';
import { ErrorToast } from '../toast/error.toast';
import { InfoToast } from '../toast/info.toast';
import { SuccessToast } from '../toast/success.toast';
import { User } from '../user.model';
import { TurnoService } from './turno.service';
import { UserManagerService } from './user-manager.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private logingSubject: BehaviorSubject<boolean>;
  public loging$: Observable<boolean>;

  private cambiarPassSubject: BehaviorSubject<boolean>;
  public cambiarPass$: Observable<boolean>;

  private pedirCodigoAuthSubject: BehaviorSubject<boolean>;
  public pedirCodigoAuth$: Observable<boolean>;

  private usuarioSubject: BehaviorSubject<any>;
  public usuario$: Observable<any>;

  private contrasenaSubject: BehaviorSubject<any>;
  public contrasena$: Observable<any>;

  private importeInicialSubject: BehaviorSubject<any>;
  public importeInicial$: Observable<any>;

  private usuarioIdSubject: BehaviorSubject<any>;
  public usuarioId$: Observable<any>;

  private tpvPathSubject: BehaviorSubject<any>;
  public tpvPath$: Observable<any>;

  private ventanaOrigenSubject: BehaviorSubject<any>;
  public ventanaOrigen$: Observable<any>;

  window = window as any;

  constructor(
    private configuration: ConfigurationService,
    private turnoService: TurnoService,
    private userService: UserManagerService,
    private toastr: ToastrService,
    private config: AppConfiguration,
    private http: HttpClient,
    private tokenManager: TokenManagerService,
    private translate: TranslateService,
    private visor: VisorService,
    private sidebarNav: SidebarNavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private printService: PrintService,
    private desgloseService: DesglosarCambioService
  ) {
    this.logingSubject = new BehaviorSubject(undefined);
    this.loging$ = this.logingSubject.asObservable();

    this.cambiarPassSubject = new BehaviorSubject(undefined);
    this.cambiarPass$ = this.cambiarPassSubject.asObservable();

    this.pedirCodigoAuthSubject = new BehaviorSubject(undefined);
    this.pedirCodigoAuth$ = this.pedirCodigoAuthSubject.asObservable();

    this.usuarioSubject = new BehaviorSubject(undefined);
    this.usuario$ = this.usuarioSubject.asObservable();

    this.contrasenaSubject = new BehaviorSubject(undefined);
    this.contrasena$ = this.contrasenaSubject.asObservable();

    this.importeInicialSubject = new BehaviorSubject(undefined);
    this.importeInicial$ = this.importeInicialSubject.asObservable();

    this.usuarioIdSubject = new BehaviorSubject(undefined);
    this.usuarioId$ = this.usuarioIdSubject.asObservable();

    this.tpvPathSubject = new BehaviorSubject(undefined);
    this.tpvPath$ = this.tpvPathSubject.asObservable();

    this.ventanaOrigenSubject = new BehaviorSubject(undefined);
    this.ventanaOrigen$ = this.ventanaOrigenSubject.asObservable();
  }

  public get tpvPathValue() {
    return this.tpvPathSubject.value;
  }
  public setTpvPath(path) {
    this.tpvPathSubject.next(path);
  }

  public get logingValue() {
    return this.logingSubject.value;
  }

  public setLoging(valor) {
    this.logingSubject.next(valor);
  }

  public get cambiarPassValue() {
    return this.cambiarPassSubject.value;
  }

  public setCambiarPass(valor) {
    this.cambiarPassSubject.next(valor);
  }

  public get pedirCodigoAuthValue() {
    return this.pedirCodigoAuthSubject.value;
  }

  public setPedirCodigoAuth(valor) {
    this.pedirCodigoAuthSubject.next(valor);
  }

  public get usuarioValue() {
    return this.usuarioSubject.value;
  }
  public setUsuario(valor) {
    this.usuarioSubject.next(valor);
  }

  public get contrasenaValue() {
    return this.contrasenaSubject.value;
  }
  public setContrasena(valor) {
    this.contrasenaSubject.next(valor);
  }
  public get importeInicialValue() {
    return this.importeInicialSubject.value;
  }
  public setImporteInicial(valor) {
    this.importeInicialSubject.next(valor);
  }

  public get usuarioIdValue() {
    return this.usuarioIdSubject.value;
  }
  public setUsuarioId(valor) {
    this.usuarioIdSubject.next(valor);
  }

  public get ventanaOrigenValue() {
    return this.ventanaOrigenSubject.value;
  }

  public setVentanaOrigen(ventana) {
    this.ventanaOrigenSubject.next(ventana);
  }

  cargarDatosLogin(
    Usuario,
    Contrasena: string,
    cambioInicial,
    codigo?,
    tokenOrigen?
  ) {
    this.setCambiarPass(false);
    this.setLoging(true);
    return this.login(Usuario, Contrasena, codigo, tokenOrigen)
      .pipe(
        mergeMap((user) => {
          if (!user) {
            this.setLoging(false);
            return of(null);
          } else {
            this.toastr.success(
              this.translate.instant('USUARIOAUTENTIFICADO'),
              '',
              {
                toastComponent: SuccessToast,
              }
            );
            const cambiarAConectado = user.Conectado === 'False' ? true : false;
            localStorage.setItem('currentUser', JSON.stringify(Usuario));
            localStorage.setItem('currentPwd', JSON.stringify(Contrasena));
            this.setPedirCodigoAuth(false);
            return this.userService
              .updateUsuarioConectado(user.Id, cambiarAConectado)
              .pipe(map(() => user));
          }
        })
      )
      .pipe(
        mergeMap((user) => {
          if (!user) {
            return of(null);
          } else {
            let desglose = [];
            if (
              this.configuration.appConfig.value.DatosResult
                .AppConfigJsonTurno &&
              this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno
                .TurnoInicioDesgloseMoneda == '1'
            ) {
              desglose = this.desgloseService.listadoBilletesMonedasValue;
              cambioInicial = this.desgloseService
                .getTotalDesglose()
                .toString();
            }
            const idTpv = this.configuration.datosTPVPathValue.pkId;
            const idDivisaRef = this.configuration.datosInicioValue.DivisaRefId;
            return this.turnoService
              .abrirCaja(
                idTpv,
                user.Id,
                idDivisaRef,
                idDivisaRef,
                cambioInicial,
                desglose
              )
              .pipe(map((res: any) => res.DatosResult));
          }
        })
      )
      .pipe(
        mergeMap((turno) => {
          if (!turno) {
            return of(null);
          } else {
            const caja = {
              CajaCodigo: turno.CajaCodigo,
              CajaId: turno.CajaId,
            };
            if (!this.configuration.tpvPathLocalValue) {
              this.configuration.getTPVPathLocal();
            }
            this.turnoService
              .updateTPVEnUso(this.configuration.tpvPathLocalValue, '1')
              .subscribe();
            this.turnoService.setCajaValue(caja);
            this.turnoService.setCajasValue(turno.ListadoCajasTurno);
            this.turnoService.setTurnoValue(turno.TurnoInfo);
            this.turnoService.setEfectivoTurnoValue(turno.TurnoEfectivo);
            this.desgloseService.setListadoBilletesMonedas(undefined);
            this.setLoging(false);
            localStorage.setItem('caja', JSON.stringify(caja));
            localStorage.setItem(
              'listadoCajas',
              JSON.stringify(turno.ListadoCajasTurno)
            );
            localStorage.setItem('turno', JSON.stringify(turno.TurnoInfo));
            localStorage.setItem('claveCentro', this.configuration.datosTPVPathValue.ClaveCentro);
            localStorage.setItem('TpvId', turno.TurnoInfo.TpvId);
            localStorage.setItem(
              'efectivoTurno',
              JSON.stringify(turno.TurnoEfectivo)
            );
            if (
              !this.configuration.datosTPVPathValue.CajaActivaDatos &&
              this.configuration.appConfig.value.DatosResult
                .AppConfigJsonTurno &&
              this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno
                .TurnoInicioImprimirInforme &&
              this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno
                .TurnoInicioImprimirInforme == '1'
            )
              this.printService.printCashOpeningReport(turno);
            return this.turnoService.eliminaReservaAforoCompleto();
          }
        })
      );
  }

  getInitData() {
    const traerConexionPrivadaBody = {
      chClaveEmpresa: this.config.getConfig('chClaveEmpresa'),
      chNombreProyecto: this.config.getConfig('chNombreProyecto'),
      chEntornoConexion: this.config.getConfig('chEntornoConexion'),
      chOrigenConexion: this.config.getConfig('chOrigenConexion'),
    };

    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/TraerConexionPrivada`,
        traerConexionPrivadaBody
      )
      .pipe(
        mergeMap((data: any) => {
          this.tokenManager.currentConexionIdSubject.next(data.pkid);
          localStorage.setItem('conexionId', JSON.stringify(data.pkid));
          return this.tokenManager
            .login(
              // this.config.getConfig('USER'),
              // this.config.getConfig('PASSWORD')
              this.config.getConfig('NUMSERIE')
            )
            .pipe(
              map((res) => {
                //obtenemos el tpvPath de la aplicacion
                this.configuration.getTPVPathLocal();
                //asignamos el tpv obtenido desde el servicio
                let config = this.configuration.tpvPathLocalValue;
                //comprobamos si el tpv esta en uso para cargar los disponibles si no lo esta
                if (!this.configuration.tpvFisicoValue) {
                  this.configuration.getTPVEnUso(config).subscribe();
                }
                //  if (
                //    this.window.external &&
                //    typeof this.window.external.obtenerEquipo === 'function'
                //  ) {
                //    config = this.window.external.obtenerEquipo();
                //  } else if (this.route.snapshot.queryParams.path) {
                //    config = this.route.snapshot.queryParams.path;
                //  } else if(this.tpvPathValue){
                //     config = this.tpvPathValue;
                //  }
                //  else {
                //    config = this.config.getConfig('TPV_PATH');

                //  }
                localStorage.setItem('currentPath', JSON.stringify(config));
                return this.http
                  .post(
                    `${this.config.getConfig(
                      'API_URL'
                    )}api/Configuracion/GetDatosTPVPath`,
                    {
                      ConexionIacpos: data.pkid,
                      VMPath: config,
                    }
                  )
                  .pipe(
                    map((TPVPath: any) => {
                      this.configuration.setDatosTPVPathValue(
                        TPVPath.DatosResult
                      );
                      return TPVPath.DatosResult;
                    })
                  );
              })
            )
            .pipe(
              mergeMap((res: any) => {
                return this.configuration.getAppConfig(res.pkId).pipe(
                  map(() => {
                    return res;
                  })
                );
              })
            )
            .pipe(
              mergeMap((res) => {
                return this.configuration.getModulos().pipe(
                  map((modulos) => {
                    this.sidebarNav.setSecciones(modulos);
                    return res;
                  })
                );
              })
            )
            .pipe(
              mergeMap(() => {
                return this.http
                  .post(
                    `${this.config.getConfig(
                      'API_URL'
                    )}api/Configuracion/GetDatosInicio`,
                    {
                      ConexionIacpos: data.pkid,
                    }
                  )
                  .pipe(
                    mergeMap((res: any) => {
                      this.visor.setToBlank();
                      return this.http
                        .post(
                          `${this.config.getConfig(
                            'API_URL'
                          )}api/Maestros/GetDivisas`,
                          {
                            ConexionIacpos: data.pkid,
                            ISO: res.DatosResult.DivisaISO,
                          }
                        )
                        .pipe(
                          map((infoDivisa: any) => {
                            this.configuration.getInternetConfig().subscribe();
                            this.configuration.setDatosInicioValue(
                              res.DatosResult
                            );
                            this.configuration.setDivisasValue(
                              infoDivisa.DatosResult.ListaDivisas
                            );
                            this.translate.setLocale(res.DatosResult.Idioma);
                            this.translate.use(res.DatosResult.Idioma);
                            this.configuration.dataReady.next(true);
                          })
                        );
                    })
                  );
              })
            );
        })
      );
  }

  CargarDatosTurno(tokenOrigen: string) {
    let user = this.login(
      JSON.parse(localStorage.getItem('currentUser')),
      JSON.parse(localStorage.getItem('currentPwd')),
      null,
      tokenOrigen
    ).subscribe();
    this.turnoService.setCajaValue(JSON.parse(localStorage.getItem('caja')));
    this.turnoService.setCajasValue(
      JSON.parse(localStorage.getItem('listadoCajas'))
    );
    this.turnoService.setTurnoValue(JSON.parse(localStorage.getItem('turno')));
    this.turnoService.setEfectivoTurnoValue(
      JSON.parse(localStorage.getItem('efectivoTurno'))
    );
  }

  actualizarDatosTPVPath(VMPath) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/Configuracion/GetDatosTPVPath`,
        {
          ConexionIacpos: conexionId,
          VMPath: VMPath,
        }
      )
      .pipe(
        map((TPVPath: any) => {
          this.configuration.setDatosTPVPathValue(TPVPath.DatosResult);
          return TPVPath.DatosResult;
        })
      )
      .pipe(
        mergeMap((res: any) => {
          return this.configuration.getAppConfig(res.pkId).pipe(
            map(() => {
              return res;
            })
          );
        })
      );
  }

  login(
    nombre: string,
    contrasena: string,
    codigo?: string,
    tokenOrigen?: string
  ): Observable<User> {
    this.setContrasena(contrasena);
    this.setUsuario(nombre);
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      NombreUsuario: nombre,
      ClaveUsuario: contrasena,
      CodigoAuth: codigo ? codigo : undefined,
      TokenOrigen: tokenOrigen ? tokenOrigen : undefined,
      ClaveCentro: this.configuration.datosTPVPathValue.ClaveCentro,
      AplicacionCod: 'PVKORE',
    };
    return (
      this.http
        .post(`${this.config.getConfig('API_URL')}api/Usuarios`, body)
        //// TODO cerrar turno cuando viene desde cambio de turno
        // .pipe(
        //   map((user:any) => {
        //     if(user.DatosResult && user.Mensajes.length == 0){
        //       if(this.ventanaOrigenValue == 'cambioUsuario')
        //       {
        //         return this.turnoService.cerrarSesion().pipe(map(() => user));
        //       }
        //     }
        //     return user;
        //   }))

        .pipe(
          map((res: any) => {
            let mensaje;
            let titulo;
            const user = <User>res.DatosResult;
            if (
              res.Mensajes &&
              res.Mensajes.length > 0 &&
              res.Mensajes[0].CodigoMensaje
            ) {
              titulo = this.translate.instant('CREDENCIALES_INVALIDAS');
              if (res.Mensajes[0].CodigoMensaje == '-5') {
                mensaje = this.translate.instant('USUARIOBLOQUEADO');
              } else if (res.Mensajes[0].CodigoMensaje == '-11') {
                mensaje = this.translate.instant('CODIGOAUTHINCORRECTO');
              } else if (res.Mensajes[0].CodigoMensaje == '-13') {
                titulo = this.translate.instant('NO_PERMISOS');
                mensaje = this.translate.instant('USUARIO_PERMISOS');
                this.router.navigate(['login']);
              } else if (res.Mensajes[0].CodigoMensaje == '10') {
                this.setPedirCodigoAuth(true);
                this.router.navigate(['codigoAuth']);
                this.toastr.info(
                  this.translate.instant('NECESARIOCODIGOCORRREO'),
                  this.translate.instant('CODIGO_AUTENTICACION'),
                  {
                    toastComponent: InfoToast,
                    timeOut: 5000,
                  }
                );
                return user;
              } else if (res.Mensajes[0].CodigoMensaje == '-12') {
                mensaje = this.translate.instant('USUARIOINCORRECTO');
              } else {
                mensaje = this.translate.instant('USUARIOINCORRECTO');
              }
              this.toastr.error(mensaje, titulo, {
                toastComponent: ErrorToast,
                timeOut: 10000,
              });
            } else {
              this.userService.setCurrentUser(user);
            }
            return user;
          })
        )
    );
  }

  comprobarUsuario(nombre: string, contrasena: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      NombreUsuario: nombre,
      ClaveUsuario: contrasena,
      ClaveCentro: this.configuration.datosTPVPathValue.ClaveCentro,
      AplicacionCod: 'PVKORE',
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/Usuarios`, body)
      .pipe(
        map((res: any) => {
          if (res.Mensajes && res.Mensajes[0]) {
            return res;
          }
          return false;
        })
      );
  }
}
