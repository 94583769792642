import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  transform(value: any, formato: string, locale: string): any {
    return moment(value, formato).locale(locale).format('L HH:mm');
  }

}
