import { Injectable } from '@angular/core';
import { AppConfiguration } from 'src/app/app.configuration';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  map,
  tap,
} from 'rxjs/operators';
import { TurnoService, ConfigurationService } from 'src/app/core';
import * as moment from 'moment';
import { Reserva } from 'src/app/modules/localizador/models/reserva.model';
import { environment } from 'src/environments/pedrera/environment.development-pedrera';

@Injectable({
  providedIn: 'root',
})
export class LocalizadorService {
  private fechaSeleccionadaSubject: BehaviorSubject<any>;
  public fechaSeleccionada: Observable<any>;

  private queryLocalizadorSubject: BehaviorSubject<any>;
  public queryLocalizador$: Observable<any>;

  private localizadorSubject: BehaviorSubject<any>;
  public localizador: Observable<any>;

  private localizadorReservaSubject: BehaviorSubject<any>;
  public localizadorReserva: Observable<any>;

  private motivosLocalizadorSubject: BehaviorSubject<any>;
  public motivosLocalizador: Observable<any>;

  private localizadoresSubject: BehaviorSubject<any>;
  public localizadores: Observable<any>;

  loadingBuscador = false;
  noSearch = true;
  entradasHolder;

  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private turno: TurnoService,
    private configuration: ConfigurationService
  ) {
    
    this.fechaSeleccionadaSubject = new BehaviorSubject(moment());
    this.fechaSeleccionada = this.fechaSeleccionadaSubject.asObservable();

    this.queryLocalizadorSubject = new BehaviorSubject('');
    this.queryLocalizador$ = this.queryLocalizadorSubject.asObservable();

    this.localizadorSubject = new BehaviorSubject(undefined);
    this.localizador = this.localizadorSubject.asObservable();

    this.localizadorReservaSubject = new BehaviorSubject(undefined);
    this.localizadorReserva = this.localizadorSubject.asObservable();

    this.localizadoresSubject = new BehaviorSubject(undefined);
    this.localizadores = this.localizadoresSubject.asObservable();

    this.motivosLocalizadorSubject = new BehaviorSubject(undefined);
    this.motivosLocalizador = this.motivosLocalizadorSubject.asObservable();
  }

  public get queryLocalizador() {
    return this.queryLocalizadorSubject.value;
  }

  setQueryLocalizador(query) {
    this.queryLocalizadorSubject.next(query);
  }

  public get fechaSeleccionadaValue() {
    return this.fechaSeleccionadaSubject.value;
  }

  setFechaSeleccionadaValue(fecha) {
    this.fechaSeleccionadaSubject.next(fecha);
  }

  public get localizadorValue() {
    return this.localizadorSubject.value;
  }

  setLocalizadorValue(localizador) {
    this.localizadorSubject.next(localizador);
  }

  public get localizadorReservaValue() {
    return this.localizadorSubject.value;
  }

  setLocalizadorReservaValue(localizador) {
    this.localizadorSubject.next(localizador);
  }

  public get localizadoresValue() {
    return this.localizadoresSubject.value;
  }

  setLocalizadoresValue(localizadores) {
    this.localizadoresSubject.next(localizadores);
  }

  public get motivosLocalizadorValue() {
    return this.motivosLocalizadorSubject.value;
  }

  setMotivosLocalizadorValue(motivos) {
    this.motivosLocalizadorSubject.next(motivos);
  }

  buscar(terms: Observable<any>, fecha) {
    return terms
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .pipe(switchMap((query) => this.buscarReservas(query)));
  }

  buscarReservas(query) {
    if (!query.localizador || query.localizador.length < 3) {
      return of([]);
    }
    if (!query.fecha) {
      query.fecha = undefined;
    } else {
      query.fecha = moment(query.fecha, 'L').format('YYYY/MM/DD');
    }
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Usuario: this.turno.turnoValue.NombreUsuario,
      Password: '',
      TipoUsuario: 0,
      Filtro: query.localizador,
      FechaDesdeVisita: query.fecha,
      FechaHastaVisita: query.fecha,
      OrdenarPor: 'localizador',
      DireccionOrden: 'ASC',
      IncluirEstado: '1',
      CentrosNombres: query.filtrarCentro? this.configuration.datosTPVPathValue.ClaveCentro : ''
    };

    this.loadingBuscador = true;
    this.noSearch = false;
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/BuscadorReservas`, body)
      .pipe(
        map((res: any) => {
          this.loadingBuscador = false;
          if (!res.DatosResult) {
            return [];
          }
          return res.DatosResult.Reservas;
        })
      );
  }

  /*   getReserva(localizador: string, numeroPedido: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      NumeroPedido: numeroPedido,
      Localizador: localizador,
      Usuario: this.turno.turnoValue.NombreUsuario,
      Password: '',
      TipoUsuario: 0
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/TraerReserva`, body).pipe(
      map((res: any) => {
        if (!res.DatosResult) {
          return {};
        }
        return res.DatosResult;
      })
    );
  } */

  getDatosAdicionalesReserva(localizador: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Usuario: this.turno.turnoValue.NombreUsuario,
      Password: '',
      TipoUsuario: 0,
      Localizador: localizador,
      RegistrosUnitario: 1,
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosReserva`, body)
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return {};
          }
          let reserva: Reserva = res.DatosResult;
          if (reserva.ReservaItems && reserva.ReservaItems.length > 0) {
            if (reserva.ReservaItems[0].TipoProducto == '2') {
              if (
                reserva.ReservaItems[0].PromocionEntradas &&
                reserva.ReservaItems[0].PromocionEntradas.length > 0 &&
                reserva.ReservaItems[0].PromocionEntradas[0].PosicionesVenta &&
                reserva.ReservaItems[0].PromocionEntradas[0].PosicionesVenta
                  .length > 0 &&
                +reserva.ReservaItems[0].PromocionEntradas[0].PosicionesVenta[0]
                  .PosicionVenta > 0
              )
                reserva.Agrupada = '1';
            } else {
              //tiene = reserva.reservaItems[0].PosicionesVenta.find(x=> x.PosicionVenta > 0);
              if (
                reserva.ReservaItems[0].PosicionesVenta &&
                reserva.ReservaItems[0].PosicionesVenta.length > 0 &&
                +reserva.ReservaItems[0].PosicionesVenta[0].PosicionVenta > 0
              )
                reserva.Agrupada = '1';
            }

            // if(reserva.ReservaItems[reserva.ReservaItems.length - 1].TipoProducto == '2')
            // {
            //   if(reserva.ReservaItems[reserva.ReservaItems.length - 1].PromocionEntradas && reserva.ReservaItems[reserva.ReservaItems.length - 1].PromocionEntradas.length > 0 && reserva.ReservaItems[reserva.ReservaItems.length - 1].PromocionEntradas[0].PosicionesVenta && reserva.ReservaItems[reserva.ReservaItems.length - 1].PromocionEntradas[0].PosicionesVenta.length > 1
            //      && +reserva.ReservaItems[reserva.ReservaItems.length - 1].PromocionEntradas[0].PosicionesVenta[0].PosicionVenta > 1)
            //     reserva.Agrupada = '1';

            // } else{
            //   //tiene = reserva.reservaItems[0].PosicionesVenta.find(x=> x.PosicionVenta > 0);
            //   if(reserva.ReservaItems[reserva.ReservaItems.length - 1].PosicionesVenta && reserva.ReservaItems[reserva.ReservaItems.length - 1].PosicionesVenta.length > 0 && +reserva.ReservaItems[reserva.ReservaItems.length - 1].PosicionesVenta[0].PosicionVenta > 1)
            //   reserva.Agrupada = '1';
            // }
          }
          return reserva;
          //return res.DatosResult;
        })
      );
  }

  insertarMotivos(listaFarId: any[]) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      ListaFarMotivos: listaFarId,
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/InsMotivosReservas`, body)
      .pipe(
        map((res: any) => {
          if (!res.DatosResult || res.DatosResult === 'false') {
            return false;
          }
          if (res.DatosResult === 'true') {
            return true;
          }
        })
      );
  }


  createToken() {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    }
    const bodyToken = {
      "usuario": environment.API_TOKEN_USER,
      "contrasena": environment.API_TOKEN_PASSWORD
    }
    return this.http
    .post(
      `${this.config.getConfig(
        'API_URL_TAQUILLA'
      )}/Token`,
      bodyToken,
      {
        headers: headers
      })
    .pipe(
      map(async (res: any) => {
        return res.token;
      })
    );
  }

  reimprimirTickets(localizador) {
    return this.createToken().pipe(
      switchMap(token => {
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));
        const idiomaParam = 'idioma=es-ES';
        const tpvIdParam =  this.turno.turnoValue.TpvId;
        const numAlbaranParam =  this.turno.turnoValue.numAlbaran;        
        const plataformaParam = 'plataforma=2';
        //const codigoCentro = this.configuration.datosInicioValue.PagoContado;
        //const UsuarioId = this.turno.turnoValue.UsuarioId;
        //const EsConjunta = 0
        //const EsReimpresion = 1;
        //const TurnoId = this.turno.turnoValue.pkid;
        //const Localizador = localizador;

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${token['__zone_symbol__value']}`
        };
        
        // Retornamos la solicitud HTTP dentro del switchMap
        return this.http.get(
          `${this.config.getConfig('API_URL_TAQUILLA')}/api/Ticket/GenerarPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${numAlbaranParam}&${plataformaParam}`,
          { headers: headers, responseType: 'json'}
          ).pipe(
            map((response: { datos: string }) => response.datos), 
            tap(pdfBase64 => {
              this.printPDFWithBase64(pdfBase64);
            })
          );
        })
      );
    }
    printPDFWithBase64(base64: string, fileName: string = 'ticket.pdf') {

      const binaryData = atob(base64);
    
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i) & 0xff;
      }
      
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    
      const blobUrl = URL.createObjectURL(blob);
    
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName; 
      link.click();
      URL.revokeObjectURL(blobUrl);
    }

  getNumPromosXEntrada(entrada) {
    let numOrdenPromoXentrada = [];
    entrada.entrada.NumOrdenPromos.forEach((numPromo) => {
      let arrayFarId = [];
      entrada.entrada.PromocionEntradas.forEach((element) => {
        if (element.NumOrdenPromo == numPromo) {
          if (
            arrayFarId.find((x) => x.TipoEntradaId === element.TipoEntradaId)
          ) {
            const indice = arrayFarId.findIndex(
              (x) => x.TipoEntradaId === element.TipoEntradaId
            );
            element.PosicionesVenta[0].FarId.forEach((datos) => {
              arrayFarId[indice].FarId.push(datos);
            });
          } else {
            arrayFarId.push({
              TipoEntradaId: element.TipoEntradaId,
              FarIds: element.PosicionesVenta[0].FarId,
            });
          }
        }
      });
      numOrdenPromoXentrada.push({
        NumOrdenPromo: numPromo,
        PromocionEntradas: arrayFarId,
      });
    });
    return numOrdenPromoXentrada;
  }

  getArrayFarId(FarId: string) {
    return FarId.split(',');
  }
}
