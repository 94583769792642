import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})

export class DesglosarCambioService {
  public billetesMonedasSubject: BehaviorSubject<any>;
  public billetesMonedas$: Observable<any>;
  public listadoBilletesMonedasSubject : BehaviorSubject<any>;
  public listadoBilletesMonedas$: Observable<any>;
  public esperaDesgloseSubject: BehaviorSubject<any>;
  public esperaDesglose$: Observable<any>;

  public ventanaOrigenSubject: BehaviorSubject<any>;
  public ventanaOrigen$: Observable<any>;



  listaBilletesDisponibles = [];
  listaDesgloseMonedas = [];
  order = '';
  selected;
  billete;
  index;
  
  constructor(private configuration:ConfigurationService) {
    this.billetesMonedasSubject = new BehaviorSubject(undefined);
    this.billetesMonedas$ = this.billetesMonedasSubject.asObservable();
    this.listadoBilletesMonedasSubject = new BehaviorSubject(undefined);
    this.listadoBilletesMonedas$ = this.listadoBilletesMonedasSubject.asObservable();
    this.esperaDesgloseSubject = new BehaviorSubject(undefined);
    this.esperaDesglose$ = this.esperaDesgloseSubject.asObservable();
    this.ventanaOrigenSubject = new BehaviorSubject(undefined);
    this.ventanaOrigen$ = this.ventanaOrigenSubject.asObservable();
  }


  public get ventanaOrigenValue(){
    return this.ventanaOrigenSubject.value;
  }

  public setVentanaOrigen(texto){
    this.ventanaOrigenSubject.next(texto);
  }

  public get billetesMonedasValue(){
    return this.billetesMonedasSubject.value;
  }
  public setBilletesMonedas(lista){
   this.billetesMonedasSubject.next(lista);
  }

  public get listadoBilletesMonedasValue(){

    return this.listadoBilletesMonedasSubject.value;
  }
  public setListadoBilletesMonedas(lista){
    
   this.listadoBilletesMonedasSubject.next(lista);
  }
  public get esperaDesgloseValue(){
    return this.esperaDesgloseSubject.value;
  }

  public setEsperarDesglose(valor){
    this.esperaDesgloseSubject.next(valor);
  }

  public addBilletesMonedas(nuevaMoneda){
    
    this.listaBilletesDisponibles = !this.listaBilletesDisponibles?[]: this.listaBilletesDisponibles;

    if(this.listaBilletesDisponibles.find(x=> x.NombreMoneda === nuevaMoneda.Nombre && x.ValorMoneda == nuevaMoneda.Valor))
    {
      let index = this.listaBilletesDisponibles.findIndex(x=> x.NombreMoneda === nuevaMoneda.Nombre && x.ValorMoneda == nuevaMoneda.Valor);
      this.listaBilletesDisponibles[index] = {...this.listaBilletesDisponibles[index], cantidad:nuevaMoneda.cantidad};
    } else{
      this.listaBilletesDisponibles.push({NombreMoneda: nuevaMoneda.Nombre,ValorMoneda: nuevaMoneda.Valor, CantidadMonedas: nuevaMoneda.cantidad, DivisaId: nuevaMoneda.DivisaId });
    }

    let nuevoListado = this.listaBilletesDisponibles.filter(x=> x.CantidadMonedas > 0);

    this.setListadoBilletesMonedas(nuevoListado);
    
  }

  
  public addMoneda(cantidad, elemento){
    let listaDesgloseMonedas = this.billetesMonedasValue;

    const index = listaDesgloseMonedas.findIndex(x=> x.Valor == elemento.getAttribute('ng-reflect-name'));
    if(cantidad == '')
      {
        cantidad = '0'
      }
   
      listaDesgloseMonedas[index] = {...listaDesgloseMonedas[index], cantidad: Number.parseInt(cantidad)};
      this.setBilletesMonedas(listaDesgloseMonedas);
      elemento.value = (cantidad);

  }


  public getTotalDesglose(){
    let importe = 0;
    if(this.listaBilletesDisponibles)
    {
      this.listaBilletesDisponibles.forEach(element => {

        Number.parseFloat(element.ValorMoneda.replace(',','.'))
        importe = importe + (Number.parseFloat(element.ValorMoneda.replace(',','.')) * element.CantidadMonedas)        
      });
    }else{
      this.listaBilletesDisponibles = [];
    }
    return importe;

  }

  setNumpadOrder(order: string) {
    this.order = order;
    this.selected.value = this.orderDispatcher();
  }

  orderDispatcher(): string {
    return this.validateOrderMoneda();
  }

  validateOrderMoneda(): string {
    
    this.listaDesgloseMonedas = this.billetesMonedasValue;
    let index = this.listaDesgloseMonedas.findIndex(x=> x=== this.billete);
    switch (this.order) {
      case 'delete':
        const newstr = this.selected.value.substring(0, this.selected.value.length - 1);
        if (newstr.length > 0) {
          this.listaDesgloseMonedas[index] = {...this.listaDesgloseMonedas[index], cantidad:newstr};
          this.setBilletesMonedas(this.listaDesgloseMonedas);
          return newstr;
        } else {
          this.listaDesgloseMonedas[index] = {...this.listaDesgloseMonedas[index], cantidad:0};
          this.setBilletesMonedas(this.listaDesgloseMonedas);
          return '0';
        }
      case 'reset':
        this.listaDesgloseMonedas[index] = {...this.listaDesgloseMonedas[index], cantidad:0};
        this.setBilletesMonedas(this.listaDesgloseMonedas);
        return '0';
      case 'losefocus':
        if (this.selected.value === '') {
          this.selected.value = 0;
        }
        this.selected.focus = false;
        return this.selected.value;
      default:
        const oldValue = ''.concat(this.selected.value);
        if (oldValue === '0' && this.order === '0') {
          return '';
        }
        const newValue = oldValue.concat(this.order);
        const Ncomma = (newValue.match(/,/g) || []).length;
        let Nintegers = 0;
        let Ndecimals = 0;
        if (Ncomma > 0) {
          Nintegers = newValue.split('.')[0].length;
          Ndecimals = newValue.split('.')[1].length;
        } else {
          Nintegers = newValue.length;
        }
        if (Ncomma < 2) {
          if (Ndecimals < 3 && Nintegers < 4) {
            if (oldValue === '' || oldValue === '0') {
              this.listaDesgloseMonedas[index] = {...this.listaDesgloseMonedas[index], cantidad:this.order};
              this.setBilletesMonedas(this.listaDesgloseMonedas);
              return this.order;
            } else {
              this.listaDesgloseMonedas[index] = {...this.listaDesgloseMonedas[index], cantidad: newValue};
              this.setBilletesMonedas(this.listaDesgloseMonedas);
              return newValue;
            }
          } else {
            return oldValue;
          }
        } else {
          return oldValue;
        }
    }
  }

  inicializarDesglose(){
    this.setListadoBilletesMonedas([]);
    this.setVentanaOrigen(undefined);
    this.listaBilletesDisponibles = [];      
  }
  
  validarNumero(char: string){
    const regex = /^\d+$/i;
    if(regex.test(char)){
      return true;
    }
    return false;
  }


}
