export function extractErrorCode(mensaje: string): string {
  const match = mensaje.match(/Cod:(\S+)/);
  return match ? match[1] : 'UNKNOWN';
}

export function extractErrorMessage(input: string): string | null {
  const regex = /Des:([^\.]+)/;
  const match = regex.exec(input);
  if (match && match.length > 1) {
    return match[1].trim(); 
  }
  return null;
}
