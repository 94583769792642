import { Injectable } from '@angular/core';
import { PrintableType } from './printableType.enum';
import { Printer } from './printer.interface';
import { NormalTicket } from './ticket/normalTicket';
import { JointTicket } from './ticket/jointTicket';
import { ReprintedTicket } from './ticket/reprintedTicket';
import { PurchaseAlbaran } from './albaran/purchaseAlbaran';
import { RefundAlbaran } from './albaran/refundAlbaran';
import { Label } from './label/label';
import { CashClosureReport } from './report/cashClosureReport';
import { RandomArchingReport } from './report/randomArchingReport';
import { OnlineTicket } from './ticket/onlineTicket';
import { PurchaseInvoice } from './invoice/purchaseInvoice';
import { AmendingInvoice } from './invoice/amendingInvoice';
import { OnlineSourceAlbaran } from './albaran/onlineSourceAlbaran';
import { PartialRefundAlbaran } from './albaran/partialRefundAlbaran';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from '../../conf';
import { CashOpeningReport } from './report/cashOpeningReport';

@Injectable({
    providedIn: 'root'
  })
export class PrinterFactory {

  public static getPrinter(printableType: PrintableType, translate: TranslateService,
    config: AppConfiguration, configuration: ConfigurationService): Printer {

    // Tickets
    if (printableType === PrintableType.NORMAL_TICKET) {
      return new NormalTicket(translate, config, configuration);
    }
    if (printableType === PrintableType.JOINT_TICKET) {
      return new JointTicket(translate, config, configuration);
    }
    if (printableType === PrintableType.REPRINTED_TICKET) {
      return new ReprintedTicket(translate, config, configuration);
    }
    if (printableType ===  PrintableType.ONLINE_TICKET) {
      return new OnlineTicket(translate, config, configuration);
    }

    // Albarans
    if (printableType === PrintableType.PURCHASE_ALBARAN) {
      return new PurchaseAlbaran(translate, config, configuration);
    }
    if (printableType === PrintableType.REFUND_ALBARAN) {
      return new RefundAlbaran(translate, config, configuration);
    }
    if (printableType === PrintableType.PARTIAL_REFUND_ALBARAN) {
      return new PartialRefundAlbaran(translate, config, configuration);
    }
    if (printableType === PrintableType.ONLINE_SOURCE_ALBARAN) {
      return new OnlineSourceAlbaran(translate, config, configuration);
    }

    // Labels
    if (printableType === PrintableType.LABEL) {
      return new Label();
    }

    // Invoices
    if (printableType === PrintableType.PURCHASE_INVOICE) {
      return new PurchaseInvoice();
    }
    if (printableType === PrintableType.AMENDING_INVOICE) {
      return new AmendingInvoice();
    }
    // Reports
    if (printableType === PrintableType.CASH_CLOSURE_REPORT) {
      return new CashClosureReport(translate);
    }
    if (printableType === PrintableType.RANDOM_ARCHING_REPORT) {
      return new RandomArchingReport();
    }
    if (printableType === PrintableType.CASH_OPENING_REPORT) {
      return new CashOpeningReport(translate);
    }
  }
}
