import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { Carrito, Carrito2, EntradasService, VentaService } from 'src/app/modules/venta';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AsociadoService } from '../../asociado.service';

@Component({
  selector: 'ticketing-validar-cliente-asociado',
  templateUrl: './validar-cliente-asociado.component.html',
  styleUrls: ['./validar-cliente-asociado.component.scss']
})
export class ValidarClienteAsociadoComponent implements OnInit {

  codigoClienteForm: FormGroup;
  totalSinDescuento = 0;
  descuento = 0;
  ventanaActualDePago = false;
  desactivarCodigoDocumento = false;
  codigo:string  = '';
  tipoD:string = '';
  optionsTipoDoc = ['NIF', 'NIE'];
  desdeAsociado = false;
  constructor( private router: Router,
               private venta: VentaService,
               private formBuilder: FormBuilder,
               private toastService: ToastrService,
               private pago: PagoService,
               private translate: TranslateService,
               private asociadoService: AsociadoService,
               private entradaService: EntradasService ) {               
                }

  ngOnInit() {
    // this.venta.carritoValue.descuentoClienteAsociadoDatos
    // this.pago.descuentoObjeto$.subscribe(tipo =>{
    //   this.codigo = tipo && tipo.Datos && tipo.Datos.Codigo && tipo.Datos.Codigo !== undefined? tipo.Datos.Codigo: '';
    //  this.tipoD = tipo && tipo.Datos && tipo.Datos.TipoDocumento && tipo.Datos.TipoDocumento !== undefined ? tipo.Datos.TipoDocumento : '';   

    //  });
     this.codigo = this.venta.carritoValue.descuentoClienteAsociadoDatos && this.venta.carritoValue.descuentoClienteAsociadoDatos.Codigo && this.venta.carritoValue.descuentoClienteAsociadoDatos.Codigo.NumeroDocumento !== undefined? this.venta.carritoValue.descuentoClienteAsociadoDatos.Codigo.NumeroDocumento: '';
     this.tipoD = this.venta.carritoValue.descuentoClienteAsociadoDatos && this.venta.carritoValue.descuentoClienteAsociadoDatos.Codigo && this.venta.carritoValue.descuentoClienteAsociadoDatos.Codigo.TipoDocumento !== undefined ? this.venta.carritoValue.descuentoClienteAsociadoDatos.Codigo.TipoDocumento : '';   
     if(this.codigo == '')
    {
      this.asociadoService.reset();
    }
    //  this.codigo = this.pago.descuentoObjetoValue && this.pago.descuentoObjetoValue.Datos && this.pago.descuentoObjetoValue.Datos.Codigo && this.pago.descuentoObjetoValue.Datos.Codigo !== undefined? this.pago.descuentoObjetoValue.Datos.Codigo: '';
    //  this.tipoD = this.pago.descuentoObjetoValue && this.pago.descuentoObjetoValue.Datos && this.pago.descuentoObjetoValue.Datos.TipoDocumento && this.pago.descuentoObjetoValue.Datos.TipoDocumento !== undefined ? this.pago.descuentoObjetoValue.Datos.TipoDocumento : '';   
    this.ventanaActualDePago = this.router.url.includes('/venta/pago')? true: false;
    if(this.ventanaActualDePago)
    this.desdeAsociado = this.venta.carritoValue.entradas.find(x=> x.entrada.desdeAsociado == true)? true: false;

    this.codigoClienteForm = this.formBuilder.group({
      codigoCliente: [
        this.codigo,
        Validators.compose([
          Validators.pattern('^[a-zA-Z0-9]*$')
        ])
      ],
      tipoDocumento: [this.tipoD,
        Validators.compose([
        Validators.pattern('^[a-zA-Z0-9]*$')
      ])],
      descuentoCodigoCliente: [(this.ventanaActualDePago?'Aplicar':'Buscar'), '' ]
    });
  }

  
aplicarCodigoCliente(){
  if(!this.desdeAsociado)
  {
  this.venta.carritoSubject.pipe(first()).subscribe((res1: Carrito) => {
    res1.carrito = new Carrito2(JSON.parse(JSON.stringify(res1)));
    
    
    if(this.validateCodigo(this.codigoClienteForm.controls['codigoCliente']) === null && this.codigoClienteForm.controls['codigoCliente'].value.length !== 0) {
      this.pago.calcDiaMinimo();
      this.pago.calcDiaMaximo();
      this.pago.validarClienteAsociado(this.codigoClienteForm.controls['tipoDocumento'].value,this.codigoClienteForm.controls['codigoCliente'].value).pipe(first()).subscribe(respuesta => {
        if (respuesta && respuesta.Mensajes &&  respuesta.Mensajes.length == 0) {
          
          
          const importeDescuento = this.pago.calculoDescuento(respuesta.DatosResult.DictTarifasProductos);
          const importeTotalSinDescuento = JSON.parse(JSON.stringify(this.venta.carritoValue.precioTotal));
          const importeTotalConDescuento = this.totalSinDescuento - this.descuento;
          if(importeDescuento > 0)
          {
            this.venta.carritoValue.setCarritoModificacionValue(res1);
            this.venta.carritoModificado = true;
            const tipoDocumento = this.codigoClienteForm.controls['tipoDocumento'].value;
            const numDocumento = this.codigoClienteForm.controls['codigoCliente'].value
            const codigo = {TipoDocumento:tipoDocumento ,NumeroDocumento:numDocumento};
            const ProductosSeleccionados = this.pago.descuentoObjetoValue;
            const descuentoClienteAsociadoDatos  = {Codigo:codigo,DtoImporte:importeDescuento,ProductosSeleccionados};
            this.asociadoService.setCodigoCliente(codigo);
            this.venta.setHayDescuento(true);
            this.pago.setDescuentoCliente(descuentoClienteAsociadoDatos, importeDescuento, importeTotalSinDescuento, importeTotalConDescuento );
            this.venta.carritoValue.recalcularTotales();
            this.venta.setCarrito(this.venta.carritoValue);
      
          }
          this.codigoClienteForm.controls['descuentoCodigoCliente'].disable();
          this.desactivarCodigoDocumento = true;          
          //this.pagosForm.controls['efectivo'].patchValue(this.pagosForm.controls['efectivo'].value);

        } else {
          if(!respuesta){
            respuesta = {Mensajes:[{CodigoMensaje:"00", DescripcionMensaje: "Error no controlado en el Servidor"}]}
          }
          this.mostrarMensaje(respuesta);
        }
      });
  }  else {
    if(this.codigoClienteForm.controls['codigoCliente'].value.length !== 0){
      this.toastService.error('', 'codigo cliente Incorrecto', {
        toastComponent: ErrorToast,
        timeOut: 5000
      });
    }
    this.codigoClienteForm.controls['codigoCliente'].patchValue('');
  }   
});
  }
}
get g() {
  return this.codigoClienteForm.controls;
}
private validateCodigo(control: AbstractControl) {
  
  if (control.value != null && control.value.length !== 0) {
  
    const str = control.value.toString().toUpperCase(); 
    const CodigoRexp = /^[a-zA-Z0-9]*$/i; 
    if (!CodigoRexp.test(control.value)) {
      return 'Invalid';
    }
  }
  return null;
}

public buscarCliente(){ 
    this.asociadoService.reset();
    if(this.validateCodigo(this.codigoClienteForm.controls['codigoCliente']) === null && this.codigoClienteForm.controls['codigoCliente'].value.length !== 0) {
      this.pago.calcDiaMinimo();
      this.pago.calcDiaMaximo();
      this.pago.consultarClienteAsociado(this.codigoClienteForm.controls['tipoDocumento'].value,this.codigoClienteForm.controls['codigoCliente'].value, this.asociadoService.fechaVentaValue.format('YYYY/MM/DD')).pipe(first()).subscribe(respuesta => {
        if (respuesta && respuesta.DatosResult && respuesta.Mensajes &&  respuesta.Mensajes.length == 0) {         
          
            let entradas: Array<any> =  Object.values(respuesta.DatosResult.DictTarifasEntradas) ;
            const entradasIdsArr = [];
            entradas.forEach(entrada => {
              if (entrada.TipoProducto === '2') {
                entrada.PromocionEntradas.forEach(item => {
                  entradasIdsArr.push(item.TipoEntradaId);
                });
              } else if (entrada.TipoProducto === '1') {
                entradasIdsArr.push(entrada.TipoEntradaId);
              }
            });
            const entradasIds = entradasIdsArr.join();
            this.entradaService
              .getRecintosEntrada(entradasIds)
              // .pipe(takeWhile(() => this.alive))
              .subscribe((recintosDisponibles: any) => {
                if (recintosDisponibles.DatosResult === null) {
                  return;
                }
                const recintos = recintosDisponibles.DatosResult.RecintosDisponibles;
                entradas.forEach(e => {
                  if (e.TipoProducto === '1') {
                    e.recintos = recintos.filter(recinto => {
                      return recinto.TipoEntrada === e.TipoEntradaId;
                    });
                  } else if (e.TipoProducto === '2') {
                    e.PromocionEntradas.forEach(item => {
                      e.recintos = recintos.filter(recinto => {
                        return recinto.TipoEntrada === item.TipoEntradaId;
                      });
                    });
                  }
                });
                this.asociadoService.setEntradasDisponibles(entradas);
              });
          this.asociadoService.setEntradasConsumidas(respuesta.DatosResult.DictTarifasEntradasConsumidas);
          // this.asociadoService.setEntradasDisponibles(respuesta.DatosResult.DictTarifasEntradas);
          this.asociadoService.setEntradasDisponibles(entradas);
          this.asociadoService.setTipoCliente(respuesta.DatosResult.TipoCliente);

            const tipoDocumento = this.codigoClienteForm.controls['tipoDocumento'].value;
            const numDocumento = this.codigoClienteForm.controls['codigoCliente'].value
            const codigo = {TipoDocumento:tipoDocumento ,NumeroDocumento:numDocumento};
            const ProductosSeleccionados =undefined;
            const importeDescuento = 0;
            const importeTotalSinDescuento = JSON.parse(JSON.stringify(this.venta.carritoValue.precioTotal));
            const importeTotalConDescuento = this.totalSinDescuento - this.descuento;
            const descuentoClienteAsociadoDatos  = {Codigo:codigo,DtoImporte:importeDescuento,ProductosSeleccionados};
            this.pago.setDescuentoCliente(descuentoClienteAsociadoDatos, importeDescuento, importeTotalSinDescuento, importeTotalConDescuento );
            this.asociadoService.setCodigoCliente(codigo);         

        } else {
          if(!respuesta){
            respuesta = {Mensajes:[{CodigoMensaje:"00", DescripcionMensaje: "Error no controlado en el Servidor"}]}
          }
          this.mostrarMensaje(respuesta);
        }
      });
  }  else {
    if(this.codigoClienteForm.controls['codigoCliente'].value.length !== 0){
      this.asociadoService.setMensajeError('codigo cliente Incorrecto');
      this.toastService.error('', 'codigo cliente Incorrecto', {
        toastComponent: ErrorToast,
        timeOut: 5000
      });
    }
  }   
}

  public mostrarMensaje(respuesta){
    let mensaje;
    switch (respuesta.Mensajes[0].CodigoMensaje) {
      case "MV": 
      case "MV1": 
      case "MV2":  
      case "MV3": 
      case "MV4": 
      case "MV5": 
      case "MV6":
      case "M0":
      case "M9":
      case "M2":
      case "M3":
      case "M4":
      case "M5":
      case "M6":  
        mensaje = this.translate.instant(respuesta.Mensajes[0].CodigoMensaje);
        break;         
      default:
        mensaje  = respuesta.Mensajes[0].DescripcionMensaje;
        break;
    }
    this.asociadoService.setMensajeError(mensaje);
    this.toastService.error('', mensaje, {
      toastComponent: ErrorToast,
      timeOut: 5000
    });
    this.codigoClienteForm.controls['codigoCliente'].patchValue('');
  }

  obtenerDesdeAsociado(){
    return this.desdeAsociado;
  }
  obtenerDesactivarCodigoCliente(){
    return this.desdeAsociado || this.desactivarCodigoDocumento;
  }
}
