import { CustomerDisplayLine } from './customerDisplayLine.model'

export class CustomerDisplayMessage {

    public lineStack: CustomerDisplayLine[];

    constructor() {
        this.lineStack = [];
    }

    pushLine(customerDisplayLine: CustomerDisplayLine) {
        this.lineStack.push(customerDisplayLine);
    }

    popLine() {
        this.lineStack.pop();
    }

    addLine(customerDisplayLine: CustomerDisplayLine): CustomerDisplayMessage {
        this.pushLine(customerDisplayLine);
        return this;
    }

    getLines() {
        return this.lineStack;
    }
}
