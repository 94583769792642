import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ticketing-agenda-calendario',
  templateUrl: './agenda-calendario.component.html',
  styleUrls: ['./agenda-calendario.component.scss']
})
export class AgendaCalendarioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
