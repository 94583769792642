import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../../shared/services/translate.service';

@Pipe({
  name: 'tipoOperacion'
})
export class TipoOperacionPipe implements PipeTransform {
  constructor (
    private translate: TranslateService
  ) { }
  transform(value: any, args?: any): any {
    switch (value) {
      case '00':
        return this.translate.instant('VENTA');
      case '01':
        return this.translate.instant('ABONO');
      default:
        return '';
    }
  }
}
