import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../services/format.service';

@Pipe({
  name: 'formatFecha'
})
export class FormatFechaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return FormatService.formatFecha(value);
  }

}
