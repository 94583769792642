import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecintosService {

  private recintosSubject: BehaviorSubject<any>;
  public recintos: Observable<any>;

  constructor() {
    
    this.recintosSubject = new BehaviorSubject(undefined);
    this.recintos = this.recintosSubject.asObservable();
  }

  public get recintosValue() {
    return this.recintosSubject.value;
  }

  setRecintosValue(recintos) {
    this.recintosSubject.next(recintos);
  }

  hayRecintosConAforoExterno(entrada) 
  {
    let respuesta = false;
    if (entrada && entrada.recintos && entrada.recintos.length > 0)
    {
      entrada.recintos.forEach(element => {
        if (element.ControlAforoExterno && element.ControlAforoExterno != '') respuesta = true;
      });
    }
    return respuesta;
  }
}
