import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { VentaService } from '../../services/venta.service';
import * as moment from 'moment';
import 'moment/min/locales';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ticketing-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit {
  idioma = 'es-Es';

  busquedaForm: FormGroup;
  myFormSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private configurationService: ConfigurationService,
    private ventaService: VentaService
  ) {
    this.busquedaForm = this.formBuilder.group({
      cliente: '',
      fecha: ''
    });
  }

  ngOnInit() {
    this.configurationService.datosInicio.subscribe(conf => (this.idioma = conf.Idioma));
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getFechaSeleccionada() {
    if (this.ventaService.fechaSeleccionadaValue) {
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('L');
    }
  }

  nextDia() {
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.add(1, 'days')
      );
    }
  }

  backDia() {
    if (this.ventaService.fechaSeleccionadaValue.isSameOrBefore(moment(), 'day')) {
      return;
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.subtract(1, 'days')
      );
    }
  }

  getNombreCliente() {
    if (this.ventaService.clienteSeleccionadoValue) {
      return this.ventaService.clienteSeleccionadoValue.NombreCliente;
    }
    return '';
  }
}
