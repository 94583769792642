import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../../shared/services/translate.service';

@Pipe({
  name: 'tipoAccion'
})
export class TipoAccionPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) { }

  transform(value: any, args?: any): any {
    switch (value) {
      case '00':
        return this.translate.instant('ANULADA');
      case '01':
        return this.translate.instant('PAGADA');
      case '02':
        return this.translate.instant('RESERVA');
      case '03':
        return this.translate.instant('MODIFICADO');
      case '04':
        return this.translate.instant('CANJEADA');
      case '05':
        return this.translate.instant('DEVUELTO');
      case '06':
        return this.translate.instant('DEVUELTA_TAQUILLA');
      case '07':
        return this.translate.instant('ACCESO_LOCALIZADOR');
      case '08':
        return this.translate.instant('ACCESO_VENTA_TAQUILLA');
      case '09':
        return this.translate.instant('VENDIDA');
      case '10':
        return this.translate.instant('ANULADA');
      default:
        return '';
    }
  }

}
