import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppConfiguration } from 'src/app/app.configuration';
import { EncriptaService } from 'src/app/shared/services/encripta.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { VentaService, StepperNavigationService, Carrito } from 'src/app/modules/venta';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { TokenManagerService } from 'src/app/core';


@Component({
  selector: 'ticketing-confirmar-cambio-fecha',
  templateUrl: './confirmar-cambio-fecha.component.html',
  styleUrls: ['./confirmar-cambio-fecha.component.scss']
})
export class ConfirmarCambioFechaComponent implements OnInit {
  @Input() id: string;
  @ViewChild('formadmin') formAdmin: ElementRef;
  urladmin: string = this.config.getConfig('ADMIN_INDEX_URL');
  private element: any;
  window = window as any;
  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private toast: ToastrService,
    private router: Router,
    private ventaService: VentaService,
    private stepperNavigation: StepperNavigationService,
    private confirmationService: ConfirmationService,
    private config: AppConfiguration,
    private tokenManager: TokenManagerService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.router.navigate(['/localizador']);
  }

  abriradminOnline(): void
  {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const user =  JSON.parse(localStorage.getItem('currentUser'));
    const pass = JSON.parse(localStorage.getItem('currentPwd'));
    const path = JSON.parse(localStorage.getItem('currentPath'))|| undefined;
    const currentToken = this.tokenManager.currentTokenValue;
    const cadena =
      '{"nombre": "' +
      user +
      '", "contrasena": "' +
      pass +
      '", "ConexionIacpos": "' +
      conexionId +
      (path? '", "tpvPath": "' +
      path: '') + 
      (currentToken? '", "tokenOrigen": "'+ currentToken.token:'') +
      '"}';
      let argsStr = EncriptaService.encripta(cadena);
      let url = this.config.getConfig('ADMIN_INDEX_URL');
      if (!url || url == "") url = "http://admin.madridsnowzone.com/admin/MenuOperacionesEspeciales.aspx";
      url = url + "?ARGS=" + argsStr;
      //llamar a Kore para que abra la pestaña agenda si no la tiene 
      if (
        this.window.external &&
        typeof this.window.external.abrirVentana === 'function'
      ) 
      {
        // this.window.external.abrirVentana(
        //   url,
        //   'Administracion'
        // );
        this.router.navigate([]).then(result => {
          window.open(
              url,
              'Admin'
          );
      });
      } 
      else 
      {
        this.router.navigate([]).then(result => {
          // window.open(
          //   url,
          //   '_blank'
          // );
          this.formAdmin.nativeElement.Action=url;
          this.formAdmin.nativeElement.hdargumentos.value=argsStr;
          this.window.open('','Admin');
          this.formAdmin.nativeElement.submit();
        });
      }
      this.router.navigate(['/localizador']);
      this.close();
  }
}
