import { Entrada } from './entrada.model';

export class Carrito {
  entradas: {
    entrada: Entrada;
    descuentos: any[];
    cantidad: number;
  }[];
  fecha: string;
  horarios: any;
  horariosPrevios: any;
  entradasPrevias: any;
  hayDescuentoMotivo: boolean;
  cantidadTotal: number;
  precioFinal: number;
  precioBase: number;
  precioConDescuento: number;
  ivaTotal: number;
  precioTotal: number;
  precioPrepago: number;
  localizador: string;
  reservaPagada: string;
  reservaAgrupada: string;
  reserva: {};
  albaran: {};
  carrito: Carrito2;
  importeDescuento: number; //Yaribel 20210727 cliente mapfre VSTS 8370
  hayDescuento: boolean; //Yaribel 20210728 cliente mapfre VSTS 8370
  precioTotalSinDescuento: number; //Yaribel 20210728 cliente mapfre VSTS 8370
  descuentoClienteAsociadoDatos: {};
  importeDescuentoCliente: number; //Añadimos el calculo total del descuento de cliente

  constructor(
    entradas: { entrada: Entrada; descuentos: []; cantidad: number }[] = []
  ) {
    this.entradas = entradas;
    this.horarios = [];
    this.horariosPrevios = [];
    this.entradasPrevias = [];
    this.hayDescuentoMotivo = false;
    this.cantidadTotal = 0;
    this.precioBase = 0;
    this.precioConDescuento = 0;
    this.ivaTotal = 0;
    this.precioTotal = 0;
    this.precioPrepago = 0;
    this.albaran = null;
    this.importeDescuento = 0; //Yaribel 20210727 cliente mapfre VSTS 8370
    this.hayDescuento = false; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.precioTotalSinDescuento = 0; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.descuentoClienteAsociadoDatos = {}; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.importeDescuentoCliente = 0;
  }

  setCarritoModificacionValue(carrito: Carrito) {
    this.entradas = carrito.entradas;
    this.horarios = carrito.horarios;
    this.horariosPrevios = carrito.horariosPrevios;
    this.entradasPrevias = carrito.entradasPrevias;
    this.hayDescuentoMotivo = carrito.hayDescuentoMotivo;
    this.cantidadTotal = carrito.cantidadTotal;
    this.precioBase = carrito.precioBase;
    this.precioConDescuento = carrito.precioConDescuento;
    this.ivaTotal = carrito.ivaTotal;
    this.precioTotal = carrito.precioTotal;
    this.precioPrepago = carrito.precioPrepago;
    this.albaran = carrito.albaran;
    this.importeDescuento = carrito.importeDescuento; //Yaribel 20210727 cliente mapfre VSTS 8370
    this.hayDescuento = carrito.hayDescuento; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.precioTotalSinDescuento = carrito.precioTotalSinDescuento; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.descuentoClienteAsociadoDatos = carrito.descuentoClienteAsociadoDatos; //Yaribel 20210728 cliente mapfre VSTS 8370
  }

  setCarritoModificacionCarritoValue(carrito: Carrito2) {
    this.entradas = carrito.entradas;
    this.horarios = carrito.horarios;
    this.hayDescuentoMotivo = carrito.hayDescuentoMotivo;
    this.cantidadTotal = carrito.cantidadTotal;
    this.precioBase = carrito.precioBase;
    this.precioConDescuento = carrito.precioConDescuento;
    this.ivaTotal = carrito.ivaTotal;
    this.precioTotal = carrito.precioTotal;
    this.precioPrepago = carrito.precioPrepago;
    this.importeDescuento = carrito.importeDescuento; //Yaribel 20210727 cliente mapfre VSTS 8370
    this.hayDescuento = carrito.hayDescuento; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.precioTotalSinDescuento = carrito.precioTotalSinDescuento; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.descuentoClienteAsociadoDatos = carrito.descuentoClienteAsociadoDatos; //Yaribel 20210728 cliente mapfre VSTS 8370
  }

  isEmpty() {
    return this.entradas.length === 0 ? true : false;
  }

  getCantidadPorEntrada(entrada: Entrada) {
    if (this.existeEntrada(entrada)) {
      return this.existeEntrada(entrada).cantidad;
    }
    return 0;
  }

  agregarEntrada(entrada: Entrada, devolucion?) {
    if (this.existeEntrada(entrada)) {
      if (devolucion) {
        this.existeEntrada(entrada).cantidad += +entrada.Cantidad; //Yaribel 20210225 Diferenciamos añadir cantidades de la devolucion xq puede ser mas de una para el resto que lo siga haciendo igual
        entrada.PromocionEntradas.forEach((element) => {
          this.existeEntrada(entrada).entrada.PromocionEntradas.push(element);
        });
      } else {
        this.existeEntrada(entrada).cantidad++; //Yaribel 20210225 Diferenciamos añadir cantidades de la devolucion xq puede ser mas de una para el resto que lo siga haciendo igual
      }
      if (
        entrada.CaracteristicasEntrada &&
        entrada.CaracteristicasEntrada.length > 0 &&
        this.existeEntrada(entrada).cantidad >
          entrada.CaracteristicasEntrada.length
      ) {
        this.existeEntrada(entrada).entrada.CaracteristicasEntrada.push(
          entrada.CaracteristicasEntrada[0]
        );
      }
    } else {
      let cantidad = devolucion ? entrada.Cantidad : 1; // Yaribel 20210224 Añadimos variable cantidad para que meta una cantidad o meta el total de entradas que recibe si es devolucion
      this.entradas.push({
        entrada: entrada,
        descuentos: [],
        cantidad: +cantidad,
      }); // Yaribel 20210224 Cambiamios que meta una cantidad a que meta el total de entradas que recibe
    }

    this.recalcularTotales();
  }

  eliminarEntrada(entrada: Entrada, devolucion?) {
    if (!this.existeEntrada(entrada)) {
      return;
    }
    const miEntrada = this.existeEntrada(entrada);
    if (miEntrada.cantidad === +entrada.Cantidad) {
      //Yaribel 20210225 comprobamos la cantidad xq puede ser mas de una
      this.entradas = this.entradas.filter((e) => {
        //
        // ;
        //
        //   (e.entrada.TipoEntradaId === entrada.TipoEntradaId || e.entrada.TipoPromocionId === entrada.TipoPromocionId),
        //     e.entrada.noEditable === entrada.noEditable
        // );
        /* 
          e.entrada.idRecintoButaca !== entrada.idRecintoButaca,
          e.entrada.fechaAuditorio === entrada.fechaAuditorio,
          e.entrada.horaAuditorio === entrada.horaAuditorio
        );*/
        return (
          ((e.entrada.CategoriaId !== entrada.CategoriaId ||
            e.entrada.TipoEntradaId !== entrada.TipoEntradaId ||
            e.entrada.TipoPromocionId !== entrada.TipoPromocionId) &&
            e.entrada.noEditable === entrada.noEditable) ||
          (e.entrada.idRecintoButaca !== entrada.idRecintoButaca &&
            e.entrada.fechaAuditorio === entrada.fechaAuditorio &&
            e.entrada.horaAuditorio === entrada.horaAuditorio)
        );
      });
    } else {
      this.existeEntrada(entrada).cantidad--;
      if (devolucion) {
        entrada.PromocionEntradas.forEach((element) => {
          //
          //
          this.removeItemFromArr(
            this.existeEntrada(entrada).entrada.PromocionEntradas,
            element
          );
        });
      }
    }
    //

    this.recalcularTotales();
  }

  removeItemFromArr(arr, item) {
    const i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
  }

  agregarEntradas(entrada: Entrada, cantidad: number) {
    if (this.existeEntrada(entrada)) {
      this.existeEntrada(entrada).cantidad = cantidad;
      /*
      
      if (cantidad > entrada.CaracteristicasEntrada.length) {
        this.existeEntrada(entrada).entrada.CaracteristicasEntrada.push(entrada.CaracteristicasEntrada);
      }*/
    } else {
      this.entradas.push({
        entrada: entrada,
        descuentos: [],
        cantidad: cantidad,
      });
    }

    this.recalcularTotales();
  }

  public resetearCarrito() {
    this.entradas = [];
    this.horarios = [];
    this.horariosPrevios = [];
    this.entradasPrevias = [];
    this.fecha = '';
    this.hayDescuentoMotivo = false;
    this.cantidadTotal = 0;
    this.precioTotal = 0;
    this.precioBase = 0;
    this.precioConDescuento = 0;
    this.precioPrepago = 0;
    this.ivaTotal = 0;
    this.localizador = undefined;
    this.reservaPagada = '';
    this.reservaAgrupada = '';
    this.carrito = null;
    this.reserva = {
      ClaveReserva: '',
      ReservaId: '',
    };
    this.albaran = {
      pkId: '',
      TPVId: '',
      NumAlbaran: '',
      MediosPago: [],
    };
    this.importeDescuento = 0; //Yaribel 20210727 cliente mapfre VSTS 8370
    this.hayDescuento = false; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.precioTotalSinDescuento = 0; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.descuentoClienteAsociadoDatos = {}; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.importeDescuentoCliente = 0;
  }

  public resetearCarritoDeLocalizador() {
    this.entradas = [];
    this.horarios = [];
    this.horariosPrevios = [];
    this.entradasPrevias = [];
    this.fecha = '';
    this.hayDescuentoMotivo = false;
    this.cantidadTotal = 0;
    this.precioTotal = 0;
    this.precioBase = 0;
    this.precioConDescuento = 0;
    this.precioPrepago = 0;
    this.ivaTotal = 0;
    this.reservaPagada = '';
    this.reservaAgrupada = '';
    this.importeDescuento = 0; //Yaribel 20210727 cliente mapfre VSTS 8370
    this.hayDescuento = false; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.precioTotalSinDescuento = 0; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.descuentoClienteAsociadoDatos = {}; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.importeDescuentoCliente = 0; //Yaribel 20221019 añadimos la variable para almacenar el descuento de los clientes registrados
  }

  public resetearHorariosCarrito() {
    this.horarios = [];
    this.horariosPrevios = [];
  }

  recalcularTotales() {
    this.purgarEntradasSinCantidad();
    this.cantidadTotal = this.calcularCantidadTotal();
    this.precioBase = this.calcularPrecioBase();
    this.precioConDescuento = this.calcularPrecioConDescuentos();
    this.ivaTotal = this.calcularIvaTotal();
    this.precioTotal = this.calcularPrecioTotal();
    this.importeDescuentoCliente = this.calcularTotalDescuentoCliente();
    //this.precioPrepago = this.calcularPrecioPrepago();
  }

  private purgarEntradasSinCantidad() {
    this.entradas = this.entradas.filter((linea) => linea.cantidad !== 0);
  }

  private calcularPrecioConDescuentos() {
    let precioTotal = 0;
    this.entradas.forEach((linea) => {
      //
      precioTotal =
        precioTotal +
        Number.parseFloat(
          linea.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
        ) *
          linea.cantidad;
      //precioTotal = Math.round(precioTotal * 100) / 100;
    });
    //
    return Number.parseFloat(
      (
        precioTotal - (this.importeDescuento ? this.importeDescuento : 0)
      ).toFixed(4)
    ); // Yaribel añadimos el descuento para sacar el importe total con descuento
  }

  private calcularPrecioBase() {
    let pBase = 0;
    this.entradas.forEach((linea) => {
      if (!linea.entrada.noEditable) {
        pBase =
          pBase +
          Number.parseFloat(linea.entrada.PrecioBase.replace(/,/, '.')) *
            linea.cantidad;
        //pBase = Math.round(pBase * 100) / 100;
      }
    });
    //
    return Number.parseFloat(pBase.toFixed(4));
  }

  private calcularIvaTotal() {
    let ivaTotal = 0;
    this.entradas.forEach((linea) => {
      if (!linea.entrada.noEditable) {
        ivaTotal =
          (Number.parseFloat(
            linea.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
          ) -
            (Number.parseFloat(linea.entrada.PrecioBase.replace(/,/, '.')) +
              Number.parseFloat(linea.entrada.Comision.replace(/,/, '.')))) *
            linea.cantidad;
      }
    });
    return Number.parseFloat(ivaTotal.toFixed(2));
    //return ivaTotal;
  }

  private calcularPrecioTotal() {
    return this.precioConDescuento;
  }

  private calcularTotalDescuentoCliente() {
    let precioTotal = 0;
    this.entradas.forEach((linea) => {
      precioTotal =
        precioTotal +
        Number.parseFloat(
          Number.parseFloat(
            (linea.entrada.ImporteDto || '0').replace(/,/, '.')
          ).toFixed(4)
        ) *
          linea.cantidad;
      //precioTotal = Math.round(precioTotal * 100) / 100;
    });
    return Number.parseFloat(precioTotal.toFixed(4));
    //return precioTotal;
  }

  private calcularPrecioPrepago() {
    let precioPrepago = 0;
    this.entradas.forEach((entrada) => {
      if (entrada.entrada.noEditable) {
        if (entrada.entrada.TipoProducto === '1') {
          precioPrepago =
            precioPrepago +
            Number.parseFloat(
              entrada.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
            ) *
              entrada.cantidad;
        } else if (entrada.entrada.TipoProducto === '2') {
          entrada.entrada.PromocionEntradas.forEach((item) => {
            precioPrepago =
              precioPrepago +
              Number.parseFloat(item.PrecioUnidad.replace(/,/, '.')) *
                entrada.cantidad *
                Number.parseFloat(item.NumeroEntradas);
          });
        }
      }
    });
    //
    return precioPrepago;
    /*     let pTotal = 0;
    this.entradas
      .filter(linea => linea.entrada.noEditable)
      .forEach(linea => {
        pBase =
          pBase +
          Number.parseFloat(linea.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')) *
            linea.cantidad;
        pBase = Math.round(pBase * 100) / 100;
      });
    return pBase + ivaTotal; */
  }

  private calcularCantidadTotal() {
    let cantidadTotal = 0;
    this.entradas.forEach((linea) => {
      cantidadTotal = cantidadTotal + +linea.cantidad;
    });

    return cantidadTotal;
  }

  private existeEntrada(entrada: Entrada) {
    //;
    //
    if (entrada.TipoProducto === '1') {
      //
      return this.entradas.find(
        (e) =>
          e.entrada.TipoEntradaId === entrada.TipoEntradaId &&
          e.entrada.CategoriaId === entrada.CategoriaId &&
          (!entrada.noEditable ||
            e.entrada.noEditable === entrada.noEditable) &&
          e.entrada.idRecintoButaca === entrada.idRecintoButaca &&
          e.entrada.fechaAuditorio === entrada.fechaAuditorio &&
          e.entrada.horaAuditorio === entrada.horaAuditorio
      );
    } else if (entrada.TipoProducto === '2') {
      return this.entradas.find(
        (e) =>
          e.entrada.TipoPromocionId === entrada.TipoPromocionId &&
          e.entrada.CategoriaId === entrada.CategoriaId &&
          (!entrada.noEditable ||
            e.entrada.noEditable === entrada.noEditable) &&
          e.entrada.idRecintoButaca === entrada.idRecintoButaca &&
          e.entrada.fechaAuditorio === entrada.fechaAuditorio &&
          e.entrada.horaAuditorio === entrada.horaAuditorio
      );
    }
    return undefined;
  }

  getCaracteristicasxEntrada(entrada: Entrada) {
    if (this.existeEntrada(entrada)) {
      return this.existeEntrada(entrada).entrada.CaracteristicasEntrada;
    }
    return [];
  }
}
export class Carrito2 {
  entradas: {
    entrada: Entrada;
    descuentos: any[];
    cantidad: number;
  }[];
  fecha: string;
  horarios: any;
  hayDescuentoMotivo: boolean;
  cantidadTotal: number;
  precioFinal: number;
  precioBase: number;
  precioConDescuento: number;
  ivaTotal: number;
  precioTotal: number;
  precioPrepago: number;
  localizador: string;
  reserva: {};
  albaran: {};
  reservaPagada: string; //Yaribel 20210210 añadimos la variable para controlar que la reserva ya este pagada
  reservaAgrupada: string; //Yaribel 20210227 añadimos la variable para controlar que la reserva este agrupada
  importeDescuento: number; //Yaribel 20210727 cliente mapfre VSTS 8370
  hayDescuento: boolean; //Yaribel 20210728 cliente mapfre VSTS 8370
  precioTotalSinDescuento: number; //Yaribel 20210728 cliente mapfre VSTS 8370
  descuentoClienteAsociadoDatos: {}; //Yaribel 20210728 cliente mapfre VSTS 8370

  constructor(carrito: Carrito) {
    this.entradas = carrito.entradas;
    this.horarios = carrito.horarios;
    this.hayDescuentoMotivo = carrito.hayDescuentoMotivo;
    this.cantidadTotal = carrito.cantidadTotal;
    this.precioBase = carrito.precioBase;
    this.precioConDescuento = carrito.precioConDescuento;
    this.ivaTotal = carrito.ivaTotal;
    this.precioTotal = carrito.precioTotal;
    this.precioPrepago = carrito.precioPrepago;
    this.importeDescuento = carrito.importeDescuento; //Yaribel 20210727 cliente mapfre VSTS 8370
    this.hayDescuento = carrito.hayDescuento; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.precioTotalSinDescuento = carrito.precioTotalSinDescuento; //Yaribel 20210728 cliente mapfre VSTS 8370
    this.descuentoClienteAsociadoDatos = {}; //Yaribel 20210728 cliente mapfre VSTS 8370
  }
}
