import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import 'moment/min/locales';
import * as QRCode from 'qrcode';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService, TurnoService } from 'src/app/core';
import {
  AlbaranDocumentBody,
  IvaDisct,
} from 'src/app/core/hardware/print/albaran/albaranDocumentBody';
import {
  JointTicketOptions,
  LabelOptions,
  NormalTicketOptions,
  OnlineTicketOptions,
  TicketsOptions,
} from 'src/app/core/hardware/print/printOptions';
import { PrintableType } from 'src/app/core/hardware/print/printableType.enum';
import { PrinterFactory } from 'src/app/core/hardware/print/printer.factory';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { EntradasService, VentaService } from 'src/app/modules/venta';
import { CentrosService } from 'src/app/modules/venta/services/centros.service';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { ClienteService } from './cliente.service';
import { FacturaService } from './factura.service';
import { TranslateService } from './translate.service';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private opcionesDeImpresionSubject: BehaviorSubject<any>;
  public opcionesDeImpresion: Observable<any>;
  public ventaAnulada = false;
  private datosInicio;
  imprimirSub: Subscription;
  imprimirFacturaSub: Subscription;
  private printFacturaSubject: BehaviorSubject<any>;
  public printFactura$: Observable<any>;
  public values: any;
  valuesNull = true;
  cabecera: any;
  datosCliente: any;
  datosCentro: any;
  claveCentro: any;
  numSaleArray = [];
  datosPrinter: any;
  tpvData: any;
  datosEntradas;

  window = window as any;
  constructor(
    private albaranService: AlbaranService,
    private ventaService: VentaService,
    private config: AppConfiguration,
    private pagoService: PagoService,
    private facturaService: FacturaService,
    private translate: TranslateService,
    private configuration: ConfigurationService,
    private turnoService: TurnoService,
    private clienteService: ClienteService,
    private http: HttpClient,
    private centroService: CentrosService,
    private entradasService: EntradasService
  ) {
    this.opcionesDeImpresionSubject = new BehaviorSubject(undefined);
    this.opcionesDeImpresion = this.opcionesDeImpresionSubject.asObservable();
    this.datosInicio = this.configuration.datosInicioValue;
    this.printFacturaSubject = new BehaviorSubject<any>(undefined);
    this.printFactura$ = this.printFacturaSubject.asObservable();
    this.pagoService.datosCabecera().subscribe((data: any) => {
      this.cabecera = data;
    });

    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: this.configuration.TPVEnUsoValue,
      ClaveCentro: localStorage.getItem('claveCentro'),
      NumAlbaran: '',
      Pagina: '1',
      NumeroRegistros: '1',
    };
    this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaran`, body)
      .subscribe((data) => {
        this.datosCliente = data;
      });
    this.centroService.getCentros().subscribe((data) => {
      this.datosCentro = data;
    });
    this.configuration
      .getAppConfig(localStorage.getItem('TpvId'))
      .subscribe((data) => {
        this.datosPrinter = data.DatosResult.AppConfigJsonTicketPrinter;
      });
  }

  public get opcionesDeImpresionValue() {
    return this.opcionesDeImpresionSubject.value;
  }

  public setOpcionesDeImpresion(opciones: any) {
    this.opcionesDeImpresionSubject.next(opciones);
  }

  //Impresion tickets (formato Grec)
  grecprintNormalTickets(
    nombreTpv,
    idTpv,
    idAlbaran,
    infoImpresion,
    fechaActividad,
    entradas,
    identificador?,
    fechaCompra?,
    horaCompra?,
    recinto?,
    motivos?
  ) {
    infoImpresion.ListadoCbQR.forEach((element: any, index) => {
      const options: NormalTicketOptions = {
        albaranId: idAlbaran,
        boughtAt: fechaCompra,
        boughtAtHour: horaCompra,
        entradaDate: fechaActividad,
        entrada: element.InfoTickets,
        locator: identificador,
        qrCode:
          element.ImprimirBarCode == '1' || element.ImprimirBarCode === 'True'
            ? element.texcoCB
            : '',
        totalQuantity: infoImpresion.ListadoCbQR.length,
        tpvName: nombreTpv,
        tpvId: idTpv,
        paseHorario: undefined,
        i: undefined,
        contadorGeneral: index,
        recinto: recinto,
        motivos: motivos,
      };

      const opt = {
        orientation: 'p',
        unit: 'mm',
        format: [153 * 2.83, 70 * 2.83],
      };

      const pdf = new jspdf(opt);
      pdf.setFontSize(8);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      if (options.qrCode !== '') {
        QRCode.toCanvas(
          options.qrCode,
          { errorCorrectionLevel: 'M' },
          (err, canvas) => {
            if (err) {
              throw err;
            }

            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(
              imgData,
              'PNG',
              47,
              (height / 1.8) * 1.5,
              width / 4,
              width / 4
            );
            // JS 20191231 Añadimos TPV y num Albaran
            pdf.text(
              '' + idTpv + ' - ' + idAlbaran,
              52.5,
              (height / 1.9) * 1.5,
              {
                angle: 90,
              }
            ); //Centro + 1 de total de entradas Cantidad
            pdf.text(
              '' +
                element.InfoTickets.NumOrden +
                '-' +
                element.InfoTickets.Cantidad,
              58.5,
              (height / 1.9) * 1.5,
              {
                angle: 90,
              }
            );
            // FJS
            const espectaculo =
              element.InfoTickets.EspectaculosImprimir.EspectaculoNombre.split(
                '-'
              );
            pdf.text(
              espectaculo[0].trimEnd().substr(0, 30),
              20,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            if (espectaculo.length > 1) {
              pdf.text(
                espectaculo[1].trimStart().substr(0, 30),
                25,
                (height / 2.24) * 1.5,
                { angle: 90 }
              );
            }
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Centro,
              20,
              (height / 4.5) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.GrupoRecinto,
              25,
              (height / 4.5) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.FechaEntrada,
              44,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.HoraEntrada,
              44,
              (height / 2.85) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.AccesoButaca,
              44,
              (height / 3.6) * 1.5,
              { angle: 90 }
            );
            if (element.InfoTickets.EspectaculosImprimir.Numeradas === '1') {
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumFila,
                44,
                (height / 4.8) * 1.5,
                { angle: 90 }
              );
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumButaca,
                44,
                (height / 7) * 1.5,
                { angle: 90 }
              );
            }

            pdf.text(
              (
                +element.InfoTickets.EspectaculosImprimir.PrecioPagado.replace(
                  /,/,
                  '.'
                ) +
                +element.InfoTickets.EspectaculosImprimir.Comision.replace(
                  /,/,
                  '.'
                )
              ).toFixed(2) + ' €',
              44,
              (height / 12) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.NombreEntrada,
              52.5,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            // JS 20191231 Añadimos PROMOTOR y CIF
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Promotor,
              58.5,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.PromotorCIF,
              65.5,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            // FJS
          }
        );
        if (
          this.window.external &&
          typeof this.window.external.imprimirPDFBinario === 'function'
        ) {
          this.window.external.imprimirPDFBinario(
            this.generatePDFBinario(pdf),
            'ticket'
          );
          // pdf.save('ticket.pdf');
        }
        pdf.save(`${this.translate.instant('ticket')}.pdf`);
      }
    });
  }
  // Impresion tickets (formato cadiz)
  cadizprintNormalTickets(
    nombreTpv,
    idTpv,
    idAlbaran,
    infoImpresion,
    fechaActividad,
    entradas,
    identificador?,
    fechaCompra?,
    horaCompra?,
    recinto?,
    motivos?
  ) {
    /*     let cantidadTotal = 0;
    entradas.forEach((e: any) => {
      cantidadTotal += e.cantidad;
    });
    let contadorGeneral = 0; */
    infoImpresion.ListadoCbQR.forEach((element: any, index) => {
      const options: NormalTicketOptions = {
        albaranId: idAlbaran,
        boughtAt: fechaCompra,
        boughtAtHour: horaCompra,
        entradaDate: fechaActividad,
        entrada: element.InfoTickets,
        locator: identificador,
        // tslint:disable-next-line:max-line-length
        qrCode:
          element.ImprimirBarCode == '1' || element.ImprimirBarCode === 'True'
            ? element.texcoCB
            : '',
        totalQuantity: infoImpresion.ListadoCbQR.length,
        tpvName: nombreTpv,
        tpvId: idTpv,
        paseHorario: undefined,
        i: undefined,
        contadorGeneral: index,
        recinto: recinto,
        motivos: motivos,
      };
      // const normalTicket = PrinterFactory.getPrinter(PrintableType.NORMAL_TICKET);
      // TODO: hay que pasarle this.ventaService.paseHorario a la funcion paint de alguna manera
      // options.paseHorario = this.ventaService.paseHorario;
      // normalTicket.paint(options);
      // .print(this.config.getConfig('CONNECTION_TYPE'));
      // contadorGeneral++;

      const opt = {
        orientation: 'p',
        unit: 'mm',
        format: [152 * 2.83, 85 * 2.83],
      };

      const pdf = new jspdf(opt);
      pdf.setFontSize(8);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      if (options.qrCode !== '') {
        QRCode.toCanvas(
          options.qrCode,
          { errorCorrectionLevel: 'M' },
          (err, canvas) => {
            if (err) {
              throw err;
            }

            // line
            // pdf.line(1, (height / 4) * 1.8, 1, (height / 4) * 2.6);

            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(
              imgData,
              'PNG',
              55,
              (height / 15) * 1.5,
              width / 4,
              width / 4
            );
            // JS 20191231 Añadimos TPV y num Albaran
            pdf.text('' + idTpv + ' - ' + idAlbaran, 20, (height / 4.6) * 1.5, {
              angle: 90,
            });
            // FJS
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.EspectaculoNombre,
              25,
              (height / 1.8) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.GrupoRecinto,
              33,
              (height / 1.8) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Recinto,
              33,
              (height / 4.6) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.FechaEntrada,
              39.5,
              (height / 1.94) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.HoraEntrada,
              39.5,
              (height / 2.7) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.NombreEntrada,
              47,
              (height / 1.79) * 1.5,
              { angle: 90 }
            );
            if (element.InfoTickets.EspectaculosImprimir.Numeradas === '1') {
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumFila,
                47,
                (height / 4.4) * 1.5,
                { angle: 90 }
              );
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumButaca,
                47,
                (height / 12.05) * 1.5,
                { angle: 90 }
              );
            }

            pdf.text(
              (
                +element.InfoTickets.EspectaculosImprimir.PrecioPagado.replace(
                  /,/,
                  '.'
                ) +
                +element.InfoTickets.EspectaculosImprimir.Comision.replace(
                  /,/,
                  '.'
                )
              ).toFixed(2) + ' €',
              58.5,
              (height / 4.7) * 1.5,
              { angle: 90 }
            );
            // JS 20191231 Añadimos PROMOTOR y CIF
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Promotor,
              58.5,
              (height / 2.1) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.PromotorCIF,
              65.5,
              (height / 1.85) * 1.5,
              { angle: 90 }
            );
            // FJS
          }
        );
        if (
          this.window.external &&
          typeof this.window.external.imprimirPDFBinario === 'function'
        ) {
          this.window.external.imprimirPDFBinario(
            this.generatePDFBinario(pdf),
            'ticket'
          );
          // pdf.save('ticket.pdf');
        }
        pdf.save(`${this.translate.instant('ticket')}.pdf`);
      }
    });
  }

  printNormalTickets(
    nombreTpv,
    idTpv,
    idAlbaran,
    infoImpresion,
    fechaActividad,
    entradas,
    identificador?,
    fechaCompra?,
    horaCompra?,
    recinto?,
    motivos?,
    tipoImpresion?
  ) {
    let standarTicket;
    let altura; // altura del papel
    let anchura; //anchura del papel
    let variacion; // TODO comprobarlo actualmente se multiplica altura por anchura por 2,83
    let alturaEfectiva; // altura disponible para la escritura
    let inicialx = 0; // posicion inicial en el eje x
    let inicialy = 0; // posicion inicial en el eje  y
    let rotacion; //rotacion del papel (angulo de impresion)
    let orientacion; // orientacion del papel valores: 'p' = o 'l' (landscape or portrait)
    let unidad; // unidad de medida cuando se especifican las coordenadas  "pt" (points), "mm" (Default), "cm", "in"
    let format; // se pueden enviar la medida o usar los siguientes formatos 'a3', 'a4' (Default),'a5' ,'letter' ,'legal'
    let y; // posicion actual en el eje Y
    let x; //posicion actual en el eje X
    let width; //anchura real del documento
    let height; //altura real del documento
    let tipoLetra; //
    const empresa = this.config.getConfig('chClaveEmpresa');
    this.datosInicio = this.configuration.datosInicioValue;
    switch (empresa) {
      case 'PEDRERA':
        altura = 60;
        anchura = 152;
        unidad = 'mm';
        orientacion = 'p';
        inicialy = 5;
        inicialx = 97;
        variacion = 2.83;
        break;
      case 'REALESCUELA':
        altura = 60;
        anchura = 152;
        unidad = 'mm';
        orientacion = 'p';
        inicialy = 5;
        inicialx = 97;
        variacion = 2.83;
        break;
      case 'SNOWZONE':
        altura = 24;
        anchura = 252;
        unidad = 'mm';
        orientacion = 'p';
        inicialy = 7;
        inicialx = 0;
        variacion = 2.83;
        break;
      default:
        break;
    }

    const opt = {
      orientation: orientacion,
      unit: unidad,
      format: [anchura * variacion, altura * variacion],
    };
    const pdf = new jspdf(opt);

    const options = {
      nombreTpv: nombreTpv,
      idTpv: idTpv,
      idAlbaran: idAlbaran,
      infoImpresion: infoImpresion.ListadoCbQR,
      fechaActividad: fechaActividad,
      entradas: entradas,
      identificador: identificador,
      fechaCompra: fechaCompra,
      horaCompra: horaCompra,
      recinto: recinto,
      motivos: motivos,
      tipoImpresion: tipoImpresion,
    };

    pdf.setFontSize(8);
    var numelemento = 0;

    infoImpresion.ListadoCbQR.forEach((element: any, index) => {
      //const qrCode = element.ImprimirBarCode == '1' || element.ImprimirBarCode === 'True'? element.texcoCB : '';
      const options: NormalTicketOptions = {
        albaranId: idAlbaran,
        boughtAt: fechaCompra,
        boughtAtHour: horaCompra,
        entradaDate: fechaActividad,
        entrada: element.InfoTickets,
        locator: identificador,
        qrCode: element.ImprimirBarCode == '1' ? element.texcoCB : '',
        totalQuantity: infoImpresion.ListadoCbQR.length,
        tpvName: nombreTpv,
        tpvId: idTpv,
        paseHorario: undefined,
        i: undefined,
        contadorGeneral: index,
        recinto: recinto,
        motivos: motivos,
      };

      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      y = inicialy;
      x = inicialx;

      if (options.qrCode !== '') {
        switch (empresa) {
          case 'PEDRERA':
            let nombreRecinto;
            let fecha;
            let hora;
            let motivoGrupo;
            let precio;
            if (
              element.InfoTickets.recintosImprimir &&
              element.InfoTickets.recintosImprimir.length > 0 &&
              element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ] != undefined
            ) {
              fecha = moment(
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].FechaHoraEntrada
              ).format('DD/MM/YYYY');
              hora = element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ].FechaHoraEntrada.slice(11, -3);
              nombreRecinto =
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].NombreRecinto;
            }
            precio = entradas.find(
              (x) => x.ItemId == element.InfoTickets.TipoEntradaId
            )
              ? entradas.find(
                  (x) => x.ItemId == element.InfoTickets.TipoEntradaId
                ).PrecioTotal
              : '';
            pdf.text(element.InfoTickets.NombreEntrada, (y += 4), x, {
              angle: 90,
            });
            if (motivos) {
              motivoGrupo = motivos.find((x) => x.NumTicket == element.NumOrden)
                ? motivos.find((x) => x.NumTicket == element.NumOrden)
                    .ItemNombre
                : '';
              pdf.text(motivoGrupo, (y += 4), x, { angle: 90 });
              pdf.text('Preu: ' + precio, y, x - 30, { angle: 90 });
            } else {
              pdf.text('Preu: ' + precio, (y += 4), x - 20, { angle: 90 });
            }

            if (nombreRecinto) {
              pdf.text(nombreRecinto, (y += 4), x, { angle: 90 });
            }
            if (fecha) {
              pdf.text('Data : ' + fecha, (y += 4), x, { angle: 90 });
            }
            if (hora) {
              pdf.text('H. Inici: ' + hora, y, x - 30, { angle: 90 });
            }
            pdf.text('Nº ALBARAN: ' + idAlbaran, (y += 4), x, { angle: 90 });
            pdf.text(
              element.nombreTpv + ' - ' + fechaCompra + ' ' + horaCompra,
              (y += 4),
              x,
              { angle: 90 }
            );
            pdf.text('N.Pers.: ' + element.InfoTickets.Cantidad, (y += 4), x, {
              angle: 90,
            });
            pdf.text('NIF: ' + this.datosInicio.CIF, (y += 4), x, {
              angle: 90,
            });
            QRCode.toCanvas(
              element.texcoCB,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', width - 30, x - 60, 20, 20);
                pdf.text(identificador, width - 5, x - 42, { angle: 90 });
              }
            );

            break;
          case 'REALESCUELA':
            if (
              element.InfoTickets.recintosImprimir &&
              element.InfoTickets.recintosImprimir.length > 0 &&
              element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ] != undefined
            ) {
              fecha = moment(
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].FechaHoraEntrada
              ).format('DD/MM/YYYY');
              hora = element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ].FechaHoraEntrada.slice(11, -3);
              nombreRecinto =
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].NombreRecinto;
            }
            precio = entradas.find(
              (x) => x.ItemId == element.InfoTickets.TipoEntradaId
            )
              ? entradas.find(
                  (x) => x.ItemId == element.InfoTickets.TipoEntradaId
                ).PrecioTotal
              : '';
            pdf.text(element.InfoTickets.NombreEntrada, (y += 4), x, {
              angle: 90,
            });
            if (motivos) {
              motivoGrupo = motivos.find((x) => x.NumTicket == element.NumOrden)
                ? motivos.find((x) => x.NumTicket == element.NumOrden)
                    .ItemNombre
                : '';
              pdf.text(motivoGrupo, (y += 4), x, { angle: 90 });
              pdf.text('Preu: ' + precio, y, x - 30, { angle: 90 });
            } else {
              pdf.text('Preu: ' + precio, (y += 4), x - 20, { angle: 90 });
            }

            if (nombreRecinto) {
              pdf.text(nombreRecinto, (y += 4), x, { angle: 90 });
            }
            if (fecha) {
              pdf.text('Data : ' + fecha, (y += 4), x, { angle: 90 });
            }
            if (hora) {
              pdf.text('H. Inici: ' + hora, y, x - 30, { angle: 90 });
            }
            pdf.text('Nº ALBARAN: ' + idAlbaran, (y += 4), x, { angle: 90 });
            pdf.text(
              element.nombreTpv + ' - ' + fechaCompra + ' ' + horaCompra,
              (y += 4),
              x,
              { angle: 90 }
            );
            pdf.text('N.Pers.: ' + element.InfoTickets.Cantidad, (y += 4), x, {
              angle: 90,
            });
            pdf.text('NIF: ' + this.datosInicio.CIF, (y += 4), x, {
              angle: 90,
            });
            QRCode.toCanvas(
              element.texcoCB,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', width - 30, x - 60, 20, 20);
                pdf.text(identificador, width - 5, x - 42, { angle: 90 });
              }
            );

            break;
          case 'SNOWZONE':
          default:
            QRCode.toCanvas(
              options.qrCode,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  0.4,
                  (height / 4.5) * 1.5,
                  width,
                  width
                );
                pdf.text(
                  element.InfoTickets.NombreEntrada,
                  7,
                  (height / 4.5) * 1.5,
                  { angle: 90 }
                );
                pdf.text(
                  idTpv + ' - ' + idAlbaran + ' / ' + element.NumOrden,
                  11,
                  (height / 4.5) * 1.5,
                  { angle: 90 }
                );
                if (
                  element.InfoTickets.recintosImprimir &&
                  element.InfoTickets.recintosImprimir.length > 0 &&
                  element.InfoTickets.recintosImprimir[
                    numelemento >
                    element.InfoTickets.recintosImprimir.length - 1
                      ? 0
                      : numelemento
                  ] != undefined
                ) {
                  pdf.text(
                    element.InfoTickets.recintosImprimir[
                      numelemento >
                      element.InfoTickets.recintosImprimir.length - 1
                        ? 0
                        : numelemento
                    ].NombreRecinto,
                    15,
                    (height / 4.5) * 1.5,
                    { angle: 90 }
                  );
                  //pdf.text(element.InfoTickets.FechaEntrada + ' ' + element.InfoTickets.HoraEntrada, 19, (height / 4.5) * 1.5, { angle: 90 });
                  pdf.text(
                    element.InfoTickets.recintosImprimir[
                      numelemento >
                      element.InfoTickets.recintosImprimir.length - 1
                        ? 0
                        : numelemento
                    ].FechaHoraEntrada,
                    19,
                    (height / 4.5) * 1.5,
                    { angle: 90 }
                  );
                  //FechaHoraEntrada
                }
              }
            );

            break;
        }

        // pdf = this.getContenidoTicketUnico(opciones,pdf,options);

        //<--

        pdf.addPage();
        numelemento++;
      }
    });
    //Como siempre añadimos una ultima pagina al final que queda en blanco, nos la pulimos
    pdf.deletePage(numelemento + 1);
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(pdf),
        'ticket'
      );
    }
    if (pdf.getNumberOfPages() != 0) {
      pdf.save(`${this.translate.instant('ticket')}.pdf`);
    }
  }
  // Reimprimir tickets (formato grec)
  grecreimprimirTicketsAlbaran(
    TPV,
    NumVenta,
    NumEntradas,
    Items,
    FechaEntrada,
    NumAlbaran,
    FechaHistorial,
    FechaHistorial2,
    AlbaranRecintos,
    AlbaranMotivos,
    ListadoCbQR
  ) {
    ListadoCbQR.ListadoCbQR.forEach((element: any, index) => {
      const opt = {
        orientation: 'p',
        unit: 'mm',
        format: [153 * 2.83, 70 * 2.83],
      };
      const pdf = new jspdf(opt);
      pdf.setFontSize(8);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      if (element.texcoCB !== '') {
        QRCode.toCanvas(
          element.texcoCB,
          { errorCorrectionLevel: 'M' },
          (err, canvas) => {
            if (err) {
              throw err;
            }

            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(
              imgData,
              'PNG',
              47,
              (height / 1.8) * 1.5,
              width / 4,
              width / 4
            );
            // JS 20191231 Añadimos TPV y num Albaran
            pdf.text(
              '' + element.nombreTpv + ' - ' + NumAlbaran,
              52.5,
              (height / 1.9) * 1.5,
              {
                angle: 90,
              }
            ); //Centro + 1 de total de entradas Cantidad
            pdf.text(
              '' +
                element.InfoTickets.NumOrden +
                '-' +
                element.InfoTickets.Cantidad,
              58.5,
              (height / 1.9) * 1.5,
              {
                angle: 90,
              }
            );
            // FJS
            const espectaculo =
              element.InfoTickets.EspectaculosImprimir.EspectaculoNombre.split(
                '-'
              );
            pdf.text(
              espectaculo[0].trimEnd().substr(0, 30),
              20,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            if (espectaculo.length > 1) {
              pdf.text(
                espectaculo[1].trimStart().substr(0, 30),
                25,
                (height / 2.24) * 1.5,
                { angle: 90 }
              );
            }
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Centro,
              20,
              (height / 4.5) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.GrupoRecinto,
              25,
              (height / 4.5) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.FechaEntrada,
              44,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.HoraEntrada,
              44,
              (height / 2.85) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.AccesoButaca,
              44,
              (height / 3.6) * 1.5,
              { angle: 90 }
            );
            if (element.InfoTickets.EspectaculosImprimir.Numeradas === '1') {
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumFila,
                44,
                (height / 4.8) * 1.5,
                { angle: 90 }
              );
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumButaca,
                44,
                (height / 7) * 1.5,
                { angle: 90 }
              );
            }

            pdf.text(
              (
                +element.InfoTickets.EspectaculosImprimir.PrecioPagado.replace(
                  /,/,
                  '.'
                ) +
                +element.InfoTickets.EspectaculosImprimir.Comision.replace(
                  /,/,
                  '.'
                )
              ).toFixed(2) + ' €',
              44,
              (height / 12) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.NombreEntrada,
              52.5,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            // JS 20191231 Añadimos PROMOTOR y CIF
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Promotor,
              58.5,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.PromotorCIF,
              65.5,
              (height / 2.24) * 1.5,
              { angle: 90 }
            );
            // FJS
          }
        );
        if (
          this.window.external &&
          typeof this.window.external.imprimirPDFBinario === 'function'
        ) {
          this.window.external.imprimirPDFBinario(
            this.generatePDFBinario(pdf),
            'ticket'
          );
          // pdf.save('ticket.pdf');
        }
        if (pdf.getNumberOfPages() != 0) {
          pdf.save(`${this.translate.instant('ticket')}.pdf`);
        }
      }
    });
  }
  // Reimprimir tickets (formato cadiz)
  cadizreimprimirTicketsAlbaran(
    TPV,
    NumVenta,
    NumEntradas,
    Items,
    FechaEntrada,
    NumAlbaran,
    FechaHistorial,
    FechaHistorial2,
    AlbaranRecintos,
    AlbaranMotivos,
    ListadoCbQR
  ) {
    ListadoCbQR.ListadoCbQR.forEach((element: any, index) => {
      const opt = {
        orientation: 'p',
        unit: 'mm',
        format: [152 * 2.83, 85 * 2.83],
      };
      const pdf = new jspdf(opt);
      pdf.setFontSize(8);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      if (element.texcoCB !== '') {
        QRCode.toCanvas(
          element.texcoCB,
          { errorCorrectionLevel: 'M' },
          (err, canvas) => {
            if (err) {
              throw err;
            }

            // line
            // pdf.line(1, (height / 4) * 1.8, 1, (height / 4) * 2.6);

            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(
              imgData,
              'PNG',
              55,
              (height / 15) * 1.5,
              width / 4,
              width / 4
            );
            // JS 20191231 Añadimos TPV y num Albaran
            pdf.text(
              '' + element.nombreTpv + ' - ' + NumAlbaran,
              20,
              (height / 4.6) * 1.5,
              {
                angle: 90,
              }
            );
            // FJS
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.EspectaculoNombre,
              25,
              (height / 1.8) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.GrupoRecinto,
              33,
              (height / 1.8) * 1.5,
              {
                angle: 90,
              }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Recinto,
              33,
              (height / 4.6) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.FechaEntrada,
              39.5,
              (height / 1.89) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.HoraEntrada,
              39.5,
              (height / 2.7) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.NombreEntrada,
              47,
              (height / 1.79) * 1.5,
              {
                angle: 90,
              }
            );
            if (element.InfoTickets.EspectaculosImprimir.Numeradas === '1') {
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumFila,
                47,
                (height / 4.4) * 1.5,
                { angle: 90 }
              );
              pdf.text(
                element.InfoTickets.EspectaculosImprimir.NumButaca,
                47,
                (height / 12.05) * 1.5,
                { angle: 90 }
              );
            }
            pdf.text(
              (
                Number.parseFloat(
                  element.InfoTickets.EspectaculosImprimir.PrecioPagado.replace(
                    /,/,
                    '.'
                  )
                ) +
                Number.parseFloat(
                  element.InfoTickets.EspectaculosImprimir.Comision.replace(
                    /,/,
                    '.'
                  )
                )
              ).toFixed(2) + ' €',
              58.5,
              (height / 4.7) * 1.5,
              { angle: 90 }
            );
            // JS 20191231 Añadimos PROMOTOR y CIF
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.Promotor,
              58.5,
              (height / 2.1) * 1.5,
              { angle: 90 }
            );
            pdf.text(
              element.InfoTickets.EspectaculosImprimir.PromotorCIF,
              65.5,
              (height / 1.85) * 1.5,
              { angle: 90 }
            );
            // FJS
          }
        );
        if (
          this.window.external &&
          typeof this.window.external.imprimirPDFBinario === 'function'
        ) {
          this.window.external.imprimirPDFBinario(
            this.generatePDFBinario(pdf),
            'ticket'
          );
          // pdf.save('ticket.pdf');
        }
        if (pdf.getNumberOfPages() != 0) {
          pdf.save(`${this.translate.instant('ticket')}.pdf`);
        }
      }
    });
  }

  reimprimirTicketsAlbaran(
    TPV,
    NumVenta,
    NumEntradas,
    Items,
    FechaEntrada,
    NumAlbaran,
    FechaHistorial,
    FechaHistorial2,
    AlbaranRecintos,
    AlbaranMotivos,
    localizador,
    ListadoCbQR,
    TipoImpresion
  ) {
    this.datosInicio = this.configuration.datosInicioValue;
    var numelemento = 0;
    let standarTicket;
    let altura; // altura del papel
    let anchura; //anchura del papel
    let variacion; // TODO comprobarlo actualmente se multiplica altura por anchura por 2,83
    let alturaEfectiva; // altura disponible para la escritura
    let inicialx = 0; // posicion inicial en el eje x
    let inicialy = 0; // posicion inicial en el eje  y
    let rotacion; //rotacion del papel (angulo de impresion)
    let orientacion; // orientacion del papel valores: 'p' = o 'l' (landscape or portrait)
    let unidad; // unidad de medida cuando se especifican las coordenadas  "pt" (points), "mm" (Default), "cm", "in"
    let format; // se pueden enviar la medida o usar los siguientes formatos 'a3', 'a4' (Default),'a5' ,'letter' ,'legal'
    let y; // posicion actual en el eje Y
    let x; //posicion actual en el eje X
    let width; //anchura real del documento
    let height; //altura real del documento
    let tipoLetra; //
    const empresa = this.config.getConfig('chClaveEmpresa');
    switch (empresa) {
      case 'PEDRERA':
        altura = 60;
        anchura = 152;
        unidad = 'mm';
        orientacion = 'p';
        inicialy = 5;
        inicialx = 97;
        variacion = 2.83;
        break;
      case 'REALESCUELA':
        altura = 60;
        anchura = 152;
        unidad = 'mm';
        orientacion = 'p';
        inicialy = 5;
        inicialx = 97;
        variacion = 2.83;
        break;
      case 'SNOWZONE':
        altura = 24;
        anchura = 252;
        unidad = 'mm';
        orientacion = 'p';
        inicialy = 7;
        inicialx = 0;
        variacion = 2.83;
        break;
      default:
        break;
    }
    //const opciones = this.crearPlantillaTickets();
    ListadoCbQR.ListadoCbQR.forEach((element: any, index) => {
      const opt = {
        orientation: orientacion,
        unit: unidad,
        format: [anchura * variacion, altura * variacion],
      };
      y = inicialy;
      x = inicialx;
      const pdf = new jspdf(opt);
      const options = {
        nombreTpv: TPV,
        idTpv: TPV,
        idAlbaran: NumAlbaran,
        infoImpresion: ListadoCbQR,
        fechaActividad: FechaEntrada,
        entradas: Items,
        identificador: localizador,
        fechaCompra: FechaHistorial,
        horaCompra: FechaHistorial2,
        recinto: AlbaranRecintos,
        motivos: AlbaranMotivos,
        tipoImpresion: TipoImpresion,
        element: element,
      };

      //let pdf = new jspdf(opciones.opt);

      //  y = opciones.inicialy;
      //  x = opciones.inicialx;
      pdf.setFontSize(8);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      if (element.texcoCB !== '') {
        //pdf = this.getContenidoTicketUnico(opciones,pdf,options);

        // Yaribel 20210120 creamos el switch por empresa para imprimir un ticket u otro (snowzone)
        switch (empresa) {
          case 'PEDRERA':
            let nombreRecinto;
            let fecha;
            let hora;
            let motivoGrupo;
            let precio;
            if (
              element.InfoTickets.recintosImprimir &&
              element.InfoTickets.recintosImprimir.length > 0 &&
              element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ] != undefined
            ) {
              fecha = moment(
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].FechaHoraEntrada
              ).format('DD/MM/YYYY');
              hora = element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ].FechaHoraEntrada.slice(11, -3);
              nombreRecinto =
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].NombreRecinto;
            }
            precio = Items.find((x) => x.numTickets == element.NumOrden)
              ? Items.find((x) => x.numTickets == element.NumOrden).itemInfo
                  .PrecioTotal
              : '';
            pdf.text(element.InfoTickets.NombreEntrada, (y += 4), x, {
              angle: 90,
            });
            if (AlbaranMotivos) {
              motivoGrupo = AlbaranMotivos.find(
                (x) => x.NumTicket == element.NumOrden
              )
                ? AlbaranMotivos.find((x) => x.NumTicket == element.NumOrden)
                    .ItemNombre
                : '';
              pdf.text(motivoGrupo, (y += 4), x, { angle: 90 });
              pdf.text('Preu: ' + precio, y, x - 30, { angle: 90 });
            } else {
              pdf.text('Preu: ' + precio, (y += 4), x - 20, { angle: 90 });
            }

            if (nombreRecinto) {
              pdf.text(nombreRecinto, (y += 4), x, { angle: 90 });
            }
            if (fecha) {
              pdf.text('Data : ' + fecha, (y += 4), x, { angle: 90 });
            }
            if (hora) {
              pdf.text('H. Inici: ' + hora, y, x - 30, { angle: 90 });
            }
            pdf.text('Nº ALBARAN: ' + NumAlbaran, (y += 4), x, { angle: 90 });
            pdf.text(
              element.nombreTpv +
                ' - ' +
                FechaHistorial +
                ' ' +
                FechaHistorial2,
              (y += 4),
              x,
              { angle: 90 }
            );
            pdf.text('N.Pers.: ' + element.InfoTickets.Cantidad, (y += 4), x, {
              angle: 90,
            });
            pdf.text('NIF: ' + this.datosInicio.CIF, (y += 4), x, {
              angle: 90,
            });
            QRCode.toCanvas(
              element.texcoCB,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', width - 30, x - 60, 20, 20);
                pdf.text(localizador, width - 5, x - 42, { angle: 90 });
              }
            );

            break;
          case 'REALESCUELA':
            if (
              element.InfoTickets.recintosImprimir &&
              element.InfoTickets.recintosImprimir.length > 0 &&
              element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ] != undefined
            ) {
              fecha = moment(
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].FechaHoraEntrada
              ).format('DD/MM/YYYY');
              hora = element.InfoTickets.recintosImprimir[
                numelemento > element.InfoTickets.recintosImprimir.length - 1
                  ? 0
                  : numelemento
              ].FechaHoraEntrada.slice(11, -3);
              nombreRecinto =
                element.InfoTickets.recintosImprimir[
                  numelemento > element.InfoTickets.recintosImprimir.length - 1
                    ? 0
                    : numelemento
                ].NombreRecinto;
            }
            precio = Items.find((x) => x.numTickets == element.NumOrden)
              ? Items.find((x) => x.numTickets == element.NumOrden).itemInfo
                  .PrecioTotal
              : '';
            pdf.text(element.InfoTickets.NombreEntrada, (y += 4), x, {
              angle: 90,
            });
            if (AlbaranMotivos) {
              motivoGrupo = AlbaranMotivos.find(
                (x) => x.NumTicket == element.NumOrden
              )
                ? AlbaranMotivos.find((x) => x.NumTicket == element.NumOrden)
                    .ItemNombre
                : '';
              pdf.text(motivoGrupo, (y += 4), x, { angle: 90 });
              pdf.text('Preu: ' + precio, y, x - 30, { angle: 90 });
            } else {
              pdf.text('Preu: ' + precio, (y += 4), x - 20, { angle: 90 });
            }

            if (nombreRecinto) {
              pdf.text(nombreRecinto, (y += 4), x, { angle: 90 });
            }
            if (fecha) {
              pdf.text('Data : ' + fecha, (y += 4), x, { angle: 90 });
            }
            if (hora) {
              pdf.text('H. Inici: ' + hora, y, x - 30, { angle: 90 });
            }
            pdf.text('Nº ALBARAN: ' + NumAlbaran, (y += 4), x, { angle: 90 });
            pdf.text(
              element.nombreTpv +
                ' - ' +
                FechaHistorial +
                ' ' +
                FechaHistorial2,
              (y += 4),
              x,
              { angle: 90 }
            );
            pdf.text('N.Pers.: ' + element.InfoTickets.Cantidad, (y += 4), x, {
              angle: 90,
            });
            pdf.text('NIF: ' + this.datosInicio.CIF, (y += 4), x, {
              angle: 90,
            });
            QRCode.toCanvas(
              element.texcoCB,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', width - 30, x - 60, 20, 20);
                pdf.text(localizador, width - 5, x - 42, { angle: 90 });
              }
            );

            break;
          case 'SNOWZONE':
          default:
            QRCode.toCanvas(
              element.texcoCB,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  0.4,
                  (height / 4.5) * 1.5,
                  width,
                  width
                );
                pdf.text(
                  element.InfoTickets.NombreEntrada,
                  7,
                  (height / 4.5) * 1.5,
                  { angle: 90 }
                );
                pdf.text(
                  element.nombreTpv +
                    ' - ' +
                    NumAlbaran +
                    ' / ' +
                    element.NumOrden,
                  11,
                  (height / 4.5) * 1.5,
                  { angle: 90 }
                );
                if (
                  element.InfoTickets.recintosImprimir &&
                  element.InfoTickets.recintosImprimir.length > 0 &&
                  element.InfoTickets.recintosImprimir[
                    numelemento > element.InfoTickets.recintosImprimir.count - 1
                      ? 0
                      : numelemento
                  ] != undefined
                ) {
                  pdf.text(
                    element.InfoTickets.recintosImprimir[
                      numelemento >
                      element.InfoTickets.recintosImprimir.count - 1
                        ? 0
                        : numelemento
                    ].NombreRecinto,
                    15,
                    (height / 4.5) * 1.5,
                    { angle: 90 }
                  );
                  pdf.text(
                    element.InfoTickets.recintosImprimir[
                      numelemento >
                      element.InfoTickets.recintosImprimir.count - 1
                        ? 0
                        : numelemento
                    ].FechaHoraEntrada,
                    19,
                    (height / 4.5) * 1.5,
                    { angle: 90 }
                  );
                }
              }
            );
            break;
        }

        if (
          this.window.external &&
          typeof this.window.external.imprimirPDFBinario === 'function'
        ) {
          this.window.external.imprimirPDFBinario(
            this.generatePDFBinario(pdf),
            'ticket'
          );
        }
        if (pdf.getNumberOfPages() != 0) {
          pdf.save(`${this.translate.instant('ticket')}.pdf`);
        }
        numelemento++;
      }
    });
  }

  // Yaribel 20210119 fin de comentamos y creamos el codigo para probar el nuevo ticket de la pedrera >>

  // Reimpresion localizadores (formato snow)
  reimprimirTickets(
    nombreTPV,
    ClaveReserva,
    FechaHoraEntrada,
    ReservaItems,
    LocalizadorValue
  ) {
    var numelemento = 0;
    const opt = {
      orientation: 'p',
      unit: 'mm',
      format: [24 * 2.83, 252 * 2.83],
    };

    const pdf = new jspdf(opt);
    pdf.setFontSize(8);
    ReservaItems.forEach((entrada) => {
      let nombreEntrada = entrada.NombreImpresion;
      let localizadorImprimir = entrada.ClaveReserva;
      let recintosImprimir = LocalizadorValue.ReservaRecintos;
      entrada.ListaReimpresion.forEach((qr) => {
        const options = {
          qrCode: qr.DatosCB,
        };

        // const normalTicket = PrinterFactory.getPrinter(PrintableType.NORMAL_TICKET);
        // TODO: hay que pasarle this.ventaService.paseHorario a la funcion paint de alguna manera
        // options.paseHorario = this.ventaService.paseHorario;
        // normalTicket.paint(options);
        // .print(this.config.getConfig('CONNECTION_TYPE'));
        // contadorGeneral++;

        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        if (options.qrCode !== '') {
          QRCode.toCanvas(
            options.qrCode,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }

              // line
              // pdf.line(1, (height / 4) * 1.8, 1, (height / 4) * 2.6);

              const imgData = canvas.toDataURL('image/png');
              pdf.addImage(
                imgData,
                'PNG',
                0.4,
                (height / 4) * 1.5,
                width,
                width
              );
              pdf.text(nombreEntrada, 7, (height / 4) * 1.5, { angle: 90 });
              pdf.text(localizadorImprimir, 11, (height / 4) * 1.5, {
                angle: 90,
              });
              let nombreRecinto = '';
              let horaRecinto = '';
              if (
                recintosImprimir != null &&
                recintosImprimir.length > numelemento
              ) {
                //nombreRecinto = recintosImprimir[numelemento].xxxxxxx;
                //horaRecinto = recintosImprimir[numelemento].xxxxxxx;
                nombreRecinto =
                  recintosImprimir[numelemento].ItemNombreImpresion;
                horaRecinto = recintosImprimir[numelemento].FechaEntrada;
              }
              pdf.text(nombreRecinto, 15, (height / 4) * 1.5, { angle: 90 });
              //pdf.text(element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.count -1?0:numelemento].FechaHoraEntrada, 19, (height / 4.5) * 1.5, { angle: 90 });
              pdf.text(horaRecinto, 19, (height / 4.5) * 1.5, { angle: 90 });
            }
          );
          pdf.addPage();
          numelemento++;
        }
      });
    });
    //Como siempre añadimos una ultima pagina al final que queda en blanco, nos la pulimos
    pdf.deletePage(numelemento + 1);
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(pdf),
        'ticket'
      );
    }
    if (pdf.getNumberOfPages() != 0) {
      pdf.save(`${this.translate.instant('ticket')}.pdf`);
    }
  }
  printOnlineTickets(
    nombreTpv,
    idAlbaran,
    fechaActividad,
    entradas,
    localizadorValue
  ) {
    entradas.forEach((element) => {
      element.ListaReimpresion.forEach((impresion, i) => {
        const options: OnlineTicketOptions = {
          albaranId: idAlbaran,
          entrada: element,
          qrCode: impresion.DatosCB,
          totalQuantity: element.Cantidad,
          tpvName: nombreTpv,
          localizadorValue: localizadorValue,
          i,
        };
        /*         const onlineTicket = PrinterFactory.getPrinter(PrintableType.ONLINE_TICKET);
        onlineTicket.paint(options).print(this.config.getConfig('CONNECTION_TYPE')); */
        const opt = {
          orientation: 'p',
          unit: 'mm',
          format: [24 * 2.83, 252 * 2.83],
        };

        const pdf = new jspdf(opt);
        pdf.setFontSize(8);
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        if (options.qrCode !== '') {
          QRCode.toCanvas(
            options.qrCode,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }

              // line
              // pdf.line(1, (height / 4) * 1.8, 1, (height / 4) * 2.6);

              const imgData = canvas.toDataURL('image/png');
              pdf.addImage(
                imgData,
                'PNG',
                0.4,
                (height / 4) * 1.5,
                width,
                width
              );
              //Este no parece usarse
              pdf.text('prueba', 7, (height / 4) * 1.5, { angle: 90 });
              pdf.text('prueba', 11, (height / 4) * 1.5, { angle: 90 });
              pdf.text('prueba', 15, (height / 4) * 1.5, { angle: 90 });
            }
          );
          if (
            this.window.external &&
            typeof this.window.external.imprimirPDFBinario === 'function'
          ) {
            this.window.external.imprimirPDFBinario(
              this.generatePDFBinario(pdf),
              'ticket'
            );
            // pdf.save('ticket.pdf');
          }
          if (pdf.getNumberOfPages() != 0) {
            pdf.save(`${this.translate.instant('ticket')}.pdf`);
          }
        }
      });
    });
  }

  printDocumentoFacturaPdf(datosFactura) {
    try {
      this.printFacturaSubject.next(false);
      let y: number;
      let x: number;
      let finalx: number;
      let tamanioLetra: number;
      let espaciado: number;
      let centrado: number;
      let tab1: number;
      let tab2: number;
      let documentBody = new AlbaranDocumentBody(
        this.translate,
        this.config,
        this.configuration
      ); // utilizamos la clase para el metodo addDotsToNumber
      this.pagoService.datosCabecera().subscribe((data: any) => {
        // let y = 0;
        // const pdf = new jspdf('p', 'mm', [80 * 2.83, 275 * 2.83]);
        const configuracion = this.crearPlantillaJustificante();
        let pdf = new jspdf(configuracion.opt);
        let y = configuracion.inicialy;
        let x = configuracion.inicialx;
        let finalx = configuracion.finalx;
        let tamanioLetra = configuracion.tamanioLetra;
        let espaciado = configuracion.espaciado;
        let centrado = configuracion.centrado;
        let tab1 = configuracion.tab1;
        let tab2 = configuracion.tab2;
        pdf.setFontSize(tamanioLetra);
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        if (data.IncluirEncabezadoTicket === 'True') {
          pdf.setFontSize(tamanioLetra);
          if (data.chLinea1 !== '') {
            pdf.setFontSize(tamanioLetra + 4);
            pdf.setFontStyle('bold');
            pdf.text(data.chLinea1, centrado, (y = y + espaciado), 'center');
            //pdf.text('Factura de venta directa', 37, (y += 4), 'center');
            const empresa = this.config.getConfig('chClaveEmpresa');
            if (empresa !== 'MAPFRE')
              pdf.text(
                this.translate.instant('JUSTIFICANTE_FACTURA_VENTA_DIRECTA'),
                centrado,
                (y += espaciado),
                'center'
              );
            pdf.setFontSize(tamanioLetra);
            pdf.setFontStyle('normal');
            pdf.line(x, (y += espaciado), finalx, y);
          }
          if (data.chLinea2 !== '') {
            pdf.text(data.chLinea2, x, (y = y + espaciado), 'left');
          }
          if (data.chLinea3 !== '') {
            pdf.text(data.chLinea3, x, (y = y + espaciado), 'left');
          }
          if (data.chLinea4 !== '') {
            pdf.text(data.chLinea4, x, (y = y + espaciado), 'left');
          }
          if (data.chLinea5 !== '') {
            pdf.text(data.chLinea5, x, (y = y + espaciado), 'left');
          }
          if (data.chLinea6 !== '') {
            pdf.text(data.chLinea6, x, (y = y + espaciado), 'left');
          }
        }
        // Yaribel 20201222 Añadimos  titulo a la factura
        //Titulo del Documento
        pdf.setFontSize(tamanioLetra + 4);
        pdf.line(x, (y += espaciado), finalx, y);
        if (
          datosFactura.Facturas[0].TituloFactura &&
          datosFactura.Facturas[0].TituloFactura.length > 0
        ) {
          pdf.text(
            datosFactura.Facturas[0].TituloFactura,
            centrado,
            (y = y + espaciado + 1),
            'center'
          );
        } else {
          //pdf.text('FACTURA', 37, (y = y + 5), 'center');
          pdf.text(
            this.translate.instant('JUSTIFICANTE_FACTURA'),
            centrado,
            (y = y + espaciado + 1),
            'center'
          );
        }

        pdf.setFontSize(tamanioLetra);
        // line
        pdf.line(x, (y += espaciado), finalx, y);

        /*     // datos emisor
      pdf.text('DIRECCION', 37, (y = y + 4), 'center');
      pdf.text(datosFactura.Facturas[0].CifPersonaJuridica, 6, (y = y + 4), {
        maxWidth: 60,
        align: 'left'
      });
      pdf.text(datosFactura.Facturas[0].DireccionFiscalPersonaJuridica, 6, (y = y + 4), {
        maxWidth: 60,
        align: 'left'
      });

      // line
      pdf.line(6, (y += 16), 70, y); */

        // datos receptor
        //pdf.text('DATOS DEL CLIENTE', 37, (y = y + 4), 'center');
        pdf.text(
          this.translate.instant('JUSTIFICANTE_DATOS_CLIENTE'),
          centrado,
          (y = y + espaciado),
          'center'
        );

        pdf.text(
          datosFactura.Facturas[0].NombreCliente,
          x,
          (y = y + espaciado),
          {
            maxWidth: finalx - 10,
            align: 'left',
          }
        );
        if (datosFactura.Facturas[0].NombreCliente.length > 40)
          pdf.text('', centrado, (y += espaciado), 'right');
        pdf.text(
          datosFactura.Facturas[0].DomicilioCliente,
          x,
          (y = y + espaciado),
          {
            maxWidth: finalx - 10,
            align: 'left',
          }
        );
        if (datosFactura.Facturas[0].DomicilioCliente.length > 40)
          pdf.text('', centrado, (y += espaciado), 'right');
        const cpCiudad =
          datosFactura.Facturas[0].CPCliente +
          ' - ' +
          datosFactura.Facturas[0].LocalidadCliente;
        pdf.text(`${cpCiudad}`, x, (y = y + espaciado), {
          maxWidth: finalx - 10,
          align: 'left',
        });
        if (cpCiudad.length > 40)
          pdf.text('', centrado, (y += espaciado), 'right');
        pdf.text(datosFactura.Facturas[0].CifCliente, x, (y = y + espaciado), {
          maxWidth: finalx - 10,
          align: 'left',
        });
        if (datosFactura.Facturas[0].CifCliente.length > 40)
          pdf.text('', centrado, (y += espaciado), 'right');
        // line
        pdf.line(x, (y += espaciado), finalx, y);

        // numero de albaran
        pdf
          // .text('IDENTIFICADOR', 6, (y += 4), 'left')
          .text(
            this.translate.instant('JUSTIFICANTE_IDENTIFICADOR'),
            x,
            (y += espaciado),
            'left'
          )
          .text(datosFactura.Facturas[0].CadClaveReserva, finalx, y, 'right');

        // numero de factura
        const numFactura =
          datosFactura.Facturas[0].LetraFactura +
          '-' +
          datosFactura.Facturas[0].NumFactura;
        pdf
          //.text('Nº FACTURA', 6, (y += 4), 'left')
          .text(
            this.translate.instant('JUSTIFICANTE_No_FACTURA'),
            x,
            (y += espaciado),
            'left'
          )
          .text(`${numFactura}`, finalx, y, 'right');

        //Yaribel 20210312  Añadimos factura rectificada cuando son abonos
        //factura Rectificada
        if (+datosFactura.Facturas[0].TotalFactura <= 0) {
          const numFacturaRectificada =
            datosFactura.Facturas[0].AbonoSerie +
            '-' +
            datosFactura.Facturas[0].Abono;
          pdf
            //.text('Nº FACTURA RECTIFICADA', 6, (y += 4), 'left')
            .text(
              this.translate.instant('JUSTIFICANTE_No_FACTURA_RECTIFICADA'),
              x,
              (y += espaciado),
              'left'
            )
            .text(`${numFacturaRectificada}`, finalx, y, 'right');
        }

        // Yaribel 20201222 Añadimos fecha de emision de la factura
        //Fecha de emision Factura
        pdf
          //.text('FECHA DE EMISION', 6, (y += 4), 'left')
          .text(
            this.translate.instant('JUSTIFICANTE_FECHA_EMISION'),
            x,
            (y += espaciado),
            'left'
          )
          .text(datosFactura.Facturas[0].FechaEmision, finalx, y, 'right');

        // Yaribel 20201222 Añadimos cabecera de los items
        //Cabecera de los items
        pdf
          //  .text('UDS.', 6, (y += 10), 'left')
          // .text('DESCRIPCION', 15, y, 'left')
          // .text('IMPORTE', 70, y, 'right');
          .text(
            this.translate.instant('JUSTIFICANTE_UDS'),
            x,
            (y += espaciado + espaciado),
            'left'
          )
          .text(
            this.translate.instant('JUSTIFICANTE_DESCRIPCION'),
            tab1,
            y,
            'left'
          )
          .text(
            this.translate.instant('JUSTIFICANTE_IMPORTE'),
            finalx,
            y,
            'right'
          );
        // >> Yaribel 20210115 modificamos la impresion de item para añadir la nueva funcion que controla la longitud de la descripcion
        // items
        datosFactura.Facturas[0].TiposEntrada.forEach((item) => {
          let ArrayItemImpresion: string[] = [];
          ArrayItemImpresion = documentBody.getArrayNombreImpresion(
            item.NombreImpresionTipoEntrada,
            ArrayItemImpresion
          );
          pdf
            .setFontSize(tamanioLetra)
            .text(item.CantUnidades, x + 5, (y += espaciado + 1), 'right')
            .setFontSize(tamanioLetra - 2)
            .text(ArrayItemImpresion[0], tab1, y, 'left')
            .setFontSize(tamanioLetra)
            .text(
              `${documentBody.addDotsToNumber(
                Number.parseFloat(item.PrecioFinalFila.replace(',', '.'))
                  .toFixed(2)
                  .replace('.', ',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
              )} €`,
              finalx,
              y,
              'right'
            );
          if (ArrayItemImpresion.length > 1) {
            for (let i = 1; i < ArrayItemImpresion.length; i++) {
              pdf
                .setFontSize(tamanioLetra - 2)
                .text(
                  ArrayItemImpresion[i],
                  tab1,
                  (y += espaciado - 1),
                  'left'
                );
            }
          }
          if (documentBody.obtenerCodigoTarjetaMonedero(item).length > 0) {
            pdf
              .setFontSize(tamanioLetra - 2)
              .text(
                item.TarjetaMonederoCodigo,
                tab1,
                (y += espaciado - 1),
                'left'
              );
          }
        });

        // Yaribel 20210115 >>
        // Desglose de prepagos
        pdf.setFontSize(tamanioLetra);
        if (
          datosFactura.Facturas[0].FacturasPrepago &&
          datosFactura.Facturas[0].FacturasPrepago != null &&
          datosFactura.Facturas[0].FacturasPrepago != undefined
        ) {
          //subTitulo
          pdf.line(x, (y += espaciado), finalx, y);
          // pdf.text('DESGLOSE PREPAGOS FACTURADOS', 37, (y = y + 4), 'center');
          pdf.text(
            this.translate.instant('DESGLOSE_PREPAGOS_FACTURADOS'),
            centrado,
            (y = y + espaciado),
            'center'
          );

          pdf
            // .text('Nº FACTURA', 6, (y += 7), 'left')
            // .text('FECHA', 32, y, 'left')
            // .text('TOTAL', 70, y, 'right');
            .text(
              this.translate.instant('JUSTIFICANTE_No_FACTURA'),
              x,
              (y += espaciado + 3),
              'left'
            )
            .text(this.translate.instant('FECHA'), tab2, y, 'left')
            .text(this.translate.instant('TOTAL'), finalx, y, 'right');
          datosFactura.Facturas[0].FacturasPrepago.forEach((prepago) => {
            pdf
              .text(
                prepago.LetraFactura + '-' + prepago.NumFactura,
                x,
                (y += espaciado),
                'left'
              )
              .text(prepago.FechaEmision, tab2 - 5, y, 'left')
              .text(
                `${documentBody.addDotsToNumber(
                  Number.parseFloat(prepago.TotalFactura.replace(',', '.'))
                    .toFixed(2)
                    .replace('.', ',')
                )} €`,
                finalx,
                y,
                'right'
              );
          });
        }
        // medios de pago
        /*    pdf.text('MEDIO DE PAGO', 6, (y += 5), 'left');
      pdf
        .text(`${this.generarFOP(paymentMethod)}:`, 6, (y += 4), 'left')
        .text(`${this.addDotsToNumber(received)} €`, 70, y, 'right'); */

        // line
        pdf.line(x, (y += espaciado), finalx, y);
        // desglose iva
        let totalBase = 0;
        let totalIva = 0;
        pdf
          // .text('% IVA', 6, (y += 7), 'left')
          // .text('BASE IMPONIBLE', 20, y, 'left')
          // .text('IMPORTE IVA', 70, y, 'right');
          .text('% IVA', x, (y += espaciado + 3), 'left')
          .text(
            this.translate.instant('JUSTIFICANTE_BASE_IMPONIBLE'),
            tab1 + 5,
            y,
            'left'
          )
          .text(
            this.translate.instant('JUSTIFICANTE_IMPORTE_IVA'),
            finalx,
            y,
            'right'
          );

        this.facturaService
          .recuperarDesgloseIVAFactura(datosFactura.Facturas[0])
          .subscribe((res) => {
            if (res) {
              res.DatosResult.FacturaIva.sort((a, b) =>
                a.PorcIva.localeCompare(b.PorcIva)
              );
              res.DatosResult.FacturaIva.forEach((item) => {
                const pvp = Number.parseFloat(
                  item.ImporteTotal.replace(',', '.')
                );
                const base = Number.parseFloat(
                  item.BaseImponible.replace(',', '.')
                );
                const iva = Number.parseFloat(
                  item.ImporteIva.replace(',', '.')
                );
                totalBase += base;
                totalIva += iva;

                pdf
                  .text(item.PorcIva, x + 5, (y += espaciado + 1), 'right')
                  .text(
                    `${documentBody.addDotsToNumber(
                      base.toFixed(2).replace('.', ',')
                    )} €`,
                    centrado,
                    y,
                    'right'
                  ) // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
                  .text(
                    `${documentBody.addDotsToNumber(
                      iva.toFixed(2).replace('.', ',')
                    )} €`,
                    finalx,
                    y,
                    'right'
                  ); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
              });
            } else {
              let agruparIVA: IvaDisct[] = [];

              datosFactura.Facturas[0].TiposEntrada.forEach((item) => {
                const pvp = Number.parseFloat(
                  item.PrecioFinalFila.replace(',', '.')
                );
                const base = Number.parseFloat(
                  item.PrecioIniFila.replace(',', '.')
                );
                const iva = pvp - base;
                totalBase += base;
                totalIva += iva;

                if (!agruparIVA.find((x) => x.pIva == item.Iva)) {
                  const agrupado = {
                    pIva: item.Iva,
                    datos: { base: 0, pvp: 0, iva: 0 },
                  };
                  agruparIVA.push(agrupado);
                }
                const agrupado = agruparIVA.find((x) => x.pIva == item.Iva);
                agruparIVA[agruparIVA.indexOf(agrupado)].datos.base += base;
                agruparIVA[agruparIVA.indexOf(agrupado)].datos.pvp += pvp;
                agruparIVA[agruparIVA.indexOf(agrupado)].datos.iva +=
                  pvp - base;

                // pdf
                //   .text(item.Iva, 11, (y += 5), 'right')
                //   .text(`${base.toFixed(2)} €`, 35, y, 'right')
                //   .text(`${iva.toFixed(2)} €`, 70, y, 'right');
              });

              agruparIVA.forEach((item) => {
                pdf
                  .text(item.pIva, x + 5, (y += espaciado + 1), 'right')
                  .text(
                    `${documentBody.addDotsToNumber(
                      item.datos.base.toFixed(2).replace('.', ',')
                    )} €`,
                    centrado,
                    y,
                    'right'
                  ) // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
                  .text(
                    `${documentBody.addDotsToNumber(
                      item.datos.iva.toFixed(2).replace('.', ',')
                    )} €`,
                    finalx,
                    y,
                    'right'
                  ); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
              });
            }
            pdf
              // .text('Total IVA', 15, (y += 5), 'left')
              .text(
                this.translate.instant('JUSTIFICANTE_TOTAL_IVA'),
                tab1,
                (y += espaciado + 1),
                'left'
              )
              .text(
                `${documentBody.addDotsToNumber(
                  totalIva.toFixed(2).replace('.', ',')
                )} €`,
                finalx,
                y,
                'right'
              ); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
            pdf
              // .text('Total base imponible', 15, (y += 5), 'left')
              .text(
                this.translate.instant('JUSTIFICANTE_TOTAL_BASE_IMPONIBLE'),
                tab1,
                (y += espaciado + 1),
                'left'
              )
              .text(
                `${documentBody.addDotsToNumber(
                  totalBase.toFixed(2).replace('.', ',')
                )} €`,
                finalx,
                y,
                'right'
              ); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma

            // total
            pdf.setFontStyle('bold');
            pdf.setFontSize(tamanioLetra + 1);
            pdf.line(x, (y += espaciado - 2), finalx, y);
            pdf
              // .text('T O T A L', 6, (y += 5), 'left')
              .text(
                this.translate.instant('JUSTIFICANTE_T_O_T_A_L'),
                x,
                (y += espaciado + 1),
                'left'
              )
              .text(
                `${documentBody.addDotsToNumber(
                  Number.parseFloat(
                    datosFactura.Facturas[0].TotalFactura.replace(',', '.')
                  )
                    .toFixed(2)
                    .replace('.', ',')
                )} €`,
                finalx,
                y,
                'right'
              );
            pdf.line(x, (y += espaciado - 2), finalx, y);
            pdf.setFontSize(tamanioLetra);
            pdf.setFontStyle('normal');

            // Metodos de Pago
            // pdf.text('MÉTODO DE PAGO', 6, (y += 5), 'left');
            pdf.text(
              this.translate.instant('JUSTIFICANTE_METODO_DE_PAGO'),
              centrado,
              (y += espaciado + 1),
              'center'
            );
            //pdf.line(x, (y += 1), 31, y);

            datosFactura.Facturas[0].FormasPago.forEach((element) => {
              if (+element.Importe != 0) {
                const nombreFOP = this.generarFOP(element.FormaPago);
                pdf
                  .text(`${nombreFOP}:`, x, (y += espaciado), {
                    maxWidth: finalx - 10,
                    align: 'left',
                  })
                  .text(
                    `${documentBody.addDotsToNumber(
                      //element.Importe
                      Number.parseFloat(element.Importe.replace(',', '.'))
                        .toFixed(2)
                        .replace('.', ',')
                    )} €`,
                    finalx,
                    y,
                    'right'
                  );
                if (nombreFOP.length > 30)
                  pdf.text('', finalx, (y += espaciado), 'right');
              }
            });

            pdf.line(x, (y += espaciado), finalx, y);

            // Yaribel 20201222 Modificamos la impresion del pie para que utilice los datos de cnfiguracion en cabecera
            // footer
            if (data && data.IncluirEncabezadoTicket === 'True') {
              if (data.chPie1 !== '') {
                pdf.text(data.chPie1, x, (y = y + espaciado), 'left');
              }
              if (data.chPie2 !== '') {
                pdf.text(data.chPie2, x, (y = y + espaciado), 'left');
              }
              if (data.chPie3 !== '') {
                pdf.text(data.chPie3, x, (y = y + espaciado), 'left');
              }
              if (data.chPie4 !== '') {
                pdf.text(data.chPie4, x, (y = y + espaciado), 'left');
              }
              if (data.chPie5 !== '') {
                pdf.text(data.chPie5, x, (y = y + espaciado), 'left');
              }
              if (data.chPie6 !== '') {
                pdf.text(data.chPie6, x, (y = y + espaciado), 'left');
              }
            } else {
              //pdf.text('Gracias por su visita', 37, (y += 3), 'center');
              pdf.text(
                this.translate.instant('JUSTIFICANTE_GRACIAS_POR_SU_VISITA'),
                centrado,
                (y += espaciado - 1),
                'center'
              );
            }
            if (
              this.window.external &&
              typeof this.window.external.imprimirPDFBinario === 'function'
            ) {
              this.window.external.imprimirPDFBinario(
                this.generatePDFBinario(pdf),
                'justificante'
              );
              // this.documentBody.save('justificante.pdf');
            }
            // pdf.save('justificante.pdf');   //Yaribel 18112020 Cambiamos el nombre del archivo de justificante a Factura
            pdf.save(`${this.translate.instant('factura')}.pdf`);
            this.printFacturaSubject.next(true);
          });
      });
    } catch (e) {
      console.error('Ocurrió un error inesperado al generar la factura: ' + e);
    }
  }
  printFacturaPDF(datosFactura) {
    //Falta en los datos de factura el  CodIdiomaDocumento
    //datosFactura.Facturas[0].CodigoCliente  && datosFactura.Facturas[0].ClienteId
    //Obtener cliente con /api/GetClientesXFiltro y filtrar el que coincida datosFactura.Facturas[0].CodigoCliente === x.Codigo  && datosFactura.Facturas[0].ClienteId === x.pkId
    let clientes;
    this.clienteService
      .findClientes(datosFactura.Facturas[0].Codigo)
      .subscribe((res: any) => {
        clientes = res || [];

        const cliente = clientes.find(
          (x) =>
            datosFactura.Facturas[0].Codigo === x.Codigo &&
            datosFactura.Facturas[0].ClienteId === x.pkId
        );
        let oldLang;
        if (cliente && cliente.CodIdiomaDocumento) {
          oldLang = this.translate.getLocale();
          this.translate.use(cliente.CodIdiomaDocumento);
          this.imprimirSub = this.translate.cambioIdioma$.subscribe((resp) => {
            if (resp) {
              this.printDocumentoFacturaPdf(datosFactura);
              this.imprimirFacturaSub = this.printFactura$.subscribe((res) => {
                if (res) {
                  this.translate.use(oldLang);
                  if (this.imprimirFacturaSub) {
                    this.imprimirFacturaSub.unsubscribe();
                  }
                }
              });
              if (this.imprimirSub) {
                this.imprimirSub.unsubscribe();
              }
            }
          });
        } else {
          this.printDocumentoFacturaPdf(datosFactura);
        }
      });
  }

  printJointTickets(
    nombreTpv,
    idTpv,
    idAlbaran,
    infoImpresion,
    fechaActividad,
    entradas,
    identificador,
    fechaCompra,
    horaCompra,
    recinto,
    motivos,
    tipoImpresion
  ) {
    const empresa = this.config.getConfig('chClaveEmpresa');
    this.datosInicio = this.configuration.datosInicioValue;
    entradas.forEach((element, index) => {
      const options: JointTicketOptions = {
        empresa: empresa,
        NIFEmpresa: this.datosInicio.CIF,
        qrCode:
          infoImpresion.ListadoCbQR[index].ImprimirBarCode == '1' ||
          infoImpresion.ListadoCbQR[index].ImprimirBarCode === 'True'
            ? infoImpresion.ListadoCbQR[index].texcoCB
            : '',
        albaranId: idAlbaran,
        entrada: element,
        totalQuantity: element.cantidad,
        tpvName: nombreTpv,
        boughtAt: fechaCompra,
        boughtAtHour: horaCompra,
        entradaDate: fechaActividad,
        //entrada: element.entrada,
        index: index + 1,
        totalEntradasConjuntas: entradas.length,
        locator: identificador,
        paseHorario: this.ventaService.paseHorario, //undefined,
        recinto: recinto,
        motivos: motivos,
        tipoImpresion: tipoImpresion,
      };

      // const jointTicket = PrinterFactory.getPrinter(PrintableType.JOINT_TICKET);
      // options.paseHorario = this.ventaService.paseHorario;
      // jointTicket
      //   .paint(options)
      //   .print(this.config.getConfig('CONNECTION_TYPE'));

      const opciones = this.crearPlantillaTickets();
      let pdf = new jspdf(opciones.opt);
      //pdf = this.getContenidoTicketUnico(opciones,pdf,options);
      // if (
      //   this.window.external &&
      //   typeof this.window.external.imprimirPDFBinario === 'function'
      // ) {
      //   this.window.external.imprimirPDFBinario(
      //     this.generatePDFBinario(pdf),
      //     'ticket'
      //   );
      // }
      // if (pdf.getNumberOfPages() != 0) {pdf.save('ticket.pdf');}
    });
  }

  private generatePDFBinario(pdf) {
    return this.arrayBufferToBase64(pdf.output('arraybuffer'));
  }

  private arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  printDocumento(options, printableType, translate, config, configuration) {
    const albaranPrinter = PrinterFactory.getPrinter(
      printableType,
      translate,
      config,
      configuration
    );
    albaranPrinter
      .paint(options)
      .print(this.config.getConfig('CONNECTION_TYPE'));
  }

  printPurchaseAlbaran(albaran, cabecera) {
    let oldLang;
    const options = {
      albaran: albaran,
      cabecera: cabecera,
    };
    //albaran.datos.AlbaranCliente[0].CodIdiomaDocumento
    if (
      albaran.datos.AlbaranCliente &&
      albaran.datos.AlbaranCliente[0] &&
      albaran.datos.AlbaranCliente[0].CodIdiomaDocumento
    ) {
      oldLang = this.translate.getLocale();
      this.translate.use(albaran.datos.AlbaranCliente[0].CodIdiomaDocumento);
      this.imprimirSub = this.translate.cambioIdioma$.subscribe((resp) => {
        if (resp) {
          this.printDocumento(
            options,
            PrintableType.PURCHASE_ALBARAN,
            this.translate,
            this.config,
            this.configuration
          );
          this.translate.use(oldLang);
          this.imprimirSub.unsubscribe();
        }
      });
    } else {
      this.printDocumento(
        options,
        PrintableType.PURCHASE_ALBARAN,
        this.translate,
        this.config,
        this.configuration
      );
    }
  }

  printRefundAlbaran(albaran, cabecera?) {
    let oldLang;
    const options = {
      albaran: albaran,
      cabecera: cabecera, //Yaribel 20201222 Añadimos cabecera para imprimir los datos de la cabecera en los justificantes con localizador
    };
    //albaran.datos.AlbaranCliente[0].CodIdiomaDocumento
    if (
      albaran.datos.AlbaranCliente &&
      albaran.datos.AlbaranCliente[0] &&
      albaran.datos.AlbaranCliente[0].CodIdiomaDocumento
    ) {
      oldLang = this.translate.getLocale();
      this.translate.use(albaran.datos.AlbaranCliente[0].CodIdiomaDocumento);
      this.imprimirSub = this.translate.cambioIdioma$.subscribe((resp) => {
        if (resp) {
          this.printDocumento(
            options,
            PrintableType.REFUND_ALBARAN,
            this.translate,
            this.config,
            this.configuration
          );
          this.translate.use(oldLang);
          this.imprimirSub.unsubscribe();
        }
      });
    } else {
      this.printDocumento(
        options,
        PrintableType.REFUND_ALBARAN,
        this.translate,
        this.config,
        this.configuration
      );
    }
    //if(oldLang){this.translate.use(oldLang); }
  }

  printPartialRefund(albaran, cabecera?) {
    const options = {
      albaran: albaran,
      cabecera: cabecera, //Yaribel 20201222 Añadimos cabecera para imprimir los datos de la cabecera en los justificantes con localizador
    };
    //albaran.datos.AlbaranCliente[0].CodIdiomaDocumento
    let oldLang;
    if (
      albaran.datos.AlbaranCliente &&
      albaran.datos.AlbaranCliente[0] &&
      albaran.datos.AlbaranCliente[0].CodIdiomaDocumento
    ) {
      oldLang = this.translate.getLocale();
      this.translate.use(albaran.datos.AlbaranCliente[0].CodIdiomaDocumento);
      this.imprimirSub = this.translate.cambioIdioma$.subscribe((resp) => {
        if (resp) {
          this.printDocumento(
            options,
            PrintableType.PARTIAL_REFUND_ALBARAN,
            this.translate,
            this.config,
            this.configuration
          );
          this.translate.use(oldLang);
          this.imprimirSub.unsubscribe();
        }
      });
    } else {
      this.printDocumento(
        options,
        PrintableType.PARTIAL_REFUND_ALBARAN,
        this.translate,
        this.config,
        this.configuration
      );
    }
    //if(oldLang){this.translate.use(oldLang); }
  }

  printOnlineSourceAlbaran(albaran, online, ventaOnline, cabecera?) {
    const options = {
      albaran: albaran,
      online: online,
      ventaOnline: ventaOnline,
      cabecera: cabecera, //Yaribel 20201222 Añadimos cabecera para imprimir los datos de la cabecera en los justificantes con localizador
    };

    let oldLang;
    if (
      albaran.datos.AlbaranCliente &&
      albaran.datos.AlbaranCliente[0] &&
      albaran.datos.AlbaranCliente[0].CodIdiomaDocumento
    ) {
      oldLang = this.translate.getLocale();
      this.translate.use(albaran.datos.AlbaranCliente[0].CodIdiomaDocumento);
      this.imprimirSub = this.translate.cambioIdioma$.subscribe((resp) => {
        if (resp) {
          this.printDocumento(
            options,
            PrintableType.ONLINE_SOURCE_ALBARAN,
            this.translate,
            this.config,
            this.configuration
          );
          this.translate.use(oldLang);
          this.imprimirSub.unsubscribe();
        }
      });
    } else {
      this.printDocumento(
        options,
        PrintableType.ONLINE_SOURCE_ALBARAN,
        this.translate,
        this.config,
        this.configuration
      );
    }
    //if(oldLang){this.translate.use(oldLang); }
  }

  printLabel(labelData) {
    const options: LabelOptions = {
      data: labelData,
    };
    const labelPrinter = PrinterFactory.getPrinter(
      PrintableType.LABEL,
      this.translate,
      this.config,
      this.configuration
    );
    labelPrinter.paint(options).print(this.config.getConfig('CONNECTION_TYPE'));
  }

  printAmendingInvoice(data) {
    const options = {
      document: data,
    };
    const amendingInvoicePrinter = PrinterFactory.getPrinter(
      PrintableType.AMENDING_INVOICE,
      this.translate,
      this.config,
      this.configuration
    );
    amendingInvoicePrinter
      .paint(options)
      .print(this.config.getConfig('CONNECTION_TYPE'));
  }

  printPurchaseInvoice(data) {
    const options = {
      document: data,
    };

    const purchaseInvoicePrinter = PrinterFactory.getPrinter(
      PrintableType.PURCHASE_INVOICE,
      this.translate,
      this.config,
      this.configuration
    );
    purchaseInvoicePrinter
      .paint(options)
      .print(this.config.getConfig('CONNECTION_TYPE'));
  }

  decode(res) {
    const linkSource = 'data:application/pdf;base64,' + res + '\n';
    const downloadLink = document.createElement('a');
    const fileName = `${this.translate.instant('factura')}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  printCashClosureReport(data) {
    let options = {};
    const cashClosureReportPrinter = PrinterFactory.getPrinter(
      PrintableType.CASH_CLOSURE_REPORT,
      this.translate,
      this.config,
      this.configuration
    );
    options = { document: null, data: data };
    cashClosureReportPrinter
      .paint(options)
      .print(this.config.getConfig('CONNECTION_TYPE'));
  }

  printCashOpeningReport(data) {
    let options = {};
    const cashOpeningReportPrinter = PrinterFactory.getPrinter(
      PrintableType.CASH_OPENING_REPORT,
      this.translate,
      this.config,
      this.configuration
    );
    options = { document: null, data: data };
    cashOpeningReportPrinter
      .paint(options)
      .print(this.config.getConfig('CONNECTION_TYPE'));
  }

  printRandomArchingReport() {
    const options = {};
    const randomArchingReportPrinter = PrinterFactory.getPrinter(
      PrintableType.RANDOM_ARCHING_REPORT,
      this.translate,
      this.config,
      this.configuration
    );
    randomArchingReportPrinter
      .paint(options)
      .print(this.config.getConfig('CONNECTION_TYPE'));
  }

  private generarFOP(fop) {
    switch (fop) {
      // case 'CA':
      //   return 'EFECTIVO';
      // case 'CC':
      //   return 'TARJETA';
      // case 'TR':
      //   return 'TRANSFERENCIA';
      // case 'TM':
      //   return 'TARJETA MONEDERO';  //Yaribel 11112020 añadimos forma de pago Tarjeta Monedero
      // case 'DP':
      //   return 'APLAZADO';
      // case 'PR':
      //   return 'PREPAGO'; //Yaribel 20201217 añadimos forma de pago Prepago
      // default:
      //   return 'EFECTIVO';
      case 'CA':
        return this.translate.instant('EFECTIVO');
      case 'CC':
        return this.translate.instant('TARJETA');
      case 'TR':
        return this.translate.instant('TRANSFERENCIA');
      case 'TM':
        return this.translate.instant('JUSTIFICANTE_TARJETA_MONEDERO');
      case 'DP':
        return this.translate.instant('APLAZADO');
      case 'PR':
        return this.translate.instant('PREPAGO');
      default:
        return this.translate.instant('EFECTIVO');
    }
  }

  imprimirTicketUnico(
    nombreTpv,
    idTpv,
    idAlbaran,
    infoImpresion,
    fechaActividad,
    entradas,
    identificador,
    fechaCompra,
    horaCompra,
    recintos,
    motivos,
    localizador,
    tipoImpresion,
    NumEntradas?
  ) {
    this.configuration
      .getAppConfig(localStorage.getItem('TpvId'))
      .subscribe((data) => {
        this.datosPrinter = data.DatosResult.AppConfigJsonTicketPrinter;
        this.pagoService.datosCabecera().subscribe((data2: any) => {
          this.cabecera = data2;
          const conexionId = JSON.parse(localStorage.getItem('conexionId'));

          let recintoSinDuplicar = [];

          const body = {
            ConexionIacpos: conexionId,
            TPVId: this.configuration.TPVEnUsoValue,
            ClaveCentro: localStorage.getItem('claveCentro'),
            NumAlbaran: idAlbaran,
            Pagina: '1',
            NumeroRegistros: '1',
          };
          this.http
            .post(
              `${this.config.getConfig('API_URL')}api/GetDatosAlbaran`,
              body
            )
            .subscribe((data) => {
              this.datosCliente = data;
              const configuracion = this.crearPlantillaTickets();
              let pdf = new jspdf(configuracion.opt);
              pdf.setFontSize(8);
              let documentBody = new AlbaranDocumentBody(
                this.translate,
                this.config,
                this.configuration
              ); // utilizamos la clase para el metodo addDotsToNumber
              //Variables para separar entradas conjuntas y  la impresion conjunta de bono
              let codigoBono = 0; // nueva variable que indica si se imprime el bono junto
              let entradasConjuntas; //indica si se imprimen las entradas del mismo tipo juntas
              //variables que asignamos los parametros de entradas para unificar la impresion
              let nombreRecinto;
              let fecha;
              let hora;
              let motivoGrupo;
              let precio;
              let cantidad;
              let recintosImprimir = [];
              let nombreImpresion;
              let textoQr;
              let numelemento = 0;
              const numPromoImpreso = [];
              infoImpresion.forEach((element: any, index) => {
                recintosImprimir = [];
                // const qrCode = element.ImprimirBarCode == '1' || element.ImprimirBarCode === 'True'? element.texcoCB : element.InfoTickets && element.InfoTickets.urlExterno? element.InfoTickets.urlExterno :'';
                // if(qrCode != '') {
                if ((element.ImprimirTicket == '1' || element.ImprimirTicket == 'True') && element.InfoTickets) {
                  if (element.InfoTickets.TipoPromocionId === '') {
                    if (element.InfoTickets.recintosImprimir && element.InfoTickets.recintosImprimir.length > 0 && element.InfoTickets.recintosImprimir[
                        index > element.InfoTickets.recintosImprimir.length - 1
                          ? 0
                          : index
                      ] != undefined
                    ) {
                      fecha = moment(
                        element.InfoTickets.recintosImprimir[
                          index >
                          element.InfoTickets.recintosImprimir.length - 1
                            ? 0
                            : index
                        ].FechaHoraEntrada
                      ).format('DD/MM/YYYY');
                      hora = element.InfoTickets.recintosImprimir[
                        index > element.InfoTickets.recintosImprimir.length - 1
                          ? 0
                          : index
                      ].FechaHoraEntrada.slice(11, -3);
                      nombreRecinto =
                        element.InfoTickets.recintosImprimir[
                          index >
                          element.InfoTickets.recintosImprimir.length - 1
                            ? 0
                            : index
                        ].NombreRecinto;
                      recintosImprimir.push({
                        fecha: fecha,
                        hora: hora,
                        nombreRecinto: nombreRecinto,
                      });
                    }
                    // precio = entradas[0].itemInfo? (entradas.find((x) => x.itemInfo.TipoEntradaId === element.InfoTickets.TipoEntradaId)? entradas.find((x) => x.itemInfo.TipoEntradaId === element.InfoTickets.TipoEntradaId).PrecioEnDivisaReferencia: '') :
                    // (entradas.find((x) => x.TipoEntradaId === element.InfoTickets.TipoEntradaId)? entradas.find((x) => x.TipoEntradaId === element.InfoTickets.TipoEntradaId).PrecioEnDivisaReferencia: '');
                    precio = entradas[0].itemInfo
                      ? entradas.find(
                          (x) =>
                            x.itemInfo.TipoEntradaId ===
                              element.InfoTickets.TipoEntradaId &&
                            x.itemInfo.CodigoPromocional ==
                              element.InfoTickets.CodigoPromocional
                        )
                        ? entradas.find(
                            (x) =>
                              x.itemInfo.TipoEntradaId ===
                                element.InfoTickets.TipoEntradaId &&
                              x.itemInfo.CodigoPromocional ==
                                element.InfoTickets.CodigoPromocional
                          ).itemInfo.PrecioUnidad
                        : ''
                      : entradas.find(
                          (x) =>
                            x.ItemId === element.InfoTickets.TipoEntradaId &&
                            x.CodigoPromocional ==
                              element.InfoTickets.CodigoPromocional
                        )
                      ? entradas.find(
                          (x) =>
                            x.ItemId === element.InfoTickets.TipoEntradaId &&
                            x.CodigoPromocional ==
                              element.InfoTickets.CodigoPromocional
                        ).PrecioUnidad
                      : '';
                    nombreImpresion = element.InfoTickets.NombreEntrada;
                  } else {
                    const entrada = entradas[0].itemInfo
                      ? entradas.find(
                          (x) =>
                            x.itemInfo.TipoPromocionId ===
                              element.InfoTickets.TipoPromocionId &&
                            x.itemInfo.CodigoPromocional ==
                              element.InfoTickets.CodigoPromocional
                        ).itemInfo
                      : entradas.find(
                          (x) =>
                            x.TipoProducto === '2' &&
                            x.ItemId === element.InfoTickets.TipoPromocionId &&
                            x.CodigoPromocional ==
                              element.InfoTickets.CodigoPromocional
                        );
                    if (element.InfoTickets.ImprimirQRPromo == '1') {
                      if (
                        !numPromoImpreso.find(
                          (x) =>
                            x.TipoPromoId ===
                              element.InfoTickets.TipoPromocionId &&
                            x.numPromo === element.InfoTickets.NumOrdenPromo
                        )
                      ) {
                        const miListado = infoImpresion.ListadoCbQR.filter(
                          (x) =>
                            x.InfoTickets.NumOrdenPromo ===
                              element.InfoTickets.NumOrdenPromo &&
                            x.InfoTickets.TipoPromocionId ===
                              element.InfoTickets.TipoPromocionId
                        );
                        if (miListado) {
                          miListado.forEach((info) => {
                            if (info.InfoTickets.recintosImprimir) {
                              
                              //let iFoundRecinto = false;
                              info.InfoTickets.recintosImprimir.forEach(
                                (recinto) => {

                                  // let rec = recintoSinDuplicar.find(r => 
                                  //   r.NombreRecinto == recinto.NombreRecinto &&
                                  //   r.FechaHoraEntrada == recinto.FechaHoraEntrada);

                                  // // busco el recito que no haya sido duplicado
                                  // if (!rec && !iFoundRecinto) {

                                    const hora = moment(
                                      recinto.FechaHoraEntrada,
                                      'YYYY/MM/DD HH:mm:ss'
                                    ).format('HH:mm');
                                    const nombreRecinto = recinto.NombreRecinto;
                                    const fecha = moment(
                                      recinto.FechaHoraEntrada,
                                      'YYYY/MM/DD HH:mm:ss'
                                    ).format('DD/MM/YYYY');
                                    recintosImprimir.push({
                                      fecha: fecha,
                                      hora: hora,
                                      nombreRecinto: nombreRecinto,
                                    });

                                    recintoSinDuplicar.push(recinto);

                                    //iFoundRecinto = true;

                                  // }

                                }
                              );
                            }
                          });
                          numPromoImpreso.push({
                            TipoPromoId: element.InfoTickets.TipoPromocionId,
                            numPromo: element.InfoTickets.NumOrdenPromo,
                          });
                        }
                      }
                    } else {
                      if (
                        element.InfoTickets.recintosImprimir &&
                        element.InfoTickets.recintosImprimir.length > 0 &&
                        element.InfoTickets.recintosImprimir[
                          index >
                          element.InfoTickets.recintosImprimir.length - 1
                            ? 0
                            : index
                        ] != undefined
                      ) {
                        fecha = moment(
                          element.InfoTickets.recintosImprimir[
                            index >
                            element.InfoTickets.recintosImprimir.length - 1
                              ? 0
                              : index
                          ].FechaHoraEntrada
                        ).format('DD/MM/YYYY');
                        hora = element.InfoTickets.recintosImprimir[
                          index >
                          element.InfoTickets.recintosImprimir.length - 1
                            ? 0
                            : index
                        ].FechaHoraEntrada.slice(11, -3);
                        nombreRecinto =
                          element.InfoTickets.recintosImprimir[
                            index >
                            element.InfoTickets.recintosImprimir.length - 1
                              ? 0
                              : index
                          ].NombreRecinto;
                        recintosImprimir.push({
                          fecha: fecha,
                          hora: hora,
                          nombreRecinto: nombreRecinto,
                        });
                      }
                    }

                    // if(entradas[0].itemInfo){
                    //   precio = entrada.itemInfo.PrecioUnidad;
                    //   nombreImpresion =  entrada.itemInfo.NombreImpresion;  //element.InfoTickets.NombreEntrada;

                    // } else{
                    //   precio = entrada.PrecioUnidad;
                    //   nombreImpresion = entrada.NombreImpresion;
                    // }

                    precio = entrada ? entrada.PrecioUnidad : 0;
                    nombreImpresion = entrada ? entrada.NombreImpresion : '';
                  }
                  if (motivos) {
                    motivoGrupo = motivos.find(
                      (x) => x.NumTicket == element.NumOrden
                    )
                      ? motivos.find((x) => x.NumTicket == element.NumOrden)
                          .ItemNombre
                      : '';
                  }
                  cantidad = element.InfoTickets.Cantidad;
                  //textoQr = element.texcoCB;
                  textoQr =
                    element.ImprimirBarCode == '1' ||
                    element.ImprimirBarCode === 'True'
                      ? element.texcoCB
                      : '';
                  // if(recintosImprimir.length > 0){
                  const options: TicketsOptions = {
                    recintosImprimir: recintosImprimir,
                    nombreImpresion: nombreImpresion,
                    precio: precio,
                    cantidad: cantidad,
                    textoQr: textoQr,
                    motivoGrupo: motivoGrupo,
                    idAlbaran: idAlbaran,
                    idTpv: idTpv,
                    nombreTpv: nombreTpv,
                    localizador: localizador,
                    fechaCompra: fechaCompra,
                    horaCompra: horaCompra,
                    urlExterno: element.InfoTickets.urlExterno
                      ? element.InfoTickets.urlExterno
                      : '',
                    ImprimirPrecio: element.InfoTickets.ImprimirPrecio
                      ? element.InfoTickets.ImprimirPrecio
                      : '0',
                    ImprimirBarCode: element.ImprimirBarCode
                      ? element.ImprimirBarCode
                      : '0',
                    espectaculosImprimir:
                      element.InfoTickets.EspectaculosImprimir,
                  };
                  pdf = this.printTicket(
                    configuracion,
                    pdf,
                    options,
                    infoImpresion
                  );
                  pdf.addPage();
                  numelemento++;
                  // }
                }
              });

              //Como siempre añadimos una ultima pagina al final que queda en blanco, eliminamos la ultima
              pdf.deletePage(numelemento + 1);

              if (pdf.getNumberOfPages() != 0) {
                if (
                  this.window.external &&
                  typeof this.window.external.imprimirPDFBinario === 'function'
                ) {
                  this.window.external.imprimirPDFBinario(
                    this.generatePDFBinario(pdf),
                    'ticket'
                  );
                }
                pdf.save(`${this.translate.instant('ticket')}.pdf`);
              }
            });
        });
      });

    //}
  }

  printTicket(configuracion, pdf, options, infoImpresion) {
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    let x = configuracion.inicialx;
    let y = configuracion.inicialy;
    let documentBody = new AlbaranDocumentBody(
      this.translate,
      this.config,
      this.configuration
    );
    switch (configuracion.empresa) {
      case 'PEDRERA':
        //IMPRESORA JUSTIFICANTES
        if (this.datosPrinter.TPrinterImpresoraSistema === '1') {
          if (this.cabecera) {
            pdf.text(this.cabecera.chLinea1, 5, 5, {});
            pdf.text(this.cabecera.chLinea2, 10, 10, {});
            pdf.text(this.cabecera.chLinea3, 5, 15, {});
            pdf.text(this.cabecera.chLinea4, 20, 20, {});
          }
          pdf.text(
            '...................................................................',
            5,
            25,
            {}
          );
          pdf.text(
            options.nombreImpresion + '' + '(' + options.cantidad + ')',
            5,
            30,
            {}
          );
          if (
            //this.turnoService.turnoValue.TpvId !== '3' &&
            options.ImprimirPrecio == 1 ||
            options.ImprimirPrecio == true
          ) {
            pdf
              .text(this.translate.instant('TICKET_PRECIO') + ': ', 5, 35, {})
              .text(
                Number.parseFloat(options.precio.replace(',', '.'))
                  .toFixed(2)
                  .replace('.', ',') + '€',
                15,
                35,
                {}
              )
              .setFontStyle('normal');
            if (this.datosCliente) {
              if (
                this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                  .AlbaranItems[0].DesglosarComision === '1'
              ) {
                pdf.text(
                  '(' +
                    this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                      .AlbaranItems[0].Comision +
                    '€ en comisión)',
                  30,
                  40,
                  {}
                );
              }
            }
          } else {
            if (
              //this.turnoService.turnoValue.TpvId !== '3' &&
              options.ImprimirPrecio == 1 ||
              options.ImprimirPrecio == true
            ) {
              //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y+=configuracion.espaciado, x - configuracion.tab1, { angle: configuracion.rotacion });
              pdf
                .text(this.translate.instant('TICKET_PRECIO') + ': ', 5, 35, {})
                .text(
                  Number.parseFloat(options.precio.replace(',', '.'))
                    .toFixed(2)
                    .replace('.', ',') + '€',
                  20,
                  35,
                  {}
                )
                .setFontStyle('normal');
              if (this.datosCliente) {
                if (
                  this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                    .AlbaranItems[0].DesglosarComision === '1'
                ) {
                  pdf.text(
                    '(' +
                      this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                        .AlbaranItems[0].Comision +
                      '€ en comisión)',
                    30,
                    40,
                    {}
                  );
                }
              }
            }
          }

          let rowAxis = 40;
          const LINE_HEIGHT = 5;
          const addRow = () => { rowAxis = rowAxis + LINE_HEIGHT };

          //se imprimen el/los recintos
          if (
            options.recintosImprimir &&
            options.recintosImprimir != undefined &&
            options.recintosImprimir.length > 0
          ) {
            //let contador=0
            rowAxis = 40;
            options.recintosImprimir.forEach((recinto) => {
              let ArrayItemImpresion: string[] = [];
              
              ArrayItemImpresion = documentBody.getArrayNombreImpresion(
                options.recintosImprimir[0].nombreRecinto,
                ArrayItemImpresion,
                rowAxis
              );
              addRow();
              pdf.text(recinto.nombreRecinto, 5, rowAxis, {});
              addRow();
              pdf.text(recinto.fecha, 5, rowAxis, {});
              addRow();
              pdf.text(recinto.hora, 5, rowAxis, {});
              addRow();
            });
          }

          addRow();

          if (this.datosCliente && this.datosCliente.NombreCliente) {
            pdf.text(this.datosCliente.NombreCliente, 5, rowAxis, {});
          }
          if (this.datosCliente) {
            pdf.text(
              this.datosCliente.DatosResult.ListadoAlbaranInfo[0].NombreCliente,
              5,
              rowAxis,
              {}
            );
          }
          
          addRow();

          if (this.datosCliente && this.datosCliente.DatosResult) {
            pdf.text(
              this.datosCliente.DatosResult.ListadoAlbaranInfo[0].NumVenta,
              5,
              rowAxis,
              {}
            );
          }
          addRow();

          //Qr
          //Comprobamos si imprime barCode o tiene urlExterno
          if (
            options.ImprimirBarCode == '1' ||
            options.ImprimirBarCode == 'True'
          ) {
            QRCode.toCanvas(
              options.textoQr,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  configuracion.tab1 > 0 ? width : 0,
                  configuracion.tab1 > 0 ? x - 80 : rowAxis,
                  35,
                  35
                );
                //identificador
              }
            );
            addRow();
          }
          if (options.urlExterno && options.urlExterno != '') {
            QRCode.toCanvas(
              options.urlExterno,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  configuracion.tab1 > 0 ? width : 0,
                  configuracion.tab1 > 0 ? x - 80 : rowAxis,
                  35,
                  35
                );
              }
            );
            addRow();
          }
        }
        break;
      case 'REALESCUELA':

        const FONTSIZE = 6;
        const FONTNAME = 'Courier';

        if (
          infoImpresion.ListadoCbQR[0].copia > 0 &&
          infoImpresion.ListadoCbQR[0].InfoTickets.recintosImprimir === null
        ) {
          pdf
            .setFontSize(FONTSIZE)
            .setFontStyle('normal')
            .text(
            this.translate.instant('NUMERO_COPIA') +
              ' ' +
              infoImpresion.ListadoCbQR[0].copia,
            y,
            x,
            { angle: configuracion.rotacion }
          );
        }
        //se imprimen el/los recintos
        if (
          options.recintosImprimir &&
          options.recintosImprimir != undefined &&
          options.recintosImprimir.length > 0
        ) {
          options.recintosImprimir.forEach((recinto) => {
            let ArrayItemImpresion: string[] = [];
            ArrayItemImpresion = documentBody.getArrayNombreImpresion(
              recinto.nombreRecinto,
              ArrayItemImpresion,
              20
            );
            pdf
              .setFontSize(FONTSIZE)
              .setFont(FONTNAME)
              .setFontStyle('normal')
              .text(recinto.fecha, (y += configuracion.espaciado), x, {
              angle: configuracion.rotacion,
            });

            pdf
              .setFontSize(FONTSIZE)
              .setFontStyle('normal');

            //motivo de grupo y precio
            if (options.motivoGrupo) {
              pdf.text(options.motivoGrupo, (y += configuracion.espaciado), x, {
                angle: configuracion.rotacion,
              });
              //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y, x - configuracion.tab1, { angle: configuracion.rotacion });

              if (
                this.turnoService.turnoValue.TpvId !== '3' &&
                (options.ImprimirPrecio == 1 || options.ImprimirPrecio == true)
              ) {
                pdf
                  .text(
                    this.translate.instant('TICKET_PRECIO') + ': ',
                    y,
                    x - configuracion.tab1 - 5,
                    { angle: configuracion.rotacion }
                  )
                  .setFontStyle('bold')
                  .text(
                    Number.parseFloat(options.precio.replace(',', '.'))
                      .toFixed(2)
                      .replace('.', ',') + '€',
                    y,
                    x - configuracion.tab1 + 5,
                    { angle: configuracion.rotacion } +
                      this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                        .AlbaranItems[0].Comision
                  )
                  .setFontStyle('normal');
              }
            } else {
              if (
                this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                  .AlbaranItems[0].DesglosarComision === '0'
              ) {
                if (
                  this.turnoService.turnoValue.TpvId !== '3' &&
                  (options.ImprimirPrecio == 1 ||
                    options.ImprimirPrecio == true)
                ) {
                  //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y+=configuracion.espaciado, x - configuracion.tab1, { angle: configuracion.rotacion });
                  pdf
                    .text(
                      this.translate.instant('TICKET_PRECIO') + ': ',
                      y,
                      x - configuracion.tab1 - 5,
                      { angle: configuracion.rotacion }
                    )
                    .setFontStyle('bold')
                    .text(
                      Number.parseFloat(options.precio.replace(',', '.'))
                        .toFixed(2)
                        .replace('.', ',') + '€',
                      y,
                      x - configuracion.tab1 + 2,
                      { angle: configuracion.rotacion }
                    )
                    .setFontStyle('normal');
                }
              }

              if (
                this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                  .AlbaranItems[0].DesglosarComision === '1'
              ) {
                if (
                  this.turnoService.turnoValue.TpvId !== '3' &&
                  (options.ImprimirPrecio == 1 ||
                    options.ImprimirPrecio == true)
                ) {
                  //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y+=configuracion.espaciado, x - configuracion.tab1, { angle: configuracion.rotacion });
                  pdf
                    .text(
                      this.translate.instant('TICKET_PRECIO') + ': ',
                      y,
                      x - configuracion.tab1 - 5,
                      { angle: configuracion.rotacion }
                    )
                    .setFontStyle('bold')
                    .text(
                      Number.parseFloat(options.precio.replace(',', '.'))
                        .toFixed(2)
                        .replace('.', ',') + '€',
                      y,
                      x - configuracion.tab1 + 2,
                      { angle: configuracion.rotacion }
                    )
                    .setFontStyle('bold')
                    .text(
                      '(' +
                        this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                          .AlbaranItems[0].Comision +
                        '€ en comisión)',
                      y,
                      x - configuracion.tab1 + 20,
                      { angle: configuracion.rotacion }
                    );
                }
              }
            }
            pdf
              .setFontSize(FONTSIZE)
              .setFont(FONTNAME)
              .text(recinto.hora, (y += configuracion.espaciado), x, {
              angle: configuracion.rotacion,
            });
            if (infoImpresion.ListadoCbQR[0].copia > 0) {
              pdf.setFontSize(FONTSIZE).setFontStyle('normal');
              pdf.text(
                this.translate.instant('NUMERO_COPIA') +
                  ' ' +
                  infoImpresion.ListadoCbQR[0].copia,
                y,
                x - configuracion.tab1 - 5,
                { angle: configuracion.rotacion }
              );
            }
            if (ArrayItemImpresion.length > 1) {
              for (let i = 1; i < ArrayItemImpresion.length; i++) {
                pdf.text(
                  ArrayItemImpresion[i],
                  (y += configuracion.espaciado),
                  x,
                  { angle: configuracion.rotacion }
                );
              }
            }
            //pdf.text(recinto.nombreRecinto + ' ' + recinto.fecha + ' ' + recinto.hora , y+=configuracion.espaciado, x, { angle: configuracion.rotacion });
          });
          pdf.setFontSize(FONTSIZE).setFontStyle('normal');
        }

        //Nombre de la entrada
        if (
          options.espectaculosImprimir !== null &&
          options.espectaculosImprimir !== undefined
        ) {
          pdf.setFontSize(FONTSIZE).setFontStyle('bold');
          pdf.text(
            options.espectaculosImprimir.EspectaculoNombre,
            (y += configuracion.espaciado),
            x,
            { angle: configuracion.rotacion }
          );
        }

        if (
          options.recintosImprimir &&
          options.recintosImprimir != undefined &&
          options.recintosImprimir.length > 0
        ) {
          options.recintosImprimir.forEach((recinto) => {
            let ArrayItemImpresion: string[] = [];
            ArrayItemImpresion = documentBody.getArrayNombreImpresion(
              recinto.nombreRecinto,
              ArrayItemImpresion,
              20
            );
            pdf.text(ArrayItemImpresion[0], (y += configuracion.espaciado), x, {
              angle: configuracion.rotacion,
            });
            if (ArrayItemImpresion.length > 1) {
              for (let i = 1; i < ArrayItemImpresion.length; i++) {
                pdf.text(
                  ArrayItemImpresion[i],
                  (y += configuracion.espaciado),
                  x,
                  { angle: configuracion.rotacion }
                );
              }
            }
            //pdf.text(recinto.nombreRecinto + ' ' + recinto.fecha + ' ' + recinto.hora , y+=configuracion.espaciado, x, { angle: configuracion.rotacion });
          });
          pdf.setFontSize(FONTSIZE).setFontStyle('normal');
        }

        //Fila

        if (
          options.espectaculosImprimir !== null &&
          options.espectaculosImprimir !== undefined
        ) {
          pdf.text(
            this.translate.instant('FILA') +
              ' ' +
              options.espectaculosImprimir.NumFila,
            (y += configuracion.espaciado),
            x,
            { angle: configuracion.rotacion }
          );
        }

        //Butaca

        if (
          options.espectaculosImprimir !== null &&
          options.espectaculosImprimir !== undefined
        ) {
          pdf.text(
            this.translate.instant('BUTACA') +
              ' ' +
              options.espectaculosImprimir.NumButaca,
            (y += configuracion.espaciado),
            x,
            { angle: configuracion.rotacion }
          );
        }
        //Nombre entrada
        pdf.text(
          options.nombreImpresion.substr(0,22), 
          (y += configuracion.espaciado), 
          x, 
          {angle: configuracion.rotacion,}
        );

        //idAlbaran
        if (options.idAlbaran.length > 0) {
          pdf.text(
            this.translate.instant('JUSTIFICANTE_No_ALBARAN') +
              ': ' +
              options.idAlbaran,
            (y += configuracion.espaciado),
            x,
            { angle: configuracion.rotacion }
          );
        }
        //nombreTpv- fechadeCompra- Horadecompra
        pdf.text(
          options.nombreTpv +
          ' ' +
          options.fechaCompra +
          ' ' +
          options.horaCompra,
          (y += configuracion.espaciado),
          x,
          { angle: configuracion.rotacion }
        );

        //Cantidad de personas

        //Número de copia
        pdf.text(
          this.translate.instant('TICKET_No_Pers') + ' ' + options.cantidad,
          (y += configuracion.espaciado),
          x,
          { angle: configuracion.rotacion }
        );
        pdf.setFontSize(FONTSIZE).setFontStyle('normal');
        pdf.text(
          this.translate.instant('JUSTIFICANTE_NIF') +
            ': ' +
            configuracion.NIFEmpresa,
          (y += configuracion.espaciado),
          x,
          { angle: configuracion.rotacion }
        );

        //CIF espectaculo
        if (
          options.espectaculosImprimir !== null &&
          options.espectaculosImprimir !== undefined &&
          options.espectaculosImprimir.PromotorCIF != null
        ) {
          pdf.text(
            this.translate.instant('CIF') +
              ' ' +
              options.espectaculosImprimir.PromotorCIF,
            (y += configuracion.espaciado),
            x,
            { angle: configuracion.rotacion }
          );
        }

        //Qr
        //Comprobamos si imprime barCode o tiene urlExterno

        if (
          options.ImprimirBarCode == '1' ||
          options.ImprimirBarCode == 'True'
        ) {
          QRCode.toCanvas(
            options.textoQr,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }
              const imgData = canvas.toDataURL('image/png');
              pdf.addImage(
                imgData,
                'PNG',
                //configuracion.tab1 > 0
                //   ? width - configuracion.tab1
                //   : (y += configuracion.espaciado - 9),
                // configuracion.tab1 > 0 ? x - 74 : x + 18,
                (y += configuracion.espaciado - 10),
                x - 1,
                12,
                12
              );
              //identificador 8 / 20
              // pdf
              // .setFontSize(FONTSIZE - 2)
              // .text(
              //   options.localizador,
              //   configuracion.tab1 > 0
              //     ? width - configuracion.tab1
              //     : (y - 1),
              //   configuracion.tab1 > 0 ? x - 60 : x + 18,
              //   { angle: configuracion.rotacion }
              // );
            }
          );
        }
        if (options.urlExterno && options.urlExterno != '') {
          QRCode.toCanvas(
            options.urlExterno,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }
              const imgData = canvas.toDataURL('image/png');
              pdf.addImage(
                imgData,
                'PNG',
                configuracion.tab1 > 0 ? width - configuracion.tab1 : 9,
                configuracion.tab1 > 0 ? x - (74 + 30) : x + (56 + 30),
                25,
                25
              );
            }
          );
        }
        break;
      case 'MAPFRE':
        //Nombre de la entrada
        pdf.setFontSize(10).setFontStyle('bold');
        pdf.text(options.nombreImpresion, (y += configuracion.espaciado), x, {
          angle: configuracion.rotacion,
        });
        pdf.setFontSize(8).setFontStyle('normal');
        //motivo de grupo y precio
        if (options.motivoGrupo) {
          pdf.text(options.motivoGrupo, (y += configuracion.espaciado), x, {
            angle: configuracion.rotacion,
          });
          //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y, x - configuracion.tab1, { angle: configuracion.rotacion });

          if (
            this.turnoService.turnoValue.TpvId !== '3' &&
            (options.ImprimirPrecio == 1 || options.ImprimirPrecio == true)
          ) {
            pdf
              .text(
                this.translate.instant('TICKET_PRECIO') +
                  ': ' +
                  Number.parseFloat(options.precio.replace(',', '.'))
                    .toFixed(2)
                    .replace('.', ',') +
                  '€',
                (y += configuracion.espaciado),
                x,
                { angle: configuracion.rotacion }
              )
              .setFontStyle('bold')
              // .text(Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y, x, { angle: configuracion.rotacion })
              .setFontStyle('normal');
          }
        } else {
          if (
            this.turnoService.turnoValue.TpvId !== '3' &&
            (options.ImprimirPrecio == 1 || options.ImprimirPrecio == true)
          ) {
            //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y+=configuracion.espaciado, x - configuracion.tab1, { angle: configuracion.rotacion });
            pdf
              .text(
                this.translate.instant('TICKET_PRECIO') +
                  ': ' +
                  Number.parseFloat(options.precio.replace(',', '.'))
                    .toFixed(2)
                    .replace('.', ',') +
                  '€',
                (y += configuracion.espaciado),
                x,
                { angle: configuracion.rotacion }
              )
              .setFontStyle('bold')
              //.text(Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y, x, { angle: configuracion.rotacion })
              .setFontStyle('normal');
          }
        }
        //se imprimen el/los recintos
        if (
          options.recintosImprimir &&
          options.recintosImprimir != undefined &&
          options.recintosImprimir.length > 0
        ) {
          pdf.setFontSize(8).setFontStyle('bold');
          options.recintosImprimir.forEach((recinto) => {
            let ArrayItemImpresion: string[] = [];
            ArrayItemImpresion = documentBody.getArrayNombreImpresion(
              recinto.nombreRecinto,
              ArrayItemImpresion,
              35
            );
            pdf.text(ArrayItemImpresion[0], (y += configuracion.espaciado), x, {
              angle: configuracion.rotacion,
            });
            pdf.text(
              ' ' + recinto.fecha + ' ' + recinto.hora,
              y,
              x - configuracion.tab2,
              { angle: configuracion.rotacion }
            );
            if (ArrayItemImpresion.length > 1) {
              for (let i = 1; i < ArrayItemImpresion.length; i++) {
                pdf.text(
                  ArrayItemImpresion[i],
                  (y += configuracion.espaciado),
                  x,
                  { angle: configuracion.rotacion }
                );
              }
            }
            //pdf.text(recinto.nombreRecinto + ' ' + recinto.fecha + ' ' + recinto.hora , y+=configuracion.espaciado, x, { angle: configuracion.rotacion });
          });
          pdf.setFontSize(8).setFontStyle('normal');
        }

        pdf.setFontSize(10).setFontStyle('bold');
        pdf.text(
          this.translate.instant('TICKET_No_Pers') + ' ' + options.cantidad,
          (y += configuracion.espaciado),
          x,
          { angle: configuracion.rotacion }
        );
        pdf.setFontSize(8).setFontStyle('normal');
        //idAlbaran
        if (options.idAlbaran.length > 0) {
          pdf.text(
            this.translate.instant('JUSTIFICANTE_No_ALBARAN') +
              ': ' +
              options.idTpv +
              '-' +
              options.idAlbaran,
            (y += configuracion.espaciado),
            x,
            { angle: configuracion.rotacion }
          );
        }
        //nombreTpv- fechadeCompra- Horadecompra
        pdf.text(
          options.nombreTpv +
            ' - ' +
            options.fechaCompra +
            ' ' +
            options.horaCompra,
          (y += configuracion.espaciado),
          x,
          { angle: configuracion.rotacion }
        );

        //Cantidad de personas
        // pdf.setFontSize(10).setFontStyle('bold');
        // pdf.text( this.translate.instant('TICKET_No_Pers') +' ' + options.cantidad, y+=configuracion.espaciado, x, { angle: configuracion.rotacion });
        // pdf.setFontSize(8).setFontStyle('normal');
        // pdf.text(this.translate.instant('JUSTIFICANTE_NIF') +': ' + configuracion.NIFEmpresa ,y+=configuracion.espaciado, x, { angle: configuracion.rotacion });

        //Qr
        //Comprobamos si imprime barCode o tiene urlExterno
        if (
          options.ImprimirBarCode == '1' ||
          options.ImprimirBarCode == 'True'
        ) {
          QRCode.toCanvas(
            options.textoQr,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }
              const imgData = canvas.toDataURL('image/png');
              pdf.addImage(
                imgData,
                'PNG',
                configuracion.tab1 > 0 ? width - configuracion.tab1 - 2 : 13,
                configuracion.tab1 > 0 ? x - 72 : x + 54,
                16,
                16
              );
              //identificador
              pdf.text(
                options.localizador,
                configuracion.tab1 > 0 ? width - 15 : 15,
                configuracion.tab1 > 0 ? x - 56 : x + 58,
                { angle: configuracion.rotacion }
              );
            }
          );
        }
        if (options.urlExterno && options.urlExterno != '') {
          QRCode.toCanvas(
            options.urlExterno,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }
              const imgData = canvas.toDataURL('image/png');
              pdf.addImage(
                imgData,
                'PNG',
                configuracion.tab1 > 0 ? width - configuracion.tab1 - 2 : 13,
                configuracion.tab1 > 0 ? x - (70 - 20) : x + (52 - 20),
                16,
                16
              );
              pdf.text(
                'audioguia',
                configuracion.tab1 > 0 ? width - 15 : 15,
                configuracion.tab1 > 0 ? x - 36 : x + 38,
                { angle: configuracion.rotacion }
              );
            }
          );
        }
        break;
      case 'SNOWZONE':
      case 'ESTEPONAEULEN':
        //IMPRESORA JUSTIFICANTES
        if (this.datosPrinter.TPrinterImpresoraSistema === '1') {
          if (this.cabecera) {
            pdf.text(this.cabecera.chLinea1, 5, 5, {});
            pdf.text(this.cabecera.chLinea2, 10, 10, {});
            pdf.text(this.cabecera.chLinea3, 5, 15, {});
            pdf.text(this.cabecera.chLinea4, 20, 20, {});
          }
          pdf.text(
            '...................................................................',
            5,
            25,
            {}
          );
          pdf.text(
            options.nombreImpresion + '' + '(' + options.cantidad + ')',
            5,
            30,
            {}
          );
          if (
            //this.turnoService.turnoValue.TpvId !== '3' &&
            options.ImprimirPrecio == 1 ||
            options.ImprimirPrecio == true
          ) {
            pdf
              .text(this.translate.instant('TICKET_PRECIO') + ': ', 5, 35, {})
              .text(
                Number.parseFloat(options.precio.replace(',', '.'))
                  .toFixed(2)
                  .replace('.', ',') + '€',
                15,
                35,
                {}
              )
              .setFontStyle('normal');
            if (this.datosCliente) {
              if (
                this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                  .AlbaranItems[0].DesglosarComision === '1'
              ) {
                pdf.text(
                  '(' +
                    this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                      .AlbaranItems[0].Comision +
                    '€ en comisión)',
                  30,
                  40,
                  {}
                );
              }
            }
          } else {
            if (
              //this.turnoService.turnoValue.TpvId !== '3' &&
              options.ImprimirPrecio == 1 ||
              options.ImprimirPrecio == true
            ) {
              //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y+=configuracion.espaciado, x - configuracion.tab1, { angle: configuracion.rotacion });
              pdf
                .text(this.translate.instant('TICKET_PRECIO') + ': ', 5, 35, {})
                .text(
                  Number.parseFloat(options.precio.replace(',', '.'))
                    .toFixed(2)
                    .replace('.', ',') + '€',
                  20,
                  35,
                  {}
                )
                .setFontStyle('normal');
              if (this.datosCliente) {
                if (
                  this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                    .AlbaranItems[0].DesglosarComision === '1'
                ) {
                  pdf.text(
                    '(' +
                      this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                        .AlbaranItems[0].Comision +
                      '€ en comisión)',
                    30,
                    40,
                    {}
                  );
                }
              }
            }
          }

          //se imprimen el/los recintos
          if (
            options.recintosImprimir &&
            options.recintosImprimir != undefined &&
            options.recintosImprimir.length > 0
          ) {
            options.recintosImprimir.forEach((recinto) => {
              let ArrayItemImpresion: string[] = [];
              ArrayItemImpresion = documentBody.getArrayNombreImpresion(
                recinto.nombreRecinto,
                ArrayItemImpresion,
                35
              );
              pdf.text(ArrayItemImpresion[0], 5, 45, {});
              pdf.text(recinto.fecha, 5, 50, {});
              pdf.text(recinto.hora, 5, 55, {});
            });
          }
          if (this.datosCliente && this.datosCliente.NombreCliente) {
            pdf.text(this.datosCliente.NombreCliente, 5, 60, {});
          }
          if (this.datosCliente) {
            pdf.text(
              this.datosCliente.DatosResult.ListadoAlbaranInfo[0].NombreCliente,
              5,
              60,
              {}
            );
          }
          if (this.datosCliente && this.datosCliente.DatosResult) {
            pdf.text(
              this.datosCliente.DatosResult.ListadoAlbaranInfo[0].NumVenta,
              5,
              65,
              {}
            );
          }

          //Qr
          //Comprobamos si imprime barCode o tiene urlExterno
          if (
            options.ImprimirBarCode == '1' ||
            options.ImprimirBarCode == 'True'
          ) {
            QRCode.toCanvas(
              options.textoQr,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  configuracion.tab1 > 0 ? width : 0,
                  configuracion.tab1 > 0 ? x - 80 : x + 40,
                  35,
                  35
                );
                //identificador
              }
            );
          }
          if (options.urlExterno && options.urlExterno != '') {
            QRCode.toCanvas(
              options.urlExterno,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  configuracion.tab1 > 0 ? width : 0,
                  configuracion.tab1 > 0 ? x - 80 : x + 40,
                  35,
                  35
                );
              }
            );
          }
        }
        break;
      default:
        //IMPRESORA JUSTIFICANTES
        if (this.datosPrinter.TPrinterImpresoraSistema === '1') {
          /*pdf.text(
            this.datosCentro.DatosResult.ListaCentros[0].Nombre,
            20,
            5,
            {}
          );*/
          pdf.text(this.cabecera.chLinea1, 5, 5, {});
          pdf.text(this.cabecera.chLinea2, 10, 10, {});
          pdf.text(this.cabecera.chLinea3, 5, 15, {});
          pdf.text(this.cabecera.chLinea4, 20, 20, {});
          pdf.text(
            '...................................................................',
            5,
            25,
            {}
          );
          pdf.text(
            options.nombreImpresion + '' + '(' + options.cantidad + ')',
            5,
            30,
            {}
          );
          if (
            //this.turnoService.turnoValue.TpvId !== '3' &&
            options.ImprimirPrecio == 1 ||
            options.ImprimirPrecio == true
          ) {
            pdf
              .text(this.translate.instant('TICKET_PRECIO') + ': ', 5, 35, {})
              .text(
                Number.parseFloat(options.precio.replace(',', '.'))
                  .toFixed(2)
                  .replace('.', ',') + '€',
                15,
                35,
                {}
              )
              .setFontStyle('normal');
            if (
              this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                .AlbaranItems[0].DesglosarComision === '1'
            ) {
              pdf.text(
                '(' +
                  this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                    .AlbaranItems[0].Comision +
                  '€ en comisión)',
                30,
                40,
                {}
              );
            }
          } else {
            if (
              //this.turnoService.turnoValue.TpvId !== '3' &&
              options.ImprimirPrecio == 1 ||
              options.ImprimirPrecio == true
            ) {
              //pdf.text('Preu' + ': ' + Number.parseFloat(options.precio.replace(',','.')).toFixed(2).replace('.',',') + '€', y+=configuracion.espaciado, x - configuracion.tab1, { angle: configuracion.rotacion });
              pdf
                .text(this.translate.instant('TICKET_PRECIO') + ': ', 5, 35, {})
                .text(
                  Number.parseFloat(options.precio.replace(',', '.'))
                    .toFixed(2)
                    .replace('.', ',') + '€',
                  20,
                  35,
                  {}
                )
                .setFontStyle('normal');
              if (
                this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                  .AlbaranItems[0].DesglosarComision === '1'
              ) {
                pdf.text(
                  '(' +
                    this.datosCliente.DatosResult.ListadoAlbaranInfo[0]
                      .AlbaranItems[0].Comision +
                    '€ en comisión)',
                  30,
                  40,
                  {}
                );
              }
            }
          }

          //se imprimen el/los recintos
          if (
            options.recintosImprimir &&
            options.recintosImprimir != undefined &&
            options.recintosImprimir.length > 0
          ) {
            options.recintosImprimir.forEach((recinto) => {
              let ArrayItemImpresion: string[] = [];
              ArrayItemImpresion = documentBody.getArrayNombreImpresion(
                recinto.nombreRecinto,
                ArrayItemImpresion,
                35
              );
              pdf.text(ArrayItemImpresion[0], 5, 45, {});
              pdf.text(recinto.fecha, 5, 50, {});
              pdf.text(recinto.hora, 5, 55, {});
            });
          }
          if (this.datosCliente && this.datosCliente.NombreCliente) {
            pdf.text(this.datosCliente.NombreCliente, 5, 60, {});
          }
          pdf.text(
            this.datosCliente.DatosResult.ListadoAlbaranInfo[0].NombreCliente,
            5,
            60,
            {}
          );
          if (this.datosCliente && this.datosCliente.DatosResult) {
            pdf.text(
              this.datosCliente.DatosResult.ListadoAlbaranInfo[0].NumVenta,
              5,
              65,
              {}
            );
          }

          //Qr
          //Comprobamos si imprime barCode o tiene urlExterno
          if (
            options.ImprimirBarCode == '1' ||
            options.ImprimirBarCode == 'True'
          ) {
            QRCode.toCanvas(
              options.textoQr,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  configuracion.tab1 > 0 ? width : 0,
                  configuracion.tab1 > 0 ? x - 80 : x + 65,
                  50,
                  50
                );
                //identificador
              }
            );
          }
          if (options.urlExterno && options.urlExterno != '') {
            QRCode.toCanvas(
              options.urlExterno,
              { errorCorrectionLevel: 'M' },
              (err, canvas) => {
                if (err) {
                  throw err;
                }
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(
                  imgData,
                  'PNG',
                  configuracion.tab1 > 0 ? width : 0,
                  configuracion.tab1 > 0 ? x - 80 : x + 65,
                  50,
                  50
                );
              }
            );
          }
        } else {
          QRCode.toCanvas(
            options.textoQr,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }
              const imgData = canvas.toDataURL('image/png');
              pdf.addImage(
                imgData,
                'PNG',
                0.4,
                (height / 4.5) * 1.5,
                width,
                width
              );
              pdf.text(options.nombreImpresion, 7, (height / 4.5) * 1.5, {
                angle: configuracion.rotacion,
              });
              pdf.text(
                options.nombreTpv +
                  ' - ' +
                  options.idAlbaran +
                  ' / ' +
                  options.NumOrden,
                11,
                (height / 4.5) * 1.5,
                { angle: configuracion.rotacion }
              );
              if (
                options.recintosImprimir &&
                options.recintosImprimir.length > 0
              ) {
                options.recintosImprimir.forEach((element) => {
                  pdf.text(element.nombreRecinto, 15, (height / 4.5) * 1.5, {
                    angle: configuracion.rotacion,
                  });
                  //pdf.text(element.InfoTickets.FechaEntrada + ' ' + element.InfoTickets.HoraEntrada, 19, (height / 4.5) * 1.5, { angle: 90 });
                  pdf.text(
                    element.fecha + '' + element.hora,
                    19,
                    (height / 4.5) * 1.5,
                    { angle: configuracion.rotacion }
                  );
                  //FechaHoraEntrada
                });
              }
            }
          );
          break;
        }
    }
    return pdf;
  }

  crearPlantillaTickets() {
    let altura; // altura del papel
    let anchura; //anchura del papel
    let variacion; // TODO comprobarlo actualmente se multiplica altura por anchura por 2,83
    let inicialx = 0; // posicion inicial en el eje x
    let inicialy = 0; // posicion inicial en el eje  y
    let rotacion; //rotacion del papel (angulo de impresion)
    let orientacion; // orientacion del papel valores: 'p' = o 'l' (landscape or portrait)
    let unidad; // unidad de medida cuando se especifican las coordenadas  "pt" (points), "mm" (Default), "cm", "in"
    let format; // se pueden enviar la medida o usar los siguientes formatos 'a3', 'a4' (Default),'a5' ,'letter' ,'legal'
    let y; // posicion actual en el eje Y
    let x; //posicion actual en el eje X
    let width; //anchura real del documento
    let height; //altura real del documento
    let tipoLetra; //
    let espaciado; // cantidad para el salto de linea
    let tab1; // espacio a primera columna
    let tab2;

    const empresa = this.config.getConfig('chClaveEmpresa');
    this.datosInicio = this.configuration.datosInicioValue;
    const configuracionTPV = Object.values(
      this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat
    );
    configuracionTPV.forEach((param) => {
      this.values = param;
      if (this.values === null) {
        this.valuesNull = false;
      }
    });
    if (this.valuesNull === true) {
      altura = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatAltura.replace(
          ',',
          '.'
        )
      );
      anchura = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatAnchura.replace(
          ',',
          '.'
        )
      );
      unidad =
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat
          .TFormatUnidad;
      orientacion =
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat
          .TFormatOrientacion;
      inicialx = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatInicialx.replace(
          ',',
          '.'
        )
      );
      inicialy = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatInicialy.replace(
          ',',
          '.'
        )
      );
      variacion = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatVariacion.replace(
          ',',
          '.'
        )
      );
      rotacion = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatRotacion.replace(
          ',',
          '.'
        )
      );
      espaciado = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatEspaciado.replace(
          ',',
          '.'
        )
      );
      tab1 = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatTab1.replace(
          ',',
          '.'
        )
      );
      tab2 = parseFloat(
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTicketFormat.TFormatTab2.replace(
          ',',
          '.'
        )
      );
    } else {
      switch (empresa) {
        case 'PEDRERA':
          altura = 60;
          anchura = 152;
          unidad = 'mm';
          orientacion = 'p';
          inicialy = 54; //5;
          inicialx = 27; // 97;
          variacion = 2.83;
          rotacion = -90; // 90 o;
          espaciado = -4; // 4;
          tab1 = -30;
          break;
        case 'MAPFRE':
          altura = 86;
          anchura = 50;
          unidad = 'mm';
          orientacion = 'p';
          inicialy = 0; //5;
          inicialx = 80; // 97;
          variacion = 2.83;
          rotacion = 90; // 90;
          espaciado = 4; // 4;
          tab1 = 30;
          tab2 = 47;
          break;
        case 'SNOWZONE':
          altura = 24;
          anchura = 252;
          unidad = 'mm';
          orientacion = 'p';
          inicialy = 7;
          inicialx = 0;
          variacion = 2.83;
          rotacion: 90;
          espaciado = 4;
          break;
        case 'REALESCUELA':
          altura = 62;
          anchura = 100;
          unidad = 'mm';
          orientacion = 'p';
          inicialy = 62; //54;//62
          inicialx = 60; // 58;
          variacion = 2.83;
          rotacion = -90; // 90;
          espaciado = -3; // 4;
          tab1 = -30;
          break;
        case 'ESTEPONAEULEN':
          altura = 62;
          anchura = 100;
          unidad = 'mm';
          orientacion = 'p';
          inicialy = 54; //5;
          inicialx = 27; // 97;
          variacion = 2.83;
          rotacion = -90; // 90 o;
          espaciado = -4; // 4;
          tab1 = -30;
          break;
        default:
          /* altura = 24;
          anchura = 252;
          unidad = 'mm';
          orientacion = 'p';
          inicialy = 7;
          inicialx = 0;
          variacion = 2.83;
          rotacion: 90;
          espaciado = 4;
          break;*/
          altura = 60;
          anchura = 152;
          unidad = 'mm';
          orientacion = 'p';
          inicialy = 54; //5;
          inicialx = 27; // 97;
          variacion = 2.83;
          rotacion = -90; // 90 o;
          espaciado = -4; // 4;
          tab1 = -30;
          break;
      }
    }

    const options = {
      opt: {
        orientation: orientacion,
        unit: unidad,
        format: [anchura * variacion, altura * variacion],
      },
      inicialx: inicialx,
      inicialy: inicialy,
      rotacion: rotacion,
      empresa: empresa,
      NIFEmpresa: this.datosInicio.CIF,
      espaciado: espaciado,
      tab1: tab1,
      tab2: tab2,
    };

    return options;
  }

  imprimirSaldoMonedero(TarjetaMonederoCodigo, saldo) {
    let documentBody = new AlbaranDocumentBody(
      this.translate,
      this.config,
      this.configuration
    ); // utilizamos la clase para el metodo addDotsToNumber
    this.pagoService.datosCabecera().subscribe((data: any) => {
      // let y = 0;
      // const pdf = new jspdf('p', 'mm', [80 * 2.83, 275 * 2.83]);
      const configuracion = this.crearPlantillaJustificante();
      let pdf = new jspdf(configuracion.opt);
      let y = configuracion.inicialy;
      let x = configuracion.inicialx;
      let finalx = configuracion.finalx;
      let tamanioLetra = configuracion.tamanioLetra;
      let espaciado = configuracion.espaciado;
      let centrado = configuracion.centrado;
      let tab1 = configuracion.tab1;
      let tab2 = configuracion.tab2;
      pdf.setFontSize(tamanioLetra);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      if (data.IncluirEncabezadoTicket === 'True') {
        if (data.chLinea1 !== '') {
          pdf.setFontSize(tamanioLetra + 4);
          pdf.setFontStyle('bold');
          pdf.text(data.chLinea1, centrado, (y = y + espaciado), 'center');
          pdf.setFontStyle('normal');
          pdf.line(x, (y += espaciado), finalx, y);
        }
        if (data.chLinea2 !== '') {
          pdf.text(data.chLinea2, x, (y = y + espaciado), 'left');
        }
        if (data.chLinea3 !== '') {
          pdf.text(data.chLinea3, x, (y = y + espaciado), 'left');
        }
        if (data.chLinea4 !== '') {
          pdf.text(data.chLinea4, x, (y = y + espaciado), 'left');
        }
        if (data.chLinea5 !== '') {
          pdf.text(data.chLinea5, x, (y = y + espaciado), 'left');
        }
        if (data.chLinea6 !== '') {
          pdf.text(data.chLinea6, x, (y = y + espaciado), 'left');
        }
      }
      //Titulo del Documento
      pdf.line(x, (y += espaciado), finalx, y);
      //pdf.text('SALDO', 37, (y = y + 4), 'center');
      pdf.text(
        this.translate.instant('SALDO'),
        centrado,
        (y = y + espaciado),
        'center'
      );

      // line
      pdf.line(x, (y += espaciado), finalx, y);

      pdf
        //.text('TARJETA ' + TarjetaMonederoCodigo + ':' , 6, (y += 4), 'left');
        .text(
          this.translate.instant('TARJETA') + ' ' + TarjetaMonederoCodigo + ':',
          x,
          (y += espaciado),
          'left'
        );

      pdf
        // .text('T O T A L', 6, (y += 5), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_T_O_T_A_L'),
          x,
          (y += espaciado + 1),
          'left'
        )

        .text(
          `${documentBody.addDotsToNumber(
            Number.parseFloat(saldo.toString().replace(',', '.'))
              .toFixed(2)
              .replace('.', ',')
          )} €`,
          finalx,
          y,
          'right'
        );
      pdf.line(x, (y += espaciado - 2), finalx, y);
      pdf.setFontSize(tamanioLetra);
      pdf.setFontStyle('normal');

      if (data && data.IncluirEncabezadoTicket === 'True') {
        if (data.chPie1 !== '') {
          pdf.text(data.chPie1, x, (y = y + espaciado), 'left');
        }
        if (data.chPie2 !== '') {
          pdf.text(data.chPie2, x, (y = y + espaciado), 'left');
        }
        if (data.chPie3 !== '') {
          pdf.text(data.chPie3, x, (y = y + espaciado), 'left');
        }
        if (data.chPie4 !== '') {
          pdf.text(data.chPie4, x, (y = y + espaciado), 'left');
        }
        if (data.chPie5 !== '') {
          pdf.text(data.chPie5, x, (y = y + espaciado), 'left');
        }
        if (data.chPie6 !== '') {
          pdf.text(data.chPie6, x, (y = y + espaciado), 'left');
        }
      } else {
        // pdf.text('Gracias por su visita', 37, (y += 3), 'center');
        pdf.text(
          this.translate.instant('JUSTIFICANTE_GRACIAS_POR_SU_VISITA'),
          centrado,
          (y += espaciado - 1),
          'center'
        );
      }
      if (
        this.window.external &&
        typeof this.window.external.imprimirPDFBinario === 'function'
      ) {
        this.window.external.imprimirPDFBinario(
          this.generatePDFBinario(pdf),
          'justificante'
        );
      }
      pdf.save(`${this.translate.instant('saldoMonedero')}.pdf`);
    });
  }

  crearPlantillaJustificante() {
    let altura; // altura del papel
    let anchura; //anchura del papel
    let variacion; // TODO comprobarlo actualmente se multiplica altura por anchura por 2,83
    let inicialx = 0; // posicion inicial en el eje x
    let inicialy = 0; // posicion inicial en el eje  y
    let finalx = 70; // posicion inicial en el eje x
    let rotacion; //rotacion del papel (angulo de impresion)
    let orientacion; // orientacion del papel valores: 'p' = o 'l' (landscape or portrait)
    let unidad; // unidad de medida cuando se especifican las coordenadas  "pt" (points), "mm" (Default), "cm", "in"
    let format; // se pueden enviar la medida o usar los siguientes formatos 'a3', 'a4' (Default),'a5' ,'letter' ,'legal'
    let tipoLetra; //Tipo de letra del documento
    let tamanioLetra; //Tamaño de letra que se utilizara en la mayoria del documento
    let espaciado; // cantidad para el salto de linea
    let tab1; // espacio a primera columna desde inicial x
    let tab2; //espacio a segunda columna desde inicial x
    let centrado; //valor de x para el centro
    let tamanio: number;
    const configImpresion =
      this.configuration.appConfig.value.DatosResult.AppConfigJsonImpresion;
    let tipo = 'Estrecho';
    if (configImpresion.ImpresionFormatoPapel === 'Estrecho') {
      tamanio =
        configImpresion.ImpresionTamanioPapel &&
        configImpresion.ImpresionTamanioPapel != null &&
        configImpresion.ImpresionTamanioPapel != undefined &&
        configImpresion.ImpresionTamanioPapel != '' &&
        +configImpresion.ImpresionTamanioPapel > 0
          ? +configImpresion.ImpresionTamanioPapel
          : 60;

      altura = 275;
      anchura = tamanio + 20;
      unidad = 'mm';
      orientacion = 'p';
      inicialy = 0;
      inicialx = 6;
      finalx = anchura - 6;
      variacion = 2.83;
      rotacion = 0;
      tamanioLetra = tamanio > 75 ? 10 : 8;
      espaciado = Math.trunc(tamanioLetra / 2);
      tab1 = Math.trunc(anchura / 5);
      tab2 = Math.trunc(anchura / 3);
      centrado = Math.trunc(anchura / 2);
      format = [anchura * variacion, altura * variacion];
    } else if (configImpresion.ImpresionFormatoPapel === 'Din-A4') {
      format = 'a4';
    } else {
      altura = 275;
      anchura = tamanio;
      unidad = 'mm';
      orientacion = 'p';
      inicialy = 0;
      inicialx = 6;
      finalx = anchura - 6;
      variacion = 2.83;
      rotacion = 0;
      tamanioLetra = tamanio > 75 ? 10 : 8;
      espaciado = Math.trunc(tamanioLetra / 2);
      tab1 = Math.trunc(anchura / 5);
      tab2 = Math.trunc(anchura / 3);
      centrado = Math.trunc(anchura / 2);
      format = [anchura * variacion, altura * variacion];
    }

    const options = {
      opt: {
        orientation: orientacion,
        unit: unidad,
        format: format,
      },
      inicialx: inicialx,
      inicialy: inicialy,
      finalx: finalx,
      rotacion: rotacion,
      espaciado: espaciado,
      tamanioLetra: tamanioLetra,
      tab1: tab1,
      tab2: tab2,
      centrado: centrado,
    };

    return options;
  }
  printPDFWithBase64(base64: string, fileName: string = 'ticket.pdf') {

    const binaryData = atob(base64);
  
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i) & 0xff;
    }
    
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  
    const blobUrl = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName; 
    link.click();
    URL.revokeObjectURL(blobUrl);
  }
}
