import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '../core/user.model';
import { UserManagerService } from '../core/auth/user-manager.service';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart, NavigationError, Event } from '@angular/router';
import { TurnoService } from '../core/auth/turno.service';

@Component({
  selector: 'ticketing-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'body is-Home'
  }
})
export class HomeComponent {

  currentTurno: any;
  toggleKeyboard: boolean;

  constructor (
      private userManager: UserManagerService,
      private turnoService: TurnoService,
      private router: Router,
    ) {
    this.turnoService.turno.subscribe(x => this.currentTurno = x);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          if (event.url.split('/')[1] === 'venta') {
            this.toggleKeyboard = false;
          }
          if (event.url.split('/')[1] === 'turno') {
            this.toggleKeyboard = true;
          }
          if (event.url.split('/')[1] === 'cambio-usuario') {
            this.toggleKeyboard = true;
          }
          if (event.url.split('/')[1] === 'localizador') {
            if (event.url.split('/')[2]) {
              this.toggleKeyboard = false;
            } else {
              this.toggleKeyboard = true;
            }
          }
          if (event.url.split('/')[1] === 'gestionar-venta') {
            this.toggleKeyboard = false;
          }
          if (event.url.split('/')[1] === 'auditorio') {
            this.toggleKeyboard = false;
          }
          if (event.url.split('/')[1] === 'cambiar-password') {
            this.toggleKeyboard = true;
          }
          if (event.url.split('/')[1] === 'login') {
            this.toggleKeyboard = true;
          }
      }
  });
  }

  logout(): void {
    this.userManager.logout();
    this.router.navigate(['login']);
  }
}
