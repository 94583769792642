import { OsConnection } from './osConnection.interface';
import { PrintableType } from '../print/printableType.enum';
import * as jspdf from 'jspdf';

export class VirtualConnection implements OsConnection {
  window = window as any;

  customerDisplay() {}

  print(document: jspdf, printableType: PrintableType) {
    if (printableType === 8) {
      document.save('documento');
    } else {
      document.save();
    }
  }

  ventaTarjeta(
    operacionVenta: string,
    tipoComunicacionVenta: string,
    importeVenta: string,
    importeVentaDevolucion: string,
    numTerminal: string,
    versionWeb: string,
    numComercio: string,
    claveComercio: string,
    puertoCOM: string
  ) {}
}
