import { Component, OnInit } from '@angular/core';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VentaService } from '../../services/venta.service';

@Component({
  selector: 'ticketing-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  pasos = [];

  constructor(
    private stepperNavigation: StepperNavigationService,
    private venta: VentaService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.stepperNavigation.getSteps().subscribe(pasos => {
      // 
      this.pasos = pasos;
    });
  }

  debeSerVisible(step: any) {
    if (
      this.venta.carritoValue.entradas.length === 0 &&
      step.url !== 'entradas'
    ) {
      step.visible = false;
    }
    return step.visible;
  }

  navegar(url) {
    if (url === 'entradas') {
      // 
      this.stepperNavigation.desbloquearNavegacionPorPago();
      this.stepperNavigation.continuarVenta.next(false);
      this.stepperNavigation.resetearPasos();
      this.router.navigate(['']);
      this.venta.currentRecinto = undefined;
    } else if (url === 'horarios') {
      this.router.navigate(['/venta/horarios']);
    } else if (url === 'pago') {
      this.router.navigate(['/venta/pago']);
    }
  }
}
