import { Injectable } from '@angular/core';
import { VentaService } from 'src/app/modules/venta';
import { MotivosService } from 'src/app/modules/venta/services/motivos.service';

@Injectable({
  providedIn: 'root'
})
export class MotivoPadService {

  order = '';
  selected;
  motivo;
  constructor(private motivoService: MotivosService) {}

  setNumpadOrder(order: string) {
    this.order = order;
    this.selected.value = this.orderDispatcher();
  }

  orderDispatcher(): string {
    switch (this.order) {
      case 'delete':
        const newstr = this.selected.value.substring(0, this.selected.value.length - 1);
        if (newstr.length > 0) {
          this.motivoService.pushMotivo(this.motivo, +newstr);
          return newstr;
        } else {
          this.motivoService.pushMotivo(this.motivo, 0);
          return '';
        }
      case 'reset':
        this.motivoService.pushMotivo(this.motivo, 0);
        return '0';
      case 'losefocus':
        if (this.selected.value === '') {
          this.selected.value = 0;
          this.motivoService.pushMotivo(this.motivo, 0);
        }
        this.selected.focus = false;
        return this.selected.value; // this.selected.value;
      default:
        const oldValue = ''.concat(this.selected.value);
        if (oldValue === '0' && this.order === '0') {
          return '';
        }
        const newValue = oldValue.concat(this.order);
        const Ncomma = (newValue.match(/,/g) || []).length;
        let Nintegers = 0;
        let Ndecimals = 0;
        if (Ncomma > 0) {
          Nintegers = newValue.split('.')[0].length;
          Ndecimals = newValue.split('.')[1].length;
        } else {
          Nintegers = newValue.length;
        }
        if (Ncomma < 2) {
          if (Ndecimals < 3 && Nintegers < 4) {
            if (oldValue === '' || oldValue === '0') {
              this.motivoService.pushMotivo(this.motivo, +this.order);
              return this.order;
            } else {
              this.motivoService.pushMotivo(this.motivo, +newValue);
              return newValue;
            }
          } else {
            return oldValue;
          }
        } else {
          return oldValue;
        }
    }
  }

}
