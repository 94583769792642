import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppConfiguration } from 'src/app/app.configuration';
import { TurnoService } from 'src/app/core';
import { EspectaculosService } from '../../services/espectaculos.service';

@Component({
  selector: 'ticketing-seleccion-butacas',
  templateUrl: './seleccion-butacas.component.html',
  styleUrls: ['./seleccion-butacas.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout layout--auditorio',
  },
})
export class SeleccionButacasComponent implements OnInit {
  constructor(
    private espectaculoService: EspectaculosService,
    private turnoService: TurnoService,
    private http: HttpClient,
    private config: AppConfiguration
  ) {}
  chNombreEspectaculo;
  ngOnInit() {
    this.chNombreEspectaculo =
      this.espectaculoService.espectaculoSeleccionadoValue.chNombreEspectaculo;
  }
}
