import { Injectable } from '@angular/core';
import { CustomerDisplay } from 'src/app/core/hardware/customer-display/customerDisplay.interface';
import { CustomerDisplayFactory } from 'src/app/core/hardware/customer-display/customerDisplay.factory';
import { CustomerDisplayType } from 'src/app/core/hardware/customer-display/customerDisplayType.enum';
import { CustomerDisplayMessage } from 'src/app/core/hardware/customer-display/customerDisplayMessage.model';
import { CustomerDisplayLine } from 'src/app/core/hardware/customer-display/customerDisplayLine.model';
import { AppConfiguration } from 'src/app/app.configuration';

@Injectable({
  providedIn: 'root'
})
export class VisorService {
  window = window as any;
  customerDisplay: CustomerDisplay;

  constructor(private config: AppConfiguration) {
    const connectionType = this.config.getConfig('DISPLAY_TYPE');
    let display = CustomerDisplayType.VISOR;
    if (connectionType === 'VISOR') {
      display = CustomerDisplayType.VISOR;
    }
    if (connectionType === 'VIRTUAL') {
      display = CustomerDisplayType.VIRTUAL;
    }
    this.customerDisplay = CustomerDisplayFactory.getCustomerDisplay(display);
  }

  display(message: CustomerDisplayMessage) {
    this.customerDisplay.printMessage(message);
  }

  setToBlank() {
    this.customerDisplay.setToBlank();
  }

  mostrarDisplayEfectivo(efectivo, cambio) {
    const message = new CustomerDisplayMessage()
      .addLine(new CustomerDisplayLine(`	EFEC.:	${this.formatearPrecio(efectivo)} E`))
      .addLine(new CustomerDisplayLine(`	CAMBIO: 	${this.formatearPrecio(cambio)} E`));
    this.customerDisplay.printMessage(message);
  }

  mostrarDisplayLineaAlbaran(cantidad, precio) {
    const message = new CustomerDisplayMessage()
      .addLine(new CustomerDisplayLine(`      ${cantidad} x ${this.formatearPrecio(precio)} E`))
      .addLine(
        new CustomerDisplayLine(
          `      ${this.formatearPrecio(cantidad * this.formatearPrecio(precio))} E`
        )
      );
    this.customerDisplay.printMessage(message);
  }

  mostrarDisplayTotalAlbaran(cantidadTotal) {
    const message = new CustomerDisplayMessage()
      .addLine(new CustomerDisplayLine(`TOTAL:	${this.formatearPrecio(cantidadTotal)} E`))
      .addLine(new CustomerDisplayLine(''));
    this.customerDisplay.printMessage(message);
  }

  mostrarCambioCompra(cambio) {
    const message = new CustomerDisplayMessage()
      .addLine(new CustomerDisplayLine(`CAMBIO: ${this.formatearPrecio(cambio)} E`))
      .addLine(new CustomerDisplayLine(''));
    this.customerDisplay.printMessage(message);
  }

  private formatearPrecio(precio) {
    if (typeof precio === 'string') {
      precio = Number.parseFloat(precio.replace(',', '.'));
    }
    return precio.toFixed(2);
  }
}
