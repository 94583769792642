import { Injectable } from '@angular/core';
import { CardPaymentType } from './cardPaymentType.enum';
import { PinpadCardPayment } from './pinpadCardPayment';
import { DatafonoCardPayment } from './datafonoCardPayment';
import { CardPayment } from './cardPayment.interface';
import { VirtualCardPayment } from './virtualCardPayment';


@Injectable({
    providedIn: 'root'
  })
export class CardPaymentFactory {
    public static getCardPayment(customerDisplayType: CardPaymentType): CardPayment {
        if (customerDisplayType === CardPaymentType.PINPAD) {
            return new PinpadCardPayment();
        }
        if (customerDisplayType === CardPaymentType.VIRTUAL) {
            return new VirtualCardPayment();
        }
        if (customerDisplayType === CardPaymentType.DATAFONO) {
            return new DatafonoCardPayment();
        }
    }
}
