import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { FormControlService } from '../../services/form-control.service';
import { PrintService } from '../../services/print.service';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import * as moment from 'moment';
import { ModalService } from '../../services/modal.service';
import { LocalizadorService } from 'src/app/modules/localizador/services/localizador.service';
import { Router } from '@angular/router';
import { Reserva } from 'src/app/modules/localizador/models/reserva.model';

@Component({
  selector: 'ticketing-etiquetas-modal',
  templateUrl: './etiquetas-modal.component.html',
  styleUrls: ['./etiquetas-modal.component.scss']
})
export class EtiquetasModalComponent implements OnInit {
  @Input() id: string;

  private element: any;
  etiquetaForm: FormGroup;
  datosEtiqueta;
  hora;
  minutos: number;
  horaEmision;
  horaMaxima;
  fecha;
  isOpen = false;

  constructor(
    private route: Router,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    el: ElementRef,
    private printService: PrintService,
    private albaranService: AlbaranService,
    private localizadorService: LocalizadorService
  ) {
    this.element = el.nativeElement;
    this.etiquetaForm = this.formBuilder.group({
      localizador: ['', Validators.required],
      fecha: [''],
      hora: [''],
      minutos: [''],
      integrantes: [''],
      horaEmision: [''],
      textoManual: ['']
    });
  }
  ngOnInit() {
    this.isOpen = true;
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.datosEtiquetas();
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.etiquetaForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  datosEtiquetas() {
    if (!this.localizadorService.localizadorValue) {
      this.datosEtiqueta = this.albaranService.albaranValue;
      this.albaranService.albaran.subscribe(res => {
        ;
        this.fecha = moment(res.FechaHoraEntrada).format('DD/MM/YYYY HH:mm');
        this.horaEmision = moment(res.FechaHoraEntrada).format('HH:mm');
        this.horaMaxima = moment(res.FechaHoraEntrada)
          .add(1, 'hours')
          .add(45, 'minutes')
          .format('HH:mm');
        this.etiquetaForm.controls['fecha'].patchValue(
          moment(this.fecha, 'DD/MM/YYYY').format('DD/MM/YYYY')
        );
      });
      this.hora = parseInt(this.horaMaxima.split(':')[0], 10);
      this.minutos = parseInt(this.horaMaxima.split(':')[1], 10);

      this.etiquetaForm.controls['localizador'].patchValue(this.datosEtiqueta.NumVenta);
      this.etiquetaForm.controls['fecha'].patchValue(this.fecha.split(' ')[0]);
      if (this.hora.toString().length === 1) {
        this.etiquetaForm.controls['hora'].patchValue('0' + this.hora);
      } else {
        this.etiquetaForm.controls['hora'].patchValue(this.hora);
      }
      if (this.minutos.toString().length === 1) {
        this.etiquetaForm.controls['minutos'].patchValue('0' + this.minutos);
      } else {
        this.etiquetaForm.controls['minutos'].patchValue(this.minutos);
      }
      this.etiquetaForm.controls['integrantes'].patchValue(this.datosEtiqueta.NumEntradas);
      this.etiquetaForm.controls['horaEmision'].patchValue(this.horaEmision);
      this.etiquetaForm.controls['textoManual'].patchValue('');
    } else {
      this.datosEtiqueta = this.localizadorService.localizadorValue;
      this.localizadorService.localizador.subscribe(res => {
        this.fecha = moment(res.FechaHoraEntrada).format('DD/MM/YYYY HH:mm');
        this.horaEmision = moment(res.FechaHoraEntrada).format('HH:mm');
        this.horaMaxima = moment(res.FechaHoraEntrada)
          .add(1, 'hours')
          .add(45, 'minutes')
          .format('HH:mm');
        this.etiquetaForm.controls['fecha'].patchValue(
          moment(this.fecha, 'DD/MM/YYYY').format('DD/MM/YYYY')
        );
      });
      this.hora = parseInt(this.horaMaxima.split(':')[0], 10);
      this.minutos = parseInt(this.horaMaxima.split(':')[1], 10);

      this.etiquetaForm.controls['localizador'].patchValue(this.datosEtiqueta.ClaveReserva);
      this.etiquetaForm.controls['fecha'].patchValue(this.fecha.split(' ')[0]);
      if (this.hora.toString().length === 1) {
        this.etiquetaForm.controls['hora'].patchValue('0' + this.hora);
      } else {
        this.etiquetaForm.controls['hora'].patchValue(this.hora);
      }
      if (this.minutos.toString().length === 1) {
        this.etiquetaForm.controls['minutos'].patchValue('0' + this.minutos);
      } else {
        this.etiquetaForm.controls['minutos'].patchValue(this.minutos);
      }
      this.etiquetaForm.controls['integrantes'].patchValue(this.datosEtiqueta.NumEntradas);
      this.etiquetaForm.controls['horaEmision'].patchValue(this.horaEmision);
      this.etiquetaForm.controls['textoManual'].patchValue('');
    }
  }

  subirHora() {
    if (this.hora < 23) {
      this.hora = this.hora + 1;
      if (this.hora.toString().length === 1) {
        this.etiquetaForm.controls['hora'].patchValue('0' + this.hora);
      } else {
        this.etiquetaForm.controls['hora'].patchValue(this.hora);
      }
    }
  }
  bajarHora() {
    if (this.hora > 0) {
      if (this.hora.toString().length === 1) {
        this.hora = ('0' + this.hora).toString();
      }
      this.hora = this.hora - 1;
      if (this.hora.toString().length === 1) {
        this.etiquetaForm.controls['hora'].patchValue('0' + this.hora);
      } else {
        this.etiquetaForm.controls['hora'].patchValue(this.hora);
      }
    }
  }
  subirMinutos() {
    if (this.minutos < 59) {
      this.minutos = this.minutos + 1;
      if (this.minutos.toString().length === 1) {
        this.etiquetaForm.controls['minutos'].patchValue('0' + this.minutos);
      } else {
        this.etiquetaForm.controls['minutos'].patchValue(this.minutos);
      }
    } else if (this.minutos === 59) {
      this.hora = this.hora + 1;
      if (this.hora.toString().length === 1) {
        this.etiquetaForm.controls['hora'].patchValue('0' + this.hora);
      } else {
        this.etiquetaForm.controls['hora'].patchValue(this.hora);
      }
      this.minutos = 0;
      if (this.minutos.toString().length === 1) {
        this.etiquetaForm.controls['minutos'].patchValue('0' + this.minutos);
      } else {
        this.etiquetaForm.controls['minutos'].patchValue(this.minutos);
      }
    }
  }
  bajarMinutos() {
    if (this.hora === 0 && this.minutos === 0) {
      return;
    }
    if (this.minutos > 0) {
      this.minutos = this.minutos - 1;
      if (this.minutos.toString().length === 1) {
        this.etiquetaForm.controls['minutos'].patchValue('0' + this.minutos);
      } else {
        this.etiquetaForm.controls['minutos'].patchValue(this.minutos);
      }
    } else if (this.minutos === 0) {
      this.hora = this.hora - 1;
      if (this.hora.toString().length === 1) {
        this.etiquetaForm.controls['hora'].patchValue('0' + this.hora);
      } else {
        this.etiquetaForm.controls['hora'].patchValue(this.hora);
      }
      this.minutos = 59;
      if (this.minutos.toString().length === 1) {
        this.etiquetaForm.controls['minutos'].patchValue('0' + this.minutos);
      } else {
        this.etiquetaForm.controls['minutos'].patchValue(this.minutos);
      }
    }
  }
  // @Todo resetear fecha al finalizar o cerrar modal
  imprimirEtiquetas() {
    this.printService.printLabel(this.etiquetaForm.value);
    this.isOpen = false;
    this.close();
  }
}
