import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../shared/services/translate.service';

@Pipe({
    name: 'estado'
})
export class EstadoPipe implements PipeTransform {
    constructor(
      private translate: TranslateService
    ) {
    }

    transform(value: any, args?: any): any {
        switch (value) {
            case 0:
                return this.translate.instant('AGOTADO');
            case 1:
                return this.translate.instant('DISPONIBLE');
            case 2:
                return this.translate.instant('ULTIMAS_SESIONES');

        }
    }

}
