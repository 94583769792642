import { Pipe, PipeTransform } from '@angular/core';
import { LoginComponent } from 'src/app/core/auth/login/login.component';
// import { ConfigurationService } from 'src/app/core';

const PADDING = '000000';

@Pipe({ name: 'myCurrency' })
export class MyCurrencyPipe implements PipeTransform {

  private DECIMAL_SEPARATOR: string;
  private FRACTION_SIZE: number;

  constructor() {
    this.DECIMAL_SEPARATOR = ',';
    this.FRACTION_SIZE = 2;
  }

  transform(value: number | string, separadorDecimal, numeroDecimales, divisaPosicion = "", divisaSimbolo = "", simboloDerecha = ""): string {
    this.DECIMAL_SEPARATOR = separadorDecimal;
    this.FRACTION_SIZE = numeroDecimales;
    if (value === undefined) {
      return '';
    }
    if (typeof value === 'string') {
      value = Number.parseFloat(value.replace(',', '.'));
    }
    // tslint:disable-next-line:prefer-const
    let [ integer, fraction = '' ] = (value || '0').toString().split('.');

    fraction = this.FRACTION_SIZE > 0
    ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, this.FRACTION_SIZE)
    : '';
    if(divisaPosicion == "D") 
      return  integer + fraction + divisaSimbolo + simboloDerecha;
    if(divisaPosicion == "I")
      return  divisaSimbolo + integer + fraction + simboloDerecha;
    // integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '');
    return integer + fraction;
  }

  // parse(value: string, fractionSize: number = 2): string {
  //   let [ integer, fraction = '' ] = (value || '').split(this.DECIMAL_SEPARATOR);

  //   integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');

  //   fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
  //     ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
  //     : '';

  //   return integer + fraction;
  // }

}
