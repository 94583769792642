import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, isObservable, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any = {};

  private cambioIdiomaSubject: BehaviorSubject<any>;
  public cambioIdioma$: Observable<any>;


  private _locale: string;

  setLocale(value: string) {
    switch (value) {
      case 'ca':
        value = 'ca';
        break;
      case 'en':
        value = 'en-GB';
        break;
      case 'en-GB':
        value = 'en-GB';
        break;
      case 'en-US':
        value = 'en-GB';
        break;
      case 'es':
        value = 'es-ES';
        break;
      default:
        break;
    }
    this._locale = value;
  }

  getLocale(): string {
    return this._locale || 'es-ES';
  }

  constructor(private http: HttpClient) {


    this.cambioIdiomaSubject = new BehaviorSubject<any>(undefined);
    this.cambioIdioma$ = this.cambioIdiomaSubject.asObservable();
  }

  public get cambioIdiomaValue() {
    return this.cambioIdiomaSubject.value;
  }


  // use(lang: string): Promise<{}> {
  //   this.setLocale(lang);
  //   return new Promise<{}>((resolve, reject) => {
  //     this.fileLangExists(lang).subscribe(existsLangPath =>
  //       {
  //         if(!existsLangPath){
  //           lang = this._locale ||'es-ES';
  //         }
  //     const langPath = `assets/i18n/${lang || this._locale || 'es-ES'}.json`;
  //     this.http.get<{}>(langPath).subscribe(
  //       translation => {
  //         this.data = Object.assign({}, translation || {});
  //         resolve(this.data);
  //       },
  //       error => {
  //         this.data = {};
  //         resolve(this.data);
  //       }
  //     );
  //   });
  //   });
  // }


  use(lang: string): Promise<{}> {
    this.setLocale(lang);
    this.cambioIdiomaSubject.next(false);
    return new Promise<{}>((resolve, reject) => {
      this.fileLangExists(lang).subscribe(existsLangPath => {
        if (!existsLangPath) {
          lang = this._locale || 'es-ES';
        }
        const langPath = `assets/i18n/${lang || this._locale || 'es-ES'}.json`;
        this.http.get<{}>(langPath).subscribe(
          translation => {
            this.data = Object.assign({}, translation || {});
            this.cambioIdiomaSubject.next(true);
            resolve(this.data);
          },
          error => {
            this.data = {};
            this.cambioIdiomaSubject.next(true);
            resolve(this.data);
          }
        );
      });
    });
  }

  /**
   * Returns a translation instantly from the internal state of loaded translation.
   * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
   */
  public instant(key: string | Array<string>, idioma?: string, interpolateParams?: Object): string | any {
    if (key === 'undefined' || key === null || !key.length) {
      throw new Error(`Parameter "key" required`);
    }
    if (!idioma) {
      idioma = this.getLocale();
    }

    let res = this.getParsedResult(idioma, key, interpolateParams);
    if (isObservable(res)) {
      if (key instanceof Array) {
        let obj: any = {};
        key.forEach((value: string, index: number) => {
          obj[key[index]] = key[index];
        });

        return obj;
      }
      return key;
    } else {
      return res;
    }

  }

  // public instant(key: string | Array<string>, interpolateParams?: Object): string | any {
  //   if ( key === 'undefined' || key === null || !key.length) {
  //     throw new Error(`Parameter "key" required`);
  //   }

  //   let res = this.getParsedResult(this.getLocale(), key, interpolateParams);
  //   if (isObservable(res)) {
  //     if (key instanceof Array) {
  //       let obj: any = {};
  //       key.forEach((value: string, index: number) => {
  //         obj[key[index]] = key[index];
  //       });
  //       return obj;
  //     }
  //     return key;
  //   } else {
  //     return res;
  //   }
  // }

  /**
   * Returns the parsed result of the translations
   */
  public getParsedResult(translations: any, key: any, interpolateParams?: Object): any {
    let res: string | Observable<string>;
    if (key instanceof Array) {
      let result: any = {},
        observables: boolean = false;
      for (let k of key) {
        result[k] = this.getParsedResult(translations, k, interpolateParams);
        if (isObservable(result[k])) {
          observables = true;
        }
      }
      if (observables) {
        const sources = key.map(k => isObservable(result[k]) ? result[k] : of(result[k] as string));
        return forkJoin(sources).pipe(
          map((arr: Array<string>) => {
            let obj: any = {};
            arr.forEach((value: string, index: number) => {
              obj[key[index]] = value;
            });
            return obj;
          })
        );
      }
      return result;
    } else if (key !== 'undefined' || key !== null || key.length > 0) {
      return res = this.data[key] || key;
    }
  }

  fileLangExists(lang: string) {
    const url = `assets/i18n/${lang}.json`;
    return this.fileExists(url);
  }

  fileExists(url: string): Observable<boolean> {
    return this.http.get(url)
      .pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

}

