import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Carrito, Carrito2 } from '../models/carrito.model';
import { Entrada } from '../models/entrada.model';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';
import * as moment from 'moment';
import 'moment/min/locales';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map, first } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import { InsUpdContactoAlbaranModel } from '../models/ins-upd-contacto-albaran-model';
import { RecintosService } from './recintos.service'; 

@Injectable({
  providedIn: 'root'
})
export class VentaService {
  public carritoSubject: BehaviorSubject<any>;
  carrito: Observable<any>;
//<< Yaribel 20210210  Añadimos observable para el carrito original de la reserva
  public carritoOrigenSubject:BehaviorSubject<any>;
  carritoOrigen: Observable<any>;
// Yaribel 20210210 >>
  private clienteSeleccionadoSubject: BehaviorSubject<any>;
  public clienteSeleccionado: Observable<any>;

  private fechaSeleccionadaSubject: BehaviorSubject<any>;
  public fechaSeleccionada: Observable<any>;

  private cantidadVentasSubject: BehaviorSubject<any>;
  public cantidadVentas: Observable<any>;

  private disabledDaysSubject: BehaviorSubject<any>;
  public disabledDays: Observable<any>;
    //<<Yaribel 20210730 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370
    private hayDescuentoSubject: BehaviorSubject<boolean>;
    public hayDescuento$: Observable<boolean>;
    //Yaribel 20210730 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370 >>

   private masEntradasSubject: BehaviorSubject<any>;
   public masEntradas$: Observable<any>;
    
  idioma = 'es-ES';
  paseHorario = true;
  carritoModificado;
  currentRecinto: any;
  currentCliente = '0';
  productoYEntradas = '';
  verCalendario = new BehaviorSubject(false);
  entradasArrCompleto = new BehaviorSubject(undefined);
  entradasArrNominativas = new BehaviorSubject(undefined);
  irPago: boolean;
  public idMarcadoExterno :string = null; 
  constructor(
    private sidebarNavigation: SidebarNavigationService,
    private http: HttpClient,
    private config: AppConfiguration,
    private configuration: ConfigurationService,
    private recintosService: RecintosService,
  ) {
    
    this.carritoSubject = new BehaviorSubject(new Carrito());
    this.carrito = this.carritoSubject.asObservable();
//<< Yaribel 20210210  Añadimos observable para el carrito original de la reserva
    this.carritoOrigenSubject = new BehaviorSubject(new Carrito());
    this.carritoOrigen = this.carritoOrigenSubject.asObservable();
// Yaribel 20210210 
    this.clienteSeleccionadoSubject = new BehaviorSubject('');
    this.clienteSeleccionado = this.clienteSeleccionadoSubject.asObservable();

    this.fechaSeleccionadaSubject = new BehaviorSubject(moment());
    this.fechaSeleccionada = this.fechaSeleccionadaSubject.asObservable();

    this.cantidadVentasSubject = new BehaviorSubject(0);
    this.cantidadVentas = this.cantidadVentasSubject.asObservable();

    this.disabledDaysSubject = new BehaviorSubject([]);
    this.disabledDays = this.disabledDaysSubject.asObservable();
    //<<Yaribel 20210730 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370
    this.hayDescuentoSubject = new BehaviorSubject(false);
    this.hayDescuento$ = this.hayDescuentoSubject.asObservable();
        //Yaribel 20210730 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370

    this.masEntradasSubject = new BehaviorSubject('');
    this.masEntradas$ = this.masEntradasSubject.asObservable();
  }


public get masEntradasValue(){
  return this.masEntradasSubject.value;
}
public setMasEntradas(valor)
{
  this.masEntradasSubject.next(valor);
}

    // << Yaribel 20210730 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370
    public get hayDescuentoValue() {
      return this.carritoValue.hayDescuento;
    }
    // << Yaribel 20210730 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370
    public setHayDescuento(descuento) {
      this.carritoValue.hayDescuento = descuento;
      this.hayDescuentoSubject.next(descuento);
    }
  public get disabledDaysValue() {
    return this.disabledDaysSubject.value;
  }

  setDisabledDays(disabledDays) {
    this.disabledDaysSubject.next(disabledDays);
  }

  public get cantidadVentasValue() {
    return this.cantidadVentasSubject.value;
  }

  setCantidadVentas(qty) {
    this.cantidadVentasSubject.next(qty);
  }

  actualizarCantidadVentas(qty) {
    const cantidadActualizar = this.cantidadVentasSubject.value + qty;
    this.cantidadVentasSubject.next(cantidadActualizar);
  }

  public get clienteSeleccionadoValue() {
    return this.clienteSeleccionadoSubject.value;
  }

  setClienteSeleccionadoValue(cliente) {
    this.clienteSeleccionadoSubject.next(cliente);
  }

  public get fechaSeleccionadaValue() {
    return this.fechaSeleccionadaSubject.value;
  }

  setFechaSeleccionadaValue(fecha = moment(), isfechaEmpty = false) {
    if(isfechaEmpty) {
      this.fechaSeleccionadaSubject.next('');
    } else {
      this.fechaSeleccionadaSubject.next(fecha);
    }

  }

  public get carritoValue() {
    return this.carritoSubject.value;
  }

  setCarrito(carrito) {
    this.carritoSubject.next(carrito);
    this.carritoModificado = true;
  }
//<< Yaribel 20210210  Añadimos observable para el carrito original de la reserva
  public get carritoOrigenValue() {
    return this.carritoOrigenSubject.value;
  }

  setCarritoOrigen(carrito) {
    this.carritoOrigenSubject.next(carrito);
  }
// Yaribel 20210210 >>

  setCarritoPromocionalValue(carritoPromocionalValue: Carrito, carritoOrigen: Carrito) {
      const carritoFinal = carritoOrigen;
      carritoFinal.setCarritoModificacionValue(carritoPromocionalValue);
      this.carritoSubject.next(carritoFinal);
      this.carritoModificado = true;
  }
  //<< Yaribel 20210210  Añadimos observable para el carrito original de la reserva
  setCarritoReservaPagadaFinalValue(carritoOrigen: Carrito, carritoModificado: Carrito)
  {
    //Comparar las entradas del carrito origen y
    //dejar solo las entradas nuevas añadidas para pagar 
    

  }
  // Yaribel 20210210 >>


  clearCarrito() {
    this.carritoValue.resetearCarrito();
    this.setCarrito(this.carritoValue);
    if(this.carritoOrigenValue){ 
      this.setCarritoOrigen(new Carrito());
    }
    this.sidebarNavigation.unblockSecciones();
  }

  getCantidadPorEntrada(entrada: Entrada) {
    // if(this.carritoValue.reservaPagada == '1' && this.carritoOrigen){
    //   const cantidad = this.carritoValue.getCantidadPorEntrada(entrada) - this.carritoOrigenValue.getCantidadPorEntrada(entrada);
    //   return cantidad;
    // }
    return this.carritoValue.getCantidadPorEntrada(entrada);
  }

  getCantidadEntradasPorMotivo(entrada: Entrada, motivo) {
    return this.carritoValue.getCantidadEntradasPorMotivo(entrada, motivo);
  }

  sendEntrada(entrada: Entrada) {
    
    // ;
    this.carritoValue.agregarEntrada(entrada);
    this.setCarrito(this.carritoValue);
    this.sidebarNavigation.blockSecciones();
  }

  sendEntradas(entrada: Entrada, cantidad: number) {
    
    // ;
    // 
    if (this.carritoValue.horarios.length > 0
      && this.carritoValue.entradasPrevias && this.carritoValue.entradasPrevias.length > 0) {
      let entradaPreviaE: any = null;
        this.carritoValue.entradasPrevias.forEach (entradaPrevia => {
          if (entradaPrevia.entrada.idTarifa == entrada.idTarifa) {
            
            entradaPreviaE = entradaPrevia;
          }
        });
      if (entradaPreviaE) {
        if (cantidad >= Number.parseInt(entradaPreviaE.cantidad, 10)) {
          const recintosAnadir = [];
          const indicesEliminar = [];
          if (entrada.TipoProducto === '1') {
            entrada.recintos.forEach(r => recintosAnadir.push(r.Recinto));
          this.carritoValue.horarios.forEach((item, index) => {
            if (recintosAnadir.includes(item.sesion.RecintoId.toString())) {
              // item.cantidad = item.cantidad + 1;
              
              if (item.sesion.RecintosSesionId) {
                this
                  .eliminarAforoSesion(item.sesion.RecintosSesionId)
                  .subscribe(data => { });
                  indicesEliminar.push(index);
              } else {
                indicesEliminar.push(index);
              }
            }
          });
          } else if (entrada.TipoProducto === '2') {
            entrada.PromocionEntradas.forEach(item => {
              entrada.recintos.forEach(r => recintosAnadir.push(r.Recinto));
              this.carritoValue.horarios.forEach((item2, index) => {
                if (recintosAnadir.includes(item2.sesion.RecintoId.toString()) &&
                  item2.recinto.entradas.includes(item.TipoEntradaId)) {
                  
                  // item.cantidad = item.cantidad + 1;
                  if (item2.sesion.RecintosSesionId) {
                    this
                    .eliminarAforoSesion(item2.sesion.RecintosSesionId)
                    .subscribe(data => {});
                    indicesEliminar.push(index);
                  } else {
                    indicesEliminar.push(index);
                  }
                }
              });
            });
          }
          if (indicesEliminar.length > 0) {
            indicesEliminar.forEach(indice => {
              if (indice > 0) {
                indice --;
              }
              this.carritoValue.horarios.splice(indice, 1);
            });
          }
        } else if (cantidad < Number.parseInt(entradaPreviaE.cantidad, 10)) {
          const recintosEliminar = [];
          const indicesEliminar = [];
          
          entrada.recintos.forEach(r => recintosEliminar.push(r.Recinto));
          // 
          const cantidadEliminada = Number.parseInt(entradaPreviaE.cantidad, 10) - cantidad;
          this.carritoValue.horarios.forEach((item, index) => {
            if (recintosEliminar.includes(item.recinto.Recinto)) {
              if (item.cantidadxentrada && item.cantidadxentrada.length > 0) {
                // 
                if (!entradaPreviaE.entrada.Caracteristicas || entradaPreviaE.entrada.Caracteristicas.NumPeriodos == '') {
                  
                  if (item.cantidad - cantidadEliminada > 0) {
                    item.cantidad = item.cantidad - cantidadEliminada;
                    item.resumen = `${item.recinto.NombreRecinto} - ${moment(
                      this.fechaSeleccionadaValue,
                      'YYYY/MM/DD'
                    )
                      .locale(this.idioma)
                      .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
                      // this
                      // .reservaAforo(item.sesion.RecintosSesionId, item.cantidad, entradaPreviaE.TipoEntradaId)
                      // .subscribe();
                      //el metodo espera un array y por aqui solo para uno
                      let tmpArrEntradas = [];
                      tmpArrEntradas.push(entradaPreviaE);
                      this
                      .reservaAforoProductos(this.mapeaListaentradas2ListaProductos(entradaPreviaE), 
                      item.recinto.Recinto, this.fechaYYYYMMDD2slash(item.sesion.Fecha, item.sesion.HoraInicio) )
                      .subscribe();
                  } else {
                    
                    this
                    .eliminarAforoSesion(item.sesion.RecintosSesionId)
                    .subscribe(data => {});
                    indicesEliminar.push(index);
                  }
                } else {
                  
                  if (item.cantidadxentrada.length > 0) {
                    for (const entradaR of item.cantidadxentrada) {
                      if (entradaR.entrada.TipoEntradaId ==  entradaPreviaE.TipoEntradaId) {
                        // this
                        // .reservaAforo(item.sesion.RecintosSesionId, entradaR.cantidad - cantidadEliminada, entradaPreviaE.TipoEntradaId)
                        // .subscribe();
                        let tmpArrEntradas = [];
                        let tmpEntrada = entradaPreviaE;
                        tmpEntrada.cntidad = entradaR.cantidad - cantidadEliminada;
                        tmpArrEntradas.push(tmpEntrada);
                        this
                        .reservaAforoProductos(this.mapeaListaentradas2ListaProductos(entradaPreviaE), 
                        item.recinto.Recinto, this.fechaYYYYMMDD2slash(item.sesion.Fecha, item.sesion.HoraInicio) )
                        .subscribe();
                      }
                    }
                  }
                }
              } else {
                if (item.cantidad - cantidadEliminada > 0) {
                  item.cantidad = item.cantidad - cantidadEliminada;
                  item.resumen = `${item.recinto.NombreRecinto} - ${moment(
                    this.fechaSeleccionadaValue,
                    'YYYY/MM/DD'
                  )
                    .locale(this.idioma)
                    .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
                   // <<Yaribel 20210112 cambiamos peticion para que llame a reservaAforoProductos

                  //   //JS 20200812 MAntenemos el Metodo API ya que no hay productos
                  // this.reservaAforo(item.sesion.RecintosSesionId, item.cantidad)
                  // .subscribe();
                  this
                  .reservaAforoProductos(this.mapeaListaentradas2ListaProductos(entradaPreviaE), 
                  item.recinto.Recinto, this.fechaYYYYMMDD2slash(item.sesion.Fecha, item.sesion.HoraInicio) )
                  .subscribe();

                  // Yaribel 20210112 >>
                } else {
                  this
                  .eliminarAforoSesion(item.sesion.RecintosSesionId)
                  .subscribe(data => {});
                  indicesEliminar.push(index);
                }
              }
            }
          });
          if (indicesEliminar.length > 0) {
            indicesEliminar.forEach(indice => {
              if (indice > 0) {
                indice --;
              }
              this.carritoValue.horarios.splice(indice, 1);
            });
          }
        }
      }
    } else {
        const recintosAnadir = [];
        const indicesEliminar = [];
        if (entrada.TipoProducto === '1') {
          entrada.recintos.forEach(r => recintosAnadir.push(r.Recinto));
        this.carritoValue.horarios.forEach((item, index) => {
          if (recintosAnadir.includes(item.sesion.RecintoId.toString())) {
            // item.cantidad = item.cantidad + 1;
            
            if (item.sesion.RecintosSesionId) {
              this
                .eliminarAforoSesion(item.sesion.RecintosSesionId)
                .subscribe(data => { });
                indicesEliminar.push(index);
            } else {
              indicesEliminar.push(index);
            }
          }
        });
        } else if (entrada.TipoProducto === '2') {
          entrada.PromocionEntradas.forEach(item => {
            entrada.recintos.forEach(r => recintosAnadir.push(r.Recinto));
            this.carritoValue.horarios.forEach((item2, index) => {
              if (recintosAnadir.includes(item2.sesion.RecintoId.toString()) &&
                item2.recinto.entradas.includes(item.TipoEntradaId)) {
                
                // item.cantidad = item.cantidad + 1;
                if (item2.sesion.RecintosSesionId) {
                  this
                  .eliminarAforoSesion(item2.sesion.RecintosSesionId)
                  .subscribe(data => {});
                  indicesEliminar.push(index);
                } else {
                  indicesEliminar.push(index);
                }
              }
            });
          });
        }
        if (indicesEliminar.length > 0) {
          indicesEliminar.forEach(indice => {
            if (indice > 0) {
              indice --;
            }
            this.carritoValue.horarios.splice(indice, 1);
          });
        }
    }
    this.carritoValue.agregarEntradas(entrada, cantidad);
    this.setCarrito(this.carritoValue);
    this.sidebarNavigation.blockSecciones();
  }

  removeEntrada(entrada: Entrada) {
    
    this.carritoValue.eliminarEntrada(entrada);
    this.setCarrito(this.carritoValue);
  }

  getFechasFestivas(fecha, tipo = 1) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const fechaDesde = moment(fecha)
      .startOf('year')
      .format('YYYY/MM/DD hh:mm:ss');
    const fechaHasta = moment(fecha)
      .endOf('year')
      .format('YYYY/MM/DD hh:mm:ss');
    const body = {
      ConexionIacpos: conexionId,
      FechaDesde: fechaDesde,
      FechaHasta: fechaHasta,
      Tipo: tipo
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/HIBDatosFestivos`, body)
      .pipe(shareReplay(1));
  }

  getCalendario(TPVId, recinto, fecha, cliente, filtrarPorCliente, items) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      RecintoId: recinto,
      EsVentaTaquilla: '1',
      Fecha: moment(fecha)
        .startOf('month')
        .format('YYYY/MM/DD'),
      FechaHasta: moment(fecha)
        .endOf('month')
        .format('YYYY/MM/DD'),
      TPVId: TPVId,
      CodigoCliente: cliente,
      FiltrarPorCliente: filtrarPorCliente,
      TipoProducto: '0',
      Items: items
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/DisponibilidadCalendario`,
      body
    );
  }

  getTiposEntradaDatos() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/GetTiposEntradaDatos`,
        body
      )
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return {};
          }
          return res.DatosResult;
        })
      );
  }

  getTemporadas() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/GetTemporadas`,
        body
      )
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return {};
          }
          return res.DatosResult;
        })
      );
  }

  reservaAforo(sesion, cantidad, entradaId?) {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      IdentificadorUnico: identificadorUnico,
      Sesion: sesion,
      Cantidad: cantidad,
      Taquilla: 1,
      IdEntrada: entradaId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/ReservaAforo`,
      body
    );
  }

  async reservaAforoaAsincrono(sesion, cantidad, entradaId?) {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      IdentificadorUnico: identificadorUnico,
      Sesion: sesion,
      Cantidad: cantidad,
      Taquilla: 1,
      IdEntrada: entradaId
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/ReservaAforo`,
        body
      )
      .pipe(
        map((res: any) => {
          if (res.DatosResult) {
            return res.DatosResult;
          }
          return [];
        })
      ).toPromise();
  }

  reservaAforoProductos(productos, recinto, fechaHora, idSesion?) {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      IdentificadorUnico: identificadorUnico,
      listadoProductos: productos,
      Recinto: recinto,
      FechaHora: fechaHora,
      Plataforma: 2,
      IdSesion: idSesion
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/ReservaAforoProductos`,
      body
    );
  }

  async reservaAforoProductosAsincrono(productos, recinto, fechaHora, idSesion?) {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      IdentificadorUnico: identificadorUnico,
      ListadoProductos: productos,
      Recinto: recinto,
      FechaHora: fechaHora,
      Plataforma: 2,
      IdSesion: idSesion
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/ReservaAforoProductos`,
      body
    ).pipe(
      map((res: any) => {
        if (res.DatosResult) {
          return res.DatosResult;
        }
        return [];
      })
    ).toPromise();
  }

  eliminarAforoSesion(sesion) {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      SesionInternetId: identificadorUnico,
      Accion: 3,
      RecintosSesionesId: sesion
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/CancelarReservaAforo`,
      body
    );
  }

  eliminaReservaAforoCompleto() {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      SesionInternetId: identificadorUnico,
      Accion: 0,
      RecintosSesionesId: ''
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/CancelarReservaAforo`,
      body
    );
  }

  insUpdContactoAlbaran(contacto: InsUpdContactoAlbaranModel) {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPV: contacto.TPV,
      NumAlbaran: contacto.NumAlbaran,
      Nombre: contacto.Nombre,
      CIF: contacto.CIF,
      Email: contacto.Email,
      Telefono: contacto.Telefono,
      Fax: contacto.Fax,
      Domicilio: contacto.Domicilio,
      Localidad: contacto.Localidad,
      Pais: contacto.Pais,
      CP: contacto.CP,
      Apellido1: contacto.Apellido1,
      Apellido2: contacto.Apellido2,
      Provincia: contacto.Provincia,
      FechaNacimiento: contacto.FechaNacimiento,
      Sexo: contacto.Sexo,
      PublicidadInterna: contacto.PublicidadInterna ? 1 : 0,
      Contacto: contacto.Contacto,
      TipoPolitica: 0,
      EnlacePolitica: contacto.EnlacePolitica,
      NombreProyecto: 'TAQUILLA',
      NombreFormulario: 'modal-contacto.component',
      Notas: contacto.Notas,
      Pregunta1: contacto.Pregunta1,
      Respuesta1: contacto.Respuesta1,
      Pregunta2: contacto.Pregunta2,
      Respuesta2: contacto.Respuesta2,
      Pregunta3: contacto.Pregunta3,
      Respuesta3: contacto.Respuesta3,
      Pregunta4: contacto.Pregunta4,
      Respuesta4: contacto.Respuesta4,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsUpdContactoAlbaran`,
      body
    ).pipe(
      map((res: any) => {
        return res;
     }));
  }

  //Modificar todas las tarifas del carrito para reflejar un cambio de fecha
  async modificarTarifasFecha(fecha: string) {
    if (fecha && this.carritoValue && this.carritoValue.entradas && this.carritoValue.entradas.length > 0 && !this.carritoValue.entradas[0].entrada.idRecintoButaca) {
      // 
      this.carritoValue.entradas.forEach(async elemento => {
        
        //Guardamos los recintos Calculados
        elemento.fecha = fecha;
        let promocionEntrada = elemento.entrada.PromocionEntradas;

        // manieva - no se usaban
        // let reservaRecintos = elemento.entrada.recintos;
        // let reservaCaracteristicasEntrada = elemento.entrada.CaracteristicasEntrada;
        // let reservaFiltrosCaracteristica = elemento.entrada.FiltrosCaracteristicas;
        // let reservaEntradasVendidasPersona = elemento.entrada.EntradasVendidasPersonas;
        // let reservaListaButacas = elemento.entrada.ListaButacas;
        // let reservaNumOrdenPromos = elemento.entrada.NumOrdenPromos;
        // let reservaNumOrdenPromosConEntradas = elemento.entrada.NumOrdenPromosConEntradas;
        // let reservaPosicionesVenta = elemento.entrada.PosicionesVenta;
        // let reservaFarId = elemento.entrada.FarId;
        // let DNIAbonadosExt = elemento.entrada.DNIAbonadosExt;
        // let desdeAsociado = elemento.entrada.desdeAsociado;
        // let codigoAsociado = elemento.entrada.codigoAsociado;

        //Cambiamos los elementos de tarifa
        let idProducto = elemento.entrada.TipoProducto && elemento.entrada.TipoProducto == "2" ? elemento.entrada.TipoPromocionId : elemento.entrada.TipoEntradaId;
        
        await this.recalcularTarifaxCambioFecha(elemento.entrada.TipoProducto, idProducto, fecha)
          .toPromise()
          .then(entradas => {
            if (entradas && entradas.length > 0) { //Yaribel 20220331 Comprobamos si tenemos tarifa para el dia seleccionado
              entradas.forEach(entradaNuevoPrecio => {

                elemento.entrada = { ...elemento.entrada, ...entradaNuevoPrecio };
                if (promocionEntrada) {
                  //Recuperamos las EVPersonas de la reserva por idEntrada
                  promocionEntrada.forEach(entradaPromocion => {
                    elemento.entrada.PromocionEntradas.find(x => x.TipoEntradaId == entradaPromocion.TipoEntradaId).EntradasVendidasPersonas = entradaPromocion.EntradasVendidasPersonas;
                  });
                }
              });

            } else if ((!this.carritoValue.localizador || this.carritoValue.localizador != undefined) && !this.carritoValue.albaran) {
              
              //manieva 11595
              //cuando es combinada podemos estar ante un espectaculo
              //en una fecha diferente
              if (!elemento.entrada.idRecintoButaca)
                elemento.cantidad = 0; //Yaribel 20220331 Quitamos las entradas si no tenemos tarifa para ese dia

            }

          });
        // 

        this.carritoValue.recalcularTotales();
      });
    }
  }

    //Modificar las tarifas del carrito cuando cambia de fecha en selector de horarios
    async modificarTarifasFechaXSesiones(fecha:string)
    {
      if (fecha && this.carritoValue && this.carritoValue.entradas && this.carritoValue.entradas.length >0)
      {
        // 
        this.carritoValue.entradas.forEach(async elemento => {
          elemento.fecha = fecha;
          //Guardamos los recintos Calculados
          let reservaEntradasVendidasPersonaPromocionesxPromocion = elemento.entrada.PromocionEntradas;
  
          //Cambiamos los elementos de tarifa
          let idProducto = elemento.entrada.TipoProducto && elemento.entrada.TipoProducto == "2"? elemento.entrada.TipoPromocionId: elemento.entrada.TipoEntradaId;
          await this.recalcularTarifaxCambioFecha(elemento.entrada.TipoProducto,idProducto, fecha).toPromise()
          .then(entradas => {
            entradas.forEach(entradaNuevoPrecio => {
              
              
              
              elemento.entrada = {...elemento.entrada,...entradaNuevoPrecio};
              if (reservaEntradasVendidasPersonaPromocionesxPromocion)
              {
                //Recuperamos las EVPersonas de la reserva por idEntrada
                reservaEntradasVendidasPersonaPromocionesxPromocion.forEach(entradaPromocion => {
                  elemento.entrada.PromocionEntradas.find(x => x.TipoEntradaId == entradaPromocion.TipoEntradaId).EntradasVendidasPersonas = entradaPromocion.EntradasVendidasPersonas;
                });
              }
              
            });
          });
          // 
          
          this.carritoValue.recalcularTotales();
        });
      }
    }

  recalcularTarifaxCambioFecha(tipoproducto, entradasString, fecha, cliente?) { 
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: this.configuration.datosTPVPathValue.pkId,
      Fecha: fecha,
      TipoProducto: tipoproducto,
      CodigoCliente: cliente ? cliente.Codigo : this.clienteSeleccionadoValue ? this.clienteSeleccionadoValue.Codigo : '0',
      FiltrarPorCliente: 0,
      Items: '[' + tipoproducto +','+ entradasString + ']'
    };
    
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/GetKoreTarifasDisponibles`,
        body
      )
      .pipe(
        map((res: any) => {
          if (res.DatosResult) {
            return res.DatosResult.ListadoKoreTarifasDisponibles;
          }
          return [];
        })
      );
  }

  getCaracteristicasxEntrada(entrada: Entrada) {
    return this.carritoValue.getCaracteristicasxEntrada(entrada);
  }

  mapeaListaentradas2ListaProductos( listaentradas, filtroProducto?) :string[]
  {
    let respuesta:string[] = [];
    listaentradas.forEach(element => {
      let idProducto = "";
      if (element.entrada.TipoProducto == "2")
        {
          if (!filtroProducto ||  filtroProducto == element.entrada.TipoPromocionId)
          idProducto = "2," + element.entrada.TipoPromocionId + "," + element.cantidad.toString();
        }
        else if (element.entrada.TipoProducto == "3")
        {
          if (!filtroProducto ||  filtroProducto == element.entrada.TipoAbonadoId)
          idProducto = "3," + element.entrada.TipoAbonadoId + "," + element.cantidad.toString();
        }
        else
        {
          if (!filtroProducto ||  filtroProducto == element.entrada.TipoEntradaId)
          idProducto = "1," + element.entrada.TipoEntradaId + "," + element.cantidad.toString();
        }
      respuesta.push(idProducto);
    });
    //if (respuesta.length > 0) respuesta = respuesta.slice(0, -1); 
    return respuesta;
  }

  fechaYYYYMMDD2slash(fecha, hora)
  {
    let respuesta = "";
    if (fecha && fecha.length == 8)
      {
        respuesta = fecha.substring(0, 4) + "/" + fecha.substring(4,6) + "/" + fecha.substring(6);
      } 
    if (hora && hora.length == 5)
      {
        respuesta = respuesta + " " + hora + ":00";
      }
      else if (hora && hora.length == 8)
      {
        respuesta = respuesta + " " + hora;
      }
      else 
      {
        respuesta = respuesta + " " + "23:59:00";
      }
      return respuesta;
  } 

  calcularDescuentoCliente(){

    this.carritoValue.calcularDescuento();
  }

  mapHorarios(recintos) {
    const aforos = new Array();
    const aforosProducto = recintos.map((e, index) => {
      return {
        cantidad: e.CantidadXRecinto || e.CantidadTotal,
        index: index,
        recinto: {
          obligatorio: (e.ControlaAforoVenta && e.ControlaAforoVenta == '1') || (e.ControlaAforoReserva && e.ControlaAforoReserva == '1') ? true : false,
          Activo: '1',
          CodigoTipoControl: '',
          ControlaAforo: e.ControlaAforoVenta? e.ControlaAforoReserva: '',
          ControlaAforoReserva: '',
          ControlaNumSesion: '0',
          DisponibleInternet: '',
          EsActividad: '',
          NombreRecinto: e.RecintoNombre,
          Recinto: e.RecintoId,
          TiempoExtra: '0',
          TieneButacas: '0',
          TipoAforo: '',
          TipoEntrada: e.ItemId,
          entradas: recintos.filter(x => x.RecintoId == e.RecintoId && x.FechaEntrada == e.FechaEntrada).map( p => p.ItemId),
          fecha: moment(e.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD'),
          sesiones: [],
          ControlAforoExterno: ''
        },
        resumen: `${e.RecintoNombre} - ${moment(
          e.FechaEntrada,
          'YYYY/MM/DD'
        )
          .locale(this.idioma)
          .format('L')} ${moment(
            e.FechaEntrada,
            'YYYY/MM/DD HH:mm:ss'
          )
            .locale(this.idioma)
            .format('HH:mm')} (${(e.CantidadXRecinto?e.CantidadXRecinto:e.CantidadTotal)})`,
        sesion: {
          Activa: 1,
          ControlaGrupo: 0,
          Disponible: 0,
          DisponibleInternet: 0,
          Fecha: moment(e.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('YYYYMMDD'),
          FranjaHorario: '',
          HoraFin: '',
          HoraInicio: moment(e.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm'),
          LimiteAforo: null,
          MaximoAforo: 0,
          MaximoInternet: 0,
          Nombre: e.RecintoNombre,
          NumSesionId: 0,
          RecintoId: e.RecintoId,
          RecintosSesionId: null,
          SesionBloqueada: 0,
          TipoAforo: '',
          TipoSesionId: 0,
          disabled: false
        }
      };
    });
    const objRecinto = [];
    const objFecha = [];
    const objResumen =[];
    const newArr = [];
    let indice = 0;
    aforosProducto.map(el => {
      if (!(el.recinto.Recinto in objRecinto)) {
        objRecinto[el.recinto.Recinto] = true;
        if (!(el.recinto.fecha in objFecha)) { objFecha[el.recinto.fecha] = true;}
        if (!(el.resumen in objResumen)) {objResumen[el.resumen] = true;}         
          el.index = indice;
          indice++;
          newArr.push(el);
      } else if (!(el.recinto.fecha in objFecha)) { 
          objFecha[el.recinto.fecha] = true;
          if (!(el.resumen in objResumen)) {objResumen[el.resumen] = true;} 
          el.index = indice;
          indice++;
          newArr.push(el);
      } else if (!(el.resumen in objResumen)) {
        objResumen[el.resumen] = true;
        el.index = indice;
        indice++;
        newArr.push(el);
      } 
    });
    aforos.push(...newArr);
    aforos.flat(1);
    return aforos;
  }

  //manieva
  //si tenemos visitas y espectaculos en el mismo carrito
  //para entre otras cosas controlar el flujo separado
  esEntradaCombinada():boolean {
    // recintos en las visitas que tengan su entraada en el array
    // sumado a los que espectaculos que no tienen elementos en recinto y si un idRecintoButaca
      // Verifica si existe la propiedad 'recinto' en las entradas
    const existePropiedadRecinto = this.carritoValue.entradas.some(e => 'recinto' in e.entrada);
    if (!existePropiedadRecinto) {
      // Si no existe la propiedad 'recintos' o no es un array, retorna false
      return false;
    }
    // Verifica si hay entradas con recintos
    const hayEntradasConRecintos = this.carritoValue.entradas.some(e => e.entrada.recintos.length > 0);

    // Verifica si hay entradas sin recintos pero con idRecintoButaca
    const hayEntradasSinRecintosConIdButaca = this.carritoValue.entradas.some(e => e.entrada.recintos.length == 0 && e.entrada.idRecintoButaca);

    // Devuelve true si existe la propiedad 'recinto' y se cumplen las condiciones de la lógica anterior
    return hayEntradasConRecintos && hayEntradasSinRecintosConIdButaca;
    // return (
    //   this.carritoValue.entradas.some(e => e.entrada.recintos.length > 0) &&
    //   this.carritoValue.entradas.some(e => e.entrada.recintos.length == 0 && e.entrada.idRecintoButaca)
    // );
  }

  checkConAforo = () : boolean => { 
    let exist;
    let conAforo = false;
    if(this.carritoValue.entradas && this.carritoValue.entradas.length > 0) {
      const entradas = this.carritoValue.entradas;
      entradas.forEach(item => {
        if(item.entrada.recintos && item.entrada.recintos.length > 0) {
          exist = item.entrada.recintos.find(val => val.ControlaAforo !== '0');
          if(exist) {
            conAforo = true;
          }
        }
      }); 
    }
    return conAforo;
  }

  //si tenemos alguna visita y sus horarios completos
  esEntradaConHorariosCompletos() : boolean { 
    if(!this.checkConAforo()) {
      return true;
    }

    if(!this.recintosService.recintosValue) {
      return false;
    }
    //existen combinaciones donde no tenemos siquiera recintos
    //que es en entradas sin horarios
    //por ende esta validacion no tiene efecto
    if (this.recintosService.recintosValue && this.recintosService.recintosValue.length == 0)
      return true;

    //para todos los recintos que sea obligatorio su carga de horario
    //busco alguna ocurrencia de ese Recinto dentro del array de horarios del carrito
    //si obtuve al menos un -1 significa que alguno no encontre y deberia
    
    //Todos los recintos tienen que tener un horario seleccionado
    //No deberia poder avanzar si la cantidad de horarios disponibles y horarios seleccionados son distintas
    let sumEntradas = 0;
    let sumHorarios = 0;
    // Suma de entradas y recintos disponibles filtrando por controlaAforo distinto a 0 y EsActividad igual a 0
    // Se multiplica la cantidad de entradas por la cantidad de entradas validas.
    this.carritoValue.entradas.forEach(element => {
      let cantidadEntradas = element.cantidad;
      let tiposEntradas = [];
      // PromocionEntrada es un elemento que puede
      if(element.entrada.PromocionEntradas && element.entrada.PromocionEntradas.length > 0){
          let totalEntradas = 0;
          element.entrada.PromocionEntradas.forEach(element => {
            totalEntradas += parseInt(element.NumeroEntradas) * cantidadEntradas;
            tiposEntradas.push(element.TipoEntradaId);
          });
          sumEntradas += totalEntradas
      }
      element.entrada.recintos.forEach(entrada => {
        if(entrada.ControlaAforo !== '0' && entrada.EsActividad === '0' && !tiposEntradas.includes(entrada.TipoEntrada))
          sumEntradas += 1 * cantidadEntradas;
      });
      if(element.entrada.auditorio == true)
        sumEntradas += element.cantidad;
    });
    // Suma de horarios disponibles siempre y cuando EsActividad es igual a 0
    // Se suma el elemento para auditorios si EsButaca igual a true
    this.carritoValue.horarios.forEach(element => {
      if(element.recinto.EsActividad === '0')
        sumHorarios += element.cantidad || 1;
      if(element.EsButaca == true)
        sumHorarios += 1;
    });;
    if(sumEntradas !== sumHorarios)
      return false;

    // En caso de que se seleccione venta sin fecha y alguna entrada posea aforo obligatorio 
    // se llena la fecha seleccionada con el valor de la fecha del carritos
    if(this.fechaSeleccionadaValue === '')
      this.setFechaSeleccionadaValue(this.carritoValue.fecha);
    if(this.recintosService.recintosValue.find(m => m.obligatorio))
    {
        return this.recintosService.recintosValue
        .filter(m => m.obligatorio)
        .map(m => this.carritoValue.horarios.findIndex(h => h.recinto.Recinto === m.Recinto))
        .some(h => h !== -1);
    }
    return true;
  }

}
