import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Carrito } from '../../venta/models/carrito.model';
import { TurnoService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class DevolucionService {
  comision;

  public carritoDevolucionSubject: BehaviorSubject<Carrito>;
  public carritoDevolucion$: Observable<any>;

  public itemsSeleccionadosSubject: BehaviorSubject<any>;
  public itemsSeleccionados: Observable<any>;

  private pagoTotalSubject: BehaviorSubject<any>;
  public pagoTotal: Observable<any>;

  private operacionesTarjetaSubject: BehaviorSubject<any>;
  public operacionesTarjeta: Observable<any>;

  private pagoDesglosadoSubject: BehaviorSubject<any>;
  public pagoDesglosado: Observable<any>;

  private opcionesPagoSubject: BehaviorSubject<any>;
  public opcionesPago: Observable<any>;

  private cambioSubject: BehaviorSubject<any>;
  public cambio: Observable<any>;

  private diferenciaSubject: BehaviorSubject<any>;
  public diferencia: Observable<any>;

  private formularioCorrectoSubject: BehaviorSubject<Boolean>;
  public formularioCorrecto: Observable<Boolean>;

  conf: any;

  constructor(private turnoService: TurnoService) {
    this.carritoDevolucionSubject = new BehaviorSubject(new Carrito());
    this.carritoDevolucion$ = this.carritoDevolucionSubject.asObservable();

    this.itemsSeleccionadosSubject = new BehaviorSubject(undefined);
    this.itemsSeleccionados = this.itemsSeleccionadosSubject.asObservable();

    this.pagoTotalSubject = new BehaviorSubject(undefined);
    this.pagoTotal = this.pagoTotalSubject.asObservable();

    this.cambioSubject = new BehaviorSubject(undefined);
    this.cambio = this.cambioSubject.asObservable();

    this.pagoDesglosadoSubject = new BehaviorSubject(undefined);
    this.pagoDesglosado = this.pagoDesglosadoSubject.asObservable();

    this.opcionesPagoSubject = new BehaviorSubject(undefined);
    this.opcionesPago = this.opcionesPagoSubject.asObservable();

    this.diferenciaSubject = new BehaviorSubject(undefined);
    this.diferencia = this.opcionesPagoSubject.asObservable();

    this.formularioCorrectoSubject = new BehaviorSubject(false);
    this.formularioCorrecto = this.pagoDesglosadoSubject.asObservable();

  }

  public get carritoDevolucion() {
    return this.carritoDevolucionSubject.value;
  }

  setCarritoDevolucion(carritoDevolucion) {
    this.carritoDevolucionSubject.next(carritoDevolucion);
  }

  public get itemsSeleccionadosValue() {
    return this.itemsSeleccionadosSubject.value;
  }

  setItemsSeleccionados(item) {
    this.itemsSeleccionadosSubject.next(item);
  }

  public get pagoDesglosadoValue() {
    return this.pagoDesglosadoSubject.value;
  }

  setPagoDesglosado(pago: any) {
    this.pagoDesglosadoSubject.next(pago);
  }

  resetPagoDesglosado() {
    const valores = {
      prepago: 0,
      transferencia: 0,
      pinpad: 0,
      datafono: 0,
      efectivo: 0,
      monedero: 0, // Yaribel 11112020 Añadimos monedero a pago desglosado
      aplazado: 0
    };
    this.pagoDesglosadoSubject.next(valores);
  }

  public get pagoTotalValue() {
    return this.pagoTotalSubject.value;
  }

  setPagoTotal(pagoTotal) {
    this.pagoTotalSubject.next(pagoTotal);
  }

  public get cambioValue() {
    return this.cambioSubject.value;
  }

  setCambio(cambio) {
    this.cambioSubject.next(cambio);
  }

  public get diferenciaValue() {
    return this.diferenciaSubject.value;
  }

  setDiferencia(cambio) {
    this.diferenciaSubject.next(cambio);
  }

  
  public get formularioCorrectoValue() {
    return this.formularioCorrectoSubject.value;
  }

  setFormularioCorrecto(formulario: Boolean) {
    this.formularioCorrectoSubject.next(formulario);
  }

  

  public generarMediosPagoVenta(): any[] {
    return [
      {
        FOP: 'CC',
        Importe:
          (this.pagoDesglosadoValue.pinpad + this.pagoDesglosadoValue.datafono) * (-1)
      },
      {
        FOP: 'CA',
        Importe: (this.pagoDesglosadoValue.efectivo).toFixed(
          2
        ) * (-1)
      },
      {
        FOP: 'TR',
        Importe: this.pagoDesglosadoValue.transferencia * (-1)
      },
      {
        FOP: 'PR',   // Yaribel 20201217 modificamos la forma de pago IN por PR
        Importe: this.pagoDesglosadoValue.prepago * (-1)
      },
      // Yaribel 30102020 añadimos medio de pago TM
      {
        FOP: 'TM',
        Importe: this.pagoDesglosadoValue.monedero * (-1)
      },
      // Yaribel 30102020 fin de añadir medio de pago
      {
        FOP: 'DP',
        Importe: this.pagoDesglosadoValue.aplazado * (-1)
      },
      {
        FOP: 'IN',  // Yaribel 20201217 añadimos la forma de pago IN para la validación con las formas de pago de CajaTurno
        Importe: 0 * (-1)
      }

    ];
  }

  public generarCajasTurnoCantidad(): any[] {
    return this.turnoService.cajasValue.map(caja => {
      return {
        CajasTurnoId: caja.pkid,
        Cantidad: this.generarMediosPagoVenta().find(
          medioDePago => medioDePago.FOP === caja.MedioPago
        ).Importe
      };
    });
  }

  esVentaCC() {
    return this.pagoDesglosadoValue.pinpad !== 0 ||
      this.pagoDesglosadoValue.datafono !== 0
      ? 1
      : 0;
  }
}
