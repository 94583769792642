import { CustomerDisplay } from './customerDisplay.interface';
import { OsConnectionFactory } from '../connection/osConnection.factory';
import { ConnectionType } from '../connection/connectionType.enum';
import { CustomerDisplayMessage } from './customerDisplayMessage.model';
import { OsConnection } from '../connection/osConnection.interface';
import { CustomerDisplayLine } from './customerDisplayLine.model';

export class VisorCustomerDisplay implements CustomerDisplay {


    osConnection: OsConnection;
    constructor() {
        this.osConnection = OsConnectionFactory.getConnection(ConnectionType.ASPNET);
    }

    printMessage(message: CustomerDisplayMessage) {
        this.osConnection.customerDisplay(message);
    }

    setToBlank() {
        const line = new CustomerDisplayLine('');
        const message = new CustomerDisplayMessage().addLine(line).addLine(line);
        this.osConnection.customerDisplay(message);
    }

}
