import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { UserManagerService } from './user-manager.service';
import { LoginService } from './login.service';
import { AppConfiguration } from 'src/app/app.configuration';

@Component({
  selector: 'ticketing-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.scss'],
  host: {
    class: 'layout__cambiar-usuario'
  }
})
export class CambiarPasswordComponent implements OnInit {
  error;
  loging = false;
  loginForm: FormGroup;
  usuarioId;
  constructor(private router: Router,    private config: AppConfiguration, private formBuilder: FormBuilder, private formControlService: FormControlService, private userService: UserManagerService, private loginService: LoginService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      contrasena: ['', Validators.required],
      cambioInicial: ''
    });

  }

  cambiarPassword(){

    this.userService.cambiarPasswordById(
      this.loginService.usuarioIdValue,
      this.loginService.usuarioValue,
      this.loginService.contrasenaValue,
      this.loginForm.controls['contrasena'].value,
    ).subscribe(res =>{
      if(res)
      {
        this.loginService.setContrasena(this.loginForm.controls['contrasena'].value);
        this.loginService.cargarDatosLogin(
          this.loginService.usuarioValue,
          this.loginForm.controls['contrasena'].value,
          this.loginService.importeInicialValue
        ).subscribe(carga =>{
          if(carga){
          if (this.config.getConfig('COMPRAINICIO')) {
            this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
          } else {
            this.router.navigate(['']);
          }
        }
        });        
      }
    });
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.loginForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }
  checkLoginIsInvalid() {
    if (this.loginForm.invalid) {
      return true;
    }
    return false;
  }

  cancelar(){
    this.loginService.setCambiarPass(false);
  }
}
