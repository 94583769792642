import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../services/format.service';

@Pipe({
  name: 'addDotsToNumber'
})
export class AddDotsToNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return FormatService.addDotsToNumber(value);
  }

}
