import { Component, OnInit } from '@angular/core';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'ticketing-imprimir',
  templateUrl: './imprimir.component.html',
  styleUrls: ['./imprimir.component.scss']
})
export class ImprimirComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }
 
}


