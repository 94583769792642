import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevolucionComponent } from './devolucion.component';

@NgModule({
  declarations: [DevolucionComponent],
  imports: [
    CommonModule
  ]
})
export class DevolucionModule { }
