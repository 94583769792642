import * as jspdf from 'jspdf';
import * as moment from 'moment';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf';
import { TranslateService } from 'src/app/shared/services/translate.service';


export enum AlbaranOperationType {
  VENTA,
  DEVOLUCION,
  ANULACION,
}

export interface IvaDisct {
  pIva: string;
  datos: IvaDictValue;
}

export interface IvaDictValue {
  base: number;
  pvp: number;
  iva: number;
}

export class AlbaranDocumentBody {
  private documentBody: any;
  private y: number;
  private x: number;
  private finalx: number;
  private tamanioLetra: number;
  private espaciado: number;
  private centrado: number;
  private tab1: number;
  private tab2: number;
  private width;
  private height;
  // constructor() {
  //   this.documentBody = new jspdf('p', 'mm', [80 * 2.83, 275 * 2.83]);
  //   this.y = 0;
  //   const width = this.documentBody.internal.pageSize.getWidth();
  //   const height = this.documentBody.internal.pageSize.getHeight();

  // }

  constructor(private translate: TranslateService, private config: AppConfiguration,    private configuration: ConfigurationService) {
    this.crearPlantillaJustificante();
    // this.documentBody = new jspdf('p', 'mm', [80 * 2.83, 275 * 2.83]);
    // this.y = 0;
    // this.x = 6;
    // this.finalx = 70;
    // this.tamanioLetra = 8;
    // this.espaciado = 4;
    // this.centrado = 37;
    // this.tab1 = 15;
    // this.tab2 = 25;
    const width = this.documentBody.internal.pageSize.getWidth();
    const height = this.documentBody.internal.pageSize.getHeight();
  }
  addClientHeader(cabecera?) {
    if (cabecera && cabecera.IncluirEncabezadoTicket === 'True') {
      this.documentBody.setFontSize(this.tamanioLetra);
      if (cabecera.chLinea1 !== '') {
        this.documentBody.setFontSize(this.tamanioLetra + 4);
        this.documentBody.setFontStyle('bold');
        this.documentBody.text(
          cabecera.chLinea1,
          this.centrado,
          (this.y = this.y + this.espaciado),
          'center'
        );
        const empresa = this.config.getConfig('chClaveEmpresa');
        if (empresa !== 'MAPFRE')
          this.documentBody.text(
            //'Factura de venta directa',
            this.translate.instant('JUSTIFICANTE_FACTURA_VENTA_DIRECTA'),
            this.centrado,
            (this.y += this.espaciado),
            'center'
          );
        this.documentBody.setFontSize(this.tamanioLetra);
        this.documentBody.setFontStyle('normal');
        this.documentBody.line(
          this.x,
          (this.y += this.espaciado),
          this.finalx,
          this.y
        );
      }
      if (cabecera.chLinea2 !== '') {
        this.documentBody.text(
          cabecera.chLinea2,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chLinea3 !== '') {
        this.documentBody.text(
          cabecera.chLinea3,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chLinea4 !== '') {
        this.documentBody.text(
          cabecera.chLinea4,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chLinea5 !== '') {
        this.documentBody.text(
          cabecera.chLinea5,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chLinea6 !== '') {
        this.documentBody.text(
          cabecera.chLinea6,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      return this;
    } else {
      this.documentBody.setFontSize(this.tamanioLetra);
      return this;
    }
  }

  addSellingPointName(TPV, name) {
    if (TPV && name && TPV.length > 0 && name.length > 0) {
      this.documentBody
        // .text('TPV', 6, (this.y += 4), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_TPV'),
          this.x,
          (this.y += this.espaciado),
          'left'
        )
        .text(TPV, this.centrado, this.y, 'right')
        .text(name, this.finalx, this.y, 'right');
    }
    return this;
  }

  addRefundingSellingPointName(TPV, name) {
    if (TPV && name && TPV.length > 0 && name.length > 0) {
      this.documentBody
        // .text('TPV DE ORIGEN', 6, (this.y += 4), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_TPV_ORIGEN'),
          this.x,
          (this.y += this.espaciado),
          'left'
        )
        .text(TPV, this.centrado, this.y, 'right')
        .text(name, this.finalx, this.y, 'right');
    }
    return this;
  }

  addAlbaranNumber(albaranNumber) {
    if (albaranNumber && albaranNumber.length > 0) {
      this.documentBody
        //      .text('Nº ALBARAN', 6, (this.y += 4), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_No_ALBARAN'),
          this.x,
          (this.y += this.espaciado),
          'left'
        )
        .text(albaranNumber, this.finalx, this.y, 'right');
    }
    return this;
  }

  addOriginAlbaranNumber(albaranNumber) {
    if (albaranNumber && albaranNumber.length > 0) {
      this.documentBody
        // .text('Nº ALBARAN DE ORIGEN', 6, (this.y += 4), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_No_ALBARAN_ORIGEN'),
          this.x,
          (this.y += this.espaciado),
          'left'
        )
        .text(albaranNumber, this.finalx, this.y, 'right');
    }
    return this;
  }

  addLocator(locator) {
    if (locator && locator.length > 0) {
      this.documentBody
        // .text('IDENTIFICADOR', 6, (this.y += 4), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_IDENTIFICADOR'),
          this.x,
          (this.y += this.espaciado),
          'left'
        )
        .text(locator, this.finalx, this.y, 'right');
    }
    return this;
  }

  addOriginLocator(locator) {
    if (locator && locator.length > 0) {
      this.documentBody
        // .text('IDENTIFICADOR ORIGEN', 6, (this.y += 4), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_IDENTIFICADOR_ORIGEN'),
          this.x,
          (this.y += this.espaciado),
          'left'
        )
        .text(locator, this.finalx, this.y, 'right');
    }
    return this;
  }

  addOnlineSourceLocator(locator) {
    if (locator && locator.length > 0) {
      this.documentBody
        // .text('Nº LOCALIZADOR INTERNET', 6, (this.y += 4), 'left')
        .text(
          this.translate.instant('JUSTIFICANTE_No_LOCALIZADOR_INTERNET'),
          this.x,
          (this.y += this.espaciado),
          'left'
        )
        .text(locator, this.finalx, this.y, 'right');
    }
    return this;
  }

  addDateOfSale(day, hour) {
    this.documentBody
      // .text('FECHA DE VISITA', 6, (this.y += 4), 'left')
      .text(
        this.translate.instant('JUSTIFICANTE_FECHA_VISITA'),
        this.x,
        (this.y += this.espaciado),
        'left'
      )
      .text(this.formatFecha(day), this.finalx - 15, this.y, 'right')
      .text(this.formatHora(hour), this.finalx, this.y, 'right');
    return this;
  }

  addDateOfOperation() {
    const fecha = moment();
    const dia = fecha.format('DD/MM/YYYY');
    const hora = fecha.format('HH:mm');
    this.documentBody
      // .text('FECHA DE OPERACIÓN', 6, (this.y += 4), 'left')
      .text(
        this.translate.instant('JUSTIFICANTE_FECHA_OPERACION'),
        this.x,
        (this.y += this.espaciado),
        'left'
      )
      .text(this.formatFecha(dia), this.finalx - 15, this.y, 'right')
      .text(this.formatHora(hora), this.finalx, this.y, 'right');
    return this;
  }

  addOnlineLocator() {
    return this;
  }

  addDateOfVisit(day, hour) {
    this.documentBody
      // .text('FECHA DE VENTA', 6, (this.y += 4), 'left')
      .text(
        this.translate.instant('JUSTIFICANTE_FECHA_VENTA'),
        this.x,
        (this.y += this.espaciado),
        'left'
      )
      .text(this.formatFecha(day), this.finalx - 15, this.y, 'right')
      .text(this.formatHora(hour), this.finalx, this.y, 'right');
    return this;
  }

  // Yaribel 20210112  Modificamos la impresion de item para añadir la nueva funcion que controla la logitud de la descripcion
  addItems(items: any[]) {
    this.documentBody
      // .text('UDS.', 6, (this.y += 10), 'left')
      // .text('DESCRIPCION', 15, this.y, 'left')
      // .text('IMPORTE', 70, this.y, 'right');
      .text(
        this.translate.instant('JUSTIFICANTE_UDS'),
        this.x,
        (this.y += this.espaciado + this.espaciado),
        'left'
      )
      .text(
        this.translate.instant('JUSTIFICANTE_DESCRIPCION'),
        this.tab2,
        this.y,
        'left'
      )
      .text(
        this.translate.instant('JUSTIFICANTE_IMPORTE'),
        this.finalx,
        this.y,
        'right'
      );
    //this.documentBody.setFontSize(6);
    items.forEach((item) => {
      let ArrayItemImpresion: string[] = [];
      ArrayItemImpresion = this.getArrayNombreImpresion(
        item.NombreImpresion,
        ArrayItemImpresion
      );
      // this.documentBody
      //   .text(item.Cantidad, 11, (this.y += 5), 'right')
      //   .text(item.NombreImpresion, 15, this.y, 'left')
      //   .text(
      //     `${this.addDotsToNumber(item.PrecioTotal
      //     )} €`,
      //     70,
      //     this.y,
      //     'right'
      //   );

      this.documentBody
        .setFontSize(this.tamanioLetra)
        .text(
          item.Cantidad,
          this.x + 5,
          (this.y += this.espaciado + 1),
          'right'
        )
        .setFontSize(this.tamanioLetra - 2)
        .text(ArrayItemImpresion[0], 15, this.y, 'left')
        .setFontSize(this.tamanioLetra)
        .text(
          `${this.addDotsToNumber(
            //item.PrecioTotal
            Number.parseFloat(item.PrecioTotal.replace(',', '.'))
              .toFixed(2)
              .replace('.', ',')
          )} €`,
          this.finalx,
          this.y,
          'right'
        );
      if (ArrayItemImpresion.length > 1) {
        for (let i = 1; i < ArrayItemImpresion.length; i++) {
          this.documentBody
            .setFontSize(this.tamanioLetra - 2)
            .text(
              ArrayItemImpresion[i],
              this.tab1,
              (this.y += this.espaciado - 1),
              'left'
            );
        }
      }
      if (this.obtenerCodigoTarjetaMonedero(item).length > 0) {
        this.documentBody
          .setFontSize(this.tamanioLetra - 2)
          .text(
            item.TarjetaMonederoCodigo,
            this.tab1,
            (this.y += this.espaciado - 1),
            'left'
          );
      }

      if (this.obtenerCodigoPromocional(item).length > 0) {
        this.documentBody
          .setFontSize(this.tamanioLetra - 2)
          .text(
            this.obtenerCodigoPromocional(item),
            this.tab1,
            (this.y += this.espaciado - 1),
            'left'
          );
      }

      // .text(
      //   `${Number.parseFloat(item.PrecioBase.replace(',', '.')).toFixed(
      //     2
      //   )} €`,
      //   70,
      //   this.y,
      //   'right'
      // );
    });
    this.documentBody.setFontSize(this.tamanioLetra);
    return this;
  }

  addItemsOnline(items: any[]) {
    this.documentBody.text(
      // 'PRODUCTOS INTERNET',
      this.translate.instant('JUSTIFICANTE_PRODUCTOS_INTERNET'),
      37,
      (this.y = this.y + this.espaciado),
      'left'
    );
    this.documentBody.line(
      this.x,
      (this.y += this.espaciado),
      this.finalx,
      this.y
    );
    items.forEach((item) => {
      let ArrayItemImpresion: string[] = [];
      ArrayItemImpresion = this.getArrayNombreImpresion(
        item.NombreImpresion,
        ArrayItemImpresion
      );
      this.documentBody
        .setFontSize(this.tamanioLetra)
        .text(
          item.Cantidad,
          this.x + 5,
          (this.y += this.espaciado + 1),
          'right'
        )
        .setFontSize(this.tamanioLetra - 2)
        .text(ArrayItemImpresion[0], this.tab1, this.y, 'left')
        .setFontSize(this.tamanioLetra)
        .text(
          `${this.addDotsToNumber(
            //item.PrecioTotal
            Number.parseFloat(item.PrecioTotal.replace(',', '.'))
              .toFixed(2)
              .replace('.', ',')
          )} €`,
          this.finalx,
          this.y,
          'right'
        );
      if (ArrayItemImpresion.length > 1) {
        for (let i = 1; i < ArrayItemImpresion.length; i++) {
          this.documentBody
            .setFontSize(this.tamanioLetra)
            .text(
              ArrayItemImpresion[i],
              this.tab1,
              (this.y += this.espaciado - 1),
              'left'
            );
        }
      }
      if (this.obtenerCodigoTarjetaMonedero(item).length > 0) {
        this.documentBody
          .setFontSize(this.tamanioLetra)
          .text(
            item.TarjetaMonederoCodigo,
            this.tab1,
            (this.y += this.espaciado - 1),
            'left'
          );
      }
      if (this.obtenerCodigoPromocional(item).length > 0) {
        this.documentBody
          .setFontSize(this.tamanioLetra - 2)
          .text(
            this.obtenerCodigoPromocional(item),
            this.tab1,
            (this.y += this.espaciado - 1),
            'left'
          );
      }
      // this.documentBody
      //   .text(item.Cantidad, 11, (this.y += 5), 'right')
      //   .text(item.NombreImpresion, 15, this.y, 'left')
      //   .text(
      //     `${this.addDotsToNumber(item.PrecioTotal)} €`,
      //     70,
      //     this.y,
      //     'right'
      //   );
    });
    return this;
  }
  addRefundedItems(items: any[]) {
    this.documentBody
      // .text('UDS.', 6, (this.y += 10), 'left')
      // .text('DESCRIPCION', 15, this.y, 'left')
      // .text('IMPORTE', 70, this.y, 'right');
      .text(
        this.translate.instant('JUSTIFICANTE_UDS'),
        this.x,
        (this.y += this.espaciado + this.espaciado),
        'left'
      )
      .text(
        this.translate.instant('JUSTIFICANTE_DESCRIPCION'),
        this.tab2,
        this.y,
        'left'
      )
      .text(
        this.translate.instant('JUSTIFICANTE_IMPORTE'),
        this.finalx,
        this.y,
        'right'
      );

    items.forEach((item) => {
      let ArrayItemImpresion: string[] = [];
      ArrayItemImpresion = this.getArrayNombreImpresion(
        item.NombreImpresion,
        ArrayItemImpresion
      );
      this.documentBody
        .setFontSize(this.tamanioLetra)
        .text(
          item.Cantidad,
          this.x + 5,
          (this.y += this.espaciado + 1),
          'right'
        )
        .setFontSize(this.tamanioLetra - 2)
        .text(ArrayItemImpresion[0], this.tab1, this.y, 'left')
        .setFontSize(this.tamanioLetra)
        .text(
          `${this.addDotsToNumber(
            //item.PrecioTotal
            Number.parseFloat(item.PrecioTotal.replace(',', '.'))
              .toFixed(2)
              .replace('.', ',')
          )} €`,
          this.finalx,
          this.y,
          'right'
        );
      if (ArrayItemImpresion.length > 1) {
        for (let i = 1; i < ArrayItemImpresion.length; i++) {
          this.documentBody
            .setFontSize(this.tamanioLetra - 2)
            .text(
              ArrayItemImpresion[i],
              this.tab1,
              (this.y += this.espaciado - 1),
              'left'
            );
        }
      }
      if (this.obtenerCodigoTarjetaMonedero(item).length > 0) {
        this.documentBody
          .setFontSize(this.tamanioLetra - 2)
          .text(
            item.TarjetaMonederoCodigo,
            this.tab1,
            (this.y += this.espaciado - 1),
            'left'
          );
      }
      if (this.obtenerCodigoPromocional(item).length > 0) {
        this.documentBody
          .setFontSize(this.tamanioLetra - 2)
          .text(
            this.obtenerCodigoPromocional(item),
            this.tab1,
            (this.y += this.espaciado - 1),
            'left'
          );
      }
      // this.documentBody
      //   .text(item.Cantidad, 11, (this.y += 5), 'right')
      //   .text(item.NombreImpresion, 15, this.y, 'left')
      //   .text(
      //     `${this.addDotsToNumber(item.PrecioTotal)} €`,
      //     70,
      //     this.y,
      //     'right'
      //   );
    });
    return this;
  }

  // Yaribel 20210112  >>

  addOperation(type: AlbaranOperationType) {
    if (type === AlbaranOperationType.VENTA) {
      this.documentBody.text(
        this.translate.instant('JUSTIFICANTE_OPERACION_VENTA'),
        this.centrado,
        (this.y += 4),
        'center'
      );
    }
    if (type === AlbaranOperationType.DEVOLUCION) {
      this.documentBody.text(
        this.translate.instant('JUSTIFICANTE_OPERACION_DEVOLUCION'),
        this.centrado,
        (this.y += 4),
        'center'
      );
    }
    if (type === AlbaranOperationType.ANULACION) {
      this.documentBody.text(
        this.translate.instant('JUSTIFICANTE_OPERACION_ANULACION'),
        this.centrado,
        (this.y += 4),
        'center'
      );
    }
    // if (type === AlbaranOperationType.VENTA) {
    //   this.documentBody.text('JUSTIFICANTE DE VENTA', 38, (this.y += 4), 'center');
    // }
    // if (type === AlbaranOperationType.DEVOLUCION) {
    //   this.documentBody.text('JUSTIFICANTE DE DEVOLUCION', 38, (this.y += 4), 'center');
    // }
    // if (type === AlbaranOperationType.ANULACION) {
    //   this.documentBody.text('JUSTIFICANTE DE ANULACION', 38, (this.y += 4), 'center');
    // }
    return this;
  }

  addLine() {
    this.documentBody.line(
      this.x,
      (this.y += this.espaciado),
      this.finalx,
      this.y
    );
    return this;
  }

  // Yaribel 18112020 Añadimos el desglose del IVa a el contenido del Justificante de Compra
  addDesgloseIVA(items: any[]) {
    let agruparIVA: IvaDisct[] = [];
    let totalBase = 0;
    let totalIva = 0;
    this.documentBody
      // .text('% IVA', 6, (this.y += 4), 'left')
      // .text('BASE IMPONIBLE', 20, (this.y), 'left')
      // .text('IMPORTE IVA', 70, this.y, 'right');
      .text(
        this.translate.instant('JUSTIFICANTE_PORCT_IVA'),
        this.x,
        (this.y += this.espaciado),
        'left'
      )
      .text(
        this.translate.instant('JUSTIFICANTE_BASE_IMPONIBLE'),
        this.tab1 + 5,
        this.y,
        'left'
      )
      .text(
        this.translate.instant('JUSTIFICANTE_IMPORTE_IVA'),
        this.finalx,
        this.y,
        'right'
      );
    items.forEach((item) => {
      if (item.TipoProducto === '2') {
        item.PromocionEntradas.forEach((entrada) => {
          if (!agruparIVA.find((x) => x.pIva == entrada.IVA)) {
            const agrupado = {
              pIva: entrada.IVA,
              datos: { base: 0, pvp: 0, iva: 0 },
            };
            agruparIVA.push(agrupado);
          }
          const agrupado = agruparIVA.find((x) => x.pIva == entrada.IVA);
          const pvp = Number.parseFloat(entrada.PrecioTotal.replace(',', '.'));
          const base = Number.parseFloat(
            (
              Number.parseFloat(entrada.PrecioBase.replace(',', '.')) *
              Number.parseFloat(entrada.NumeroEntradas.replace(',', '.'))
            ).toFixed(4)
          );
          const descuento = Number.parseFloat(
            (
              Number.parseFloat((entrada.ImporteDto || '0').replace(',', '.')) *
              Number.parseFloat(entrada.NumeroEntradas.replace(',', '.'))
            ).toFixed(4)
          );
          const baseConDto = Number.parseFloat(
            (
              Number.parseFloat(
                (entrada.PrecioBaseConDto || entrada.PrecioBase).replace(
                  ',',
                  '.'
                )
              ) * Number.parseFloat(entrada.NumeroEntradas.replace(',', '.'))
            ).toFixed(4)
          );
          const iva = Number.parseFloat((pvp + descuento - base).toFixed(4));
          agruparIVA[agruparIVA.indexOf(agrupado)].datos.base += baseConDto;
          //agruparIVA[agruparIVA.indexOf(agrupado)].datos.base  +=  base;
          agruparIVA[agruparIVA.indexOf(agrupado)].datos.pvp += pvp;
          agruparIVA[agruparIVA.indexOf(agrupado)].datos.iva +=
            Number.parseFloat((pvp + descuento - base).toFixed(4));
          totalBase += baseConDto;
          totalIva += iva;
        });
      } else if (item.TipoProducto === '1') {
        if (!agruparIVA.find((x) => x.pIva == item.IVA)) {
          const agrupado = {
            pIva: item.IVA,
            datos: { base: 0, pvp: 0, iva: 0 },
          };
          agruparIVA.push(agrupado);
        }
        const agrupado = agruparIVA.find((x) => x.pIva == item.IVA);
        const pvp = Number.parseFloat(item.PrecioTotal.replace(',', '.'));
        const base = Number.parseFloat(
          (
            Number.parseFloat(item.PrecioBase.replace(',', '.')) *
            Number.parseFloat(item.Cantidad.replace(',', '.'))
          )
            .toFixed(4)
            .replace(',', '.')
        ); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
        const descuento = Number.parseFloat(
          (
            Number.parseFloat((item.ImporteDto || '0').replace(',', '.')) *
            Number.parseFloat(item.Cantidad.replace(',', '.'))
          )
            .toFixed(4)
            .replace(',', '.')
        );
        const baseConDto = Number.parseFloat(
          (
            Number.parseFloat(
              (item.PrecioBaseConDto || item.PrecioBase).replace(',', '.')
            ) * Number.parseFloat(item.Cantidad.replace(',', '.'))
          ).toFixed(4)
        );
        const iva = Number.parseFloat((pvp + descuento - base).toFixed(4));
        agruparIVA[agruparIVA.indexOf(agrupado)].datos.base += baseConDto;
        agruparIVA[agruparIVA.indexOf(agrupado)].datos.pvp += pvp;
        agruparIVA[agruparIVA.indexOf(agrupado)].datos.iva += Number.parseFloat(
          (pvp + descuento - base).toFixed(4)
        );
        totalBase += baseConDto;
        totalIva += iva;
      }
    });

    agruparIVA.sort((a, b) => a.pIva.localeCompare(b.pIva));
    agruparIVA.forEach((item) => {
      this.documentBody
        .text(item.pIva, this.x + 5, (this.y += this.espaciado + 1), 'right')
        .text(
          `${this.addDotsToNumber(
            item.datos.base.toFixed(2).replace('.', ',')
          )} €`,
          this.tab2 + 10,
          this.y,
          'right'
        )
        .text(
          `${this.addDotsToNumber(
            item.datos.iva.toFixed(2).replace('.', ',')
          )} €`,
          this.finalx,
          this.y,
          'right'
        );
    });

    this.documentBody
      // .text('Total IVA', 15, (this.y += 5), 'left')
      .text(
        this.translate.instant('JUSTIFICANTE_TOTAL_IVA'),
        15,
        (this.y += this.espaciado + 1),
        'left'
      )
      .text(
        `${this.addDotsToNumber(totalIva.toFixed(2).replace('.', ','))} €`,
        this.finalx,
        this.y,
        'right'
      ); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
    this.documentBody
      // .text('Total base imponible', 15, (this.y += 5), 'left')
      .text(
        this.translate.instant('JUSTIFICANTE_TOTAL_BASE_IMPONIBLE'),
        15,
        (this.y += this.espaciado + 1),
        'left'
      )
      .text(
        `${this.addDotsToNumber(totalBase.toFixed(2).replace('.', ','))} €`,
        this.finalx,
        this.y,
        'right'
      )
      if(items[0].DesglosarComision === '1') {
        this.documentBody.text(this.translate.instant('JUSTIFICANTE_COMISION'), 15, this.y += this.espaciado + 1, 'left')
        .text(items[0].Comision + ' ' + '€', this.finalx, this.y, 'right');
      }; // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
    return this;
  }

  addTotal(total) {
    this.documentBody.setFontStyle('bold');
    this.documentBody.setFontSize(this.tamanioLetra + 1);
    this.documentBody
      // .text('T O T A L', 6, (this.y += 5), 'left')
      .text(
        this.translate.instant('JUSTIFICANTE_T_O_T_A_L'),
        this.x,
        (this.y += this.espaciado + 1),
        'left'
      )
      .text(
        `${this.addDotsToNumber(
          //total
          Number.parseFloat(total.replace(',', '.'))
            .toFixed(2)
            .replace('.', ',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
        )} €`,
        this.finalx,
        this.y,
        'right'
      );
    this.documentBody.line(
      this.x,
      (this.y += this.espaciado - 2),
      this.finalx,
      this.y
    );
    this.documentBody.setFontSize(this.tamanioLetra);
    this.documentBody.setFontStyle('normal');
    return this;
  }

  addPaymentMethod(paymentMethod, received, albaran) {
    this.documentBody.text(
      this.translate.instant('JUSTIFICANTE_METODO_DE_PAGO'),
      this.centrado,
      (this.y += this.espaciado + 1),
      'center'
    );
    //this.documentBody.line(6, (this.y += 1), 31, this.y);
    paymentMethod.forEach((element) => {
      // tslint:disable-next-line:max-line-length

      // << Yaribel 20210114  añadimos codigo de tarjeta a la forma de pago monedero
      const nombreFOP =
        this.generarFOP(element.FOP) +
        this.obtenerCodigoTarjetaMonedero(element);

      this.documentBody
        // .text(`${this.generarFOP(element.FOP) + this.obtenerCodigoTarjetaMonedero(element)}:`, 6, (this.y += 4), 'left')
        .text(`${nombreFOP}:`, this.x, (this.y += this.espaciado), {
          maxWidth: 60,
          align: 'left',
        })
        .text(
          `${this.addDotsToNumber(
            //element.Importe
            Number.parseFloat(element.Importe.replace(',', '.'))
              .toFixed(2)
              .replace('.', ',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
          )} €`,
          this.finalx,
          this.y,
          'right'
        );
      if (nombreFOP.length > 30)
        this.documentBody.text(
          '',
          this.finalx,
          (this.y += this.espaciado),
          'right'
        );
      if (element.FOP === 'CC') {
          this.documentBody
            .text(
              this.translate.instant('NUMERO_TARJETA'),
              this.x,
              (this.y += this.espaciado),
              'left'
            )
            .text(albaran.PagoCCInfo.NumTarjeta, this.finalx, this.y, 'right');
        
      }
    });
    // Yaribel 20210114 >>
    return this;
  }
  addPaymentOnline(received) {
    // @TODO deberian pasarse en forma de lista y generarse las lineas por cada forma de pago
    this.documentBody
      .text(`${'ONLINE'}:`, this.x, (this.y += this.espaciado), 'left')
      .text(
        `${this.addDotsToNumber(
          //received
          Number.parseFloat(received.replace(',', '.'))
            .toFixed(2)
            .replace('.', ',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
        )} €`,
        this.finalx,
        this.y,
        'right'
      );
    return this;
  }
  addChange(change) {
    this.documentBody
      // .text('CAMBIO:', 6, (this.y += 4), 'left')
      .text(
        this.translate.instant('CAMBIO'),
        this.x,
        (this.y += this.espaciado),
        'left'
      )
      .text(
        `${this.addDotsToNumber(
          //change
          Number.parseFloat(change.replace(',', '.'))
            .toFixed(2)
            .replace('.', ',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
        )} €`,
        this.finalx,
        this.y,
        'right'
      );
    return this;
  }

  addFooterMessage(cabecera?) {
    if (cabecera) {
      if (cabecera.chPie1 !== '') {
        this.documentBody.text(
          cabecera.chPie1,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }

      if (cabecera.chPie2 !== '') {
        this.documentBody.text(
          cabecera.chPie2,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chPie3 !== '') {
        this.documentBody.text(
          cabecera.chPie3,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chPie4 !== '') {
        this.documentBody.text(
          cabecera.chPie4,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chPie5 !== '') {
        this.documentBody.text(
          cabecera.chPie5,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
      if (cabecera.chPie6 !== '') {
        this.documentBody.text(
          cabecera.chPie6,
          this.x,
          (this.y = this.y + this.espaciado),
          'left'
        );
      }
    } else {
      this.documentBody.text(
        // 'Gracias por su visita',
        this.translate.instant('JUSTIFICANTE_GRACIAS_POR_SU_VISITA'),
        this.centrado,
        (this.y += this.espaciado - 1),
        'center'
      );
    }

    return this;
  }

  build(): any {
    return this.documentBody;
  }

  private generarFOP(fop) {
    switch (fop) {
      // case 'CA':
      //   return 'EFECTIVO';
      // case 'CC':
      //   return 'TARJETA';
      // case 'TR':
      //   return 'TRANSFERENCIA';
      // case 'TM':
      //   return 'TARJETA MONEDERO';  //Yaribel 11112020 añadimos forma de pago Tarjeta Monedero
      // case 'DP':
      //   return 'APLAZADO';  //Yaribel 20201209 añadimo forma de pago DP
      // case 'PR':
      //   return 'PREPAGO'; //Yaribel 20201217 añadimos forma de pago Prepago
      // default:
      //   return 'EFECTIVO';
      case 'CA':
        return this.translate.instant('EFECTIVO');
      case 'CC':
        return this.translate.instant('TARJETA');
      case 'TR':
        return this.translate.instant('TRANSFERENCIA');
      case 'TM':
        return this.translate.instant('JUSTIFICANTE_TARJETA_MONEDERO');
      case 'DP':
        return this.translate.instant('APLAZADO');
      case 'PR':
        return this.translate.instant('PREPAGO');
      default:
        return this.translate.instant('EFECTIVO');
    }
  }

  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    let negativo = false;
    if (number.substring(0, 1) === '-') {
      negativo = true;
      number = number.substring(1);
    }
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        newInteger = negativo ? '-' + newInteger : newInteger;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        newInteger = negativo ? '-' + newInteger : newInteger;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      newInteger = negativo ? '-' + newInteger : newInteger;
      return newInteger;
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    number = negativo ? '-' + number : number;
    return number + d;
  }

  formatFecha(fecha) {
    const splitFecha = fecha.split('/');
    if (splitFecha[0].length === 4 && splitFecha.length === 3) {
      return splitFecha[2] + '/' + splitFecha[1] + '/' + splitFecha[0];
    }
    return fecha;
  }

  formatHora(hora) {
    const splitHora = hora.split(':');
    if (splitHora.length === 3) {
      return splitHora[0] + ':' + splitHora[1] + ' h';
    }
    return hora + ' h';
  }

  // << Yaribel 20210114 Creamos funcion para controlar la longitud de la descripcion y hacer salto de linea
  getArrayNombreImpresion(
    NombreImpresion: string,
    result: string[],
    cantidad?
  ) {
    let limite = cantidad || 27;
    let variable = 4;
    if (
      NombreImpresion.length > limite &&
      NombreImpresion.length > limite + variable
    ) {
      const miRexp = /\s[a-zA-Z]|-|\+|\//;

      let separador;
      while (!separador && limite + variable < NombreImpresion.length) {
        separador = miRexp.exec(
          NombreImpresion.substring(limite - variable, limite + variable)
        );
        if (separador && separador.length > 0) {
          limite = limite - variable + separador.index + 1;
        }
        variable++;
      }
      const primerNombreImpresion: string = NombreImpresion.substring(
        0,
        limite
      );
      result.push(primerNombreImpresion);
      NombreImpresion = NombreImpresion.substring(limite);
      result = this.getArrayNombreImpresion(NombreImpresion, result);
    } else {
      result.push(NombreImpresion);
    }
    return result;
  }

  obtenerCodigoTarjetaMonedero(item: any) {
    return item.TarjetaMonederoCodigo && item.TarjetaMonederoCodigo.length > 0
      ? ' ' + item.TarjetaMonederoCodigo
      : '';
  }
  obtenerCodigoPromocional(item: any) {
    return item.CodigoPromocional && item.CodigoPromocional.trim().length > 0
      ? this.translate.instant('CODIGO') + ': ' + item.CodigoPromocional
      : '';
  }

  // Yaribel 20210114 >>

  crearPlantillaJustificante() {
    let altura; // altura del papel
    let anchura; //anchura del papel
    let variacion; // TODO comprobarlo actualmente se multiplica altura por anchura por 2,83
    let inicialx = 0; // posicion inicial en el eje x
    let inicialy = 0; // posicion inicial en el eje  y
    let finalx = 70; // posicion inicial en el eje x
    let rotacion; //rotacion del papel (angulo de impresion)
    let orientacion; // orientacion del papel valores: 'p' = o 'l' (landscape or portrait)
    let unidad; // unidad de medida cuando se especifican las coordenadas  "pt" (points), "mm" (Default), "cm", "in"
    let format; // se pueden enviar la medida o usar los siguientes formatos 'a3', 'a4' (Default),'a5' ,'letter' ,'legal'
    let tipoLetra; //Tipo de letra del documento
    let tamanioLetra; //Tamaño de letra que se utilizara en la mayoria del documento
    let espaciado; // cantidad para el salto de linea
    let tab1; // espacio a primera columna desde inicial x
    let tab2; //espacio a segunda columna desde inicial x
    let centrado; //valor de x para el centro
    let tamanio: number;
    const configImpresion =
      this.configuration.appConfig.value.DatosResult.AppConfigJsonImpresion;
    let tipo = 'Estrecho';
    if (configImpresion.ImpresionFormatoPapel === 'Estrecho') {
      tamanio =
        configImpresion.ImpresionTamanioPapel &&
        configImpresion.ImpresionTamanioPapel != null &&
        configImpresion.ImpresionTamanioPapel != undefined &&
        configImpresion.ImpresionTamanioPapel != '' &&
        +configImpresion.ImpresionTamanioPapel > 0
          ? +configImpresion.ImpresionTamanioPapel
          : 60;

      altura = 275;
      anchura = tamanio + 20;
      unidad = 'mm';
      orientacion = 'p';
      inicialy = 0;
      inicialx = 6;
      finalx = anchura - 6;
      variacion = 2.83;
      rotacion = 0;
      tamanioLetra = tamanio > 75 ? 10 : 8;
      espaciado = Math.trunc(tamanioLetra / 2);
      tab1 = Math.trunc(anchura / 5);
      tab2 = Math.trunc(anchura / 3);
      centrado = Math.trunc(anchura / 2);
      this.documentBody = new jspdf(orientacion, unidad, [
        anchura * variacion,
        altura * variacion,
      ]);
    } else if (configImpresion.ImpresionFormatoPapel === 'Din-A4') {
    } else {
      altura = 275;
      anchura = tamanio + 20;
      unidad = 'mm';
      orientacion = 'p';
      inicialy = 0;
      inicialx = 6;
      finalx = anchura - 6;
      variacion = 2.83;
      rotacion = 0;
      tamanioLetra = tamanio > 75 ? 10 : 8;
      espaciado = Math.trunc(tamanioLetra / 2);
      tab1 = Math.trunc(anchura / 5);
      tab2 = Math.trunc(anchura / 3);
      centrado = Math.trunc(anchura / 2);
      format = [anchura * variacion, altura * variacion];
      this.documentBody = new jspdf(orientacion, unidad, [
        anchura * variacion,
        altura * variacion,
      ]);
      //this.documentBody = new jspdf('p', 'mm', [80 * 2.83, 275 * 2.83]);
    }

    // switch (tipo)
    // {
    //   case 'ancho':
    //     altura = 275;
    //     anchura = 95;
    //     unidad = 'mm';
    //     orientacion = 'p';
    //     inicialy = 0; //5;
    //     inicialx = 6; // 97;
    //     finalx = 85;
    //     variacion = 2.83;
    //     rotacion = 0; // 90;
    //     tamanioLetra= 10;
    //     espaciado = 5; // 4;
    //     tab1 = 20;
    //     tab2 = 30;
    //     centrado= 44;
    //     break;
    //   case 'estrecho':
    //     altura = 275;
    //     anchura = 80;
    //     unidad = 'mm';
    //     orientacion = 'p';
    //     inicialy = 0;
    //     inicialx = 6;
    //     finalx = 70;
    //     variacion = 2.83;
    //     rotacion= 0;
    //     tamanioLetra= 8;
    //     espaciado = 4;
    //     tab1 = 15;
    //     tab2 = 25;
    //     centrado = 37;

    //     break;
    //     default:
    //       altura = 275;
    //       anchura = 80;
    //       unidad = 'mm';
    //       orientacion = 'p';
    //       inicialy = 0;
    //       inicialx = 6;
    //       finalx = 70;
    //       variacion = 2.83;
    //       rotacion= 0;
    //       tamanioLetra = 8;
    //       espaciado = 4;
    //       tab1 = 15;
    //       tab2 = 25;
    //       centrado = 37;
    //     break;
    // }

    // switch (tipo)
    // {
    //   case 'ancho':
    //     altura = 275;
    //     anchura = 95;
    //     unidad = 'mm';
    //     orientacion = 'p';
    //     inicialy = 0; //5;
    //     inicialx = 6; // 97;
    //     finalx = 85;
    //     variacion = 2.83;
    //     rotacion = 0; // 90;
    //     espaciado = 5; // 4;
    //     tab1 = 20;
    //     tab2 = 30;
    //     tamanioLetra= 10;
    //     centrado= 44;
    //     break;
    //   case 'estrecho':
    //     altura = 275;
    //     anchura = 80;
    //     unidad = 'mm';
    //     orientacion = 'p';
    //     inicialy = 0;
    //     inicialx = 6;
    //     finalx = 70;
    //     variacion = 2.83;
    //     rotacion= 0;
    //     espaciado = 4;
    //     tamanioLetra= 8;
    //     tab1 = 15;
    //     tab2 = 25;
    //     centrado = 37;

    //     break;
    //     default:
    //       altura = 275;
    //       anchura = 80;
    //       unidad = 'mm';
    //       orientacion = 'p';
    //       inicialy = 0;
    //       inicialx = 6;
    //       finalx = 70;
    //       variacion = 2.83;
    //       rotacion= 0;
    //       espaciado = 4;
    //       tamanioLetra = 8;
    //       tab1 = 15;
    //       tab2 = 25;
    //       centrado = 37;
    //     break;
    // }

    this.y = inicialy;
    this.x = inicialx;
    this.finalx = finalx;
    this.tamanioLetra = tamanioLetra;
    this.espaciado = espaciado;
    this.centrado = centrado;
    this.tab1 = tab1;
    this.tab2 = tab2;
    // const width = this.documentBody.internal.pageSize.getWidth();
    // const height = this.documentBody.internal.pageSize.getHeight();

    // const options = {opt:{
    //   orientation: orientacion,
    //   unit: unidad,
    //   format: [anchura * variacion, altura * variacion]
    // }, inicialx : inicialx, inicialy : inicialy, finalx:finalx, rotacion: rotacion, empresa: empresa, espaciado: espaciado, tamanioLetra:tamanioLetra, tab1: tab1, tab2:tab2 };

    // return options;
  }

  // constructor(private translate: TranslateService, private config: AppConfiguration) {
  //   this.documentBody = new jspdf('p', 'mm', [80 * 2.83, 275 * 2.83]);
  //   this.y = 0;
  //   const width = this.documentBody.internal.pageSize.getWidth();
  //   const height = this.documentBody.internal.pageSize.getHeight();

  // }
  //   addClientHeader(cabecera?) {

  //   if (cabecera && cabecera.IncluirEncabezadoTicket === 'True') {
  //     this.documentBody.setFontSize(8);
  //     if (cabecera.chLinea1 !== '') {
  //       this.documentBody.setFontSize(12);
  //       this.documentBody.setFontStyle('bold');
  //       this.documentBody.text(
  //         cabecera.chLinea1,
  //         37,
  //         (this.y = this.y + 4),
  //         'center'
  //       );
  //       this.documentBody.text(
  //         //'Factura de venta directa',
  //         this.translate.instant('JUSTIFICANTE_FACTURA_VENTA_DIRECTA'),
  //         37,
  //         (this.y += 4),
  //         'center'
  //       );
  //       this.documentBody.setFontSize(8);
  //       this.documentBody.setFontStyle('normal');
  //       this.documentBody.line(6, (this.y += 4), 70, this.y);
  //     }
  //     if (cabecera.chLinea2 !== '') {
  //       this.documentBody.text(
  //         cabecera.chLinea2,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chLinea3 !== '') {
  //       this.documentBody.text(
  //         cabecera.chLinea3,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chLinea4 !== '') {
  //       this.documentBody.text(
  //         cabecera.chLinea4,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chLinea5 !== '') {
  //       this.documentBody.text(
  //         cabecera.chLinea5,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chLinea6 !== '') {
  //       this.documentBody.text(
  //         cabecera.chLinea6,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     return this;
  //   } else {
  //     this.documentBody.setFontSize(8);
  //     return this;
  //   }
  // }

  // addSellingPointName(TPV, name) {
  //   if(TPV && name && TPV.length > 0 && name.length > 0){
  //   this.documentBody
  //     // .text('TPV', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_TPV'), 6, (this.y += 4), 'left')
  //     .text(TPV, 40, this.y, 'right')
  //     .text(name, 70, this.y, 'right');
  //   }
  //   return this;
  // }

  // addRefundingSellingPointName(TPV,name) {
  //   if(TPV && name && TPV.length > 0 && name.length > 0){
  //   this.documentBody
  //     // .text('TPV DE ORIGEN', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_TPV_ORIGEN'), 6, (this.y += 4), 'left')
  //     .text(TPV, 40, this.y, 'right')
  //     .text(name, 70, this.y, 'right');
  //   }
  //   return this;
  // }

  // addAlbaranNumber(albaranNumber) {
  //   if(albaranNumber && albaranNumber.length > 0){
  //   this.documentBody
  // //      .text('Nº ALBARAN', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_No_ALBARAN'), 6, (this.y += 4), 'left')
  //     .text(albaranNumber, 70, this.y, 'right');
  //   }
  //   return this;
  // }

  // addOriginAlbaranNumber(albaranNumber) {
  //   if(albaranNumber && albaranNumber.length > 0){
  //   this.documentBody
  //     // .text('Nº ALBARAN DE ORIGEN', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_No_ALBARAN_ORIGEN'), 6, (this.y += 4), 'left')
  //     .text(albaranNumber, 70, this.y, 'right');
  //   }
  //   return this;
  // }

  // addLocator(locator) {
  //   if(locator && locator.length > 0){
  //   this.documentBody
  //     // .text('IDENTIFICADOR', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_IDENTIFICADOR'), 6, (this.y += 4), 'left')
  //     .text(locator, 70, this.y, 'right');
  //   }
  //   return this;
  // }

  // addOriginLocator(locator) {
  //   if(locator && locator.length > 0){
  //   this.documentBody
  //     // .text('IDENTIFICADOR ORIGEN', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_IDENTIFICADOR_ORIGEN'), 6, (this.y += 4), 'left')
  //     .text(locator, 70, this.y, 'right');
  //   }
  //   return this;
  // }

  // addOnlineSourceLocator(locator) {
  //   if (locator && locator.length > 0) {
  //     this.documentBody
  //       // .text('Nº LOCALIZADOR INTERNET', 6, (this.y += 4), 'left')
  //       .text(this.translate.instant('JUSTIFICANTE_No_LOCALIZADOR_INTERNET'), 6, (this.y += 4), 'left')
  //       .text(locator, 70, this.y, 'right');
  //   }
  //   return this;
  // }

  // addDateOfSale(day, hour) {
  //   this.documentBody
  //     // .text('FECHA DE VISITA', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_FECHA_VISITA'), 6, (this.y += 4), 'left')
  //     .text(this.formatFecha(day), 55, this.y, 'right')
  //     .text(this.formatHora(hour), 70, this.y, 'right');
  //   return this;
  // }

  // addDateOfOperation() {
  //   const fecha = moment();
  //   const dia = fecha.format('DD/MM/YYYY');
  //   const hora = fecha.format('HH:mm');
  //   this.documentBody
  //     // .text('FECHA DE OPERACIÓN', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_FECHA_OPERACION'), 6, (this.y += 4), 'left')
  //     .text(this.formatFecha(dia), 55, this.y, 'right')
  //     .text(this.formatHora(hora), 70, this.y, 'right');
  //   return this;
  // }

  // addOnlineLocator() {
  //   return this;
  // }

  // addDateOfVisit(day, hour) {
  //   this.documentBody
  //     // .text('FECHA DE VENTA', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_FECHA_VENTA'), 6, (this.y += 4), 'left')
  //     .text(this.formatFecha(day), 55, this.y, 'right')
  //     .text(this.formatHora(hour), 70, this.y, 'right');
  //   return this;
  // }

  // // Yaribel 20210112  Modificamos la impresion de item para añadir la nueva funcion que controla la logitud de la descripcion
  // addItems(items: any[]) {
  //   this.documentBody
  //     // .text('UDS.', 6, (this.y += 10), 'left')
  //     // .text('DESCRIPCION', 15, this.y, 'left')
  //     // .text('IMPORTE', 70, this.y, 'right');
  //     .text(this.translate.instant('JUSTIFICANTE_UDS'), 6, (this.y += 10), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_DESCRIPCION'), 25, this.y, 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_IMPORTE'), 70, this.y, 'right');
  //     //this.documentBody.setFontSize(6);
  //   items.forEach(item => {
  //     let ArrayItemImpresion: string[] = [];
  //     ArrayItemImpresion = this.getArrayNombreImpresion(item.NombreImpresion, ArrayItemImpresion);
  //     // this.documentBody
  //     //   .text(item.Cantidad, 11, (this.y += 5), 'right')
  //     //   .text(item.NombreImpresion, 15, this.y, 'left')
  //     //   .text(
  //     //     `${this.addDotsToNumber(item.PrecioTotal
  //     //     )} €`,
  //     //     70,
  //     //     this.y,
  //     //     'right'
  //     //   );

  //     this.documentBody
  //     .setFontSize(8)
  //     .text(item.Cantidad, 11, (this.y += 5), 'right')
  //     .setFontSize(6)
  //     .text(ArrayItemImpresion[0], 15, this.y, 'left')
  //     .setFontSize(8)
  //     .text(
  //       `${this.addDotsToNumber( //item.PrecioTotal
  //         (Number.parseFloat(item.PrecioTotal.replace(',','.'))).toFixed(2).replace('.',',')
  //       )} €`,
  //       70,
  //       this.y,
  //       'right'
  //     );
  //     if(ArrayItemImpresion.length > 1)
  //       {
  //         for(let i = 1; i < ArrayItemImpresion.length; i++)
  //         {
  //           this.documentBody
  //           .setFontSize(6)
  //           .text(ArrayItemImpresion[i], 15, this.y +=3, 'left');

  //         }
  //       }
  //     if (this.obtenerCodigoTarjetaMonedero(item).length > 0 ){
  //       this.documentBody
  //          .setFontSize(6)
  //           .text(item.TarjetaMonederoCodigo, 15, this.y +=3, 'left');

  //     }

  //     // .text(
  //     //   `${Number.parseFloat(item.PrecioBase.replace(',', '.')).toFixed(
  //     //     2
  //     //   )} €`,
  //     //   70,
  //     //   this.y,
  //     //   'right'
  //     // );
  //   });
  //   this.documentBody.setFontSize(8);
  //   return this;
  // }

  // addItemsOnline(items: any[]) {
  //   this.documentBody.text(
  //     // 'PRODUCTOS INTERNET',
  //     this.translate.instant('JUSTIFICANTE_PRODUCTOS_INTERNET'),
  //     37,
  //     (this.y = this.y + 4),
  //     'left'
  //   );
  //   this.documentBody.line(6, (this.y += 4), 70, this.y);
  //   items.forEach(item => {
  //     let ArrayItemImpresion: string[] = [];
  //     ArrayItemImpresion = this.getArrayNombreImpresion(item.NombreImpresion, ArrayItemImpresion);
  //     this.documentBody
  //     .setFontSize(8)
  //     .text(item.Cantidad, 11, (this.y += 5), 'right')
  //     .setFontSize(6)
  //     .text(ArrayItemImpresion[0], 15, this.y, 'left')
  //     .setFontSize(8)
  //     .text(
  //       `${this.addDotsToNumber(//item.PrecioTotal
  //         (Number.parseFloat(item.PrecioTotal.replace(',','.'))).toFixed(2).replace('.',',')
  //       )} €`,
  //       70,
  //       this.y,
  //       'right'
  //     );
  //     if(ArrayItemImpresion.length > 1)
  //       {
  //         for(let i = 1; i < ArrayItemImpresion.length; i++)
  //         {
  //           this.documentBody
  //           .setFontSize(6)
  //           .text(ArrayItemImpresion[i], 15, this.y +=3, 'left');

  //         }
  //       }
  //     if (this.obtenerCodigoTarjetaMonedero(item).length > 0 ){
  //       this.documentBody
  //           .setFontSize(6)
  //           .text(item.TarjetaMonederoCodigo, 15, this.y +=3, 'left');

  //     }
  //     // this.documentBody
  //     //   .text(item.Cantidad, 11, (this.y += 5), 'right')
  //     //   .text(item.NombreImpresion, 15, this.y, 'left')
  //     //   .text(
  //     //     `${this.addDotsToNumber(item.PrecioTotal)} €`,
  //     //     70,
  //     //     this.y,
  //     //     'right'
  //     //   );
  //   });
  //   return this;
  // }
  // addRefundedItems(items: any[]) {
  //   this.documentBody
  //     // .text('UDS.', 6, (this.y += 10), 'left')
  //     // .text('DESCRIPCION', 15, this.y, 'left')
  //     // .text('IMPORTE', 70, this.y, 'right');
  //     .text(this.translate.instant('JUSTIFICANTE_UDS'), 6, (this.y += 10), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_DESCRIPCION'), 25, this.y, 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_IMPORTE'), 70, this.y, 'right');

  //   items.forEach(item => {
  //     let ArrayItemImpresion: string[] = [];
  //     ArrayItemImpresion = this.getArrayNombreImpresion(item.NombreImpresion, ArrayItemImpresion);
  //     this.documentBody
  //     .setFontSize(8)
  //     .text(item.Cantidad, 11, (this.y += 5), 'right')
  //     .setFontSize(6)
  //     .text(ArrayItemImpresion[0], 15, this.y, 'left')
  //     .setFontSize(8)
  //     .text(
  //       `${this.addDotsToNumber(//item.PrecioTotal
  //         (Number.parseFloat(item.PrecioTotal.replace(',','.'))).toFixed(2).replace('.',',')
  //       )} €`,
  //       70,
  //       this.y,
  //       'right'
  //     );
  //     if(ArrayItemImpresion.length > 1)
  //       {
  //         for(let i = 1; i < ArrayItemImpresion.length; i++)
  //         {
  //           this.documentBody
  //           .setFontSize(6)
  //           .text(ArrayItemImpresion[i], 15, this.y +=3, 'left');

  //         }
  //       }
  //     if (this.obtenerCodigoTarjetaMonedero(item).length > 0 ){
  //       this.documentBody
  //           .setFontSize(6)
  //           .text(item.TarjetaMonederoCodigo, 15, this.y +=3, 'left');

  //     }

  //     // this.documentBody
  //     //   .text(item.Cantidad, 11, (this.y += 5), 'right')
  //     //   .text(item.NombreImpresion, 15, this.y, 'left')
  //     //   .text(
  //     //     `${this.addDotsToNumber(item.PrecioTotal)} €`,
  //     //     70,
  //     //     this.y,
  //     //     'right'
  //     //   );
  //   });
  //   return this;
  // }

  // // Yaribel 20210112  >>

  // addOperation(type: AlbaranOperationType) {
  //   if (type === AlbaranOperationType.VENTA) {
  //     this.documentBody.text(this.translate.instant('JUSTIFICANTE_OPERACION_VENTA'), 38, (this.y += 4), 'center');
  //   }
  //   if (type === AlbaranOperationType.DEVOLUCION) {
  //     this.documentBody.text(this.translate.instant('JUSTIFICANTE.OPERACION.DEVOLUCION'), 38, (this.y += 4), 'center');
  //   }
  //   if (type === AlbaranOperationType.ANULACION) {
  //     this.documentBody.text(this.translate.instant('JUSTIFICANTE.OPERACION.ANULACION'), 38, (this.y += 4), 'center');
  //   }
  //   // if (type === AlbaranOperationType.VENTA) {
  //   //   this.documentBody.text('JUSTIFICANTE DE VENTA', 38, (this.y += 4), 'center');
  //   // }
  //   // if (type === AlbaranOperationType.DEVOLUCION) {
  //   //   this.documentBody.text('JUSTIFICANTE DE DEVOLUCION', 38, (this.y += 4), 'center');
  //   // }
  //   // if (type === AlbaranOperationType.ANULACION) {
  //   //   this.documentBody.text('JUSTIFICANTE DE ANULACION', 38, (this.y += 4), 'center');
  //   // }
  //   return this;
  // }

  // addLine() {
  //   this.documentBody.line(6, (this.y += 4), 70, this.y);
  //   return this;
  // }

  // // Yaribel 18112020 Añadimos el desglose del IVa a el contenido del Justificante de Compra
  // addDesgloseIVA(items: any[]){
  // let agruparIVA: IvaDisct[] = [];
  // let totalBase = 0;
  // let totalIva = 0;
  //   this.documentBody
  //   // .text('% IVA', 6, (this.y += 4), 'left')
  //   // .text('BASE IMPONIBLE', 20, (this.y), 'left')
  //   // .text('IMPORTE IVA', 70, this.y, 'right');
  //   .text(this.translate.instant('JUSTIFICANTE_PORCT_IVA'), 6, (this.y += 4), 'left')
  //   .text(this.translate.instant('JUSTIFICANTE_BASE_IMPONIBLE'), 20, (this.y), 'left')
  //   .text(this.translate.instant('JUSTIFICANTE_IMPORTE_IVA'), 70, this.y, 'right');
  //   items.forEach(item => {

  //     if(item.TipoProducto === '2')
  //     {
  //       item.PromocionEntradas.forEach(entrada => {

  //         if(!agruparIVA.find(x => x.pIva == entrada.IVA)){
  //           const agrupado = { pIva: entrada.IVA , datos: {base: 0, pvp: 0, iva: 0 }}
  //           agruparIVA.push(agrupado);
  //         }
  //         const agrupado = agruparIVA.find(x => x.pIva == entrada.IVA);
  //         const pvp = Number.parseFloat(entrada.PrecioTotal.replace(',', '.'));
  //         const base = Number.parseFloat((Number.parseFloat(entrada.PrecioBase.replace(',', '.')) * Number.parseFloat(entrada.NumeroEntradas.replace(',', '.'))).toFixed(2).replace(',', '.'));
  //         const iva = pvp - base;
  //         agruparIVA[agruparIVA.indexOf(agrupado)].datos.base  +=  base;
  //         agruparIVA[agruparIVA.indexOf(agrupado)].datos.pvp  += pvp;
  //         agruparIVA[agruparIVA.indexOf(agrupado)].datos.iva += pvp - base;
  //         totalBase += base;
  //         totalIva += iva;

  //       });

  //     } else if (item.TipoProducto === '1')
  //     {
  //       if(!agruparIVA.find(x => x.pIva == item.IVA)){
  //         const agrupado = { pIva: item.IVA , datos: {base: 0, pvp: 0, iva: 0 }}
  //         agruparIVA.push(agrupado);
  //       }
  //       const agrupado = agruparIVA.find(x => x.pIva == item.IVA);
  //       const pvp = Number.parseFloat(item.PrecioTotal.replace(',', '.'));
  //       const base = Number.parseFloat((Number.parseFloat(item.PrecioBase.replace(',', '.')) * Number.parseFloat(item.Cantidad.replace(',', '.'))).toFixed(2).replace(',', '.'));  // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
  //       const iva = pvp - base;
  //       agruparIVA[agruparIVA.indexOf(agrupado)].datos.base  +=  base;
  //       agruparIVA[agruparIVA.indexOf(agrupado)].datos.pvp  += pvp;
  //       agruparIVA[agruparIVA.indexOf(agrupado)].datos.iva += pvp - base;
  //       totalBase += base;
  //       totalIva += iva;
  //     }
  // });

  // agruparIVA.sort((a,b) => a.pIva.localeCompare(b.pIva));
  // agruparIVA.forEach(item => {
  //   this.documentBody
  //   .text(item.pIva, 11, (this.y += 5), 'right')
  //   .text(`${this.addDotsToNumber(item.datos.base.toFixed(2).replace('.', ','))} €`, 35, this.y, 'right')
  //   .text(`${this.addDotsToNumber(item.datos.iva.toFixed(2).replace('.', ','))} €`, 70, this.y, 'right');
  // });

  // this.documentBody
  //   // .text('Total IVA', 15, (this.y += 5), 'left')
  //   .text(this.translate.instant('JUSTIFICANTE_TOTAL_IVA'), 15, (this.y += 5), 'left')
  //   .text(`${this.addDotsToNumber(totalIva.toFixed(2).replace('.', ','))} €`, 70, this.y, 'right'); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
  //   this.documentBody
  //   // .text('Total base imponible', 15, (this.y += 5), 'left')
  //   .text(this.translate.instant('JUSTIFICANTE_TOTAL_BASE_IMPONIBLE'), 15, (this.y += 5), 'left')
  //   .text(`${this.addDotsToNumber(totalBase.toFixed(2).replace('.', ','))} €`, 70, this.y, 'right'); // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
  //   return this;
  // }

  // addTotal(total) {
  //   this.documentBody.setFontStyle('bold');
  //   this.documentBody.setFontSize(9);
  //   this.documentBody
  //     // .text('T O T A L', 6, (this.y += 5), 'left')
  //     .text(this.translate.instant('JUSTIFICANTE_T_O_T_A_L'), 6, (this.y += 5), 'left')
  //     .text(`${this.addDotsToNumber(
  //       //total
  //       (Number.parseFloat(total.replace(',','.'))).toFixed(2).replace('.',',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
  //       )} €`, 70, this.y, 'right');
  //   this.documentBody.line(6, (this.y += 2), 70, this.y);
  //   this.documentBody.setFontSize(8);
  //   this.documentBody.setFontStyle('normal');
  //   return this;
  // }

  //   // @TODO deberian pasarse en forma de lista y generarse las lineas por cada forma de pago
  //   // this.documentBody.text('MÉTODO DE PAGO', 6, (this.y += 5), 'left');
  //   this.documentBody.text(this.translate.instant('JUSTIFICANTE_METODO_DE_PAGO'), 38, (this.y += 5), 'center');
  //   //this.documentBody.line(6, (this.y += 1), 31, this.y);
  //   paymentMethod.forEach(element => {
  //     // tslint:disable-next-line:max-line-length

  //     // << Yaribel 20210114  añadimos codigo de tarjeta a la forma de pago monedero
  //     const nombreFOP = this.generarFOP(element.FOP) + this.obtenerCodigoTarjetaMonedero(element);
  //     this.documentBody
  //       // .text(`${this.generarFOP(element.FOP) + this.obtenerCodigoTarjetaMonedero(element)}:`, 6, (this.y += 4), 'left')
  //       .text(`${nombreFOP}:`, 6, (this.y += 4), {
  //         maxWidth: 60,
  //         align: 'left'
  //       })
  //       .text(`${this.addDotsToNumber(
  //         //element.Importe
  //         (Number.parseFloat(element.Importe.replace(',','.'))).toFixed(2).replace('.',',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
  //                   )} €`, 70, this.y, 'right');
  //     if(nombreFOP.length > 30)
  //     this.documentBody.text('',70,(this.y += 4), 'right')
  //   });
  // // Yaribel 20210114 >>
  //   return this;
  // }
  // addPaymentOnline(received) {
  //   // @TODO deberian pasarse en forma de lista y generarse las lineas por cada forma de pago
  //   this.documentBody
  //     .text(`${'ONLINE'}:`, 6, (this.y += 4), 'left')
  //     .text(`${this.addDotsToNumber(//received
  //       (Number.parseFloat(received.replace(',','.'))).toFixed(2).replace('.',',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
  //       )} €`, 70, this.y, 'right');
  //   return this;
  // }
  // addChange(change) {
  //   this.documentBody
  //     // .text('CAMBIO:', 6, (this.y += 4), 'left')
  //     .text(this.translate.instant('CAMBIO'), 6, (this.y += 4), 'left')
  //     .text(`${this.addDotsToNumber(//change
  //       (Number.parseFloat(change.replace(',','.'))).toFixed(2).replace('.',',') // Yaribel 20210115 controlamos que los importes tengan 2 decimales y que el simbolo decimal sea la coma
  //       )} €`, 70, this.y, 'right');
  //   return this;
  // }

  // addFooterMessage(cabecera?) {

  //   if(cabecera) {
  //     if (cabecera.chPie1 !== '') {
  //       this.documentBody.text(
  //         cabecera.chPie1,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }

  //     if (cabecera.chPie2 !== '') {
  //       this.documentBody.text(
  //         cabecera.chPie2,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chPie3 !== '') {
  //       this.documentBody.text(
  //         cabecera.chPie3,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chPie4 !== '') {
  //       this.documentBody.text(
  //         cabecera.chPie4,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chPie5 !== '') {
  //       this.documentBody.text(
  //         cabecera.chPie5,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //     if (cabecera.chPie6 !== '') {
  //       this.documentBody.text(
  //         cabecera.chPie6,
  //         6,
  //         (this.y = this.y + 4),
  //         'left'
  //       );
  //     }
  //   }else{
  //   this.documentBody.text(
  //     // 'Gracias por su visita',
  //     this.translate.instant('JUSTIFICANTE_GRACIAS_POR_SU_VISITA'),
  //     37,
  //     (this.y += 3),
  //     'center'
  //   );
  //   }

  //   return this;
  // }

  // build(): any {
  //   return this.documentBody;
  // }

  // private generarFOP(fop) {
  //   switch (fop) {
  //     // case 'CA':
  //     //   return 'EFECTIVO';
  //     // case 'CC':
  //     //   return 'TARJETA';
  //     // case 'TR':
  //     //   return 'TRANSFERENCIA';
  //     // case 'TM':
  //     //   return 'TARJETA MONEDERO';  //Yaribel 11112020 añadimos forma de pago Tarjeta Monedero
  //     // case 'DP':
  //     //   return 'APLAZADO';  //Yaribel 20201209 añadimo forma de pago DP
  //     // case 'PR':
  //     //   return 'PREPAGO'; //Yaribel 20201217 añadimos forma de pago Prepago
  //     // default:
  //     //   return 'EFECTIVO';
  //     case 'CA':
  //       return this.translate.instant('EFECTIVO');
  //     case 'CC':
  //       return this.translate.instant('TARJETA');
  //     case 'TR':
  //       return this.translate.instant('TRANSFERENCIA');
  //     case 'TM':
  //       return this.translate.instant('JUSTIFICANTE_TARJETA_MONEDERO');
  //     case 'DP':
  //       return this.translate.instant('APLAZADO');
  //     case 'PR':
  //       return this.translate.instant('PREPAGO');
  //     default:
  //       return this.translate.instant('EFECTIVO');
  //   }
  // }

  // addDotsToNumber(number, decimalLength = 2) {
  //   number = '' + number;
  //   number.replace('.', ',');
  //   let negativo = false;
  //   if(number.substring(0, 1) === '-')
  //   {
  //     negativo =  true;
  //     number = number.substring(1);
  //   }
  //   if (number.includes(',')) {
  //     // tslint:disable-next-line:prefer-const
  //     let [integer, decimal] = number.split(',');
  //     if (integer.length > 3) {
  //       const integerArray = integer.split('');
  //       let newInteger = '';
  //       integerArray.forEach((num, index) => {
  //         if ((integerArray.length - index) % 3 === 0) {
  //             newInteger += '.';
  //         }
  //         newInteger += num;
  //       });
  //       while (decimal.length < decimalLength) {
  //         decimal += '0';
  //       }
  //       newInteger += ',' + decimal;
  //       newInteger = negativo? '-' + newInteger : newInteger;
  //       return newInteger;
  //     } else {
  //       let newInteger = integer;

  //       while (decimal.length < decimalLength) {
  //         decimal += '0';
  //       }
  //       newInteger += ',' + decimal;
  //       newInteger = negativo? '-' + newInteger : newInteger;
  //       return newInteger;
  //     }
  //   } else if (number.length > 3) {
  //     const integerArray = number.split('');
  //     let newInteger = '';
  //     integerArray.forEach((num, index) => {
  //       if ((integerArray.length - index) % 3 === 0) {
  //           newInteger += '.';
  //         }
  //       newInteger += num;
  //     });
  //     let decimal = ',';
  //     while (decimal.length <= decimalLength) {
  //     }
  //     newInteger += decimal;
  //     newInteger = negativo? '-' + newInteger : newInteger;
  //     return newInteger;
  //   }
  //   let d = ',';
  //   for (let i = 0; i < decimalLength; ++i) {
  //     d += '0';
  //   }
  //   number = negativo? '-' + number : number;
  //   return number + d;
  // }

  // formatFecha(fecha) {
  //   const splitFecha = fecha.split('/');
  //   if (splitFecha[0].length === 4 && splitFecha.length === 3) {
  //     return splitFecha[2] + '/' + splitFecha[1] + '/' + splitFecha[0];
  //   }
  //   return fecha;
  // }

  // formatHora(hora) {
  //   const splitHora = hora.split(':');
  //   if (splitHora.length === 3) {
  //     return splitHora[0] + ':' + splitHora[1] + ' h';
  //   }
  //   return hora + ' h';
  // }

  // // << Yaribel 20210114 Creamos funcion para controlar la longitud de la descripcion y hacer salto de linea
  // getArrayNombreImpresion(NombreImpresion: string, result: string[])
  // {
  //   let limite = 27;
  //   let variable = 4;
  //   if(NombreImpresion.length > limite && NombreImpresion.length > (limite + variable))
  //   {
  //     const miRexp = /\s[a-zA-Z]|-|\+|\//;

  //     let separador;
  //   while(!separador && (limite + variable) < NombreImpresion.length)
  //   {
  //     separador = miRexp.exec(NombreImpresion.substring(limite - variable, limite + variable));
  //     if(separador && separador.length > 0)
  //     {
  //       limite = limite - variable + separador.index + 1
  //     }
  //     variable++;
  //   }
  //   const primerNombreImpresion: string = NombreImpresion.substring(0,limite);
  //   result.push(primerNombreImpresion);
  //   NombreImpresion = NombreImpresion.substring(limite);
  //   result = this.getArrayNombreImpresion(NombreImpresion, result);
  // } else{
  //   result.push(NombreImpresion);
  // }
  //   return result;
  // }

  // obtenerCodigoTarjetaMonedero(item:any){
  //   return (item.TarjetaMonederoCodigo && item.TarjetaMonederoCodigo.length > 0)? ' ' + item.TarjetaMonederoCodigo: '';

  // }

  // // Yaribel 20210114 >>
}
