import { Printer } from '../printer.interface';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import { PrintableType } from '../printableType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { ConnectionType } from '../../connection/connectionType.enum';
import { RandomArchingReportOptions } from '../printOptions';

export class RandomArchingReport implements Printer {
  private window = window as any;

  private documentBody;
  type: PrintableType;
  private DATE_FORMAT = 'DD/MM/YYYY HH:mm';
  constructor() {
    this.type = PrintableType.RANDOM_ARCHING_REPORT;
    this.documentBody = new jspdf('p', 'mm', 'a4');
  }

  paint(options: RandomArchingReportOptions): Printer {
    this.documentBody.autoTable({
      theme: 'plain',
      tableHeight: 20,
      headStyles: { textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table0'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table1'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      tableWidth: 80,
      columnStyles: { 0: { fontStyle: 'bold' }, textColor: [0, 0, 0] },
      margin: { right: 100 },
      html: '#my-table2'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: 30,
      tableWidth: 102,
      columnStyles: {
        0: { cellWidth: 62 },
        1: { cellWidth: 40 }
      },
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        halign: 'center'
      },
      margin: { left: 94 },
      html: '#my-table3'
    });
    /*this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table4',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table5',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' },
      },
      html: '#my-table6',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table7',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table8',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table9',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' },
      },
      html: '#my-table10',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table11',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table12',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table13',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' },
      },
      html: '#my-table14',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table15',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table16',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table17',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' },
      },
      html: '#my-table18',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table19',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table20',
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' },
      },
      html: '#my-table21',
    });*/
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table22'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fillColor: [240, 240, 240] },
      columnStyles: {
        0: { fontStyle: 'bold', textColor: [0, 0, 0] },
        1: { cellWidth: 20, halign: 'right' }
      },
      html: '#my-table23'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table24'
    });
    this.documentBody.autoTable({
      theme: 'plain',
      tableHeight: 60,
      columnStyles: {
        0: { fontStyle: 'bold', halign: 'center' },
        1: { fontStyle: 'bold', halign: 'center' }
      },
      html: '#my-table25'
    });

    const pageCount = this.documentBody.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      this.documentBody.setPage(i);
      this.documentBody.setFontSize(10);
      this.documentBody.text(10, 290, moment().format(this.DATE_FORMAT));
      this.documentBody.text(
        190,
        290,
        this.documentBody.internal.getCurrentPageInfo().pageNumber +
          '/' +
          pageCount
      );
    }
    return this;
  }

  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    // connection.print(this.documentBody, this.type);
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(this.documentBody),
        'documento'
      );
    } else {
      this.documentBody.save('parcial.pdf');
    }
  }

  private generatePDFBinario(pdf) {
    return this.arrayBufferToBase64(pdf.output('arraybuffer'));
  }

  private arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
