import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditorioComponent } from './auditorio.component';
import { EspectaculosComponent } from './components/espectaculos/espectaculos.component';
import { ListaEspectaculosComponent } from './components/espectaculos/lista-espectaculos/lista-espectaculos.component';
import { StepperAuditorioComponent } from './shared/stepper-auditorio/stepper-auditorio.component';
import { ButtonSiguienteComponent } from './shared/button-siguiente/button-siguiente.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
// import { DateFormatPipe } from './date.pipe';
import { EstadoPipe } from './estado.pipe';
import { MapaRecintoComponent } from './components/seleccion-butacas/mapa-recinto/mapa-recinto.component';
import { SeleccionButacasComponent } from './components/seleccion-butacas/seleccion-butacas.component';
import { ResumenCompraComponent } from '../venta/components/resumen-compra/resumen-compra.component';
import { VentaModule } from '../venta/venta.module';
import { ControlesComponent } from './components/seleccion-butacas/mapa/controles/controles.component';
import { MapaComponent } from './components/seleccion-butacas/mapa/mapa/mapa.component';
import { MinimapaComponent } from './components/seleccion-butacas/mapa/minimapa/minimapa.component';
import { SelectorTarifasComponent } from './components/seleccion-butacas/selector-tarifas/selector-tarifas.component';
import { SeleccionSesionesComponent } from './components/seleccion-sesiones/seleccion-sesiones.component';
import { AccordionSesionesComponent } from './components/seleccion-sesiones/accordion-sesiones/accordion-sesiones.component';
import { PanelSesionesComponent } from './components/seleccion-sesiones/panel-sesiones/panel-sesiones.component';
import { CellSesionesComponent } from './components/seleccion-sesiones/cell-sesiones/cell-sesiones.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AuditorioComponent,
    EspectaculosComponent,
    ListaEspectaculosComponent,
    MapaRecintoComponent,
    SeleccionButacasComponent,
    StepperAuditorioComponent,
    ButtonSiguienteComponent,
    // DateFormatPipe,
    EstadoPipe,
    ControlesComponent,
    MapaComponent,
    MinimapaComponent,
    SelectorTarifasComponent,
    SeleccionSesionesComponent,
    AccordionSesionesComponent,
    PanelSesionesComponent,
    CellSesionesComponent
  ],
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    VentaModule,
    FormsModule
  ]
})
export class AuditorioModule {}
