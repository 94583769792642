import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizadorComponent } from './localizador.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { DetalleLocalizadorComponent } from './components/detalle-localizador/detalle-localizador.component';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MotivosLocalizadorComponent } from './components/motivos-localizador/motivos-localizador.component';

@NgModule({
  declarations: [LocalizadorComponent, DetalleLocalizadorComponent, MotivosLocalizadorComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PerfectScrollbarModule
  ]
})
export class LocalizadorModule { }
