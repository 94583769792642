import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ConfirmationService } from '../../services/confirmation.service';

@Component({
  selector: 'ticketing-confirmar-reimpresion-dia-distinto',
  templateUrl: './confirmar-reimpresion-dia-distinto.component.html',
  styleUrls: ['./confirmar-reimpresion-dia-distinto.component.scss']
})
export class ConfirmarReimpresionDiaDistintoComponent implements OnInit {
  @Input() id: string;
  private element: any;
  constructor(
    private modalService: ModalService,
    private confirmationService: ConfirmationService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  cancelar() {
    this.confirmationService.setConfirmado(false);
    this.close();
  }
  confirmarReimpresionDiaDistinto() {
    this.confirmationService.setConfirmado(true);
    this.close();
  }
}
