import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd
} from '@angular/router';
import { VentaService, StepperNavigationService } from 'src/app/modules/venta';

@Injectable({ providedIn: 'root' })
export class PagoGuard implements CanActivate {
  constructor(
    private router: Router,
    private venta: VentaService,
    private stepper: StepperNavigationService
  ) {}

  currentUrl;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pasoSeleccionado = this.stepper.pasos.find(paso => paso.url === state.url.split('/')[2]);
    const pasoActual = this.stepper.pasos.find(paso => paso.url === this.router.url.split('/')[2]);

    const pasoSeleccionadoIndex = this.stepper.pasos.indexOf(pasoActual);
    const pasoActualIndex = this.stepper.pasos.indexOf(pasoSeleccionado);

    if (!this.venta.carritoModificado) {
      return true;
    }
    if (pasoSeleccionadoIndex > pasoActualIndex) {
      return true;
    }
    if (!this.router.getCurrentNavigation().extras.state && this.venta.carritoModificado) {
      return false;
    }
    if (this.venta.carritoValue.entradas.length === 0) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
