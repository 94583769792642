import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { PrintService } from '../../services/print.service';
import { VentaService } from 'src/app/modules/venta';
import { TurnoService } from 'src/app/core';
import { LocalizadorService } from 'src/app/modules/localizador/services/localizador.service';
import { Reserva } from 'src/app/modules/localizador/models/reserva.model';

@Component({
  selector: 'ticketing-confirmar-entradas-localizador',
  templateUrl: './confirmar-entradas-localizador.component.html',
  styleUrls: ['./confirmar-entradas-localizador.component.scss']
})
export class ConfirmarEntradasLocalizadorComponent implements OnInit {
  reserva;
  @Input() id: string;
  private element: any;
  constructor(
    private modalService: ModalService,
    private ventaService: VentaService,
    private turnoService: TurnoService,
    private el: ElementRef,
    private printService: PrintService,
    private localizadorService: LocalizadorService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  imprimirTicketsLocalizador() {
    this.localizadorService
      .getDatosAdicionalesReserva(this.localizadorService.localizadorValue.ClaveReserva)
      .subscribe((reserva: Reserva) => {
        this.localizadorService.setLocalizadorValue(reserva);
        this.reserva = reserva;
        this.printService.printOnlineTickets(
          this.turnoService.turnoValue.NombreTPV,
          this.localizadorService.localizadorValue.ClaveReserva,
          `${this.localizadorService.localizadorValue.FechaHoraEntrada}`,
          this.reserva.ReservaItems,
          this.localizadorService.localizadorValue
        );
        this.localizadorService.setLocalizadorValue(undefined);
        this.localizadorService.entradasHolder = undefined;
        this.close();
      });

    // this.printService.printOnlineTickets(
    //   this.turnoService.turnoValue.NombreTPV,
    //   this.localizadorService.localizadorValue.ClaveReserva,
    //   `${this.localizadorService.localizadorValue.FechaHoraEntrada}`,
    //   this.reserva,
    //   this.localizadorService.localizadorValue
    // );

    // this.localizadorService.setLocalizadorValue(undefined);
    // this.localizadorService.entradasHolder = undefined;
    // this.close();
  }
}
