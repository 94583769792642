import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppConfiguration } from 'src/app/app.configuration';
import { VentaService, StepperNavigationService} from 'src/app/modules/venta';

@Component({
  selector: 'ticketing-confirmar-gestion',
  templateUrl: './confirmar-gestion.component.html'
})
export class ConfirmarGestionComponent implements OnInit {
  @Input() id:string;
  private element: any;
  @Output() acepta: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private el: ElementRef,
    private modalService: ModalService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }
  
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }
  gestionar (){
    this.acepta.emit();
    this.close(); 
  }
}
