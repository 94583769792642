import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfiguration } from 'src/app/app.configuration';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CentrosService {

  constructor(
    private configuration: ConfigurationService,
    private config: AppConfiguration,
    private http: HttpClient,
    private turno: TurnoService) { }

  
  
  getCentros() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Enlacemenu: null,
      Nombre: "",
      Activo: "",
      Clave: localStorage.getItem('claveCentro'),
      Usuario: "",
      Password: "",     
    };
    return this.http
    .post(
      `${this.config.getConfig('API_URL')}api/Customan/ObtenerCentros`,
      body
    )
  };
  
}

