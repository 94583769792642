import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenManagerService } from './core/auth/token-manager.service';
import { ConfigurationService } from './core/conf/configuration.service';
import { AppConfiguration } from './app.configuration';
import { VisorService } from './shared/services/visor.service';
import { mergeMap, map } from 'rxjs/operators';
import { TranslateService } from './shared/services/translate.service';
import { SidebarNavigationService } from './shared/services/sidebar-navigation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ticketing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  window = window as any;
  constructor(
    private config: AppConfiguration,
    private http: HttpClient,
    private tokenManager: TokenManagerService,
    private translate: TranslateService,
    private configuration: ConfigurationService,
    private visor: VisorService,
    private sidebarNav: SidebarNavigationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const traerConexionPrivadaBody = {
      chClaveEmpresa: this.config.getConfig('chClaveEmpresa'),
      chNombreProyecto: this.config.getConfig('chNombreProyecto'),
      chEntornoConexion: this.config.getConfig('chEntornoConexion'),
      chOrigenConexion: this.config.getConfig('chOrigenConexion')
    };
    this.http
      .post(
        `${this.config.getConfig('API_URL')}api/TraerConexionPrivada`,
        traerConexionPrivadaBody
      )
      .subscribe((data: any) => {
        this.tokenManager.currentConexionIdSubject.next(data.pkid);
        localStorage.setItem('conexionId', JSON.stringify(data.pkid));
        this.tokenManager
          .login(
            // this.config.getConfig('USER'),
            // this.config.getConfig('PASSWORD')
            this.config.getConfig('NUMSERIE')
          )
          .pipe(
            mergeMap(res => {
              //obtenemos el tpvPath de la aplicacion
              this.configuration.getTPVPathLocal();
              //asignamos el tpv obtenido desde el servicio
              let config = this.configuration.tpvPathLocalValue;
              //comprobamos si el tpv esta en uso para cargar los disponibles si no lo esta 
              if(!this.configuration.tpvFisicoValue) {this.configuration.getTPVEnUso(config).subscribe();}
              // if (
              //   this.window.external &&
              //   typeof this.window.external.obtenerEquipo === 'function'
              // ) {
              //   config = this.window.external.obtenerEquipo();
              // } else if (this.route.snapshot.queryParams.path) {
              //   config = this.route.snapshot.queryParams.path;
              // } else {
              //   config = this.config.getConfig('TPV_PATH');
              // }
              return this.http
                .post(
                  `${this.config.getConfig(
                    'API_URL'
                  )}api/Configuracion/GetDatosTPVPath`,
                  {
                    ConexionIacpos: data.pkid,
                    VMPath: config
                  }
                )
                .pipe(map((TPVPath: any) => {
                  this.configuration.setDatosTPVPathValue(
                    TPVPath.DatosResult
                  );
                  return TPVPath.DatosResult;
                }));
            }))
          .pipe(mergeMap((res: any) => {
            return this.configuration.getAppConfig(res.pkId)
              .pipe(map(() => {
                return res;
              }));
          }))
          .pipe(
            mergeMap(res => {
              return this.configuration.getModulos().pipe(
                map(modulos => {
                  this.sidebarNav.setSecciones(modulos);
                  return res;
                })
              );
            })
          )
          .subscribe(() => {
            this.http
              .post(
                `${this.config.getConfig(
                  'API_URL'
                )}api/Configuracion/GetDatosInicio`,
                {
                  ConexionIacpos: data.pkid
                }
              )
              .subscribe((res: any) => {
                this.visor.setToBlank();
                this.http
                  .post(
                    `${this.config.getConfig(
                      'API_URL'
                    )}api/Maestros/GetDivisas`,
                    {
                      ConexionIacpos: data.pkid,
                      ISO: res.DatosResult.DivisaISO
                    }
                  )
                  .subscribe((infoDivisa: any) => {
                    this.configuration.getInternetConfig().subscribe();
                    this.configuration.setDatosInicioValue(res.DatosResult);
                    this.configuration.setDivisasValue(
                      infoDivisa.DatosResult.ListaDivisas
                    );
                    this.translate.setLocale(res.DatosResult.Idioma);
                    this.translate.use(res.DatosResult.Idioma);
                    this.configuration.dataReady.next(true);
                  });
              });
          });
      });
    
    }
  ngOnInit() { }
}
