import { Component, OnInit, OnDestroy } from '@angular/core';
import { VentaService, StepperNavigationService } from 'src/app/modules/venta';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ConfigurationService } from 'src/app/core';
import { takeWhile } from 'rxjs/operators';
import * as moment from 'moment';
import { EspectaculosService } from '../../services/espectaculos.service';
import { Actividad } from '../../models/actividad.model';

@Component({
  selector: 'ticketing-seleccion-sesiones',
  templateUrl: './seleccion-sesiones.component.html',
  styleUrls: ['./seleccion-sesiones.component.scss']
})
export class SeleccionSesionesComponent implements OnInit, OnDestroy {
  fecha;
  recintos = [];
  currentRecinto;
  currentRecintoIndex = 0;
  sesionSeleccionada;
  idioma = 'es-ES';
  listadoProductos = [];
  sesionesSeleccionadas: any;
  espectaculoSeleccionado: Actividad;

  private alive = true;

  constructor(
    private ventaService: VentaService,
    private modalService: ModalService,
    private configurationService: ConfigurationService,
    private espectaculosService: EspectaculosService,
    private stepperNavigationService: StepperNavigationService
  ) {}

  ngOnInit() {
    /* this.sesionesSeleccionadas = Object.assign(
      {},
      this.ventaService.carritoValue.horarios
    ); */

    this.configurationService.datosInicio
      .pipe(takeWhile(() => this.alive))
      .subscribe(conf => (this.idioma = conf.Idioma));

    this.sesionesFechasDisponibles();
    this.ventaService.fechaSeleccionada
      .pipe(takeWhile(() => this.alive))
      .subscribe(fecha => {
        this.sesionesFechasDisponibles();
      });
    /*   this.recintos = this.recintosService.recintosValue.map(recinto => {
      
      return { ...recinto, fecha: this.fecha, sesiones: {} };
    }); */

    /*  const codRecintos = this.recintos.map(recinto => {
      return { codRecinto: recinto.Recinto };
    });
    this.ventaService.carritoValue.entradas.forEach(entrada => {
      if (entrada.entrada.TipoProducto === '1') {
        this.listadoProductos.push(
          entrada.entrada.TipoProducto + ',' + entrada.entrada.TipoEntradaId
        );
      } else if (entrada.entrada.TipoProducto === '2') {
        this.listadoProductos.push(
          entrada.entrada.TipoProducto + ',' + entrada.entrada.TipoPromocionId
        );
      }
    }); */

    /*     this.ventaService.fechaSeleccionada
      .pipe(takeWhile(() => this.alive))
      .subscribe(fecha => {
        this.fecha = moment(fecha).format('YYYY/MM/DD');
        this.entradasService
          .getSesionesEntreFechas(
            this.fecha,
            this.fecha,
            codRecintos,
            this.listadoProductos
          )
          .pipe(takeWhile(() => this.alive))
          .subscribe((res: any) => {
            if (res.DatosSesionesEntreFechas) {
              this.franjasHorario = [
                ...new Set(
                  res.DatosSesionesEntreFechas.map(item => item.FranjaHorario)
                )
              ];
            }
            codRecintos.forEach(cod => {
              const recinto = this.recintos.find(
                r => r.Recinto === cod.codRecinto
              );
              recinto.fecha = moment(fecha).format('YYYY/MM/DD');
              this.franjasHorario.forEach(franja => {
                recinto.sesiones[franja] = this.generarFechas(
                  recinto,
                  franja,
                  res.DatosSesionesEntreFechas || []
                );
              });
            });
            this.currentRecinto = this.recintos[this.currentRecintoIndex];
            this.ventaService.currentRecinto = this.recintos[
              this.currentRecintoIndex
            ];
          });
      }); */
  }

  sesionesFechasDisponibles() {
    this.fecha = moment(this.ventaService.fechaSeleccionadaValue).format(
      'YYYY/MM/DD'
    );
    this.espectaculoSeleccionado = this.espectaculosService.espectaculoSeleccionadoValue;
    const startDateOfDay = moment(this.fecha)
      .startOf('day')
      .format('YYYY/MM/DD HH:mm:ss');
    const endDateOfDay = moment(this.fecha)
      .endOf('day')
      .format('YYYY/MM/DD HH:mm:ss');

    this.espectaculosService
      .getSesionesFechasDisponibles(
        this.espectaculoSeleccionado.iEspectaculoId,
        this.espectaculoSeleccionado.pkIdGR,
        startDateOfDay,
        endDateOfDay
      )
      .subscribe((data: any) => {
        this.espectaculoSeleccionado.sesiones = [
          ...data.DatosResult.ButacasDisponibleFecha
        ];
        this.espectaculoSeleccionado.sesiones.forEach((sesion: any) => {
          sesion.HoraDeSesion = moment(
            sesion.HoraDeSesion,
            'DD/MM/YYYY HH:mm:ss'
          ).format('HH:mm');
          sesion.HoraFinDeSesion = moment(
            sesion.HoraFinDeSesion,
            'DD/MM/YYYY HH:mm:ss'
          ).format('HH:mm');
        });
      });
  }

  isSelected(sesion) {
    return this.ventaService.carritoValue.horarios.find(
      horario =>
        horario.sesion.RecintosSesionId === sesion.RecintosSesionId &&
        horario.sesion.Fecha === sesion.Fecha &&
        horario.sesion.HoraInicio === sesion.HoraInicio
    );
  }

  ngOnDestroy() {
    this.alive = false;
  }

  generarFechas(recinto, franja, datosSesion) {
    const sesiones = datosSesion
      .filter(sesion => {
        return (
          sesion.RecintoId === Number.parseInt(recinto.Recinto, 10) &&
          sesion.FranjaHorario === franja
        );
      })
      .map(sesion => {
        const f = moment(
          `${this.fecha} ${sesion.HoraInicio}`,
          'YYYY/MM/DD HH:mm'
        );
        return { ...sesion, disabled: f.isBefore(moment()) };
      });
    const sesionesLimpias = [];
    while (sesiones.length) {
      const trocito = sesiones.splice(0, this.totalCeldasPorFila());
      if (!trocito.every(val => val.disabled)) {
        sesionesLimpias.push(...trocito);
      }
    }
    return sesionesLimpias;
  }

  totalCeldasPorFila() {
    const totalWidth = document.querySelector('.col-seleccion').clientWidth;
    const cellWidth = 64;
    return Math.round(totalWidth / cellWidth);
  }

  sesionSobrepasaCantidadEntradasSeleccionadas(sesion: any): boolean {
    return this.getTotalEntradas() > sesion.Disponible;
  }

  getTotalEntradas() {
    // cambiar esto por get total aforo
    const entradasConRecinto = [];
    let cantidad = '';
    const reducer = (acc = 0, currentValue) => acc + currentValue;
    this.currentRecinto.entradas.forEach(entrada => {
      entradasConRecinto.push(
        this.ventaService.carritoValue.entradas.find(linea => {
          if (linea.entrada.TipoProducto === '1') { //entradas sueltas
            cantidad = 'cantidad';
            return linea.entrada.TipoEntradaId == entrada;
          } else if (linea.entrada.TipoProducto === '2') { //promociones
            return linea.entrada.PromocionEntradas.find((item, index) => {
              if (item.TipoEntradaId == entrada) {
              }
              cantidad = `entrada.PromocionEntradas[${index}].NumeroEntradas`;
              return item.TipoEntradaId == entrada;
            });
          }
        })
      );
    });
    return entradasConRecinto.map(e => e.cantidad).reduce(reducer);
  }

  seleccionarSesion(sesion) {
    this.espectaculosService.sesionSeleccionada = {
      espectaculoId: this.espectaculosService.espectaculoSeleccionadoValue
        .iEspectaculoId,
      fechaSesion: moment(sesion.DiaDeSesion, 'DD/MM/YYYY HH:mm:ss').format(
        'YYYY/MM/DD'
      ),
      horaInicioSesion: moment(sesion.HoraDeSesion, 'HH:mm').format('HH:mm:ss')
    };
    this.stepperNavigationService.nextActividad();
  }

  /* async seleccionarSesion(sesion) {

    
    
    if (Object.keys(this.sesionesSeleccionadas).length > 0) {
      
      for (let i = 0; i < Object.keys(this.sesionesSeleccionadas).length; i++) {
        if (
          this.sesionesSeleccionadas[i].sesion.RecintoId === sesion.RecintoId
        ) {
          this.ventaService
            .eliminarAforoSesion(
              this.sesionesSeleccionadas[i].sesion.RecintosSesionId
            )
            .subscribe();
        }
      }
    }
    if (
      sesion.disabled ||
      this.sesionSobrepasaCantidadEntradasSeleccionadas(sesion)
    ) {
      
      return;
    }
    if (this.sesionSeleccionada === sesion) {
      const index = this.ventaService.carritoValue.horarios.findIndex(
        recinto => recinto.sesion === this.sesionSeleccionada
      );
      this.ventaService.carritoValue.horarios.splice(index, 1);
      this.sesionSeleccionada = undefined;
      
      return;
    }
    const infoSesion = {
      index: this.currentRecintoIndex,
      recinto: this.currentRecinto,
      sesion: sesion,
      cantidad: this.getTotalEntradas(),
      resumen: `${this.currentRecinto.NombreRecinto} - ${moment(
        this.fecha,
        'YYYY/MM/DD'
      )
        .locale(this.idioma)
        .format('L')} ${sesion.HoraInicio} (${this.getTotalEntradas()})`
    };
    const sesionExistente = this.sesionYaExiste(infoSesion.index);
    // this.sesionYaExiste2(infoSesion);
    this.sesionSeleccionada = sesion;
    if (sesionExistente !== -1) {
      this.ventaService.carritoValue.horarios[sesionExistente] = infoSesion;
      await this.entradasService
        .recalcularPrecio(
          this.configurationService.datosTPVPathValue.pkId,
          this.currentRecinto.entradas,
          sesion.HoraInicio,
          moment(this.fecha, 'YYYY/MM/DD'),
          this.ventaService.clienteSeleccionadoValue
        )
        .toPromise()
        .then(entradas => {
          entradas.forEach(entradaNuevoPrecio => {
            const { recintos } = this.ventaService.carritoValue.entradas.find(
              entradaCarrito =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada;
            this.ventaService.carritoValue.entradas.find(
              entradaCarrito =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada = { ...entradaNuevoPrecio, recintos: recintos };
          });
          this.ventaService.carritoValue.recalcularTotales();
        });
      this.ventaService
        .reservaAforo(sesion.RecintosSesionId, this.getTotalEntradas())
        .subscribe();
      
      this.next();
      return;
    }
    this.ventaService.carritoValue.horarios.push(infoSesion);
    this.ventaService.carritoValue.fecha = this.calcularFechaMinima();
    await this.entradasService
      .recalcularPrecio(
        this.configurationService.datosTPVPathValue.pkId,
        this.currentRecinto.entradas,
        sesion.HoraInicio,
        moment(this.fecha, 'YYYY/MM/DD'),
        this.ventaService.clienteSeleccionadoValue
      )
      .toPromise()
      .then(entradas => {
        entradas.forEach(entradaNuevoPrecio => {
          const { recintos } = this.ventaService.carritoValue.entradas.find(
            entradaCarrito =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada;
          this.ventaService.carritoValue.entradas.find(
            entradaCarrito =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada = { ...entradaNuevoPrecio, recintos: recintos };
        });
        this.ventaService.carritoValue.recalcularTotales();
      });
    
    this.ventaService
      .reservaAforo(sesion.RecintosSesionId, this.getTotalEntradas())
      .subscribe();
    this.next();
  }
  next() {
    if (this.comprobarLimiteNext()) {
      this.navigationService.next();
      return;
    }
    this.currentRecintoIndex++;
    this.currentRecinto = this.recintos[this.currentRecintoIndex];
    this.ventaService.currentRecinto = this.recintos[this.currentRecintoIndex];
  } */

  sesionYaExiste(index) {
    return this.ventaService.carritoValue.horarios.findIndex(
      sesion => sesion.index === index
    );
  }

  calcularFechaMinima() {
    const fechas = this.ventaService.carritoValue.horarios.map(espectaculo => {
      return moment(
        `${espectaculo.recinto.fecha} ${espectaculo.sesion.HoraInicio}`,
        'YYYY/MM/DD HH:mm:ss'
      );
    });
    return moment
      .min(fechas)
      .locale(this.idioma)
      .format('YYYY/MM/DD HH:mm:ss');
  }

  getFechaSeleccionada() {
    if (this.ventaService.fechaSeleccionadaValue) {
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('L');
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  nextDia() {
    /* if (this.ventaService.carritoValue.entradas.length > 0) {
      this.refrescarCarrito();
    } */

    const futuribleDate = moment(this.ventaService.fechaSeleccionadaValue).add(
      1,
      'days'
    );
    if (
      this.ventaService.disabledDaysValue.includes(
        futuribleDate.format('DD/MM/YYYY')
      )
    ) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.add(2, 'days')
      );
      this.sesionesFechasDisponibles();
      return;
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.add(1, 'days')
      );
      this.sesionesFechasDisponibles();
      return;
    }
  }

  backDia() {
    /*  if (this.ventaService.carritoValue.entradas.length > 0) {
      this.refrescarCarrito();
    } */
    const futuribleDate = moment(
      this.ventaService.fechaSeleccionadaValue
    ).subtract(1, 'days');
    if (
      this.ventaService.fechaSeleccionadaValue.isSameOrBefore(moment(), 'day')
    ) {
      return;
    }
    if (
      this.ventaService.disabledDaysValue.includes(
        futuribleDate.format('DD/MM/YYYY')
      )
    ) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.subtract(2, 'days')
      );
      return;
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.subtract(1, 'days')
      );
      return;
    }
  }
}
