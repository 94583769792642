import { Component, Input, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { CellComponent } from '../cell/cell.component';

@Component({
  selector: 'ticketing-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements AfterContentInit {
  @ContentChildren(CellComponent) cells: QueryList<CellComponent>;

  @Input() opened = false;
  @Input() title: string;
  @Input() qty: number;
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngAfterContentInit() {
    this.cells.forEach((t) => {
      t.toggle.subscribe(() => {
        this.selectCell(t);
      });
    });
  }

  selectCell(cell: CellComponent) {
  }

}
