import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { Router } from '@angular/router';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { User } from 'src/app/core/user.model';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { SuccessToast } from 'src/app/core/toast/success.toast';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { VentaService } from 'src/app/modules/venta';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { MotivosService } from 'src/app/modules/venta/services/motivos.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { DevolucionService } from 'src/app/modules/gestionar-venta/services/devolucion.service';
import { DesglosarCambioService } from '../../services/desglosar-cambio.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { LoginService } from 'src/app/core/auth/login.service';

@Component({
  selector: 'ticketing-cambio-usuario',
  templateUrl: './cambio-usuario.component.html',
  styleUrls: ['./cambio-usuario.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout__cambiar-usuario'
  }
})
export class CambioUsuarioComponent implements OnInit, OnDestroy {
  error;
  loging = false;
  loginForm: FormGroup;

  constructor(
    private configuration: ConfigurationService,
    private turnoService: TurnoService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private router: Router,
    private userService: UserManagerService,
    private toastr: ToastrService,
    private ventaService: VentaService,
    private pagoService: PagoService,
    private config: AppConfiguration,
    private motivosService: MotivosService,
    private devolucionService: DevolucionService,
    private desgloseService: DesglosarCambioService,
    private translate: TranslateService,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      usuario: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')])
      ],
      contrasena: ['', Validators.required],
      cambioInicial: ''
    });
  }

  ngOnDestroy() {
    this.formControlService.unfocus();
  }

  login() {
    this.loginService.setVentanaOrigen('cambioUsuario');
    this.ventaService.clearCarrito();
    this.ventaService.setFechaSeleccionadaValue();
    this.ventaService.setClienteSeleccionadoValue('');
    this.ventaService.currentCliente = '0';
    this.pagoService.setPagoTotal(undefined);
    this.pagoService.setCambio(undefined);
    this.devolucionService.setPagoTotal(undefined);
    this.devolucionService.setCambio(undefined);
    this.motivosService.setMotivosSeleccionados([]);
    if (this.checkLoginIsInvalid() || this.loging) {
      return;
    }
    this.loging = true;

    this.loginService
      .login(this.loginForm.controls['usuario'].value, this.loginForm.controls['contrasena'].value)
      .pipe(
        mergeMap(user => {
          if (!user) {
            return of(null);
          } else {

            return this.turnoService.cerrarSesion().pipe(map(() => user));
          }
        })
      )
      .pipe(
        mergeMap(user => {
          if (!user) {
            this.loging = false;
            return of(null);
          } else {

            this.toastr.success(this.translate.instant('USUARIOAUTENTIFICADO'), '', {
              toastComponent: SuccessToast
            });
            const cambiarAConectado = user.Conectado === 'False' ? true : false;
            //<<Yaribel 20210315 añadimos que nos actualice el usuario y contraseña actual
            localStorage.setItem('currentUser', JSON.stringify(this.loginForm.controls['usuario'].value));
            localStorage.setItem('currentPwd', JSON.stringify(this.loginForm.controls['contrasena'].value));
            //Yaribel 20210315 >>
            return this.userService
              .updateUsuarioConectado(user.Id, cambiarAConectado)
              .pipe(map(() => user));
          }
        })
      )
      .pipe(
        mergeMap(user => {
          if (!user) {
            return of(null);
          } else {

            const idTpv = this.configuration.datosTPVPathValue.pkId;
            const idDivisaRef = this.configuration.datosInicioValue.DivisaRefId;
            let importe = '0';
            let desglose = [];
            if (this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno && this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno.TurnoInicioDesgloseMoneda == '1') {
              desglose = this.desgloseService.listadoBilletesMonedasValue;
              importe = this.desgloseService.getTotalDesglose().toString();
            }
            return this.turnoService
              .abrirCaja(idTpv, user.Id, idDivisaRef, idDivisaRef, importe, desglose)
              .pipe(map((res: any) => res.DatosResult));
          }
        })
      )
      .subscribe((turno: any) => {
        if (!turno) {
        } else {

          const caja = {
            CajaCodigo: turno.CajaCodigo,
            CajaId: turno.CajaId
          };
          this.turnoService.setCajaValue(caja);
          this.turnoService.setCajasValue(turno.ListadoCajasTurno);
          this.turnoService.setTurnoValue(turno.TurnoInfo);
          this.turnoService.setEfectivoTurnoValue(turno.TurnoEfectivo);
          this.desgloseService.setListadoBilletesMonedas(undefined);
          this.loging = false;
          if (this.config.getConfig('COMPRAINICIO')) {
            this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
          } else {
            this.router.navigate(['']);
          }
        }
      });
  }

  checkLoginIsInvalid() {
    if (this.loginForm.invalid) {
      return true;
    }
    return false;
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.loginForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  deleteValue() {
    this.formControlService.setToBlank();
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
    element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  mostrarMensajeLoginIncorrecto() {
    this.error = 'Error de identificación';
  }
}
