import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ticketing-minimapa',
  templateUrl: './minimapa.component.html',
  styleUrls: ['./minimapa.component.scss']
})
export class MinimapaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
