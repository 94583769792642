import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ticketing-auditorio',
  templateUrl: './auditorio.component.html',
  styleUrls: ['./auditorio.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout'
  }
})
export class AuditorioComponent implements OnInit {
  constructor(private router: Router) {
    this.router.navigate(['auditorio', 'actividades']);
  }

  ngOnInit() {}
}
