import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfiguration } from 'src/app/app.configuration';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { LoginService } from '../login.service';
import { UserManagerService } from '../user-manager.service';

@Component({
  selector: 'ticketing-codigo-auth',
  templateUrl: './codigo-auth.component.html',
  styleUrls: ['./codigo-auth.component.scss'],
  host: {
    class: 'layout__cambiar-usuario'
  }
})
export class CodigoAuthComponent implements OnInit {
error;
  codigoForm: FormGroup;
  usuarioId;
  @Input() id: string;
  private element: any;
  window = window as any;

  constructor(private router: Router,
              private el: ElementRef,
              private config: AppConfiguration, 
              private formBuilder: FormBuilder, 
              private formControlService: FormControlService, 
              private userService: UserManagerService, 
              private loginService: LoginService,
              private modalService: ModalService) { 
                this.element = el.nativeElement;
              }

  ngOnInit() {
    // const modal = this;
    // if (!this.id) {
    //   return;
    // }

    // document.body.appendChild(this.element);
    // this.element.addEventListener('click', function(e: any) {
    //   if (e.target.className === 'jw-modal') {
    //     modal.close();
    //   }
    // });

    // this.modalService.add(this);


    this.codigoForm = this.formBuilder.group({
      codigo: ['', Validators.required]
    });
  }

  hacerLogin(){
        this.loginService.cargarDatosLogin(
          this.loginService.usuarioValue,
          this.loginService.contrasenaValue,
          this.loginService.importeInicialValue,
          this.codigoForm.controls['codigo'].value
        ).subscribe(carga =>{
          if(carga){
            //this.close();
            if (this.config.getConfig('COMPRAINICIO')) {
              this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
            } else {
              this.router.navigate(['']);
            }
          }
        });        
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.codigoForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  checkLoginIsInvalid() {
    // if (this.codigoForm.invalid) {
    //   return true;
    // }
    return false;
  }

  cancelar(){
    this.loginService.setPedirCodigoAuth(false);
     this.router.navigate(['']);
    // this.element.style.display = 'none';
    // document.body.classList.remove('jw-modal-open');
    //this.close();
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.loginService.setPedirCodigoAuth(false);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }
}

