import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/core';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { DesglosarCambioService } from '../../services/desglosar-cambio.service';

@Component({
  selector: 'ticketing-movimientos-caja',
  templateUrl: './movimientos-caja.component.html',
  styleUrls: ['./movimientos-caja.component.scss']
})
export class MovimientosCajaComponent implements OnInit {
  esperarSub: Subscription;
  constructor(private configuration: ConfigurationService, private router: Router, private desgloseService:DesglosarCambioService,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private modalService: ModalService) { }

  ngOnInit() {

    if(this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno && this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno.TurnoMovimientosDesgloseMoneda == "1"){

    } else{
      this.router.navigate(['turno', 'retirarIntroducir']);
    } 

  }

  retirar(){
    this.desgloseService.setVentanaOrigen('retirar');
    this.peticion();
  }

  introducir(){
    this.desgloseService.setVentanaOrigen('introducir'); 
    this.peticion();
}

peticion(){
  this.desgloseService.setEsperarDesglose(true);
    this.modalService.open('desglosarCambio');

    }

    tienePermiso(accion: string) {
      const conf = {
        toast: false,
        elevarUsuario: false
      };
      return this.permisosService.puedeAccionar(this.user.currentUserValue, accion, conf);
    }
  
}
