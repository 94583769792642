import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AlbaranService } from '../../../services/albaran.service';

@Component({
  selector: 'ticketing-albaran-siguiente',
  templateUrl: './albaran-siguiente.component.html',
  styleUrls: ['./albaran-siguiente.component.scss']
})
export class AlbaranSiguienteComponent implements OnInit {

  @Output() movimiento = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  siguienteAlbaran() {
    this.movimiento.emit(1);
  }

}
