import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { EntradasService } from 'src/app/modules/venta';
import { mergeMap, takeWhile, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { MotivosService } from 'src/app/modules/venta/services/motivos.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { MotivoPadService } from 'src/app/shared/services/motivo-pad.service';
import { Entrada } from 'src/app/modules/venta/models/entrada.model';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { ConfigurationService } from 'src/app/core';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { ToastrService } from 'ngx-toastr';
import { LocalizadorService } from '../../services/localizador.service';
import { TranslateService } from '../../../../shared/services/translate.service';

@Component({
  selector: 'ticketing-motivos-localizador',
  templateUrl: './motivos-localizador.component.html',
  styleUrls: ['./motivos-localizador.component.scss']
})
export class MotivosLocalizadorComponent implements OnInit, OnDestroy {
  @Input() entradas: any[];
  @Input() id: string;

  private element: any;

  currentEntrada;
  motivos;
  listaMotivosSeleccionados;
  motivoSeleccionado;

  configuracionDivisas;

  private alive = true;

  constructor(
    private modalService: ModalService,
    private entradaService: EntradasService,
    private motivosService: MotivosService,
    private el: ElementRef,
    private formControlService: FormControlService,
    private motivoPadService: MotivoPadService,
    private numpadService: NumpadService,
    private configuration: ConfigurationService,
    private toast: ToastrService,
    private localizadorService: LocalizadorService,
    private translate: TranslateService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.configuracionDivisas = this.configuration.divisasValue[0];
    this.currentEntrada = this.entradas[0];
    this.entradaService.setEntradaSeleccionada(this.currentEntrada.entrada);
    this.entradaService.entradaSeleccionada$
      .pipe(
        mergeMap(entrada => {
          this.currentEntrada.entrada = entrada;
          if (entrada) {
            //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables                   
            return this.entradaService.getDescuentos(this.currentEntrada.entrada.GrupoMotivoId);            
                
            // if (entrada.TipoProducto === '2') {
            //   let motivoId = 0;
            //   entrada.PromocionEntradas.array.forEach(element => {
            //     if (element.GrupoMotivoId != '') {
            //       motivoId = element.GrupoMotivoId;
            //     }
            //   });
            //   return this.entradaService.getDescuentos(motivoId);
            // } else {
            //   return this.entradaService.getDescuentos(this.currentEntrada.entrada.GrupoMotivoId);
            // }

            // Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables >>

          } else {
            return of(entrada);
          }
        })
      )
      .pipe(
        mergeMap(res => {
          if (this.currentEntrada) {
            return this.motivosService
              .getMotivosSeleccionadosByEntradaId(this.currentEntrada.entrada.TipoEntradaId)
              .pipe(
                map(motivosSeleccionados => {
                  if (motivosSeleccionados) {
                    this.listaMotivosSeleccionados = motivosSeleccionados.lista;
                  }
                  return res;
                })
              );
          } else {
            return of(res);
          }
        })
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe(motivos => {
        this.motivos = motivos;
      });
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.alive = false;
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  forwardEntrada(entrada) {
    this.entradas.indexOf(linea => linea.entrada.TipoProductoId === entrada.TipoProductoId);
  }

  backEntrada() {}

  getPrecioTotal() {
    return this.motivosService.getPrecioTotal();
  }

  getCantidadTotal() {
    return this.motivosService.getCantidadTotal();
  }

  selectMotivo(motivo) {
    this.motivoSeleccionado = motivo;
    this.motivoSeleccionado.idEntradaAsociada = this.currentEntrada.entrada.TipoEntradaId;
    // this.entrada.motivos.push(motivo);
    const input = document.getElementById(`motivo-${this.motivoSeleccionado.PkId}`);
    this.motivoPadService.selected = input;
    this.motivoPadService.motivo = this.motivoSeleccionado;
    this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    // tslint:disable-next-line: deprecation
    if ((<HTMLElement>event.target).getAttribute('category') === 'MOTIVO') {
      this.numpadService.commaDisabled = true;
    } else {
      this.numpadService.commaDisabled = false;
    }
  }

  getCantidadPorMotivo(id) {
    if (this.listaMotivosSeleccionados) {
      return this.listaMotivosSeleccionados.find(m => m.PkId === id)
        ? this.listaMotivosSeleccionados.find(m => m.PkId === id).numMotivo
        : '';
    } else {
      return '';
    }
  }

  eliminarMotivo(motivo) {
    const oldVals = {
      motivoSeleccionado: this.motivoPadService.motivo,
      selected: this.motivoPadService.selected
    };
    this.motivoSeleccionado = motivo;
    this.motivoSeleccionado.idEntradaAsociada = this.currentEntrada.entrada.TipoEntradaId;
    const input = document.getElementById(`motivo-${this.motivoSeleccionado.PkId}`);
    this.motivoPadService.selected = input;
    this.motivoPadService.motivo = this.motivoSeleccionado;
    this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    this.formControlService.setToBlank();

    if (oldVals.motivoSeleccionado) {
      this.motivoSeleccionado = oldVals.motivoSeleccionado;
    }
    if (oldVals.selected) {
      this.motivoPadService.selected = oldVals.selected;
    }

    this.motivoPadService.motivo = this.motivoSeleccionado;
    this.formControlService.setCurrentFormGroup(
      null,
      this.motivoPadService.selected,
      this.motivoPadService.selected.getAttribute('category')
    );
    // this.numpadService.entrada = entradaOriginal;
  }

  focusValue(event: any, motivo) {
    this.motivoSeleccionado = motivo;
    event.srcElement.value = '';
    this.motivoPadService.selected = event.srcElement;
    this.motivoPadService.motivo = motivo;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  unfocusValue(event: any) {
    if (event.srcElement.value === '') {
      this.motivoPadService.setNumpadOrder('reset');
      return;
    }
  }

  inputDeTeclado(event: any, entrada: Entrada) {
    event.preventDefault();
    const charCode = event.keyCode;
    this.motivoPadService.selected = event.srcElement;
    this.motivoPadService.motivo = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );

    if (this.isDeleteButtonPressed(charCode)) {
      this.formControlService.deleteChar();
      return;
    }

    if (this.isEnterButton(charCode)) {
      this.formControlService.unfocus();
      event.srcElement.blur();
      return;
    }

    if (this.isNumberKey(charCode)) {
      this.formControlService.inputChar(event.key);
      return;
    }
  }

  isNumberKey(charCode: number) {
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isDeleteButtonPressed(charCode: number) {
    return charCode === 8 ? true : false;
  }

  isEnterButton(charCode: number) {
    return charCode === 13 ? true : false;
  }

  agregarMotivosLocalizador() {
    if (this.getCantidadTotal() > this.currentEntrada.cantidad) {
      this.toast.error('', this.translate.instant('ERROR_MOTIVOS_CANTIDAD'), {
        toastComponent: ErrorToast,
        timeOut: 5000
      });
      return;
    }
    const listaFarMotivos = this.motivosService.motivosSeleccionadosValue.map(motivo => {
      return {
        FarId: this.entradaService.entradaSeleccionada.FarId,
        ListaMotivos: motivo.lista.map(motivoDetalle => {
          return { MotivoId: motivoDetalle.PkId, Cantidad: motivoDetalle.numMotivo };
        })
      };
    });
    this.localizadorService.insertarMotivos(listaFarMotivos).subscribe(res => {
      this.close();
    });
  }
}
