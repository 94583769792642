import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit
} from '@angular/core';
import { PanelSesionesComponent } from '../panel-sesiones/panel-sesiones.component';

@Component({
  selector: 'ticketing-accordion-sesiones',
  templateUrl: './accordion-sesiones.component.html',
  styleUrls: ['./accordion-sesiones.component.scss']
})
export class AccordionSesionesComponent implements AfterContentInit {
  @ContentChildren(PanelSesionesComponent) panels: QueryList<
    PanelSesionesComponent
  >;

  ngAfterContentInit() {
    this.panels.toArray().forEach(t => {
      t.toggle.subscribe(() => {
        this.openPanel(t);
      });
    });
  }

  openPanel(panel: PanelSesionesComponent) {
    panel.opened = !panel.opened;
  }
}
