import { Injectable } from '@angular/core';
import { ConnectionType } from './connectionType.enum';
import { OsConnection } from './osConnection.interface';
import { AspNetConnection } from './aspNetConnection';
import { VirtualConnection } from './virtualConnection';

@Injectable({
    providedIn: 'root'
})
export class OsConnectionFactory {

  public static getConnection(connectionType: ConnectionType): OsConnection {
    if (connectionType === ConnectionType.ASPNET) {
      return new AspNetConnection();
    }
    if (connectionType === ConnectionType.VIRTUAL) {
      return new VirtualConnection();
    }
  }
}
