import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { AlbaranService } from '../../services/albaran.service';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { shareReplay, mergeMap, take } from 'rxjs/operators';
import { PrintService } from 'src/app/shared/services/print.service';
import { DevolucionService } from '../../services/devolucion.service';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { Router } from '@angular/router';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { AlbaranRecinto } from '../../models/albaran.model';

@Component({
  selector: 'ticketing-resumen-venta',
  templateUrl: './resumen-venta.component.html',
  styleUrls: ['./resumen-venta.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'col-resumen'
  }
})
export class ResumenVentaComponent implements OnInit {
  albaran$: Observable<any>;
  valoresImpresion$: Observable<any>;
  divisaSimboloDecimal: string;
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  divisaSimboloDerecha: string;
  esGranDivisa: boolean;
  habilitarDevolucion = false;
  cantidadProductos = 0;
  recintos$: Observable<any>;
  recintos: AlbaranRecinto[];


  constructor(
    private albaranService: AlbaranService,
    private configuration: ConfigurationService,
    private devolucionService: DevolucionService,
    private printService: PrintService,
    private facturaService: FacturaService,
    private router: Router,
    private turnoService: TurnoService,
    private pagoService: PagoService
  ) {
  }

  ngOnInit() {
    this.divisaSimboloDecimal = this.configuration.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configuration.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configuration.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configuration.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha = this.configuration.divisasValue[0].SimboloDerecha;
    this.albaran$ = this.albaranService.albaran.pipe(shareReplay(5));
    this.valoresImpresion$ = this.printService.opcionesDeImpresion;
    this.recintos$ = this.albaranService.albaran;
    this.recintos$.subscribe((data: any) => {
      if (data !== null && data !== undefined) {
        return this.filtrarRecintos(data.AlbaranRecintos);
      }
    });
    this.albaranService.albaran.subscribe(data => {
      if (data !== null && data !== undefined) {
        // 
        this.cantidadProductos = 0;
        data.AlbaranItems.forEach(element => {
          this.cantidadProductos += Number.parseInt(element.Cantidad, 10);
          // 
        });
        // 
        // // this.recintos = data.AlbaranRecintos.map(recinto => this.filtrarRecintos(recinto,data.AlbaranRecintos));
        // this.recintos$ = data.AlbaranRecintos;
        // 
        // this.recintos = data.AlbaranRecintos.map(recinto => this.filtrarRecintos(recinto,data.AlbaranRecintos));
        // this.recintos$ = data.AlbaranRecintos;
        // this.recintos$.subscribe(data =>{
        //   this.recintos = this.filtrarRecintos(data.AlbaranRecintos);
        // 
      }
    });
    this.devolucionService.carritoDevolucion$.subscribe(carro => {
      if (carro && carro.entradas.length > 0) {
        this.habilitarDevolucion = true;
      } else {
        this.habilitarDevolucion = false;
      }
    });

  }

  conversor(precio: string) {
    return Number.parseFloat(precio.replace(/,/, '.'));
  }

  sePuedeFinalizar() {
    // Apaño para precio 0
    if (
      this.devolucionService.carritoDevolucion.entradas.length > 0 &&
      this.devolucionService.carritoDevolucion.precioTotal === 0
    ) {
      return false;
    }
    return (this.devolucionService.diferenciaValue
      && this.devolucionService.diferenciaValue == this.devolucionService.carritoDevolucion.precioTotal && this.devolucionService.formularioCorrectoValue)
      ? false
      : true;
  }

  async devolver() {
    this.habilitarDevolucion = false;
    let oldNumAlbaran: string = '';
    let oldAlbaranTPVid: string = '';

    // 
    if (this.albaranService.albaranValue.TienePagoCC === '1') {
      this.albaranService
        .generarIdentificadores(
          this.albaranService.albaranValue.TPVId,
          this.albaranService.albaranValue.Prefijo
        )
        .subscribe((data: any) => {
          this.albaranService
            .devolucionParcialTarjeta(
              this.devolucionService.itemsSeleccionadosValue,
              data.DatosResult.Identificador,
              data.DatosResult.NumAlbaran
            )
            .subscribe((item: any) => {
              if (item.DatosResult) {
                oldAlbaranTPVid = this.albaranService.albaranValue.TPVId;
                oldNumAlbaran = this.albaranService.albaranValue.NumAlbaran;
                this.albaranService
                  .datosAlbaranConTPVId(item.DatosResult.NumAlbaran, item.DatosResult.TpvId)
                  .subscribe(res => {
                    if (
                      res.DatosResult.ListadoAlbaranInfo[0].Facturado === '1'
                    ) {
                      this.facturaService
                        .pdfFactura(res.DatosResult.ListadoAlbaranInfo[0])
                        .subscribe((facturas: any) => {
                          if (facturas.DatosResult) {
                            this.printService.printFacturaPDF(
                              facturas.DatosResult
                            );
                            this.albaranService
                              .refrescarAlbaran(
                                this.albaranService.albaranValue.NumAlbaran
                              )
                              .subscribe();
                            this.albaranService.anulacionTotal.next(false);
                            this.devolucionService.setCarritoDevolucion(null);
                            this.devolucionService.setPagoTotal(null);
                            this.devolucionService.setPagoDesglosado(null);
                            this.devolucionService.setDiferencia(null);
                            this.devolucionService.setCambio(null);
                            this.router.navigate(['gestionar-venta']);
                          }
                        });
                    } else {
                      this.albaranService
                        .refrescarAlbaran(
                          this.albaranService.albaranValue.NumAlbaran
                        )
                        .subscribe();
                      this.albaranService.anulacionTotal.next(false);
                      this.devolucionService.setCarritoDevolucion(null);
                      this.devolucionService.setItemsSeleccionados(null);
                      this.devolucionService.setPagoTotal(null);
                      this.devolucionService.setPagoDesglosado(null);
                      this.devolucionService.setDiferencia(null);
                      this.devolucionService.setCambio(null);
                      this.router.navigate(['gestionar-venta']);
                    }
                  });
                this.imprimirJustificanteDevolucion(item.DatosResult.NumAlbaran, 'PARCIAL');
                this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();

              }
            });
        });
    } else {
      const res: any = await this.albaranService.devolucionParcial(
        this.devolucionService.itemsSeleccionadosValue
      );
      if (res.DatosResult) {
        this.albaranService
          .datosAlbaranConTPVId(res.DatosResult.NumAlbaran, res.DatosResult.TpvId)
          .subscribe(res => {
            if (res.DatosResult.ListadoAlbaranInfo[0].Facturado === '1') {
              this.facturaService
                .pdfFactura(res.DatosResult.ListadoAlbaranInfo[0])
                .subscribe((facturas: any) => {
                  if (facturas.DatosResult) {
                    this.printService.printFacturaPDF(
                      facturas.DatosResult
                    );

                    this.albaranService
                      .refrescarAlbaran(
                        this.albaranService.albaranValue.NumAlbaran
                      )
                      .subscribe();
                    this.albaranService.anulacionTotal.next(false);
                    this.devolucionService.setCarritoDevolucion(null);
                    this.devolucionService.setItemsSeleccionados(null);
                    this.devolucionService.setPagoTotal(null);
                    this.devolucionService.setPagoDesglosado(null);
                    this.devolucionService.setDiferencia(null);
                    this.devolucionService.setCambio(null);
                    this.router.navigate(['gestionar-venta']);
                  }
                });
            } else {
              this.albaranService
                .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
                .subscribe();
              this.albaranService.anulacionTotal.next(false);
              this.devolucionService.setCarritoDevolucion(null);
              this.devolucionService.setItemsSeleccionados(null);
              this.devolucionService.setPagoTotal(null);
              this.devolucionService.setPagoDesglosado(null);
              this.devolucionService.setDiferencia(null);
              this.devolucionService.setCambio(null);
              this.router.navigate(['gestionar-venta']);
            }
            this.imprimirJustificanteDevolucion(res.DatosResult.NumAlbaran, 'PARCIAL');
            this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();

          });


      }
    }
  }

  imprimirJustificanteDevolucion(NumAlbaran: string, tipo: string) {
    // TODO  Yaribel 20201124 mejorar mapear el objeto y no usar refrescarAlbaran
    this.albaranService.refrescarAlbaran(NumAlbaran).subscribe(res => {
      if (res) {
        const albaranValue = this.albaranService.albaranValue;
        const NombreTPV = this.albaranService.albaranValue.NombreTPV;
        const IdTPV = this.albaranService.albaranValue.IdTPV;
        this.pagoService.datosCabecera().pipe(take(1)).subscribe(data => {
          switch (tipo) {
            case 'TOTAL' :
              this.printService.printRefundAlbaran({
                datos: albaranValue,
                NombreTPV: NombreTPV,
                IdTPV: IdTPV
              }, data);
              break;
            case 'PARCIAL':
              this.printService.printPartialRefund({
                datos: albaranValue
              }, data);
              break;
          }
        });

      }

    });


  }

  getNombreCliente() {
    if (this.albaranService.albaranValue.AlbaranCliente && this.albaranService.albaranValue.AlbaranCliente[0]) {
      return `${this.albaranService.albaranValue.AlbaranCliente[0].NombreCliente}`;
    }
    return '';
  }

  getLocalizador() {
    return this.albaranService.albaranValue.Localizador
      ? this.albaranService.albaranValue.Localizador
      : '';
  }


  filtrarRecintos(recintos) {

    const recintosFiltrados: AlbaranRecinto[] = [];
    if (recintos && recintos != undefined && recintos != null && recintos.length > 0) {
      let index = 0;
      recintos.forEach((element) => {
        if (!recintosFiltrados.find(x => x.RecintoId == element.RecintoId && x.FechaEntrada == element.FechaEntrada && x.Espectaculo == element.Espectaculo && x.NumButaca == element.NumButaca && x.NumFila == element.NumFila)) {
          recintosFiltrados.push(element);
          recintosFiltrados[index].pkId = '';
          index++;
        }
        // recintosFiltrados.push({Espectaculo: element.Espectaculo, FechaEntrada: element.FechaEntrada, RecintoNombre: element.RecintoNombre, NombreGuia: element.NombreGuia,
        // NumButaca: element.NumButaca, NumFila: element.NumFila, CantidadTotal: element.CantidadTotal});

      });
      // return this.groupBy(recintosFiltrados, c=> c.RecintoId);
      // return this.removeDuplicates(recintosFiltrados, 'RecintoId');
    }
    return recintosFiltrados;
  }

  groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

}
