import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { VentaService } from 'src/app/modules/venta';
import { Router } from '@angular/router';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { DesglosarCambioService } from 'src/app/modules/gestionar/services/desglosar-cambio.service';

@Component({
  selector: 'ticketing-gestionar-turno',
  templateUrl: './gestionar-turno.component.html',
  styleUrls: ['./gestionar-turno.component.scss']
})
export class GestionarTurnoComponent implements OnInit {
  @Input() id: string;
  private element: any;
  window = window as any;
  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private confirmationService: ConfirmationService,
    private userService: UserManagerService,
    private turnoService: TurnoService,
    private ventaService: VentaService,
    private router: Router,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private configuration: ConfigurationService,
    private desgloseService: DesglosarCambioService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);

    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });

    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  salirSinCerrarTurno() {
    if(!this.configuration.tpvPathLocalValue){
      this.configuration.getTPVPathLocal();
    }
    this.turnoService.updateTPVEnUso(this.configuration.tpvPathLocalValue, '0').subscribe();
    //Cargamos el tpv original 
    const config = this.configuration.getTPVPathOrigenValue();    
    this.configuration.setTpvPathLocal(config);
    //fin de cargamos tpv original
    this.turnoService.getDatosTPVPath().subscribe();
    this.userService.logout();
    this.close();

  }

  cambiarUsuario() {
    this.router.navigate(['cambio-usuario']);
    this.close();
  }

  cerrarCaja() {
    if(this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno && this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno.TurnoCierreDesgloseMoneda == "1")
    {
      //this.router.navigate(['desglose']);
      this.desgloseService.setEsperarDesglose(true);
      this.desgloseService.setVentanaOrigen('cerrarTurno');
      this.modalService.open('desglosarCambio');
    } else{
      this.modalService.open('cerrarTurno');
    }

     this.confirmationService.confirmado.subscribe( (estaConfirmado: Boolean) => {
       if (estaConfirmado) {
         
         this.ventaService.clearCarrito();
        this.confirmationService.setConfirmado(false);
        if(!this.configuration.tpvPathLocalValue){
          this.configuration.getTPVPathLocal();
        }
        this.turnoService.updateTPVEnUso(this.configuration.tpvPathLocalValue, '0').subscribe();
         this.modalService.close('confirmarCierreTurno');
         this.close();
       }
     });
  }

  salir() {
    
    if (
      this.window.external &&
      typeof this.window.external.salir === 'function'
    ) {
      this.window.external.salir();
    }
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false
    };
    return this.permisosService.puedeAccionar(
      this.user.currentUserValue,
      accion,
      conf
    );
  }

  tieneTurnoAbierto(){
    if(this.turnoService.turnoValue)
    return true;
    return false
  }
}
