import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/login.service';
import { TokenManagerService } from '../auth/token-manager.service';
import { TurnoService } from '../auth/turno.service';

@Injectable({
  providedIn: 'root'
})
export class CambiarPassGuard implements  CanActivate {
  constructor(private router: Router, private loginService: LoginService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const cambiarPass = this.loginService.cambiarPassValue;
    const conCodigoAuth = this.loginService.pedirCodigoAuthValue;
    if(conCodigoAuth){
      return true;
    }
    if (cambiarPass) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
