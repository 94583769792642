import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';

type typeTranslate = 'UPPER' | 'LOWER' | 'CAPITALIZE';

@Pipe({
  name: 'translateV2',
  pure: false
})
export class TranslatePipeV2 implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(key: string, mode: typeTranslate = 'UPPER'): string {
    const value = this.translate.data[key] || key;
    switch (mode) {
      case 'UPPER':
        return value.toUpperCase();
      case 'LOWER':
        return value.toLowerCase();
      case 'CAPITALIZE':
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      default:
        return value;
    }
  }
}
