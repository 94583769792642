export enum PrintableType {
    NORMAL_TICKET,
    JOINT_TICKET,
    REPRINTED_TICKET,
    ONLINE_TICKET,
    TICKET,
    LABEL,
    ALBARAN,
    RANDOM_ARCHING_REPORT,
    CASH_CLOSURE_REPORT,
    CASH_OPENING_REPORT,
    PURCHASE_ALBARAN,
    PURCHASE_ALBARAN_A4,
    REFUND_ALBARAN,
    REFUND_ALBARAN_A4,
    PARTIAL_REFUND_ALBARAN,
    ONLINE_SOURCE_ALBARAN,
    ONLINE_SOURCE_ALBARAN_A4,
    PURCHASE_INVOICE,
    AMENDING_INVOICE
}
