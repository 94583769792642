import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormControlService } from '../../services/form-control.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { UsuarioElevadoService } from 'src/app/core/security/usuario-elevado.service';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { ToastrService } from 'ngx-toastr';
import { SuccessToast } from 'src/app/core/toast/success.toast';

@Component({
  selector: 'ticketing-elevar-usuario',
  templateUrl: './elevar-usuario.component.html',
  styleUrls: ['./elevar-usuario.component.scss']
})
export class ElevarUsuarioComponent implements OnInit {
  @Input() id: string;
  private element: any;

  loginForm: FormGroup;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private userService: UserManagerService,
    private usuarioElevado: UsuarioElevadoService,
    private permisosService: PermisosService,
    private toastService: ToastrService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      usuario: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')])
      ],
      contrasena: ['', Validators.required],
      cambioInicial: ''
    });
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.loginForm.reset();
    // this.formControlService.unfocus();
  }

  elevarUsuario() {
    this.userService
      .elevarUsuario(
        this.loginForm.controls['usuario'].value,
        this.loginForm.controls['contrasena'].value
      )
      .subscribe(user => {
        const permiso = this.permisosService.puedeAccionar(user, this.permisosService.accion, {
          toast: false,
          elevarUsuario: false
        });
        if (permiso) {
          this.close();
          this.toastService.success('', 'Se han otorgado permisos para esta accion', {
            toastComponent: SuccessToast
          });
          this.usuarioElevado.elevar();
          //this.usuarioElevado.reducir();
        }
      });
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.loginForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  checkLoginIsInvalid() {
    if (this.loginForm.invalid) {
      return true;
    }
    return false;
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }
}
