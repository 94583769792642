import { Component, OnInit } from '@angular/core';
import { InfoMapasService } from 'src/app/modules/auditorio/services/info-mapas.service';
import * as d3 from 'd3';
import { ButacasService } from 'src/app/modules/auditorio/services/butacas.service';
import { EspectaculosService } from 'src/app/modules/auditorio/services/espectaculos.service';

@Component({
  selector: 'ticketing-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit {
  svg;
  recinto;
  zoom;
  width;
  height;
  zoomed;

  constructor(
    private infoMapasService: InfoMapasService,
    private butacasService: ButacasService,
    private espectaculosService: EspectaculosService
  ) {}

  ngOnInit() {
    this.infoMapasService
      .getSVG(
        this.espectaculosService.espectaculoSeleccionadoValue.chNombreFicheroSVG
      )
      .subscribe((data: any) => {
        this.width = d3
          .select('.asientos')
          .node()
          .getBoundingClientRect().width;
        this.height = d3
          .select('.asientos')
          .node()
          .getBoundingClientRect().height;

        /*         
        

        const height = 2.03 * this.height + 20;
        const width = 1.95 * this.width + 260; */

        this.svg = d3
          .select('svg')
          .attr('preserveAspectRatio', 'xMidYMid meet')
          .attr(
            'viewBox',
            '0 0 ' + this.width * 1.2 + ' ' + this.height * 1.75
          )
          .attr('width', this.width)
          .attr('height', this.height)
          .append('g')
          .attr('transform', 'translate(0,0) scale(1)');

        // 

        this.recinto = this.svg
          .append('g')
          .attr('id', 'recinto')
          .attr('x', '0')
          .attr('y', '0')
          .attr('transform', 'translate(' + this.width / 2 + ',0) scale(1)');
        const rec = document.getElementById('recinto');
        if (rec) {
          rec.insertAdjacentHTML('beforeend', data);
        }

        this.zoom = d3
          .zoom()
          .scaleExtent([1, 10])
          .on('zoom', () => {
            this.recinto.attr('transform', d3.event.transform);
          });
        this.svg.call(this.zoom);
        this.svg.call(this.zoom.transform, d3.zoomIdentity);
        this.butacasService.getEstadosButacas(
          this.recinto,
          this.espectaculosService.espectaculoSeleccionadoValue
            .chNombreFicheroSVG
        );
      });
  }

  zoomIn() {
    this.svg.transition().duration(250).call(this.zoom.scaleBy, 2);
  }

  zoomOut() {
    this.svg.transition().duration(250).call(this.zoom.scaleBy, 0.5);
  }

  reset() {
    this.svg
      .transition()
      .duration(250)
      .call(this.zoom.transform, d3.zoomIdentity);
  }

  panUp() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, 0, 100);
  }

  panDown() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, 0, -100);
  }

  panLeft() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, 100, 0);
  }

  panRight() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, -100, 0);
  }

  diagonalTopRight() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, -50, 50);
  }
  diagonalTopLeft() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, 50, 50);
  }

  diagonalBottomRight() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, -50, -50);
  }

  diagonalBottomLeft() {
    this.svg.transition().duration(250).call(this.zoom.translateBy, 50, -50);
  }
}
