import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenManagerService } from '../auth/index';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private tokenManager: TokenManagerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentToken = this.tokenManager.currentTokenValue;
        if (currentToken && currentToken.token) {
            const ClienteApi = {
                Id: currentToken.sessionId,
                Token: currentToken.token
            };
            request = request.clone({
                body: {...request.body, ClienteApi}
            });
        }
        return next.handle(request);
    }
}
