import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncriptaService {
  constructor() {}


  static encripta(cadena) {
    let encodeStr = '';
    const clave = '%ü&/@#$A·33424kjfdsfffsñdlfjdfksdfi234';

    for (let i = 0; i < cadena.length; i++) {
      const sCodigo = clave[this.fmod(i, clave.length)];
      encodeStr =
        // tslint:disable-next-line: no-bitwise
        encodeStr + ('0' + (sCodigo.charCodeAt(0) ^ cadena.charCodeAt(i)).toString(16)).substr(-2);
    }
    return encodeStr;
  }

  static fmod(a, b) {
    return Number((a - Math.floor(a / b) * b).toPrecision(8));
  }
  
  static funcionEncript(json) {
    const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
    let final = '';
    for (let index = 1; index <= json.length; index++) {
      const tokenMio = this.Mid(json, index, 1);
      const codigo = this.Mid(clavePrivada, ((index - 1) % clavePrivada.length) + 1, 1);
      // tslint:disable-next-line:no-bitwise
      const a = (this.Asc(codigo) ^ this.Asc(tokenMio)).toString(16);
      final = final + this.Right('0' + a, 2);
    }
    return final;
  }

  static funcionDesencript(token) {
    const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
    let final = '';
    let j = 1;
    for (let index = 1; index <= token.length; index += 2) {
      const tokenMio = this.Mid(token, index, 2);
      const codigo = this.Mid(clavePrivada, ((j - 1) % clavePrivada.length) + 1, 1);
      // tslint:disable-next-line:no-bitwise
      final = final + this.Chr(this.Asc(codigo) ^ (parseInt('0x' + tokenMio, 16)));
      j = j + 1;
    }
    return final;
  }

  static Mid(strMid, intBeg, intEnd) {
    if (strMid === null || strMid === '' || intBeg < 0) {
      return '';
    }
    intBeg -= 1;
    if (intEnd === null || intEnd === '') {
      return strMid.substr(intBeg);
    } else {
      return strMid.substr(intBeg, intEnd);
    }
  }

  static Chr(num) {
    const res = String.fromCharCode(num);
    return res;
  }

  static Asc(str) {
    return str.charCodeAt(0);
  }

  static Right(str, n) {
    if (n <= 0) {
      return '';
    } else if (n > String(str).length) {
      return str;
    } else {
      const iLen = String(str).length;
      return String(str).substring(iLen, iLen - n);
    }
  }
}
