import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { NumpadService } from './numpad.service';
import { MotivoPadService } from './motivo-pad.service';
import { Router } from '@angular/router';
import { AsociadoService } from 'src/app/modules/Asociado/asociado.service';
import { DesglosarCambioService } from 'src/app/modules/gestionar/services/desglosar-cambio.service';

export interface FormularioSeleccionado {
  form: FormGroup;
  elemento: any;
  tipo: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormControlService {
  private DECIMAL_DIVIDER = ',';
  private MAX_ENTEROS = 10;
  private MAX_DECIMALES = 2;
  // CANTIDAD

  // ALFANUMERICO

  // MONEDA

  // MOTIVO

  // 1. el usuario hace focus y se pone el formulario en el formcontrol con un tipo
  // 2. el usuario pincha a una tecla de los componentes teclado o numpad
  // 3. la tecla se envia al servicio control de formulario
  // 4. segun el tipo de formulario al que se ha clicado, se llamara al servicio de teclado o de pad para validar
  //  4.a ALFANUMERICO EN KEYBOARD
  //  4.b MONEDA Y CANTIDAD EN NUMPAD
  // 5. Una vez el input se valida correctamente, se devuelve la información al formgroup
  // 6. Se añade la información al formgroup

  private currentFormGroupSubject: BehaviorSubject<FormularioSeleccionado>;
  public currentFormGroup: Observable<any>;

  constructor(
    private numpadService: NumpadService,
    private motivoPadService: MotivoPadService,
    private asociadoService: AsociadoService,
    private desgloseService:DesglosarCambioService,
    private router: Router
  ) {
    this.currentFormGroupSubject = new BehaviorSubject(undefined);
    this.currentFormGroup = this.currentFormGroupSubject.asObservable();
  }

  public get currentFormGroupValue() {
    return this.currentFormGroupSubject.value;
  }

  public setCurrentFormGroup(formGroup: FormGroup, elemento: any, tipo = 'ALFANUMERICO') {
    const formularioSeleccionado: FormularioSeleccionado = {
      form: formGroup,
      elemento: elemento,
      tipo: tipo
    };
    this.currentFormGroupSubject.next(formularioSeleccionado);
  }

  inputChar(char: string) {
    
    if (this.currentFormGroupValue.tipo === 'ASOCIADO') {
      this.asociadoService.setNumpadOrder(char);
      return;
    }
    if (this.currentFormGroupValue.tipo === 'MOTIVO') {
      this.motivoPadService.setNumpadOrder(char);
      return;
    }
    if (this.currentFormGroupValue.tipo === 'CANTIDAD') {
      this.numpadService.setNumpadOrder(char);
      return;
    }
    if (this.currentFormGroupValue.tipo === 'DESGLOSE') {
      if(this.validarNumero(char)){
        

        //this.desgloseService.setNumpadOrder(char);
        let nuevoValor = this.newValue(char)
        if(nuevoValor.length > 4)
        {
          nuevoValor = nuevoValor.substring(0,4);
        }
        this.desgloseService.addMoneda(nuevoValor,this.currentFormGroupValue.elemento);

      //   this.currentFormGroupValue.form.controls[
      //    this.currentFormGroupValue.elemento.getAttribute('ng-reflect-name')
      //  ].setValue(nuevoValor);

      //this.currentFormGroupValue.elemento.value = (nuevoValor);
      //this.currentFormGroupValue.elemento.value = (this.newValue(char));

      //  this.currentFormGroupValue.form.controls[
      //    this.currentFormGroupValue.elemento.getAttribute('formcontrolname')
      //  ].patchValue(this.newValue(char));

        }
      return;
    }
    if (this.validar(this.currentFormGroupValue.tipo, char)) {
      this.currentFormGroupValue.form.controls[
        this.currentFormGroupValue.elemento.getAttribute('formcontrolname')
      ].patchValue(this.newValue(char));
      return;
    }
  }

  deleteChar() {
    
    if (this.currentFormGroupValue.tipo === 'ASOCIADO') {
      this.asociadoService.setNumpadOrder('delete');
      return;
    }
    if (this.currentFormGroupValue.tipo === 'MOTIVO') {
      this.motivoPadService.setNumpadOrder('delete');
      return;
    }    if (this.currentFormGroupValue.tipo === 'DESGLOSE') {
      
      this.desgloseService.setNumpadOrder('delete');
      return;
    }
    if (this.currentFormGroupValue.tipo === 'CANTIDAD') {
      this.numpadService.setNumpadOrder('delete');
      return;
    }
    this.currentFormGroupValue.form.controls[
      this.currentFormGroupValue.elemento.getAttribute('formcontrolname')
    ].patchValue(
      this.currentFormGroupValue.elemento.value.substring(
        0,
        this.currentFormGroupValue.elemento.value.length - 1
      )
    );
  }

  setToBlank() {
    
    if (this.currentFormGroupValue.tipo === 'ASOCIADO') {
      this.asociadoService.setNumpadOrder('reset');
      return;
    }
    if (this.currentFormGroupValue.tipo === 'DESGLOSE') {
      
      this.asociadoService.setNumpadOrder('reset');
      return;
    }
    if (this.currentFormGroupValue.tipo === 'MOTIVO') {
      this.motivoPadService.setNumpadOrder('reset');
      return;
    }
    if (this.currentFormGroupValue.tipo === 'CANTIDAD') {
      this.numpadService.setNumpadOrder('reset');
      return;
    }
    if (this.currentFormGroupValue.form && this.currentFormGroupValue.form.controls) {
      this.currentFormGroupValue.form.controls[
        this.currentFormGroupValue.elemento.getAttribute('formcontrolname')
      ].patchValue('');
    }
  }

  unfocus() {
    
    if (this.currentFormGroupValue && this.currentFormGroupValue.tipo === 'ASOCIADO') {
      this.currentFormGroupSubject.next(null);
      return;
    }
    if (this.currentFormGroupValue && this.currentFormGroupValue.tipo === 'DESGLOSE') {
      
      this.desgloseService.setNumpadOrder('losefocus');
      this.currentFormGroupSubject.next(null);
      return;
    }
    if (this.currentFormGroupValue && this.currentFormGroupValue.tipo === 'MOTIVO') {
      // this.motivoPadService.setNumpadOrder('losefocus');
      this.currentFormGroupSubject.next(null);
      return;
    }
    if (this.currentFormGroupValue && this.currentFormGroupValue.tipo === 'CANTIDAD') {
      this.numpadService.setNumpadOrder('losefocus');
      this.currentFormGroupSubject.next(null);
      return;
    }
    if (this.currentFormGroupValue && this.currentFormGroupValue.form) {
      this.currentFormGroupValue.form.reset();
    }
    this.currentFormGroupSubject.next(null);
  }

  private newValue(char: string) {
    return this.currentFormGroupValue.elemento.value + char;
  }

  validar(type, char) {
    if (
      this.currentFormGroupSubject.value.elemento.id === 'localizador' ||
      this.currentFormGroupSubject.value.elemento.id === 'horaEmision'
    ) {
      return false;
    }

    switch (type) {
      case 'ALFANUMERICO':
        return this.validarAlfanumerico(char);
      case 'TEXTAREA':
        return this.validarLargo(50);
      case 'DIVISA':
        return this.validarDivisa(char);
      case 'BOLSACAMBIO':
        return this.validarLargo(10);
      case 'FILTER':
        return true;
      case 'default':
        return this.validarAlfanumerico(char);
    }
  }

  validarAlfanumerico(char: string) {
    if (char === ',' || char === '-') {
      return false;
    }
    return true;
  }

  validarDivisa(char) {
    const valorAntiguo = this.currentFormGroupValue.elemento.value;
    const valorNuevo = this.currentFormGroupValue.elemento.value + char;

    if (this.router.url === '/turno/consulta' && char === '-') {
      return true;
    }

    if (valorAntiguo.substr(-1) === this.DECIMAL_DIVIDER && char === this.DECIMAL_DIVIDER) {
      return false;
    }
    if (valorNuevo.split(this.DECIMAL_DIVIDER).length > 2) {
      return false;
    }
    if (valorAntiguo) {
      const valorEnteros = valorAntiguo.split(this.DECIMAL_DIVIDER)[0];
      const valorDecimales = valorAntiguo.split(this.DECIMAL_DIVIDER)[1] || '';
      if (
        valorEnteros.length === this.MAX_ENTEROS &&
        char !== this.DECIMAL_DIVIDER &&
        !valorNuevo.split(this.DECIMAL_DIVIDER)[1]
      ) {
        return false;
      }
      if (valorDecimales.length === this.MAX_DECIMALES) {
        return false;
      }
    }
    if (
      !Number.parseFloat(this.currentFormGroupValue.elemento.value + char) &&
      Number.parseFloat(this.currentFormGroupValue.elemento.value + char) !== 0
    ) {
      return false;
    }
    return true;
  }

  validarLargo(numeroMaximoDeCaracteres: number) {
    if (this.currentFormGroupValue.elemento.value.length === numeroMaximoDeCaracteres) {
      return false;
    }
    return true;
  }

  validarNumero(char: string){
    const regex = /^\d+$/i;
    if(regex.test(char)){
      return true;
    }
    return false;
  }
}
