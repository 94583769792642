import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterContentInit
} from '@angular/core';
import { CellSesionesComponent } from '../cell-sesiones/cell-sesiones.component';

@Component({
  selector: 'ticketing-panel-sesiones',
  templateUrl: './panel-sesiones.component.html',
  styleUrls: ['./panel-sesiones.component.scss']
})
export class PanelSesionesComponent implements AfterContentInit {
  @ContentChildren(CellSesionesComponent) cells: QueryList<
    CellSesionesComponent
  >;

  @Input() opened = false;
  @Input() title: string;
  @Input() qty: number;
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngAfterContentInit() {
    this.cells.forEach(t => {
      t.toggle.subscribe(() => {
        this.selectCell(t);
      });
    });
  }

  selectCell(cell: CellSesionesComponent) {}
}
