export class PrinterUtils {

    public pdfToBinary(pdf) {
        return this.arrayBufferToBase64(pdf.output('arraybuffer'));
    }

    private arrayBufferToBase64(buffer: Iterable<number>) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}
