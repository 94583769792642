import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ticketing-albaran-previo',
  templateUrl: './albaran-previo.component.html',
  styleUrls: ['./albaran-previo.component.scss']
})
export class AlbaranPrevioComponent implements OnInit {

  @Output() movimiento = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  retrocederAlbaran() {
    this.movimiento.emit(-1);
  }

}
