import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserManagerService } from '../auth/user-manager.service';
import { PermisosService } from '../security/permisos.service';
import { UsuarioElevadoService } from '../security/usuario-elevado.service';

@Injectable({
  providedIn: 'root'
})
export class PermisosGuard implements CanActivate {
  constructor(private router: Router, private permisoService: PermisosService, private user: UserManagerService, private usuarioElevadoService: UsuarioElevadoService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let permiso = this.usuarioElevadoService.haSidoElevadoValue ? this.usuarioElevadoService.haSidoElevadoValue: this.permisoService.puedeAccionar(
        this.user.currentUserValue,
        'TurnoConsultar',
        {
          toast: true,
          elevarUsuario: false
        }
      ) || false;
      
      return permiso;
    

  }
  
}
