import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { VentaService } from '../../services/venta.service';
import { EntradasService } from '../../services/entradas.service';
import { PagerService } from 'src/app/shared/services/pager.service';
import { MotivosService } from '../../services/motivos.service';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ticketing-selector-motivos',
  templateUrl: './selector-motivos.component.html',
  styleUrls: ['./selector-motivos.component.scss']
})
export class SelectorMotivosComponent implements OnInit, AfterViewChecked, OnDestroy {
  motivos = [];
  currentMotivo;
  currentMotivoIndex = 0;
  currentClienteIndex = 1;
  todosLosMotivosAplicados;
  aplicarTodos;

  carrito;
  pager: any = {};
  pagedItems: any[];

  private alive = true;

  constructor(
    private venta: VentaService,
    private entradaService: EntradasService,
    private motivosService: MotivosService,
    private pagerService: PagerService,
    private navigationService: StepperNavigationService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.carrito = this.venta.carrito
      .pipe(takeWhile(() => this.alive))
      .subscribe(carrito => (this.carrito = carrito));
    this.motivosService.motivosValue.forEach(motivo => {
      this.entradaService
        .getDescuentos(motivo.GrupoMotivoId,1) //Yaribel 20201224 Añadimos solicitar solo los seleccionables 
        .pipe(takeWhile(() => this.alive))
        .subscribe((res: any) => {
          if (res !== null) {
            this.motivos.push({
              motivo: motivo,
              descuentosAplicables: res
            });
          }
          this.currentMotivo = this.motivos[this.currentMotivoIndex];
        });
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  next() {
    if (this.comprobarLimiteNext()) {
      this.navigationService.next();
      return;
    }
    if (this.currentMotivo.motivo.cantidad === this.currentClienteIndex || this.aplicarTodos) {
      this.nextEntrada();
    } else {
      this.currentClienteIndex++;
    }
  }

  comprobarLimiteNext() {
    return (
      (this.currentMotivoIndex === this.motivos.length - 1 && this.aplicarTodos) ||
      (this.currentMotivoIndex === this.motivos.length - 1 &&
        this.currentClienteIndex === this.currentMotivo.motivo.cantidad)
    );
  }

  nextEntrada() {
    this.currentMotivoIndex++;
    this.currentMotivo = this.motivos[this.currentMotivoIndex];
    this.currentClienteIndex = 1;
    this.aplicarTodos = this.comprobarAplicarTodos();
  }

  prev() {
    if (this.comprobarLimitePrev()) {
      return;
    }
    if (this.currentClienteIndex !== 1 && !this.aplicarTodos) {
      this.currentClienteIndex--;
    } else {
      this.prevEntrada();
    }
  }

  comprobarLimitePrev() {
    return (
      (this.currentMotivoIndex === 0 && this.aplicarTodos) ||
      (this.currentClienteIndex === 1 && this.currentMotivoIndex === 0)
    );
  }

  prevEntrada() {
    this.currentMotivoIndex--;
    this.currentMotivo = this.motivos[this.currentMotivoIndex];
    this.currentClienteIndex = this.aplicarTodos ? 1 : this.currentMotivo.motivo.cantidad;
    this.aplicarTodos = this.comprobarAplicarTodos();
  }

  comprobarAplicarTodos() {
    const lineaEntradaSeleccionada = this.venta.carritoValue.entradas.find(
      linea => linea.entrada === this.currentMotivo.motivo.Entrada
    );
    return lineaEntradaSeleccionada.descuentos.find(dto => dto.todos);
  }

  aplicarDescuentoMotivo(descuento) {
    const lineaEntradaSeleccionada = this.venta.carritoValue.entradas.find(
      linea => linea.entrada === this.currentMotivo.motivo.Entrada
    );
    if (this.aplicarTodos) {
      lineaEntradaSeleccionada.descuentos = [];
      lineaEntradaSeleccionada.descuentos.push({
        numeroCliente: 0,
        motivoDescuento: descuento,
        todos: true
      });
    } else {
      const hayDescuentoMotivo = lineaEntradaSeleccionada.descuentos.find(
        dto => dto.numeroCliente === this.currentClienteIndex
      );
      if (hayDescuentoMotivo) {
        lineaEntradaSeleccionada.descuentos[
          lineaEntradaSeleccionada.descuentos.indexOf(hayDescuentoMotivo)
        ] = { numeroCliente: this.currentClienteIndex, motivoDescuento: descuento };
      } else {
        lineaEntradaSeleccionada.descuentos.push({
          numeroCliente: this.currentClienteIndex,
          motivoDescuento: descuento
        });
      }
    }
    this.next();
  }

  onOffSwitch() {
    if (this.aplicarTodos) {
      const lineaEntradaSeleccionada = this.venta.carritoValue.entradas.find(
        linea => linea.entrada === this.currentMotivo.motivo.Entrada
      );
      lineaEntradaSeleccionada.descuentos = [];
      this.currentClienteIndex = 1;
    }
    this.aplicarTodos = !this.aplicarTodos;
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.motivos.length, page, 24);
    this.pagedItems = this.motivos.slice(this.pager.startIndex, this.pager.endIndex + 1);

    this.pager.pages = this.pager.pages.filter(num => num <= this.pager.totalPages);

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  isSelected(descuento) {
    const lineaEntradaSeleccionada = this.venta.carritoValue.entradas.find(
      linea => linea.entrada === this.currentMotivo.motivo.Entrada
    );
    let descuentoAplicado;
    if (this.comprobarAplicarTodos()) {
      descuentoAplicado = lineaEntradaSeleccionada.descuentos.find(dto => dto.numeroCliente === 0);
      this.aplicarTodos = true;
    } else {
      descuentoAplicado = lineaEntradaSeleccionada.descuentos.find(
        dto => dto.numeroCliente === this.currentClienteIndex
      );
    }
    if (!descuentoAplicado) {
      return;
    }
    return descuentoAplicado.motivoDescuento.PkId === descuento.PkId;
  }
}
